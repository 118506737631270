import { LIST_CHANNELS, BEFORE_CHANNEL, CHANNEL_ANALYTICS, BEFORE_CHANNEL_ANALYTICS, UPDATE_CHANNEL_DETAILS, GET_CHANNEL, GET_CHANNEL_VIDEOS, GET_CHANNEL_DETAILS, SUBSCRIBE_UNSUBSCRIBE_CHANNEL, BEFORE_CHANNEL_SUBS } from '../../../redux/types';

const initialState = {
    listChannels: null,
    listChannelsAuth: false,

    getChannelData: null,
    getChannelAuth: false,

    getChannelDetailsData: null,
    getChannelDetailsAuth: false,

    getChannelVideosData: null,
    getChannelVideosAuth: false,

    updateDetailsData: null,
    updateDetailsAuth: false,

    channelAnalyticsAuth: false,
    channelAnalyticsData: null,

    subscribeUnsubscribeData: null,
    subscribeUnsubscribeAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_CHANNELS:
            return {
                ...state,
                listChannels: action.payload,
                listChannelsAuth: true
            }
        case LIST_CHANNELS:
            return {
                ...state,
                listChannels: action.payload,
                listChannelsAuth: true
            }
        case GET_CHANNEL:
            return {
                ...state,
                getChannelData: action.payload,
                getChannelAuth: true
            }
        case GET_CHANNEL_DETAILS:
            return {
                ...state,
                getChannelDetailsData: action.payload,
                getChannelDetailsAuth: true
            }
        case GET_CHANNEL_VIDEOS:
            return {
                ...state,
                getChannelVideosData: action.payload,
                getChannelVideosAuth: true
            }
        case UPDATE_CHANNEL_DETAILS:
            return {
                ...state,
                updateDetailsData: action.payload,
                updateDetailsAuth: true
            }
        case BEFORE_CHANNEL:
            return {
                ...state,
                listChannels: null,
                listChannelsAuth: false,

                updateDetailsData: null,
                updateDetailsAuth: false,

                getChannelData: null,
                getChannelAuth: false,

                getChannelVideosData: null,
                getChannelVideosAuth: false,

                getChannelDetailsData: null,
                getChannelDetailsAuth: false
            }
        case CHANNEL_ANALYTICS:
            return {
                ...state,
                channelAnalyticsData: action.payload,
                channelAnalyticsAuth: true
            }
        case BEFORE_CHANNEL_ANALYTICS:
            return {
                ...state,
                channelAnalyticsData: null,
                channelAnalyticsAuth: false,
            }
        case SUBSCRIBE_UNSUBSCRIBE_CHANNEL:
            return {
                ...state,
                subscribeUnsubscribeData: action.payload,
                subscribeUnsubscribeAuth: true
            }
        case BEFORE_CHANNEL_SUBS:
            return {
                ...state,
                subscribeUnsubscribeData: null,
                subscribeUnsubscribeAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}