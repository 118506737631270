import React, { useState, useEffect } from 'react';
import './siteTabs.css';
import { FaSearch } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { getAllPublicVideoContent, beforePublicVideoContent } from '../Videos/video.actions';
import { useNavigate } from 'react-router-dom';
import { ENV } from '../../../../config/config';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import moment from 'moment';

const Research = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = ENV.getUserKeys('_id')?._id
    const videoSelector = useSelector((state) => state.videos)

    const [loadMore, setLoadMore] = useState(false)
    const [searchedVideos, setVideos] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [loader, setLoader] = useState(false)
    const [searched, setSearched] = useState(false)
    const [pagination, setPagination] = useState({})

    useEffect(() => {
        if (videoSelector.publicVideoContentAuth) {
            setLoader(false)
            let data = videoSelector.publicVideoContent
            setVideos(loadMore ? searchedVideos.concat(data?.videos) : data?.videos)
            setPagination(data?.pagination);
            setLoadMore(false)
            dispatch(beforePublicVideoContent());
        }

    }, [videoSelector.publicVideoContentAuth, videoSelector.videoCategoriesAuth]);

    useEffect(() => {
        if (!searchTerm) {
            setSearched(false)
            setVideos([])
            setPagination({})
        }

    }, [searchTerm])

    useEffect(() => {
        if (props.date.startDate && props.date.endDate) {
            if (props.selected.value !== 'custom' && searched)
                searchVideos()

            if (props.searchCustom)
                searchVideos()

        }
    }, [props.date, props.searchCustom])

    const searchVideos = () => {
        if (!searchTerm)
            return
        setLoader(true)
        setSearched(true)

        getPublicVideos()
    }

    const loadMoreVideos = () => {
        setLoadMore(true)
        setLoader(true)
        setSearched(true)
        const { page } = pagination
        getPublicVideos(page + 1)
    }

    const getPublicVideos = (page = '') => {
        let payload = { userId }

        if (searchTerm)
            payload.searchTerm = encodeURIComponent(searchTerm)

        if (props.date.startDate && props.date.endDate) {
            let { startDate, endDate } = props.date
            payload = { ...payload, startDate: moment(new Date(startDate)), endDate: moment(new Date(endDate)) }
        }

        if (page)
            payload.page = page

        const qs = ENV.objectToQueryString(payload);

        dispatch(getAllPublicVideoContent(qs))
    }
    return (
        <>
            {loader && <FullPageLoader />}
            <div className={searchedVideos?.length ? 'dashboardPage' : ''}>
                <div className='analyticResearch'>
                    <div className='topRes'>
                        <h4>Explore what viewers are searching for on BiiView</h4>
                        <p className="Tab-sofia-p">Use this tool to research some of the top searches from viewers on your channel and across YouTube in the last 28 days</p>
                    </div>
                    <div className='researchForm'>
                        <input
                            type='text'
                            placeholder='Search videos with title, keywords or description'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={(e) => { if (e.key === 'Enter') searchVideos() }}
                        />
                        <span className='researchIcon pointer' onClick={searchVideos}><FaSearch /></span>
                    </div>
                </div>
            </div>
            <div className='wrapVideos mt-4'>
                {
                    searchedVideos && searchedVideos?.length ?
                        searchedVideos.map((video, index) => (
                            <div className='exploreCard' key={index}>
                                <div className='exploreImg pointer' onClick={() => navigate(`/media-player/${video.slug}/${video.channelName?.toLowerCase()}`)} >
                                    <img className='img-fluid' src={video.thumbnails[0].localUrl} alt={video.name}></img>
                                </div>
                                <div className='cardFooter'>
                                    <div className='userImg me-3'>
                                        <img className='img-fluid' src={video.user.profileImage} alt={video.user.name}></img>
                                    </div>
                                    <div className='videoReview pointer' onClick={() => navigate(`/media-player/${video?.slug}/${video?.channelName?.toLowerCase()}`)}>
                                        <p>{video.title}</p>
                                        <span>{video?.views ? video.views : 0} {video?.views > 1 ? 'Views' : 'View'}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        null
                }
            </div>
            {
                searched ?
                    <h4 className='text-center'>No Matching Video Found</h4>
                    : null
            }
            {
                pagination.total > searchedVideos?.length ?
                    <div className='text-center w-100 d-block'>
                        <button className="orange-btn text-uppercase mx-auto" onClick={loadMoreVideos}>Load more</button>
                    </div>
                    :
                    null
            }
        </>
    )
}
export default Research