import React, { useState } from 'react';
import { useEffect } from 'react';
import { ENV } from '../../../../config/config';
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import '../../../Frontend/Player/player.css';
import { FaPlayCircle } from 'react-icons/fa';
// import Favourite from './FavouriteComponent';
import { addRemoveFavourites, beforeFavourites, listUserFavourites } from '../users.actions';
// import { favouriteVideosSection } from '../../ApplicationTour/TourCompIdentifiers';
import { FcLike } from 'react-icons/fc';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
const { defaultPlaceholderImage } = ENV

const UserFavourites = (props) => {
    const dispatch = useDispatch()
    const userId = ENV.getUserKeys('_id')?._id

    const [loader, setLoader] = useState(true);
    const [userFavourites, setFavourites] = useState([])
    const [pagination, setPagination] = useState({})
    const [loadMore, setLoadMore] = useState(false);

    const userSelector = useSelector((state) => state.user)

    useEffect(() => {
        if (userId) {
            dispatch(listUserFavourites(`userId=${userId}`))
        }
    }, [])


    useEffect(() => {
        if (userSelector.listUserFavouritesAuth) {
            let { favourites, pagination } = userSelector.listUserFavourites?.data
            setFavourites(loadMore ? userFavourites.concat(favourites) : favourites)
            setPagination(pagination)
            setLoader(false)
            setLoadMore(false)
            dispatch(beforeFavourites())

        }
    }, [userSelector.listUserFavouritesAuth])

    const loadMoreVideos = () => {
        setLoadMore(true)
        setLoader(true)

        const { page } = pagination
        dispatch(listUserFavourites(`userId=${userId}&page=${page + 1}`))
    }

    useEffect(() => {
        console.log('userFavourites videos', userFavourites)
    }, [userFavourites])

    const callback = (id) => {
        setLoader(true)
        dispatch(addRemoveFavourites({userId, videoId: id, type: 2 }))

        // dispatch(listUserFavourites(`userId=${userId}&page=${pagination.page + 1}`))
    }

    useEffect(() => {
        if (userSelector.addRemoveFavouriteAuth) {
            // let { favourites, pagination } = userSelector.listUserFavourites?.data
            dispatch(listUserFavourites(`userId=${userId}&page=${pagination.page + 1}`))
            setLoader(true)
            // setLoadMore(false)
            dispatch(beforeFavourites())

        }
    }, [userSelector.addRemoveFavouriteAuth])


    return (
        <>
            {
                loader &&
                <FullPageLoader/>
            }
            <section className="explore explore-sec pt-0">
                <div className='v-custom-sitecards recomemded-videos'>
                    <div className="dashboardHeader">
                        <h2 id="watch-history-step" className="text-uppercase">Your Favourite Videos</h2>
                    </div>
                    {/* <h5 className='' id={favouriteVideosSection}>Your Favourite Videos</h5> */}
                    <div className="wrapVideos">
                        {
                            userFavourites && userFavourites?.length ?
                                userFavourites.map((fav, index) => (
                                    // <Col xl={4} md={6} key={index}>
                                        <div className='exploreCard'>
                                            <div className='exploreImg' >
                                                <a href={`/media-player/${fav.video?.slug}/${fav.video?.channel?.name?.toLowerCase()}`} rel='noreferrer' className='position-relative'>
                                                    <img className='img-fluid video-img' src={fav.video.thumbnail || defaultPlaceholderImage} alt={'thumbnail'}></img>
                                                    <div className='player-icon'><FaPlayCircle /></div>
                                                </a>
                                            </div>
                                            
                                            {/* <Favourite
                                                _id={fav.video._id}
                                                count={fav.favouritesCount}
                                                userFavourite={fav.userFavourite}
                                                content={userFavourites}
                                                setContent={setFavourites}
                                                callback={callback}
                                            /> */}
                                            <div className='cardFooter'>
                                                <div className="d-flex mb-3">
                                                    <div className='userImg me-3'>
                                                        <img className='img-fluid' src={fav.video.channel?.image || defaultPlaceholderImage} alt={'channel'}></img>
                                                    </div>
                                                    <div className='videoReview'>
                                                        <p>{fav.video.title}</p>
                                                        <span>{fav.video.totalViews || 0}  {fav?.video.totalViews > 1 ? 'Views' : 'View'}</span>
                                                    </div>
                                                </div>
                                                <div className="make-video-favorite">
                                                    <button onClick={() => callback(fav.video._id)}>
                                                        <FcLike />
                                                        <span className="count">{fav.favouritesCount || ''}</span>
                                                    </button>                                       
                                                </div>
                                            </div>
                                        </div>
                                    // </Col>
                                )) :
                               ""
                        }
                    </div>
                    {userFavourites && userFavourites?.length ?
                        ""
                    :
                        <div className="no-record-found d-flex justify-content-center align-items-center">You have not added any video to your favourites!</div>
                    }
                    {
                        pagination.total > userFavourites?.length ?
                            <div className='text-center w-100 d-block'>
                                <button className="orange-btn text-uppercase mx-auto" onClick={loadMoreVideos}>Load more</button>
                            </div>
                            :
                            null
                    }
                </div>
            </section>
        </>
    );
}

export default UserFavourites