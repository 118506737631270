import React, { useState, useEffect } from 'react';
import './siteTabs.css';
import { Row, Col } from 'react-bootstrap'
import ReactECharts from 'echarts-for-react'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { beforeVideoAnalytics } from '../Videos/video.actions';

const Audience = (props) => {

    const dispatch = useDispatch()
    const colors = ['#5470C6', '#FD6F21'];
    const tabColors = {
        'grey': '#393C48',
        'black': '#0D0F17'
    }
    const [options, setOptions] = useState({
        color: colors,
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                type: 'line',
                color: colors[1],
                symbol: "circle"
            }
        ]
    })

    const [clickedStatOption, setClickedOption] = useState(1);
    const [analytics, setAnalytics] = useState({})
    const videosSelector = useSelector((state) => state.videos)

    useEffect(() => {
        if (videosSelector.videoAnalyticsAuth) {
            let data = videosSelector?.videoAnalyticsData
            if (data?.videoAnalytics && data?.statType == '3') {
                setAnalytics(data?.videoAnalytics)
            }

            props.setLoader(false)
            setClickedOption(1)
            dispatch(beforeVideoAnalytics())
        }
    }, [videosSelector.videoAnalyticsAuth])

    useEffect(() => {
        if (clickedStatOption && analytics) {
            props.setLoader(false)
            let optionsData = { ...options }
            let { returningAudienceStats, uniqueAudienceStats, identifiedObjects } = analytics
            let xAxisDates = []
            let yAxisValues = []

            let key = clickedStatOption === 1 ? returningAudienceStats : clickedStatOption === 2 ? uniqueAudienceStats : clickedStatOption === 3 ? identifiedObjects : null
            let dateIntervals = []
            let seriesData = []

            if (key)
                if (key.data?.length) {
                    let indexes = []
                    key.data.map((stat) => {

                        let statDate = moment(new Date(stat.date)).format('DD-MMM-YY')
                        xAxisDates.push(statDate)
                        yAxisValues.push(stat.total);

                        dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate, 1, yAxisValues.length)
                        seriesData = new Array(dateIntervals.length).fill(0)
                        dateIntervals.map((dt, indx) => {
                            if (dt === statDate)
                                indexes.push(indx)
                        })
                    })
                    indexes.map((i, ind) => {
                        seriesData[i] = yAxisValues[ind]
                    })
                }
                else {
                    dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate)
                    seriesData = new Array(dateIntervals.length).fill(0)
                }

            optionsData.xAxis = { ...optionsData.xAxis, data: dateIntervals }
            optionsData.series = [
                {
                    ...optionsData.series[0],
                    name: clickedStatOption === 1 ? 'Returning Audience' : clickedStatOption === 2 ? 'Returning Audience' : clickedStatOption === 3 ? 'Recognized Objects' : '',
                    data: seriesData

                }
            ]

            setOptions(optionsData)
        }
    }, [clickedStatOption, analytics])

    const statOptionsChangeHandler = (option) => {
        setClickedOption(option)
        props.setLoader(true)
    }

    return (
        <>
            <div className='dashboardPage'>
                <div className='analyticContent'>
                    <div className='analyticGraph'>
                        {
                            analytics &&
                            <div className='analyticsBar'>
                                <div className={`analyticsBarBox pointer ${clickedStatOption === 1 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(1)}>
                                    <span>Returning Viewers</span>
                                    <h4>{analytics.returningAudienceStats ? analytics.returningAudienceStats?.total : 0}</h4>
                                </div>
                                <div className={`analyticsBarBox pointer ${clickedStatOption === 2 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(2)}>
                                    <span>Unique Viewers</span>
                                    <h4>{analytics.uniqueAudienceStats ? analytics.uniqueAudienceStats?.total : 0}</h4>
                                </div>
                                <div className={`analyticsBarBox pointer ${clickedStatOption === 3 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(3)}>
                                    <span>Recognized Objects</span>
                                    <h4>{analytics.identifiedObjects ? analytics.identifiedObjects?.total : 0}</h4>
                                </div>
                            </div>
                        }
                        <div className='graphItems mt-4 mx-4'>
                            <ul>
                                {
                                    clickedStatOption === 1 ?
                                        <li className='orange'><div className='orange'><p>Returning Viewers</p></div></li> :
                                        <li><div className='blue'><p>New Viewers</p></div></li>

                                }
                            </ul>
                        </div>
                        <ReactECharts option={options} />
                    </div>
                    {/* <Row className='tabBox'>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Watch time from subscribers</h4>
                                        <p className='md-P mb-0'>Watch time · Last 28 days</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2 op6'>Nothing to show for these dates</p>
                                    <div>
                                        <button className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Age and gender</h4>
                                        <p className='md-P mb-0'>Views · Last 28 days</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2 op6'>Not enough demographic data to show this report</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Top geographies</h4>
                                        <p className='md-P mb-0'>Views · Last 28 days</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2'>Geographic data for private and unlisted videos is no longer available</p>
                                    <div>
                                        <button className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Top subtitle/CC languages</h4>
                                        <p className='md-P mb-0'>Views · Last 28 days</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2'>Nothing to show for these dates</p>
                                    <div>
                                        <button className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                </div>
            </div>
        </>
    )
}
export default Audience