import React, { useEffect, useState } from 'react'
import ApplicationTour from '../ApplicationTour/ApplicationTours'
// import Header from '../Header/Header'
import MainSidebar from '../UserDashboard/MainSidebar/MainSidebar'

const Dashboard = ({ title, children }) => {

    const [sidebar, setSidebar] = useState(true)
    const location = window.location.pathname.split('/')[1]

    useEffect(() => {
        if (title)
            document.title = 'BiiView | ' + title;
        else
            document.title = "BiiView";
    }, [title]);

    return (
        <>
            {/* <Header /> */}
            <MainSidebar sidebar={sidebar} setSidebar={setSidebar} />
            <div className={sidebar ? 'sitePages  sitePages-main-width' : 'sitePages sitePages-width'}>
                {children}
            </div>
            {
                localStorage.getItem('encuse') ?
                    < ApplicationTour /> :
                    null
            }
        </>
    )
}

export default Dashboard