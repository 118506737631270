import React, { useState, useEffect } from 'react';
import '../../Forms/Forms.css'
import './Package.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { listPlans, beforePlans } from '../package.actions';
import { getUser, beforeUser } from '../../UserComponents/users.actions';
import PackageCard from './PackageCard';
import { ENV } from '../../../../config/config';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { selectPlanSection } from '../../ApplicationTour/TourCompIdentifiers';
import ApplicationTour from '../../ApplicationTour/ApplicationTours';

const Package = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const userId = ENV.getUserKeys('_id')?._id
    let channelId = localStorage.getItem('channelId')

    const [loader, setLoader] = useState(true)
    const [plans, setPlans] = useState([])
    const [planId, setId] = useState('')
    const [user, setUser] = useState({})

    const plansSelector = useSelector((state) => state.packages)
    const userSelector = useSelector((state) => state.user)
    const errorSelector = useSelector((state) => state.error)

    useEffect(() => {
        if (errorSelector) {
            setLoader(false)
        }
    }, [errorSelector])

    useEffect(() => {
        dispatch(listPlans())
        if (userId)
            dispatch(getUser(`userId=${userId}`))
    }, [])

    useEffect(() => {
        if (userSelector.getUserAuth) {
            let { data } = userSelector.getUserData
            setUser(data.user)
            setId(data.user.subscribedPlan.plan.id)
            dispatch(beforeUser())
        }
    }, [userSelector.getUserAuth])

    useEffect(() => {
        if (plansSelector.listPlansAuth) {
            let { plans } = plansSelector.listPlansRes.data
            setPlans(plans)
            setLoader(false)
            dispatch(beforePlans())
        }
    }, [plansSelector.listPlansAuth])

    useEffect(() => {
        if (plansSelector.subscribePlanAuth) {
            let data = plansSelector.subscribePlan

            let subscribedPlan = data?.subscribedPlan
            let userData = data?.user ? data.user.newUser : user
            // let channel = data?.user ? data.user.channelId : channelId
            let channelName = data?.user ? data.user.channelName : channelName

            dispatch(listPlans())
            setLoader(false)
            setId(subscribedPlan.planId)

            navigate(userData.type === 1 ? '/explore' : `/publisher-dashboard/${channelName}`)
            dispatch(beforePlans())
        }
    }, [plansSelector.subscribePlanAuth])

    return (
        <>
            {loader && <FullPageLoader />}
            <div className=''>
                <div className='biiview-form packages-sec'>
                    <Container fluid className='formContainer'>
                        <div className='wrapForm' style={{ display: "block", margin: "unset" }}>
                            <div className='formStyle package'>
                                <div className='formHeader border-0 mb-0 d-flex align-items-center justify-content-center pt-4'>
                                    <h2 id={selectPlanSection} className='text-uppercase mb-0'>Choose your Package</h2>
                                    {/* <p className="button mb-0 link text-uppercase" onClick={() => navigate(-1)}>back</p> */}
                                </div>
                                <Row>
                                    {
                                        plans && plans.length ?
                                            plans.map((plan, index) => {
                                                return (
                                                    <Col xl={4} md={6} key={index} className='mb-4'>
                                                        <PackageCard plan={plan} isPlanActive={planId === plan._id ? true : false} setLoader={setLoader} />
                                                    </Col>
                                                )
                                            }) :
                                            <p>No Package Found</p>
                                    }
                                </Row>

                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            {userId && <ApplicationTour />}
        </>
    )
}

export default Package;