import React, { useState, useEffect } from 'react'
import { ReactNetflixPlayer } from "react-netflix-player"
import { useNavigate, useParams, } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getVideo } from '../Videos/video.actions';
import { ENV } from '../../../../config/config';
import { beforeChannelRole, getChannelRole } from '../../ChannelRoles/channelRoles.actions';
import { Helmet } from 'react-helmet-async/lib/index.esm';

const PlayVideo = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { slug } = useParams()
    const userId = ENV.getUserKeys('_id')?._id
    const [video, setVideo] = useState({})
    const channelId = localStorage.getItem('channelId')

    const rolesSelector = useSelector((state) => state.roles)
    const videoSelector = useSelector((state) => state.videos)
    const errorSelector = useSelector((state) => state.error)

    useEffect(() => {
        if (errorSelector)
            navigate('/')
    }, [errorSelector])

    useEffect(() => {
        if (slug) {
            dispatch(getVideo(slug, `all=${true}`))
            dispatch(getChannelRole(`channelId=${channelId}&userId=${userId}`))
        }
        else
            navigate('/')
    }, [])

    useEffect(() => {
        if (videoSelector.getVideoAuth) {
            setVideo(videoSelector.getVideoData.video)
        }
    }, [videoSelector.getVideoAuth])

    useEffect(() => {
        if (rolesSelector.channelRoleAuth) {
            let role = rolesSelector.channelRoleData.memberRole
            dispatch(beforeChannelRole())
            if (!role.viewChannelVideos)
                navigate('/')
        }
    }, [rolesSelector.channelRoleAuth])

    return (
        <>
            {
                video?.metaTitle && video?.metaDescription &&
                <Helmet>
                    {video?.metaTitle && <title>{video?.metaTitle}</title>}
                    {video?.metaDescription && <meta name="description" content={video?.metaDescription} />}
                </Helmet>

            }
            <ReactNetflixPlayer
                playerLanguage='en'
                autoPlay={false}
                title={video.title || ''}
                titleMedia={video.title || 'Title'}
                src={video && video.video}
            />
        </>
    )
}

export default PlayVideo