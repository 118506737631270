import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { getInfoContent, beforeInformative } from './informative.actions'
import { useParams } from 'react-router-dom'
import FullPageLoader from '../FullPageLoader/FullPageLoader'

const InfoContent = () => {
    const { slug } = useParams()

    const [content, setContent] = useState({})
    const [loader, setLoader] = useState(true)

    const dispatch = useDispatch()
    const informativeSelector = useSelector((state) => state.informative)

    useEffect(() => {
        if (slug)
            dispatch(getInfoContent(slug))
    }, [slug])

    useEffect(() => {
        if (informativeSelector.infoContentAuth) {
            setContent(informativeSelector.infoContent?.cms)
            setLoader(false)
            dispatch(beforeInformative())
        }
    }, [informativeSelector.infoContentAuth])

    return (
        loader ? <FullPageLoader /> :
            <>
                {
                    content &&
                    <section className='help-sec faq-sec pb-0 informative-info-content'>
                        <Container className='position-relative'>
                            <h1><span className='theme'>{content.title}</span></h1>
                            <div dangerouslySetInnerHTML={{ __html: content.description }}></div>
                        </Container>
                    </section>
                }
            </>
    )
}

export default InfoContent