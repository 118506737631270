import React, { useEffect, useState } from 'react'
import { Images } from '../../../../assets/assets'
import { Button, Modal } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faCircleInfo, faEarthAsia, faFlag, faGlobe, faPlay, faShare, faUserPlus, faUserMinus, faUsers } from '@fortawesome/free-solid-svg-icons';
import './Details.css'
import moment from 'moment';
import SocialShare from '../../SocialShare/SocialShare';
import { ENV } from '../../../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { beforeChannels, beforeChannelSubscription, getChannelDetails, subscribeUnsubscribeChannel } from '../../MyChannels/channels.actions';
import Swal from 'sweetalert2';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';


function Details() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { channelName } = useParams()
	const userId = ENV.getUserKeys('_id')?._id
	const [loader, setLoader] = useState(false)
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [channelDetails, setChannelDetails] = useState({})
	const [hasSubscribed, setSubscribed] = useState(false)
	const channelSelector = useSelector((state) => state.channels)


	useEffect(() => {
		let qs = ``
		if (channelName)
			qs = `channelName=${encodeURIComponent(channelName)}`
		if (userId)
			qs = `${qs}&userId=${userId}`

		dispatch(getChannelDetails(qs))
	}, []);

	useEffect(() => {
		if (channelSelector.getChannelDetailsAuth) {
			let data = channelSelector.getChannelDetailsData?.channel
			setChannelDetails(data)

			setSubscribed(data.userSubscribed ? true : false)

			dispatch(beforeChannels())
		}
	}, [channelSelector.getChannelDetailsAuth])

	useEffect(() => {
		if (channelSelector.subscribeUnsubscribeAuth) {
			let type = channelSelector.subscribeUnsubscribeData?.type
			setSubscribed(type === 1 ? true : false)
			setLoader(false)
			dispatch(beforeChannelSubscription())
		}
	}, [channelSelector.subscribeUnsubscribeAuth])

	const subscribeUnsubscribeHandler = (type) => {
		if(!userId){
			navigate('/login')
			return 
		}
			
		if (type === 2) {
			Swal.fire({
				title: 'Unsubscribe',
				html: "Are you sure you want to unsubscribe? You might miss out on important updates.",
				showCloseButton: true,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			}).then(async (result) => {
				if (result.value) {
					setLoader(true)
					dispatch(subscribeUnsubscribeChannel({ channelName, userId, type }))
				}
			})
		}
		else {
			setLoader(true)
			dispatch(subscribeUnsubscribeChannel({ channelName, userId, type }))
		}

	}

	return (
		loader ? <FullPageLoader /> :
			<div id='channel-details'>
				<div className='channel-details-header'>
					<div className='channel-details-coverPhoto'>
						<img src={channelDetails.channelBannerImage} alt='' className='img-fluid' />
					</div>
				</div>
				<div className='about-channel d-flex align-items-center'>
					<div className='channel-profileImg'>
						<img src={channelDetails.channelImage} alt='' className='img-fluid' />
					</div>
					<div className='channel-userName'>
						<h3>{channelDetails.title}</h3>
						<div className='channel-info-modal-wrapper'>
							<Button variant="primary" onClick={handleShow}>
								{channelDetails.aboutChannel ? channelDetails.aboutChannel?.length > 30 ? `${channelDetails.aboutChannel?.slice(0, 30)}...` : channelDetails.aboutChannel : 'Click to view details'}
							</Button>
							<Modal className='channel-info-modal' show={show} onHide={handleClose} centered>
								<div className='model-content-wrapper'>
									<Modal.Header closeButton>
										<Modal.Title>
											<strong>About</strong>
											<p>{channelDetails.aboutChannel}</p>
										</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<div className='Channel-social-links-wrapper'>
											<strong>Links</strong>
											<ul className='channel-details-modal-ul'>
												{
													channelDetails?.instagramUrl &&
													<li className='d-flex align-items-center'>
														<div className='channel-social-icon'>
															<img src={Images.instaLogo} alt='' className='img-fluid' />
														</div>
														<div className='channel-social-Link'>
															<span className='d-block'>Instagram</span>
															<Link to={channelDetails.instagramUrl} target='blank'>{channelDetails.instagramUrl}</Link>
														</div>
													</li>
												}
												{
													channelDetails?.facebookUrl &&
													<li className='d-flex align-items-center'>
														<div className='channel-social-icon'>
															<img src={Images.facebookLogo} alt='' className='img-fluid' />
														</div>
														<div className='channel-social-Link'>
															<span className='d-block'>Facebook</span>
															<Link to={channelDetails.facebookUrl} target='blank'>{channelDetails.facebookUrl}</Link>
														</div>
													</li>
												}
											</ul>
										</div>
										<div className='channel-info'>
											<strong>Channel Details</strong>
											<ul className='channel-details-modal-ul'>
												<li className='d-flex align-items-center'>
													<div className='channel-social-icon'>
														<FontAwesomeIcon icon={faEnvelope} />
													</div>
													<div className='channel-social-Link channel-info-email-Link'>
														<a href={`mailto:${channelDetails.owner?.email}`}>View Email Address</a>
														{/* <Link to={owner?.email} target='blank' mailto>View Email Address</Link> */}
													</div>
												</li>
												{
													channelDetails.websiteUrl &&
													<li className='d-flex align-items-center'>
														<div className='channel-social-icon'>
															<FontAwesomeIcon icon={faGlobe} />
														</div>
														<div className='channel-social-Link channel-info-link'>
															<Link to={channelDetails.websiteUrl}>{channelDetails.websiteUrl}</Link>
														</div>
													</li>
												}
												<li className='d-flex align-items-center'>
													<div className='channel-social-icon'>
														<FontAwesomeIcon icon={faUsers} />
													</div>
													<div className='channel-social-Link channel-info-link'>
														<span className='d-block'>{channelDetails.totalSubscribers} {channelDetails.totalSubscribers > 1 ? 'subscribers' : 'subscriber'}</span>
													</div>
												</li>
												<li className='d-flex align-items-center'>
													<div className='channel-social-icon'>
														<FontAwesomeIcon icon={faPlay} />
													</div>
													<div className='channel-social-Link channel-info-link'>
														<span className='d-block'>{channelDetails.uploadedVideosCount} {channelDetails.uploadedVideosCount > 1 ? 'videos' : 'video'}</span>
													</div>
												</li>
												<li className='d-flex align-items-center'>
													<div className='channel-social-icon'>
														<FontAwesomeIcon icon={faCircleInfo} />
													</div>
													<div className='channel-social-Link channel-info-link'>
														<span className='d-block'>Joined {moment(channelDetails.createdAt).format('MMM, DD, YYYY')}</span>
													</div>
												</li>
												<li className='d-flex align-items-center'>
													<div className='channel-social-icon'>
														<FontAwesomeIcon icon={faEarthAsia} />
													</div>
													<div className='channel-social-Link channel-info-link'>
														<span className='d-block'>{channelDetails.countryResidence}</span>
													</div>
												</li>
											</ul>
										</div>
									</Modal.Body>
									<Modal.Footer className='d-flex justify-content-start'>
										<SocialShare title={channelDetails.title} shareUrl={`${ENV.baseUrl}channel-details/${channelName}`} />

										{/* <Button variant="secondary" onClick={handleClose}>
										<FontAwesomeIcon icon={faShare} />
										<span>Share channel</span>
									</Button> */}
										{/* <Button variant="primary" onClick={handleClose}>
										<FontAwesomeIcon icon={faFlag} />
										<span>Report user</span>
									</Button> */}
									</Modal.Footer>
								</div>

							</Modal>
						</div>
						{
							channelDetails?.name !== localStorage.getItem('channelName') ?
								!hasSubscribed ?
									<Button className='Subscribe-btn' onClick={() => subscribeUnsubscribeHandler(1)}>
										<FontAwesomeIcon icon={faUserPlus} />
										<span>Subscribe</span>
									</Button> :
									<Button className='Subscribe-btn' onClick={() => subscribeUnsubscribeHandler(2)}>
										<FontAwesomeIcon icon={faUserMinus} />
										<span>Unsubscribe</span>
									</Button> :
								null
						}
					</div>
				</div>
			</div>
	)
}

export default Details