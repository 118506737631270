exports.channelRolesComponent = [
	{
		role: 'viewChannelDashboard',
		url: 'publisher-dashboard',
		navigateTo: '/my-videos'
	},
	{
		role: 'viewChannelVideos',
		url: 'my-videos',
		navigateTo: '/video-analytics'
	},
	{
		role: 'viewVideoAnalytics',
		url: 'video-analytics',
		navigateTo: '/channel-analytics'
	},
	{
		role: 'viewChannelAnalytics',
		url: 'channel-analytics',
		navigateTo: '/subtitles'
	},
    {
		role: 'viewVideoSubtitles',
		url: 'subtitles',
		navigateTo: '/contact-admin'
	},
	{
		role: 'viewContactAdmin',
		url: 'contact-admin',
		navigateTo: '/login'
	},
]