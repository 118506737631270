import React from 'react';
import './DashboardHome.css';
import ExploreVideos from '../HomePage/Explore/ExploreVideos';



const DashboardHome = () => {
    return (
        <>
            <ExploreVideos />
        </>
    )
}
export default DashboardHome