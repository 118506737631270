import {
    LIST_VLOGS, BEFORE_VLOG, GET_VLOGS
} from '../../../redux/types';

const initialState = {
    vlogsRes: [],
    vlogsAuth: false,
    pagination: null,
    getVlogAuth: false,
    getVlogRes: null
}

export default function (state = initialState, action) {
    switch (action.type) { 
        case LIST_VLOGS:
            return {
                ...state,
                vlogsAuth: true,
                vlogsRes: [...state.vlogsRes,...action.payload.vlogs],
                pagination: action.payload.pagination
            }
        case GET_VLOGS:
            return {
                ...state,
                getVlogAuth: true,
                getVlogRes: action.payload.vlog,
            }
        case BEFORE_VLOG:
            return {
                ...state,
                vlogsRes: [],
                vlogsAuth: false,
                pagination: null,
                getVlogAuth: false,
                getVlogRes: null
            }
        default:
            return {
                ...state
            }
    }
}