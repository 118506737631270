import {
    GET_TOP_VIDEOS, BEFORE_DASHBOARD, DASHBOARD_ANALYTICS,
} from '../../../../redux/types';

const initialState = {
    topVideos: null,
    topVideosAuth: false,

    dashboardAnalytics: null,
    dashboardAnalyticsAuth: false,

};

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TOP_VIDEOS:
            return {
                topVideos: action.payload,
                topVideosAuth: true
            }
        case DASHBOARD_ANALYTICS:
            return {
                dashboardAnalytics: action.payload,
                dashboardAnalyticsAuth: true
            }
        case BEFORE_DASHBOARD:
            return {
                topVideos: null,
                topVideosAuth: false,

                dashboardAnalytics: null,
                dashboardAnalyticsAuth: false,
            }
        default:
            return state;
    }
}