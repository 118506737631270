import { BEFORE_INFORMATIVE, LIST_INFO_PAGES, GET_INFO_PAGE, GET_ERRORS } from '../../../redux/types';
import { ENV } from './../../../config/config';
import { emptyError } from '../../../redux/shared/error/error.action'


export const beforeInformative = () => {
    return {
        type: BEFORE_INFORMATIVE
    }
}

export const getInfoContent = (slug) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}informative/get/${slug}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_INFO_PAGE,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const listInfoPages = () => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}informative/list`;

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_INFO_PAGES,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};