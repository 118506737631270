import React, { useEffect, useState } from 'react'
import '../../Models/Models.css'
import { Table } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { ENV } from "../../../../../config/config";
import { inviteUser, getChannelUsers, beforeInvite, deleteUser } from '../../../Invites/invites.actions'
import { useSelector, useDispatch } from 'react-redux'
import { beforeChannelRole, getChannelRole, listChannelRoles } from '../../../ChannelRoles/channelRoles.actions'
import { RiDeleteBin4Line } from "react-icons/ri";
import Swal from 'sweetalert2';
import Select from 'react-select';
import "./Permissions.css"

const { userPlaceholderImage } = ENV

const Permissions = ({ setShow, ownerId }) => {
    const [role, setRole] = useState();
    const [channelRoles, setRoles] = useState([])
    const [memberRole, setMemberRole] = useState()
    const [inviteTo, setInviteTo] = useState();
    const { _id, email } = ENV.getUserKeys();
    const [msg, setMsg] = useState({})
    const [channelUsers, setChannelUsers] = useState();
    const [channelName, setName] = useState('')
    const channelId = localStorage.getItem('channelId')
    const dispatch = useDispatch();

    const inviteSelector = useSelector((state) => state.invites)
    const rolesSelector = useSelector((state) => state.roles)

    useEffect(() => {
        if (channelId) {
            dispatch(listChannelRoles())
            dispatch(getChannelRole(`channelId=${channelId}&userId=${_id}`))
            dispatch(getChannelUsers(`channelId=${channelId}`))
        }
    }, [])

    useEffect(() => {
        if (rolesSelector.channelRoleAuth) {
            let role = rolesSelector.channelRoleData.memberRole
            setMemberRole(role)
            dispatch(beforeChannelRole())
        }
    }, [rolesSelector.channelRoleAuth])

    useEffect(() => {
        if (rolesSelector.channelRolesAuth) {
            const items = rolesSelector?.channelRolesData?.channelRoles
                ?.map(item => ({
                    default: item.default,
                    value: item._id,
                    label: item.title
                }))
                ?.filter(item => item.default === false);

            setRoles(items)
            dispatch(beforeChannelRole())
        }
    }, [rolesSelector.channelRolesAuth])

    useEffect(() => {
        if (inviteSelector.channelUsersAuth) {
            setName(inviteSelector.channelUsers?.data[0]?.channel?.name)
            setChannelUsers(inviteSelector.channelUsers.data)
            dispatch(beforeInvite())
        }
    }, [inviteSelector.channelUsersAuth])

    useEffect(() => {
        if (inviteSelector.deleteChannelUserAuth) {
            dispatch(beforeInvite())
            dispatch(getChannelUsers(`channelId=${channelId}`))
        }
    }, [inviteSelector.deleteChannelUserAuth])

    const invite = () => {
        if (!role) {
            setMsg({ ...msg, role: "Role Required!" })
            return;
        }
        if (!inviteTo) {
            setMsg({ ...msg, email: "Email Required!" })
            return;
        }
        setShow(false)
        dispatch(inviteUser({ _id, inviteFrom: email, inviteTo, role, channelId: localStorage.getItem('channelId') }))
    }

    const deleteChannelUser = (userId, channelId) => {
        Swal.fire({
            title: 'Delete Publisher',
            html: 'Are you sure you want to delete this publisher ?',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.value) {
                // setLoader(true)
                dispatch(deleteUser({ userId, channelId }))
            }
        })
    };
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];
    return (
        <>
            <div className='modalPage user-permissions h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div className='general'>
                        <h4>Permissions for {channelName ? channelName : 'Channel'}</h4>
                        <p className='max-95'>Add or change permissions for this channel. For now, these permissions won't apply to some channel features and parts of BiiView.</p>
                        {
                            memberRole && memberRole?.addMembersInChannel ?
                                <div className='dashboardHeader ps-0 d-sm-flex'>
                                    <div className="fileGroupList me-2 mb-2">
                                        <Form.Group>
                                            <Form.Control
                                                name="email"
                                                value={inviteTo}
                                                placeholder="Enter email to invite..."
                                                type="email"
                                                onChange={(e) => setInviteTo(e.target.value)}
                                                className="mb-0"
                                            ></Form.Control>
                                        </Form.Group>
                                        <span className={msg?.email ? 'text-danger' : "d-none"}>{msg?.email}</span>
                                    </div>
                                    <div className='me-2 mb-2'>
                                        <div className='select-items custom-form-select h-100'>
                                            {/* <Form.Select
                                                value={role}
                                                onChange={(e) => setRole(e.target.value)}
                                                className="h-100"
                                            >
                                                <option value={""}> Select Role</option>
                                                {
                                                    channelRoles && channelRoles.length ?
                                                        channelRoles.map((chRole, ind) => {
                                                            return !chRole.default ? (
                                                                <option key={ind} value={chRole._id}> {chRole.title}</option>
                                                            ) : null
                                                        }) :
                                                        <option value={""}> No Role Found</option>

                                                }
                                            </Form.Select> */}
                                            <div className="custom-react-select flex-fill">
                                                {
                                                    channelRoles && channelRoles.length ?
                                                    <Select
                                                        options={channelRoles}
                                                        value={channelRoles && channelRoles.length > 0 ? channelRoles.find(option => option.value === role) : null}
                                                        onChange={(e) => setRole(e.value)}
                                                        classNamePrefix="triage-select"
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                            <span className={msg?.role ? 'text-danger' : "d-none"}>{msg?.role}</span>
                                        </div>
                                    </div>

                                    <button className="text-uppercase mt-0 theme-button mb-2"
                                        hidden={!email || !role}
                                        style={{ minHeight: "unset", padding: "0 15px" }}
                                        onClick={invite}
                                    > Invite
                                    </button>

                                </div> : null
                        }
                        {
                            memberRole && memberRole?.viewChannelMembers ?
                                <div className='modalTable'>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th><p>name</p></th>
                                                <th><p>role</p></th>
                                                <th><p>Action</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {channelUsers && channelUsers.map((val, key) => (
                                                <tr key={key}>
                                                    <td>
                                                        <div className='permissionRoles'>
                                                            <div className='roleProfile'>
                                                                <figure className='roleImg'><img className='img-fluid' src={val.profileImage || userPlaceholderImage} alt={val?.fullName}></img></figure>
                                                                <div>
                                                                    <p className='userName mb-0'>{val.fullName}</p>
                                                                    <p className='roleEmail mb-0'>{val.email}</p>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className='role mb-0'>{val.role.title}
                                                        </p>
                                                    </td>
                                                    {
                                                        memberRole?.removeMemberFromChannel &&
                                                        <td>
                                                            <p className='role mb-0'>
                                                                {(val.channel?.ownerId === ownerId || val.userId === _id) ?
                                                                    ""
                                                                    :
                                                                    <span onClick={(e) => deleteChannelUser(val.userId, val.channelId)}>
                                                                        <RiDeleteBin4Line />
                                                                    </span>}
                                                            </p>
                                                        </td>
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div> : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default Permissions