import React, { useEffect, useState } from 'react'
import Details from '../ChannelDetails/Details/Details'
import ChannelVideos from './Details/ChannelVideos'

function ChannelDetails() {
	return (
		<div>
			<Details/>
			<ChannelVideos/>
		</div>
	)
}

export default ChannelDetails