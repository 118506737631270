import { GET_CHANNEL_ROLE, LIST_CHANNEL_ROLES, BEFORE_CHANNEL_ROLE } from '../../../redux/types';

const initialState = {

    channelRoleData: null,
    channelRoleAuth: false,

    channelRolesData: null,
    channelRolesAuth: false

}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_CHANNEL_ROLE:
            return {
                ...state,
                channelRoleData: action.payload,
                channelRoleAuth: true
            }
        case LIST_CHANNEL_ROLES:
            return {
                ...state,
                channelRolesData: action.payload,
                channelRolesAuth: true,
            }
        case BEFORE_CHANNEL_ROLE:
            return {
                channelRoleData: null,
                channelRoleAuth: false,

                channelRolesData: null,
                channelRolesAuth: false
            }
        default:
            return {
                ...state
            }
    }
}