import React, { useState } from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap';
import './CommunityQuestions.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

function CommunityQuestions() {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<section id='Community-questions'>
			<Container>
				<div className='community-posts-wrapper mx-auto'>
					<div className='Community-questions'>
						<h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h3>
						<div className='community-modal-wrapper'>
							<Button className='community-answer-btn btn theme-btn orange-btn' variant="primary" onClick={handleShow}>
								<FontAwesomeIcon icon={faPenToSquare} />
								<span>Answer</span>
							</Button>

							<Modal className='community-answer-modal' show={show} onHide={handleClose} centered>
								<Modal.Header closeButton>
									<Modal.Title>
										<h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h3>
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Form className='Community-answer-form'>
										<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
											{/* <Form.Label>Example textarea</Form.Label> */}
											<Form.Control as="textarea" rows={3} placeholder='Write Your Answer' />
										</Form.Group>
									</Form>
								</Modal.Body>
								<Modal.Footer>
									<div className='answer-modal-footer	d-flex align-items-center justify-content-between w-100'>
										<Form.Group className='position-relative Community-choose-img'>											
											<span><FontAwesomeIcon icon={faImages} /></span>	
											<Form.Control type="file" />
										</Form.Group>
										<Button className='orange-main-button text-center' variant="primary" onClick={handleClose}>
											Post
										</Button>
									</div>
								</Modal.Footer>
							</Modal>
							<button className='btn answer-follow-btn'><FontAwesomeIcon icon={faUserPlus} /> Follow</button>
						</div>
					</div>
				</div>
			</Container>
		</section>
	)
}

export default CommunityQuestions