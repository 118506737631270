import React, { useEffect, useState } from 'react'
import '../../Models/Models.css'
import { Row, Col } from 'react-bootstrap'
import { TiArrowSortedDown } from "react-icons/ti"
import countryLanguage from "country-language";
import { getCategories, beforeVideo } from '../../Videos/video.actions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

const UploadAdvanceSettings = (props) => {
    const dispatch = useDispatch()

    const [categories, setCategories] = useState([])
    const allLanguages = countryLanguage.getLanguages().map((l) => l.name[0])
    const [languages, setLanguages] = useState([])

    const videoSelector = useSelector((state) => state.videos)

    useEffect(() => {
        if(allLanguages){
            const items = allLanguages.map(item => ({
                value: item,
                label: item
            }));

            setLanguages(items)
        }
    }, [allLanguages])

    useEffect(() => {
        dispatch(getCategories())
    }, [])

    useEffect(() => {
        if (videoSelector.videoCategoriesAuth) {
            const items = videoSelector.videoCategories.categories.map(category => ({
                value: category._id,
                label: category.name
              }));
            setCategories(items);
            dispatch(beforeVideo());
        }
    }, [videoSelector.videoCategoriesAuth]);

    const onChangeHandler = (e) => {
        console.log(e.name,": ",e.vlaue)
        props.setData({ [e.name]: e.value })
    }
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];
    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div>
                        <div className='general mb-0'>
                            <div>
                                <h4>Automatic chapters</h4>
                                <p>Chapters and key moments make your video easier to watch. You can overwrite automatic suggestions by creating your own chapters in the video description.</p>
                            </div>
                            <div className='fileGroup'>
                                <div className='d-flex justify-content-start align-items-center checkboxText mb-3'>
                                    <div className="form-check checkTable me-2 px-0 modalCheck">
                                        {/* <input className="form-check-input" type="checkbox" checked={props.chaptersAndMoments} onChange={() => props.setData({ chaptersAndMoments: !props.chaptersAndMoments })} name="chaptersAndMoments" id="flexCheckDefault" /> */}
                                        <label className="right-label-checkbox">Allow automatic chapters and key moments
                                            <input type="checkbox" checked={props.chaptersAndMoments} onChange={() => props.setData({ chaptersAndMoments: !props.chaptersAndMoments })} name="chaptersAndMoments" id="flexCheckDefault" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    {/* <h4 className='check-H4 mb-0'>Allow automatic chapters and key moments</h4> */}
                                </div>
                                <div className='fileGroupList'>
                                    <Row>
                                        <Col md={6}>
                                            <div className='modalSelect mb-2'>
                                                {/* <select className="form-select" name="videoCategory" value={props.videoCategory} onChange={onChangeHandler} aria-label="Default select example">
                                                    <option value="">Select Category</option>
                                                    {
                                                        categories && categories.length ?
                                                            categories.map((category, key) => {
                                                                return <option key={key} value={category._id}>{category.name}</option>
                                                            })
                                                            :
                                                            <option value="">No Categories found</option>
                                                    }
                                                </select>
                                                <span><TiArrowSortedDown /></span> */}
                                                 <div className="custom-react-select flex-fill">
                                                 {/* {console.log("Props.UploadAdvaceSettings", props)} */}
                                                    <Select
                                                        name="videoCategory"
                                                        options={categories}
                                                        value={categories && categories.length > 0 ? categories.find(option => option.value === props.videoCategory) : null}
                                                        // onChange={onChangeHandler} // Update category state with the selected option's value
                                                        onChange={(e) => props.setData({ videoCategory: e.value })}
                                                        classNamePrefix="triage-select"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='fileGroupList'>
                                    <h4>Language and captions certification</h4>
                                    <Row>
                                        <Col md={6}>
                                            <div className='modalSelect mb-2'>
                                                {/* <select className="form-select" name="videoLanguage" value={props.videoLang} onChange={onChangeHandler} aria-label="Default select example">
                                                    <option selected>Select Video Language</option>
                                                    {
                                                        allLanguages && allLanguages.length ?
                                                            allLanguages.map((lang, key) => {
                                                                return <option key={key} value={lang}>{lang}</option>
                                                            })
                                                            :
                                                            <option value="">No Languages found</option>
                                                    }
                                                </select>
                                                <span><TiArrowSortedDown /></span> */}
                                                <div className="custom-react-select flex-fill">
                                                    <Select
                                                        name="videoLanguage"
                                                        options={languages}
                                                        value={languages && languages.length > 0 ? languages.find(option => option.value === props.videoLang) : null} // Setting the selected option based on category state
                                                        // onChange={onChangeHandler} // Update category state with the selected option's value
                                                        onChange={(e) => props.setData({ videoLanguage: e.value })}
                                                        classNamePrefix="triage-select"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='modalSelect mb-2'>
                                                {/* <select className="form-select" name="titleDescLanguage" value={props.titleDescLang} onChange={onChangeHandler} aria-label="Default select example">
                                                    <option value="">Select Title & Description Language</option>
                                                    {
                                                        allLanguages && allLanguages.length ?
                                                            allLanguages.map((lang, key) => {
                                                                return <option key={key} value={lang}>{lang}</option>
                                                            })
                                                            :
                                                            <option value="">No Languages found</option>
                                                    }
                                                </select>
                                                <span><TiArrowSortedDown /></span> */}
                                                <div className="custom-react-select flex-fill">
                                                    <Select
                                                        name="titleDescLanguage"
                                                        options={languages}
                                                        value={languages && languages.length > 0 ? languages.find(option => option.value === props.titleDescLang) : null} // Setting the selected option based on category state
                                                        // onChange={onChangeHandler} // Update category state with the selected option's value
                                                        onChange={(e) => props.setData({ titleDescLanguage: e.value })}
                                                        classNamePrefix="triage-select"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UploadAdvanceSettings