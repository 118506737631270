import { toast } from "react-toastify";
import { emptyError } from '../../../../redux/shared/error/error.action';
import { ENV } from '../../../../config/config';
import { GET_TRENDING_VIDEOS, GET_TOP_VIDEOS_VIEWS, BEFORE_VIDEO, EDIT_VIDEO, GET_ERRORS, LIST_VIDEO, DELETE_VIDEO, BEFORE_VIDEO_CONTENT, VIDEO_CONTENT_UPLOADED, BEFORE_VIDEO_CONTENT_UPLOADED, GET_VIDEO, PUBLIC_VIDEO, BEFORE_PUBLIC_VIDEO_CONTENT, VIDEO_CATEGORIES, VIDEO_STAT, BEFORE_STAT, VIDEO_ANALYTICS, BEFORE_VID_ANALYTICS, RECOMMENDED_VIDEOS, BEFORE_RECOMMENDED_VIDEOS, BEFORE_SEARCHED_VIDEOS, SEARCHED_VIDEOS, CREATE_VIDEO, CHANGE_UPLOAD_STATUS, EDIT_ANNOTATION, BEFORE_ANNOTATION_EDIT, GET_BOUNDING_OBJECTS, BEFORE_BOUNDING_OBJECTS, ADD_BOUNDING_OBJECTS, UPSERT_COMMENT, LIST_VIDEO_COMMENTS, DELETE_VIDEO_COMMENT, BEFORE_VIDEO_COMMENTS, UPSERT_VIDEO_COMMENT, BEFORE_LIKE_DISLIKE, ADD_REMOVE_LIKE_DISLIKE } from "../../../../redux/types";

export const beforeVideo = () => {
  return {
    type: BEFORE_VIDEO
  }
};

export const createVideo = (body = null) =>
  (dispatch) => {
    dispatch(emptyError());
    const { accessToken } = ENV.getUserKeys('accessToken')
    const url = `${ENV.url}videos/create`
    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': ENV.Authorization,
        'x-auth-token': ENV.x_auth_token,
        'x-access-token': accessToken || ''
      },
      body,
    },
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type: CREATE_VIDEO,
            payload: data
          })
        } else {
          toast.error(data.message);
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) toast.error(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
};

export const editVideo = (body) => dispatch => {

  const { accessToken } = ENV.getUserKeys('accessToken')

  dispatch(emptyError());

  fetch(`${ENV.url}videos/edit`, {
    method: 'PUT',
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
      'x-access-token': accessToken || ''
    },
    body,
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        toast.success(data.message, { toastId: 'update-video' })
        dispatch({
          type: EDIT_VIDEO,
          payload: data
        })
      } else {
        toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const uploadSubtitleFile = (body) => dispatch => {

  const { accessToken } = ENV.getUserKeys('accessToken')

  dispatch(emptyError());

  fetch(`${ENV.url}videos/upload/subtitle`, {
    method: 'PUT',
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
      'x-access-token': accessToken || ''
    },
    body,
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) { } else {
        toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const getVideoContent = (qs = '') => (dispatch) => {

  const { accessToken } = ENV.getUserKeys('accessToken')

  dispatch(emptyError());

  let url = `${ENV.url}videos/list?${qs}`;

  fetch(url, {
    method: "GET",
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
      'x-access-token': accessToken || ''
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: LIST_VIDEO,
          payload: data,
        });
      } else {
        toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    })
};

export const deleteVideo = (id, showToast = true) => dispatch => {
  dispatch(emptyError());

  fetch(`${ENV.url}videos/delete/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        if (showToast)
          toast.success(data.message, { toastId: 'del-video' })
        dispatch({
          type: DELETE_VIDEO,
          payload: data
        })
      } else {
        if (showToast)
          toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const deleteMultipleVideos = (videoIds) => dispatch => {
  dispatch(emptyError());

  fetch(`${ENV.url}videos/delete/multiple`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
    },
    body: JSON.stringify(videoIds)
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        toast.success(data.message, { toastId: 'del-videos' })
        dispatch({
          type: DELETE_VIDEO,
          payload: data
        })
      } else {
        toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const beforeVideoContent = () => {
  return {
    type: BEFORE_VIDEO_CONTENT
  }
};

export const beforeVideoContentUploaded = () => {
  return {
    type: BEFORE_VIDEO_CONTENT_UPLOADED
  }
};

export const VideoUploadedSucess = () => {
  return {
    type: VIDEO_CONTENT_UPLOADED,

  }
};

//public site display
export const getAllPublicVideoContent = (qs = '') => (dispatch) => {
  const { accessToken } = ENV.getUserKeys('accessToken')

  dispatch(emptyError());

  let url = `${ENV.url}videos/Publiclist?${qs}`;

  fetch(url, {
    method: "GET",
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
      'x-access-token': accessToken || '',
      'cache-control': 'no-cache'
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: PUBLIC_VIDEO,
          payload: data,
        });
      } else {
        toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    })
};

//public site display
export const getCategories = () => (dispatch) => {

  const { accessToken } = ENV.getUserKeys('accessToken')

  dispatch(emptyError());

  let url = `${ENV.url}video/categories/list`;

  fetch(url, {
    method: "GET",
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
      'x-access-token': accessToken || ''
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: VIDEO_CATEGORIES,
          payload: data,
        });
      } else {
        toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    })
};

export const beforePublicVideoContent = () => {
  return {
    type: BEFORE_PUBLIC_VIDEO_CONTENT
  }
};

export const getVideo = (id, qs = '') => (dispatch) => {

  const { accessToken } = ENV.getUserKeys('accessToken')

  dispatch(emptyError());

  let url = `${ENV.url}videos/get/${id}`;
  if (qs)
    url = `${url}?${qs}`

  fetch(url, {
    method: "GET",
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
      'x-access-token': accessToken || '',
      'cache-control': 'no-cache'

    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_VIDEO,
          payload: data,
        });
      } else {
        toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    })
};

export const updateVideoUploadStatus = (videoId) => dispatch => {
  dispatch(emptyError());
  fetch(`${ENV.url}videos/update-status/${videoId}`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: CHANGE_UPLOAD_STATUS,
        });
      }
      else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

// Video Stats 
export const createVideoStats = (body) => dispatch => {
  dispatch(emptyError());
  fetch(`${ENV.url}videos/stats/create?type=${body.type}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    },
    body: JSON.stringify(body)
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: VIDEO_STAT,
          payload: data,
        });
      }
      else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

export const createImpressionStats = (body) => dispatch => {
  dispatch(emptyError());
  fetch(`${ENV.url}videos/create-stats`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    },
    body: JSON.stringify(body)
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: VIDEO_STAT,
          payload: data,
        });
      }
      else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

export const beforeVideoStats = () => {
  return {
    type: BEFORE_STAT
  }
};

// Video Analytics 
export const getVideoAnalytics = (qs = '') => dispatch => {
  dispatch(emptyError());

  let url = `${ENV.url}videos/analytics`
  if (qs)
    url = `${url}?${qs}`

  fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: VIDEO_ANALYTICS,
          payload: data,
        });
      }
      else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

export const beforeVideoAnalytics = () => {
  return {
    type: BEFORE_VID_ANALYTICS
  }
};

// Recommended Videos
export const getRecommendedVideos = (qs = '') => (dispatch) => {

  dispatch(emptyError());
  let url = `${ENV.url}videos/recommended-videos`;

  if (qs)
    url = `${url}?${qs}`


  fetch(url, {
    method: "GET",
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: RECOMMENDED_VIDEOS,
          payload: data,
        });
      } else {
        toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    })
};

export const beforeRecommendedVideos = () => {
  return {
    type: BEFORE_RECOMMENDED_VIDEOS
  }
};

export const getSearchedVideos = (qs = '') => (dispatch) => {
  const { accessToken } = ENV.getUserKeys('accessToken')

  dispatch(emptyError());

  let url = `${ENV.url}videos/Publiclist?${qs}`;

  fetch(url, {
    method: "GET",
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
      'x-access-token': accessToken || ''
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: SEARCHED_VIDEOS,
          payload: data.data,
        });
      } else {
        toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    })
};

export const beforeSearchedVideos = () => {
  return {
    type: BEFORE_SEARCHED_VIDEOS
  }
};

// Annotation Edit
export const editAnnotation = (id, body) => dispatch => {
  dispatch(emptyError());
  fetch(`${ENV.url}videos/update/metadata/${id}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    },
    body: JSON.stringify(body)
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        toast.success(data.message)
        dispatch({
          type: EDIT_ANNOTATION,
        });
      }
      else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

export const beforeEditAnnotation = () => {
  return {
    type: BEFORE_ANNOTATION_EDIT,
  }
};

// Add Bounding Boxes 
export const addBoundingObjects = (id, body) => dispatch => {
  dispatch(emptyError());
  fetch(`${ENV.url}videos/objects/upsert/${id}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    },
    body: JSON.stringify(body)
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        toast.success(data.message)
        dispatch({
          type: ADD_BOUNDING_OBJECTS,
        });
      }
      else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

export const getBoundingObjects = (id) => dispatch => {
  dispatch(emptyError());
  fetch(`${ENV.url}videos/objects/get/${id}`, {
    method: 'GET',
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        // toast.success(data.message)
        dispatch({
          type: GET_BOUNDING_OBJECTS,
          payload: data
        });
      }
      else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

export const beforeBoundingObjects = () => {
  return {
    type: BEFORE_BOUNDING_OBJECTS,
  }
};

export const getTrendingVideos = () => dispatch => {
  dispatch(emptyError());
  fetch(`${ENV.url}videos/trending/get/`, {
    method: 'GET',
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        if(data.trending){
          dispatch({
            type: GET_TRENDING_VIDEOS,
            payload: data
          });
        }
      }
      else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

export const getTopVideosViews = (id) => dispatch => {
  dispatch(emptyError());
  fetch(`${ENV.url}videos/top-videos/get/`, {
    method: 'GET',
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        if(data.topVideos){
          dispatch({
            type: GET_TOP_VIDEOS_VIEWS,
            payload: data
          });
        }
      }
      else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

// Like Dislike Video

export const addRemoveLikeDislike = (payload) => dispatch => {
  dispatch(emptyError());
  const url = `${ENV.url}videos/stats/like-dislike`;
  fetch(url, {
      method: 'POST',
      headers: {
          'content-type': 'application/json',
          'Authorization': ENV.Authorization,
          'x-auth-token': ENV.x_auth_token
      },
      body: JSON.stringify(payload)

  }).then(res => res.json()).then(data => {
      if (data.success) {
          dispatch({
              type: ADD_REMOVE_LIKE_DISLIKE,
              payload: data
          })
      } else {
          toast.error(data.message, { toastId: ADD_REMOVE_LIKE_DISLIKE })
          dispatch({
              type: GET_ERRORS,
              payload: data
          })
      }
  }).catch(errors => {
      dispatch({
          type: GET_ERRORS,
          payload: errors
      })
  })
};

export const beforeLikeDislike = () => {
  return {
      type: BEFORE_LIKE_DISLIKE
  }
}

// Video Comments

export const upsertVideoComment = (payload) => dispatch => {
  dispatch(emptyError());
  let url = `${ENV.url}videos/comments/upsert`;
  fetch(url, {
      method: 'POST',
      headers: {
          'Content-type' : 'application/json',
          'Authorization': ENV.Authorization,
          'x-auth-token': ENV.x_auth_token
      },
      body: JSON.stringify(payload)

  }).then(res => res.json()).then(data => {
      if (data.success) {
          dispatch({
              type: UPSERT_VIDEO_COMMENT,
              payload: data
          })
      } else {
          toast.error(data.message, {
              toastId: `${UPSERT_VIDEO_COMMENT}-err`
          })
          dispatch({
              type: GET_ERRORS,
              payload: data
          })
      }
  }).catch(error => {
      if (error.response && error.response.data) {
          const { data } = error.response
          if (data.message)
              toast.error(data.message, {
                  toastId: `${UPSERT_VIDEO_COMMENT}-err`
              })
      }
      dispatch({
          type: GET_ERRORS,
          payload: error
      })
  })
};

export const listVideoComments = (qs = '') => dispatch => {
  dispatch(emptyError());
  let url = `${ENV.url}videos/comments/list`;
  if (qs)
      url += `?${qs}`

  fetch(url, {
      method: 'GET',
      headers: {
          'Authorization': ENV.Authorization,
          'x-auth-token': ENV.x_auth_token
      }
  }).then(res => res.json()).then(data => {
      if (data.success) {
          dispatch({
              type: LIST_VIDEO_COMMENTS,
              payload: data.data
          })
      } else {
          toast.error(data.message, {
              toastId: `${LIST_VIDEO_COMMENTS}-err`
          })
          dispatch({
              type: GET_ERRORS,
              payload: data
          })
      }
  }).catch(error => {
      if (error.response && error.response.data) {
          const { data } = error.response
          if (data.message)
              toast.error(data.message, {
                  toastId: `${LIST_VIDEO_COMMENTS}-err`
              })
      }
      dispatch({
          type: GET_ERRORS,
          payload: error
      })
  })
};

export const deleteVideoComment = (id) => dispatch => {
  dispatch(emptyError());
  let url = `${ENV.url}videos/comments/delete/${id}`;
  fetch(url, {
      method: 'DELETE',
      headers: {
          'Authorization': ENV.Authorization,
          'x-auth-token': ENV.x_auth_token
      },

  }).then(res => res.json()).then(data => {
      if (data.success) {
          dispatch({
              type: DELETE_VIDEO_COMMENT,
          })
      } else {
          toast.error(data.message, {
              toastId: `${DELETE_VIDEO_COMMENT}-err`
          })
          dispatch({
              type: GET_ERRORS,
              payload: data
          })
      }
  }).catch(error => {
      if (error.response && error.response.data) {
          const { data } = error.response
          if (data.message)
              toast.error(data.message, {
                  toastId: `${DELETE_VIDEO_COMMENT}-err`
              })
      }
      dispatch({
          type: GET_ERRORS,
          payload: error
      })
  })
};

export const beforeVideoComment = () => {
  return {
      type: BEFORE_VIDEO_COMMENTS
  }
}
