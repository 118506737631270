import React from 'react'
import CommunityHeader from './CommunityHeader/CommunityHeader';
import CommunityPosts from './CommunityPosts/CommunityPosts';
import Footer from '../Footer/Footer';

function Comunity() {
	return (
		<div className='community-wrapper'> 
			<CommunityHeader />
			<CommunityPosts />
			<Footer />
		</div>
	)
}

export default Comunity;