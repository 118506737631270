import { LIST_BILLING_HISTORY, GET_INVOICE, BEFORE_BILLING_HISTORY, DOWNLOAD_INVOICE } from '../../../redux/types';

const initialState = {
    listBillingHistoryData: null,
    listBillingHistoryAuth: false,

    getInvoiceAuth: false,
    getInvoiceData: null,

    downloadInvoiceData: null,
    downloadInvoiceAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_BILLING_HISTORY:
            return {
                ...state,
                listBillingHistoryData: action.payload,
                listBillingHistoryAuth: true
            }
        case GET_INVOICE:
            return {
                ...state,
                getInvoiceData: action.payload,
                getInvoiceAuth: true
            }
        case DOWNLOAD_INVOICE:
            return {
                ...state,
                downloadInvoiceData: action.payload,
                downloadInvoiceAuth: true
            }
        case BEFORE_BILLING_HISTORY:
            return {
                ...state,
                listBillingHistoryData: null,
                listBillingHistoryAuth: false,

                getInvoiceData: null,
                getInvoiceAuth: false,

                downloadInvoiceAuth: false,
                downloadInvoiceData: null
            }
        default:
            return {
                ...state
            }
    }
}