import React, { useEffect, useState } from 'react';
import MemberSideBar from '../MemberDashboard/MemberSidebar/MemberDashboardSidebar'
import Dashboard from './Dashboard';
import { ENV } from '../../../config/config';
// import ProfileComponent from './ProfileComponent';
// import UserNotifications from '../Notifications/UserNotifications';
import { useSearchParams } from 'react-router-dom';
import ApplicationTour from '../ApplicationTour/ApplicationTours';
import Header from '../Header/Header';

const { getUserKeys } = ENV

const MemberDashboard = ({ title, children }) => {

  const [dashboardBar, setDashboardBar] = useState(true)

  const userType = getUserKeys('type')?.type
  const userId = getUserKeys('_id')?._id
  const [searchParams, setSearchParams] = useSearchParams();

  const adminSecretKey = searchParams.get("key")
  const channelId = localStorage.getItem('channelId')
  const location = window.location.pathname.split('/')[1]
  const excludedTourPages = [
    'my-videos',
    'contact-admin'
  ]

  useEffect(() => {
    if (title)
      document.title = 'BiiView | ' + title;
    else
      document.title = "BiiView";
  }, [title]);

  useEffect(() => {
    let bodyElement = document.getElementsByTagName('body')[0]
    if(bodyElement){
      bodyElement.classList.add('dashboard-page')
    }
    console.log(bodyElement)

  },[])

  return (
    <>
      <Header />
      {
        userId && userType && userType === 2 ?
          <div className='dashboardBody'>
            {
              userId && excludedTourPages.indexOf(location) === -1 &&
              <ApplicationTour />
            }
            <MemberSideBar dashboardBar={dashboardBar} setDashboardBar={setDashboardBar} channel={channelId} />
            <div className={dashboardBar ? 'dashboard-sitePages dashboard-main-width px40' : ' right dashboard-sitePages sitePages-width'}>
              {/* {
                userId ?
                  <div className='memberdashboard-icons'>
                    <UserNotifications />
                    <ProfileComponent />
                  </div>
                  : null
              } */}
              {children}
            </div>
          </div> :
          < Dashboard>
            {children}
          </Dashboard>
      }
    </>
  )
}

export default MemberDashboard