import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import Join from './Join'
import Overview from './Overview'
import Team from './Team'
import Together from './Together'
import Help from './Help'
import Reviews from './Reviews'
import './About.css'

const Aboutus = () => {
	return (
		<div className="content-page">
			<section className='together-sec mission-banner'>
				<Container className="customBox">
					<Row>
						<Col lg={6}>
							<div className='wrapper'>
								<h4 className='H2'>Our Mission</h4>
								<p>Our mission is to help vendors and marketers promote their brands and increase sales in a revolutionary way using our state-of-the-art,  AI- empowered object recognition software solution.</p>
							</div>
						</Col>
						<Col lg="6">
							<div className='wrapper'>
								<h4 className='H2'>Who We Are</h4>
								<p>At our core, we are pioneers in the realm of AI object recognition software. With a relentless pursuit of perfection, our software converts pixels into impeccable results.</p>
							</div>
						</Col>
					</Row>
				</Container>
				{/* <div className='people-working'>
					<img className='aboutImg img-fluid' src='/images/people-working.png' alt='about-biiview' />
				</div> */}
			</section>
			<section className='content-section'>
				<Container className='customBox'>
					<Row className="align-items-center">
						<Col lg={6}>
							<h1 className='mb-5 mb-lg-0 border-heading'>Don't Just See <span className="theme">The World</span>, Understand It. BiiView Gives You A <span className="theme">Whole New Perspective</span>!</h1>
						</Col>
						<Col lg={6} className="d-flex align-items-end">
							<h3 className='mb-0'>BiiView is a revolutionary video analysis solution with intuitive machine-learning models for seamless video processing, object recognition, and quick labeling.</h3>
						</Col>
					</Row>
				</Container>
			</section>
			<section className='content-section'>
				<Container className='customBox'>
					<Row className='align-items-center'>
						<Col lg={6} className="order-0 order-lg-1">
							<h2 className='mb-5 mb-lg-0 border-heading'>Harnessing the synergy of exceptional user experience and <span className='theme'>cutting-edge technology</span>.</h2>
						</Col>
						<Col lg={6} className="order-1 order-lg-0">
							<h3 className='mb-4'>When it comes to object recognition, our AI software BiiView stands as a visionary, capable of perceiving what others often overlook.</h3>
							<p>We have developed this comprehensive AI platform that handles everything in one place: data extraction, labeling, training, and model hosting. Utilizing a diverse array of pre-trained datasets for image detection, we provide an all-in-one solution – No coding required.</p>
							<p>Leveraging predefined and custom models, our system efficiently identifies objects and labels them using an in-built, extensive database, besides producing new high-quality datasets more efficiently.</p>
						</Col>
					</Row>
				</Container>
			</section>
			<Team />
			<Reviews />
			<Join />
			<Help />
		</div>
	)
}

export default Aboutus