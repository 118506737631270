import React, { useEffect } from 'react'
import { useState } from 'react'
import '../../Models/Models.css'
import { Form, Card } from 'react-bootstrap'
import { ENV } from '../../../../../config/config'
import SmallLoader from '../../../../../utils/SmallLoader/SmallLoader'
import { validateUrl } from '../../../../../utils/Validations/validations' 
const { allowedMediaTypes, defaultPlaceholderImage } = ENV

const SocialSettings = (props) => {

    const [bannerImage, setBannerImage] = useState('')

    // useEffect(() => {
    //     if (props.channelImage)
    //         setBannerImage(props.channelImage)

    // }, [props.channelImage])

    // const onFileChange = (e) => {
    //     const allowedImgTypes = allowedMediaTypes.images
    //     let file = e.target.files[0]

    //     if (file) {
    //         let fileExt = file.name.split('.').pop();
    //         if (allowedImgTypes.indexOf(fileExt) <= -1) {
    //             props.setErrors({ ...props.errors, channelImage: `Supported File Types are: ${allowedImgTypes.join(', ')}` })
    //             return
    //         }

    //         let reader = new FileReader();
    //         reader.readAsDataURL(file)
    //         reader.onload = function (e) {
    //             let result = e.target.result
    //             setBannerImage(result)
    //             props.setErrors({ ...props.errors, channelImage: `` })
    //             props.setData({ channelImageUrl: file })
    //         }
    //     }
    // }

    return (
        // props.loader ?
        //     <SmallLoader height={40} width={40} />
        //     :
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div className='general'>
                        <h4>Social Settings</h4>
                        {/* <div className='fileGroupList'>
                                <p className='fileLabel'>bannerImage</p>
                                <div className="position-relative mb-3">
                                    <Form.Control
                                        className="text-white mb-0"
                                        placeholder="Company"
                                        type="file"
                                        varient="info"
                                        accept=".png,.jpeg,.jpg"
                                        id="channelImage"
                                        onChange={onFileChange}
                                    >
                                    </Form.Control>
                                    <span className="choose-file-text">Choose File</span>
                                </div>
                                {
                                    props.errors && props.errors.channelImage &&
                                    <span className='submit-errors text-danger'>{props.errors.channelImage}</span>
                                }
                            </div>
                            {
                                bannerImage &&
                                <div className='userBlock d-flex justify-content-start'>
                                    <div className='userImg'>
                                        <img className='img-fluid' src={bannerImage || defaultPlaceholderImage} alt='channel'>
                                        </img>
                                    </div>
                                </div>
                            } */}
                        <div className='fileGroupList'>
                            <p className='fileLabel'>Facebook Url</p>
                            <Form.Control
                                type="text"
                                name="facebookUrl"
                                value={props?.facebookUrl || ''}
                                onChange={(e) => props.setData({ facebookUrl: e.target.value })}
                            >
                            </Form.Control>
                            {
                                props.errors && props.errors.facebookUrl &&
                                <span className='submit-errors text-danger'>{props.errors.facebookUrl}</span>
                            }
                        </div>
                        <div className='fileGroupList'>
                            <p className='fileLabel'>LinkedIn Url</p>
                            <Form.Control
                                type="text"
                                name="linkedinUrl"
                                value={props?.linkedinUrl || ''}
                                onChange={(e) => props.setData({ linkedinUrl: e.target.value })}
                            >
                            </Form.Control>
                            {
                                props.errors && props.errors.linkedinUrl &&
                                <span className='submit-errors text-danger'>{props.errors.linkedinUrl}</span>
                            }
                        </div>
                        <div className='fileGroupList'>
                            <p className='fileLabel'>Instagram Url</p>
                            <Form.Control
                                type="text"
                                name="instagramUrl"
                                value={props?.instagramUrl || ''}
                                onChange={(e) => props.setData({ instagramUrl: e.target.value })}
                            >
                            </Form.Control>
                            {
                                props.errors && props.errors.instagramUrl &&
                                <span className='submit-errors text-danger'>{props.errors.instagramUrl}</span>
                            }
                        </div>
                        <div className='fileGroupList'>
                            <p className='fileLabel'>Website Url</p>
                            <Form.Control
                                type="text"
                                name="websiteUrl"
                                value={props?.websiteUrl || ''}
                                onChange={(e) => props.setData({ websiteUrl: e.target.value })}
                            >
                            </Form.Control>
                            {
                                props.errors && props.errors.websiteUrl &&
                                <span className='submit-errors text-danger'>{props.errors.websiteUrl}</span>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default SocialSettings