import React from 'react';
import { Oval } from 'react-loader-spinner';

const SmallLoader = (props) => {

    let styleProps = {
        height: 20,
        width: 20,
        color: "#FD6F21",
        visible: true,
        wrapperClass: "justify-content-center h-100 align-items-center",
        secondaryColor: "#6e6c69",
        strokeWidth: 3,
        ...props
    }

    return (
        <Oval
            {...styleProps}
        />
    );
};

export default SmallLoader;