import { LIST_PLANS, SUBSCRIBE_PLAN, GET_PLAN, CANCEL_SUBSCRIPTION, BEFORE_PLANS } from '../../../redux/types';

const initialState = {
    listPlansRes: null,
    listPlansAuth: false,

    getPlanAuth: false,
    getPlanData: null,

    subscribePlan: null,
    subscribePlanAuth: false,

    cancelSubscriptionData: null,
    cancelSubscriptionAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_PLANS:
            return {
                ...state,
                listPlansRes: action.payload,
                listPlansAuth: true
            }
        case GET_PLAN:
            return {
                ...state,
                getPlanData: action.payload,
                getPlanAuth: true
            }
        case SUBSCRIBE_PLAN:
            return {
                ...state,
                subscribePlan: action.payload,
                subscribePlanAuth: true
            }
        case CANCEL_SUBSCRIPTION:
            return {
                ...state,
                cancelSubscriptionData: action.payload,
                cancelSubscriptionAuth: true
            }
        case BEFORE_PLANS:
            return {
                ...state,
                listPlansRes: null,
                listPlansAuth: false,
                subscribePlan: null,
                subscribePlanAuth: false,
                cancelSubscriptionData: null,
                cancelSubscriptionAuth: false
            }
        default:
            return {
                ...state
            }
    }
}