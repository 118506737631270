import React, { useEffect, useState } from 'react';
import { HiOutlineClipboardDocument } from "react-icons/hi2"
import { ENV } from '../../../../../config/config';
import copy from 'copy-to-clipboard';
import { Tooltip } from 'react-tooltip'
const { defaultPlaceholderImage, baseUrl } = ENV

const VideoUrlSection = (props) => {
    const [url, setUrl] = useState(defaultPlaceholderImage)
    const [copied, setCopied] = useState('')

    useEffect(() => {
        if (props.video && props.inputs) {
            let { video, inputs } = props
            if (video.thumbnails?.length)
                setUrl(video.thumbnails[0])

            else if (inputs.thumbnails?.length)
                setUrl(inputs.thumbnails[0])
            
            else
                setUrl(defaultPlaceholderImage)
        }
    }, [props.video, props.inputs])

    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => {
                setCopied('')

            }, 1000);
            return () => clearTimeout(timer)
        }
    }, [copied])

    return (
        <div className='videoLinkBox'>
            <div className='videoLinkImg'>
                <figure className='mb-0'><img className='img-fluid' src={url} alt={props.video.title}></img></figure>
            </div>
            <div className='vidoDetails'>
                <h5 className='ellipse'>{props.video.title}</h5>
                <p className='mb-0'>Video Link:</p>
                <div className='clipboardDiv'>
                    <a href={`${baseUrl}media-player/${props.video?.slug}/${props?.video?.channel?.name?.toLowerCase()}`} className='videoLink ellipse' target={'_blank'} rel="noreferrer">{`${baseUrl}media-player/${props.video?.slug}/${props?.video?.channel?.name?.toLowerCase()}`}</a>
                    <button data-tooltip-id="my-tooltip" data-tooltip-content="Copy" style={{minHeight: "unset"}} className='p-2 position-relative' type="button" onClick={() => copy(`${baseUrl}media-player/${props.video?.slug}/${props?.video?.channel?.name?.toLowerCase()}`, { onCopy: setCopied('copied') })}>
                        <HiOutlineClipboardDocument />
                        {copied && <p className='text-copied'>Copied!</p>}
                    </button>
                    <Tooltip id="my-tooltip" />
                </div>
            </div>
        </div>
    )
}

export default VideoUrlSection