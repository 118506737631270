import React, { useState } from 'react';
import { useEffect } from 'react';
import { ENV } from '../../../config/config';
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { getRecommendedVideos, beforeRecommendedVideos } from '../MemberDashboard/Videos/video.actions';
import './player.css';
import SmallLoader from '../../../utils/SmallLoader/SmallLoader';
import { FaPlayCircle } from 'react-icons/fa';
import { recommendedVideosSection } from '../ApplicationTour/TourCompIdentifiers';
import Favourite from '../UserComponents/Favourites/FavouriteComponent';
const { defaultPlaceholderImage } = ENV

const RecommendedVideos = (props) => {
    const dispatch = useDispatch()
    const userId = ENV.getUserKeys('_id')?._id

    const [loader, setLoader] = useState(true);
    const [recommendedVideos, setVideos] = useState([])
    const [pagination, setPagination] = useState({})
    const [loadMore, setLoadMore] = useState(false);

    const videosSelector = useSelector((state) => state.videos)

    useEffect(() => {
        if (props.slug) {
            dispatch(getRecommendedVideos(`slug=${props.slug}&userId=${userId}`))
        }
    }, [props.slug])


    useEffect(() => {
        if (videosSelector.recommendedVideosAuth) {
            let { videos, pagination } = videosSelector.recommendedVideosData?.data
            setVideos(loadMore ? recommendedVideos.concat(videos) : videos)
            setPagination(pagination)
            setLoader(false)
            setLoadMore(false)
            dispatch(beforeRecommendedVideos())

        }
    }, [videosSelector.recommendedVideosAuth])

    const loadMoreVideos = () => {
        setLoadMore(true)
        setLoader(true)

        const { page } = pagination
        dispatch(getRecommendedVideos(`slug=${props.slug}&page=${page + 1}`))
    }

    return (
        <>
            {
                loader &&
                <SmallLoader height={40} width={40} />
            }
            <div className='v-custom-sitecards recomemded-videos'>
                <h5 className='section-heading' id={recommendedVideosSection}>Watch more recommended videos</h5>
                <Row>
                    {
                        recommendedVideos && recommendedVideos?.length ?
                            recommendedVideos.map((video, index) => (
                                <Col md={12} key={index}>
                                    <div className='exploreCard d-flex'>
                                        <div className='exploreImg'>
                                            <a href={`/media-player/${video?.slug}/${video?.channel?.name?.toLowerCase()}`} rel='noreferrer' className='position-relative d-inline-block align-top'>
                                                <img className='img-fluid video-img' src={video.thumbnail || defaultPlaceholderImage} alt={'thumbnail'}></img>
                                                <div className='player-icon'><FaPlayCircle /></div>
                                            </a>
                                        </div>
                                        <div className='cardFooter flex-column'>
                                            <div className="d-flex mb-3">
                                                <div className='userImg me-3'>
                                                    <img className='img-fluid' src={video.channel?.image || defaultPlaceholderImage} alt={'channel'}></img>
                                                </div>
                                                <div className='videoReview'>
                                                    <p>{video.title}</p>
                                                    <span>{video.totalViews || 0}  {video?.totalViews > 1 ? 'Views' : 'View'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )) :
                            <div className="no-video-found d-flex justify-content-center align-items-center">
                                <span>No Video Found</span>
                            </div>
                    }
                </Row>
                {
                    pagination.total > recommendedVideos?.length ?
                        <div className='text-center w-100 d-block'>
                            <button className="orange-btn text-uppercase mx-auto" onClick={loadMoreVideos}>Load more</button>
                        </div>
                        :
                        null
                }
            </div>
        </>
    );
}

export default RecommendedVideos