import React from 'react';
import './Explore.css'
import { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { getAllPublicVideoContent, beforePublicVideoContent, getCategories, beforeVideo, createVideoStats, beforeVideoStats, getTopVideosViews, getTrendingVideos } from "../../MemberDashboard/Videos/video.actions";
import { ENV } from '../../../../config/config';
import { Form } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { FaPlayCircle } from 'react-icons/fa';
import Favourite from '../../UserComponents/Favourites/FavouriteComponent';
import { exploreVideosSearch, exploreVideosSelect } from '../../ApplicationTour/TourCompIdentifiers';
import Slider from "react-slick";
import { Images } from '../../../../assets/assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRightLong, faCircle, faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Helmet } from 'react-helmet-async/lib/index.esm';
const { getUserKeys, statTypes } = ENV

const ExploreVideos = (props) => {
    const navigate = useNavigate()

    let userData = getUserKeys();

    const [videoPublicContent, setPublicvideoContent] = useState(null);
    const [meta, setMeta] = useState({ title: '', description: '' })
    const [pageSize, setPageSize] = useState(16);
    const { url } = useParams();
    const [loader, setLoader] = useState(true)
    const [total, setTotal] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [categories, setCategories] = useState();
    const [selected, setSelected] = useState()
    const [searched, setSearched] = useState(false)
    const [show, setShow] = useState(false)
    const [pagination, setPagination] = useState({})
    const [loadMore, setLoadMore] = useState(false)

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <button type="button" className='slick-arrow slick-prev' id='left-arrow-btn' onClick={onClick}>
                <FontAwesomeIcon icon={faArrowLeftLong} className='arrow' />
            </button>
        );
    }

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <button type="button" className='slick-arrow slick-next' id='right-arrow-btn' onClick={onClick} >
                <FontAwesomeIcon icon={faArrowRightLong} className='arrow' />
            </button>
        );
    }
    const [topVideos, setTopVideos] = useState({})
    const [settings, setSettings] = useState({
        dots: true,
        // infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        dots: false,
        // autoplay:true,
        autoplaySpeed: 3500,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 2056,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 1921,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });
    // const [topVideoSettings, setSettings] = useState({...settings})
    // const [topVideoSettings, setSettings] = useState({...settings})

    const [trending, setTrending] = useState([])

    useEffect(() => {
        props.getTopVideosViews();
        props.getTrendingVideos();
        const qs = ENV.objectToQueryString({
            page: 1,
            limit: pageSize,
        });
        props.getAllPublicVideoContent(qs);
        if (userData.type === 2)
            props.getCategories();
    }, []);

    useEffect(() => {
        if (props.videos.getTrendingVideosAuth) {
            setTrending(props.videos.getTrendingVideos.trending)
        }
    }, [props.videos.getTrendingVideosAuth])

    useEffect(() => {
        if (props.videos.getTopVideosViewsAuth) {
            setTopVideos(props.videos.getTopVideosViews.topVideos)
            setSettings({ ...settings, infinite: props.videos.getTopVideosViews.topVideos.length > 4 })

        }

    }, [props.videos.getTopVideosViewsAuth])


    useEffect(() => {
        reset();
        setSearchTerm("")
        setLoader(true)
        if (url && userData.type == 1) {
            let qs = ENV.objectToQueryString({ url: url, userId: userData?._id || '' });
            props.getAllPublicVideoContent(qs);
        }
        if (url === undefined && userData.type == 1) {
            const qs = ENV.objectToQueryString({
                page: 1,
                limit: pageSize,
            });
            props.getAllPublicVideoContent(qs);
        }
    }, [url])

    useEffect(() => {
        if (searched && !searchTerm) {
            const qs = ENV.objectToQueryString({
                page: 1,
                limit: pageSize,
            });
            props.getAllPublicVideoContent(qs);
            setLoader(true)
            setSearched(false)
        }

    }, [searchTerm])

    useEffect(() => {
        if (props.videos.publicVideoContentAuth) {
            let data = props.videos.publicVideoContent.videos
            let { category } = props.videos.publicVideoContent
            setLoader(false)
            setPublicvideoContent(loadMore ? videoPublicContent.concat(data) : data)
            setPagination(props.videos.publicVideoContent.pagination)
            setTotal(props.videos.publicVideoContent.pagination.total);
            setLoadMore(false)
            setMeta({ title: category?.metaTitle, description: category?.metaDescription })

            let videoIds = data.map((vid) => {
                return { id: vid._id, channelId: vid.channelId }
            })

            if (videoIds?.length) {
                let statsPayload = { type: statTypes.impressions, videoIds }
                if (userData?._id)
                    statsPayload.userId = userData?._id
                props.createVideoStats({ ...statsPayload })
            }

            if (searched)
                setShow(true)
            props.beforePublicVideoContent();
        }
    }, [props.videos.publicVideoContentAuth]);

    useEffect(() => {
        if (userData.type === 2 && props.videos.videoCategoriesAuth) {
            setCategories(props.videos.videoCategories.categories);
            props.beforeVideo();
        }
    }, [props.videos.videoCategoriesAuth]);

    useEffect(() => {
        if (props.videos.createVideoStatAuth) {
            props.beforeVideoStats()
        }

    }, [props.videos.createVideoStatAuth])


    const reset = () => {
        setPageSize(16)
    }

    async function LoadMore() {
        setLoadMore(true)
        setLoader(true)

        let obj = {};
        obj.page = pagination.page + 1;
        obj.limit = pageSize;
        obj.userId = userData?._id || '';
        obj.loadMore = true;
        if (userData.type == 1) {
            if (url) {
                obj.url = url;
            }
        }
        if (userData.type == 2) {
            if (selected) {
                obj.categoryId = selected;
            }
        }
        if (searchTerm) {
            obj.searchTerm = searchTerm;
        }
        const qs = ENV.objectToQueryString(obj);
        props.getAllPublicVideoContent(qs);
    }
    const onChangeSelect = (e) => {
        setSearchTerm("");
        setSelected(e.target.value)
        reset();
        setLoader(true)
        let qs = ENV.objectToQueryString({ categoryId: e.target.value, userId: userData?._id || '', loadMore: false, limit: pageSize });
        props.getAllPublicVideoContent(qs);
    }
    const getSearch = (e) => {
        reset();
        let obj = {};
        obj.userId = userData?._id || '';
        obj.loadMore = false;
        obj.searchTerm = searchTerm;
        if (url) {
            obj.url = url;
        }
        if (selected) {
            obj.categoryId = selected;
        }
        setLoader(true)
        setSearched(true)
        const qs = ENV.objectToQueryString(obj);
        props.getAllPublicVideoContent(qs);
    }

    const resetSearch = () => {
        setLoader(true)
        setSearched(false)
        setSearchTerm('')
        setSelected('')
        setShow(false)
        props.getAllPublicVideoContent();
    }

    const onClickHandler = (video) => {
        console.log(video)
        let slug = video?.slug || video?.video?.slug
        let channelName = video?.channelName || video?.channel?.name

        let url = `/media-player/${slug}/${channelName}`

        // navigate(url)
        window.location.href = url;
        let statsPayload = {
            channelId: video.channelId,
            type: statTypes.impressionsRate,
            videoId: video._id
        }

        if (userData?._id)
            statsPayload.userId = userData._id

        props.createVideoStats({ ...statsPayload })

    }

    return (
        <>
            {loader && <FullPageLoader />}
            {
                meta?.title && meta?.description &&
                <Helmet>
                    <title>{meta?.title}</title>
                    <meta name="description" content={meta?.description} />
                </Helmet>
            }
            <div className='explore explore-sec pt-0 '> {/* px40  */}
                <div className="video-slider-block top-videos">
                    <div className="heading-block mb-3">
                        <h3>Top Videos</h3>
                    </div>
                    {topVideos?.length > 0 ?
                        <div className="video-slider">
                            <Slider {...settings}>
                                {topVideos.map((item, i) => (
                                    <div key={i}>
                                        <div className="slide-holder" >
                                            <div className="img-holder" /* onClick={() => onClickHandler(item)} */>
                                                <img src={item?.video?.thumbnail} alt={item?.video?.title} />
                                                <div className="play-icon-holder d-flex justify-content-center align-items-center" onClick={() => onClickHandler(item)}>
                                                    <FontAwesomeIcon icon={faCirclePlay} alt="" />
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="author-pic">
                                                    <img className="img-fluid" src={item?.user?.profileImage} alt="" />
                                                </div>
                                                <div className="flex-fill d-flex flex-column video-meta-content">
                                                    <strong className="video-title">{item?.video?.title}</strong>
                                                    <Link to={`/channel-details/${encodeURIComponent(item?.channel?.name)}`}><span className="channel-name">{item?.channel?.name}</span></Link>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <span className="video-cat">{item?.video?.category}</span>
                                                        {/* <span className="video-cat">16-11-2023</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        : <div className="no-record-found d-flex justify-content-center align-items-center">No Record Found</div>
                    }
                </div>
                <div className="video-slider-block trending-videos">
                    <div className="heading-block mb-3">
                        <h3>Trending</h3>
                    </div>
                    {trending?.length > 0 ?
                        <div className="video-slider">
                            <Slider {...settings}>
                                {trending.map((item, i) => (
                                    <div key={i}>
                                        <div className="slide-holder" /* onClick={() => onClickHandler(item)} */>
                                            <div className="img-holder">
                                                <img src={item?.video?.thumbnail} alt={item?.video?.title} />
                                                <div className="play-icon-holder d-flex justify-content-center align-items-center" onClick={() => onClickHandler(item)}>
                                                    <FontAwesomeIcon icon={faCirclePlay} alt="" />
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="author-pic">
                                                    <img className="img-fluid" src={item?.user?.profileImage} alt="" />
                                                </div>
                                                <div className="flex-fill d-flex flex-column video-meta-content">
                                                    <strong className="video-title">{item?.video?.title}</strong>
                                                    <Link to={`/channel-details/${encodeURIComponent(item?.channel?.name)}`}><span className="channel-name">{item?.channel?.name}</span></Link>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <span className="video-cat">{item?.video?.category}</span>
                                                        {/* <span className="video-cat">16-11-2023</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        : <div className="no-record-found d-flex justify-content-center align-items-center">No Record Found</div>
                    }
                </div>
                <div className="dashboardHeader">
                    <h3>All Others</h3>
                </div>
                {/* <section className='dashboardHeader pt-0 d-flex justify-content-between flex-wrap'>
                    {userData && userData.type == 2 && categories &&
                        <div className='select-category-dropdown d-flex mb-3 flex-wrap align-items-center'>
                            <label className='me-3'>Category </label>
                            <div className='select-items custom-form-select'>
                                <Form.Select
                                    id={exploreVideosSelect}
                                    value={selected}
                                    onChange={onChangeSelect}
                                    className="pe-2"
                                >
                                    <option key={0} value={""}>
                                        All
                                    </option>
                                    {categories.map((category, index) => (
                                        <option key={index} value={category._id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                    }
                    <div className='explore-search-videos d-flex mb-3 ms-auto flex-column flex-sm-row'>
                        <div className="fileGroupList w-100" id={exploreVideosSearch}>
                            <Form.Group>
                                <Form.Control
                                    name="searchTerm"
                                    value={searchTerm}
                                    placeholder="Title, Description or Keyword"
                                    type="text"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="mb-0 explore-search-input"
                                ></Form.Control>
                            </Form.Group>
                        </div>
                        <div className='d-flex me-auto'>
                            <button className="orange-btn text-uppercase mt-0 category-video-btn explore-search-btn ms-2" hidden={!searchTerm} onClick={getSearch}>Search</button>
                            <button className="orange-btn text-uppercase mt-0 category-video-btn explore-search-btn ms-2" hidden={!show} onClick={resetSearch}>Reset</button>
                        </div>
                    </div>
                </section> */}
                <div className="other-videos">
                    <div className="d-flex flex-wrap">
                        {videoPublicContent?.length > 0 ? (
                            videoPublicContent.map((exploreData, index) => {
                                return (
                                    <div className="video-holder" key={index}>
                                        <div className="img-holder">
                                            <img src={exploreData.thumbnails[0].localUrl} alt={exploreData.name} />
                                            <div className="play-icon-holder d-flex justify-content-center align-items-center" onClick={() => onClickHandler(exploreData)}>
                                                <FontAwesomeIcon icon={faCirclePlay} alt="" />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="author-pic">
                                                <img className="img-fluid" src={exploreData.user.profileImage} alt={exploreData.user.name} />
                                            </div>
                                            <div className="flex-fill d-flex flex-column video-info">
                                                <strong className="video-title">{exploreData.title}</strong>
                                                <Link to={`/channel-details/${encodeURIComponent(exploreData?.channelName)}`}><span className="channel-name">{exploreData?.channelName}</span></Link>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span className="channel-name">{exploreData?.views ? exploreData.views : 0} {exploreData?.views > 1 ? 'Views' : 'View'}</span>
                                                    <span className="video-cat">{moment(exploreData.createdAt).format('DD-MM-YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )
                            :
                            <div className="no-record-found d-flex justify-content-center align-items-center">No Record Found</div>

                        }
                    </div>
                </div>
                {/* <div className='wrapVideos'>
                    {videoPublicContent?.length > 0 ? (
                        videoPublicContent.map((exploreData, index) => {
                            return (
                                <div className='exploreCard' key={index}>
                                    <div className='exploreImg pointer position-relative' onClick={() => onClickHandler(exploreData)} >
                                        <img className='img-fluid video-img' src={exploreData.thumbnails[0].localUrl} alt={exploreData.name}></img>
                                        <div className='player-icon'><FaPlayCircle /></div>
                                    </div>
                                    <Favourite
                                        _id={exploreData._id}
                                        count={exploreData.favouritesCount}
                                        userFavourite={exploreData.userFavourite}
                                        content={videoPublicContent}
                                        setContent={setPublicvideoContent}
                                    />
                                    <div className='cardFooter'>
                                        <div className='userImg me-3'>
                                            <img className='img-fluid' src={exploreData.user.profileImage} alt={exploreData.user.name}></img>
                                        </div>
                                        <div className='videoReview pointer' onClick={() => onClickHandler(exploreData)}>
                                            <p>{exploreData.title}</p>
                                            <span>{exploreData?.views ? exploreData.views : 0} {exploreData?.views > 1 ? 'Views' : 'View'}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <>No Record Found</>
                    )

                    }

                </div> */}
                {
                    (total > videoPublicContent?.length) ?
                        (<div className='text-center w-100 d-block'>
                            <button className="orange-btn load-more-btn text-uppercase mx-auto" onClick={LoadMore}>Load more</button>
                        </div>)
                        :
                        ("")
                }
            </div >

        </>
    )
}

const mapStateToProps = (state) => ({
    videos: state.videos,
});

export default connect(mapStateToProps, {
    getAllPublicVideoContent,
    beforePublicVideoContent,
    getCategories,
    beforeVideo,
    createVideoStats,
    beforeVideoStats,
    getTopVideosViews,
    getTrendingVideos
})(ExploreVideos);
