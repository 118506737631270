import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { beforeChat, getChat, sendMessage } from './contactAdmin.actions';
import moment from 'moment';

const ContactAdmin = () => {

    const dispatch = useDispatch()
    const channelId = localStorage.getItem('channelId')
    // const publisherId = ENV.getUserKeys('_id')?._id

    const [message, setMessage] = useState('')
    const [channelDetails, setChannel] = useState({})
    const [loader, setLoader] = useState(true)
    const [submitted, setSubmitted] = useState(false)
    const [chatDetails, setChat] = useState([])
    const [errors, setErrors] = useState({})
    const [location, setLocation] = useState({
        lat: 0,
        lng: 0,
    })
    const [mapsData, setMapData] = useState()
    const contactAdminSelector = useSelector((state) => state.chat)
    const contactSelector = useSelector((state) => state.contact)

    useEffect(() => {
        if (channelId)
            dispatch(getChat(`channelId=${channelId}`))
    }, [])

    useEffect(() => {
        if (contactAdminSelector.getChatAuth) {
            let { chat, channel } = contactAdminSelector.getChatData
            setChat(chat)
            setLoader(false)
            setChannel({ ...channel })
            dispatch(beforeChat())
        }
    }, [contactAdminSelector.getChatAuth])

    const submit = () => {
        if (message) {
            setErrors({})
            setSubmitted(true)
            setChat([...chatDetails, { message, sentBy: 1, createdAt: new Date() }])
            dispatch(sendMessage({ channelId, message }))
            setMessage('')
        }
        else {
            setErrors({...errors, message: 'Please type a message to send!'})
        }

    };

    return (
        <>
            {loader && <FullPageLoader />}
           <div className='position-relative contact-main'>
            <div className='contact-wrapper'>
                    {/* <Container className=''> */}
                        {/* <Row className="d-flex justify-content-end" >                    */}
                            {/* <Col lg={12}> */}
                                {
                                    chatDetails?.length ?
                                        chatDetails.map((message) => {
                                            return (
                                                <>
                                                    {
                                                        message?.sentBy === 0 ?
                                                            <div>
                                                                <p>{message?.message}</p>
                                                                <div className='userImg me-2'>
                                                                    <img src={message?.admin?.image} className="img-fluid" alt={`${message?.admin?.name}`} />
                                                                </div>

                                                                <p>{message?.admin?.name}</p>
                                                                <p>{moment(message?.createdAt).format("DD-MM-YYYY HH:mm")}</p>
                                                            </div> :
                                                            message?.sentBy === 1 ?
                                                                <div>
                                                                    <p>{message?.message}</p>
                                                                    <p>{channelDetails?.name}</p>
                                                                    <div className='userImg me-2'>
                                                                        <img src={channelDetails?.channelImageLocal} className="img-fluid" alt={'sender-channel'} />
                                                                    </div>

                                                                    <p>{moment(message?.createdAt).format("DD-MM-YYYY HH:mm")}</p>
                                                                </div> :
                                                                null
                                                    }

                                                </>
                                            )
                                        }) :
                                        <p>No messages yet!</p>
                                }
                            {/* </Col> */}
                            {/* <Col lg={12}> */}
                                {/* <div className='help-sec'>
                                        <div className='form-wrapper'> */}
                                <Form className='send-message-from'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Message <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control as="textarea" type="text" placeholder="Type Message" name='name' value={message} onChange={(e) => setMessage(e.target.value)} />
                                        {
                                            errors && errors.message &&
                                            <span className='text-danger'>{errors.message}</span>
                                        }
                                    </Form.Group>
                                    <div className='submit-btn'>
                                        <Button variant="" onClick={submit}>Send</Button>
                                    </div>
                                </Form>
                                {/* </div>
                                    </div> */}
                            {/* </Col> */}
                        {/* </Row> */}
                    {/* </Container> */}
                </div>
           </div>
        </>
    )
}

export default ContactAdmin