import React from 'react'
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import { analyticsSelect } from '../../components/Frontend/ApplicationTour/TourCompIdentifiers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { ENV } from '../../config/config';


const DateTimeFilters = ({ date, startTime, endTime, setStartTime, setEndTime, selected, resetFiltersHandler, onSearchHandler, durationChangeHandler }) => {

    const durationList = [
        { value: '24', label: 'Last 24 hours', unit: 'hours' },
        { value: '7', label: 'Last 7 days', unit: 'days' },
        { value: '15', label: 'Last 15 days', unit: 'days' },
        { value: '30', label: 'Last 30 days', unit: 'days' },
        { value: 'custom', label: 'Custom' }
    ];

    // const durationChangeHandler = (e) => {
    //     if ((e.value === 'custom') || e.value !== selected.value) {

    //         if (e.value === 'custom') {
    //             setModal(true)
    //             setCustom(false)
    //         }

    //         setSelected({ value: e.value, label: e.label, unit: e.unit })
    //     }
    // }

    return (
        <div className=''>
            <div className='analytic-box mb-3 mb-md-5'>
                <div className="row">
                    <div className="col-md-4">
                        <div className="analytic-block">
                            {
                                date?.startDate && date?.endDate && startTime && endTime &&
                                <p className='me-1 mb-2'>{`${date?.startDate} ${startTime} – ${date?.endDate} ${endTime}`}</p>
                            }
                            <div className='searchByDays align-items-center'>
                                <div><h4 className='mb-1'>{selected?.key || durationList[0]?.key}</h4></div>
                                <div className='select-items custom-react-select w-100'>
                                    <Select id={analyticsSelect} classNamePrefix="triage-select" className='iconDD barDD'
                                        placeholder='Select Type'
                                        options={durationList}
                                        value={selected}
                                        onChange={durationChangeHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="analytic-block">
                                    <div className='d-flex'>
                                        <Form.Group className='formGroup me-3 w-75' controlId="formBasicEmail">
                                            <Form.Label><div className='d-flex align-items-center'>Start Time<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                            <TimePicker clearIcon={false} onKeyDown={(e) => ENV.integerNumberValidator(e)} onChange={setStartTime} value={startTime} locale="sv-sv" format='H:mm' disableClock={true} />
                                        </Form.Group>
                                        <Form.Group className='formGroup me-3 w-75' controlId="formBasicEmail">
                                            <Form.Label><div className='d-flex align-items-center'>End Time<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                            <TimePicker clearIcon={false} onKeyDown={(e) => ENV.integerNumberValidator(e)} onChange={setEndTime} value={endTime} locale="sv-sv" format='H:mm' disableClock={true} />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="analytic-block">
                                    <label className="d-block">&nbsp;</label>
                                    <div className='d-flex align-items-center justify-content-between btns-holder'>
                                        <button className="mt-0 orange-main-button text-uppercase" onClick={resetFiltersHandler}><FontAwesomeIcon icon={faArrowsRotate} /></button>
                                        <button className="mt-0 black-btn text-uppercase" onClick={onSearchHandler}><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=''>
                    
                </div>
            </div>
        </div>
    )
}


export default DateTimeFilters