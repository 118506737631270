import React, { useState } from 'react';
import { useEffect } from 'react';
import $ from 'jquery';
import VideoJS from './VideoJS'
import videojs from 'video.js';
import { ENV } from '../../../config/config';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { getVideoMetadata, beforeVideoMetadata } from './player.action';
import { Container, Form, Modal, Button } from 'react-bootstrap'
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import RecommendedVideos from './RecommendedVideos';
import VideoDetails from './VideoDetails';
import { createVideoStats, beforeVideoStats } from '../MemberDashboard/Videos/video.actions';
import { getChannelRole, beforeChannelRole } from '../ChannelRoles/channelRoles.actions';
// import { use } from 'echarts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async/lib/index.esm';
import VideoComments from '../MemberDashboard/Videos/VideoComments';
import './player.css';

let metadata = [];
let videoTitle = '';
let subtitleTracksList = [];
let subtitlesSource = '';
let offsetX = 0;
let offsetY = 0;
let renderedWidth = 0;
let renderedHeight = 0;
let mouseIdDown = false;
let intentionalCheck = false
var objects = {
    'person': '#DFFF00',
    'bicycle': '#C0C0C0',
    'car': '#808080',
    'motorcycle': '#000000',
    'airplane': '#FF0000',
    'bus': '#800000',
    'train': '#FFFF00',
    'truck': '#808000',
    'boat': '#00FF00',
    'traffic light': '#008000',
    'fire hydrant': '#00FFFF',
    'stop sign': '#008080',
    'parking meter': '#0000FF',
    'bench': '#000080',
    'bird': '#FF00FF',
    'cat': '#800080',
    'dog': '#DFFF00',
    'horse': '#FFBF00',
    'sheep': '#FF7F50',
    'cow': '#DE3163',
    'elephant': '#9FE2BF',
    'bear': '#40E0D0',
    'zebra': '#6495ED',
    'giraffe': '#CCCCFF',
    'backpack': '#F0FFFF',
    'umbrella': '#89CFF0',
    'handbag': '#7393B3',
    'tie': '#088F8F',
    'suitcase': '#0096FF',
    'frisbee': '#0047AB',
    'skis': '#5F9EA0',
    'snowboard': '#6495ED',
    'sports ball': '#00FFFF',
    'kite': '#00008B',
    'baseball bat': '#6F8FAF',
    'baseball glove': '#1434A4',
    'skateboard': '#7DF9FF',
    'surfboard': '',
    'tennis racket': '#6082B6',
    'bottle': '#00A36C',
    'wine glass': '#3F00FF',
    'cup': '#5D3FD3',
    'fork': '#ADD8E6',
    'knife': '#191970',
    'spoon': '#1F51FF',
    'bowl': '#A7C7E7',
    'banana': '#CCCCFF',
    'apple': '#96DED1',
    'sandwich': '#4169E1',
    'orange': '#0F52BA',
    'broccoli': '#9FE2BF',
    'carrot': '#87CEEB',
    'hot dog': '#4682B4',
    'pizza': '#008080',
    'donut': '#40E0D0',
    'cake': '#0437F2',
    'chair': '#40B5AD',
    'couch': '#0818A8',
    'potted plant': '#EADDCA',
    'bed': '#E1C16E',
    'dining table': '#CD7F32',
    'toilet': '#DAA06D',
    'tv': '#800020',
    'laptop': '#E97451',
    'mouse': '#D27D2D',
    'remote': '#6F4E37',
    'keyboard': '#B87333',
    'cell phone': '#8B0000',
    'microwave': '#988558',
    'oven': '#C2B280',
    'toaster': '#C19A6B',
    'sink': '#E5AA70',
    'refrigerator': '#966919',
    'book': '#F0E68C',
    'clock': '#C04000',
    'vase': '#967969',
    'scissors': '#CC7722',
    'teddy bear': '#808000',
    'hair drier': '#4A0404',
    'toothbrush': '#A95C68'
}

const Player = (props) => {
    const playerRef = React.useRef(null);
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams();
    const [loader, setLoader] = useState(false);
    // const [mouseX, setMouseX] = useState(0);
    // const [mouseY, setMouseY] = useState(0);
    const [addCustomClass, setAddCustomClass] = useState(true);
    // const [videoId, setVideoId] = useState('');
    const [videoOptions, setVideoOptions] = useState({
        autoplay: true,
        controls: true,
        loop: true,
        responsive: true,
        metadata: true,
        sources: [{
            src: 'https://static.vecteezy.com/system/resources/previews/004/844/745/mp4/icon-loading-10-circle-gradient-angle-loop-out-black-background-gradient-animation-for-game-animation-and-others-free-video.mp4', //`${ENV.streamingUrl}${1684479039317}/output.m3u8`,
            type: 'video/mp4'
        }],
        //https://kot-politiken.s3-eu-west-1.amazonaws.com/2019/114_en.vtt.txt
        //'http://192.168.9.231/videos/1694591644861/subtitle.vtt.txt'
        // tracks: [{ src: '', kind: 'captions', srclang: 'en', label: 'English' }]

    })
    const { id, channel } = useParams()
    const [statId, setStatId] = useState('')
    const [nDuration, setNDuration] = useState(0)
    const [currentWatchTime, setCurrentTime] = useState(0)
    const [socket, setSocket] = useState(null)
    const [clickedObject, setObject] = useState()
    const [openModal, setModal] = useState({})
    const [metaTitle, setMetaTitle] = useState('')
    const [metaDesc, setMetaDesc] = useState('')

    const userId = ENV.getUserKeys('_id')?._id
    const userType = ENV.getUserKeys('type')?.type

    const channelName = channel
    const userChannelId = localStorage.getItem('channelId')
    const adminSecretKey = searchParams.get("key")

    window.onbeforeunload = () => {
        if (currentWatchTime > 0 && statId && socket) {
            emitWatchTimeEvent({ statId, watchTime: currentWatchTime })
        }
    }

    const setVideoSubtitle = (subtitleTracks) => {
        // subtitleTracksList = [
        //     {
        //         kind: 'captions',
        //         src: 'https://solutions.brightcove.com/bcls/assets/captions/intro-vcs.vtt', 
        //         srclang: 'en',
        //         label: 'English'
        //     },
        //     {
        //         src: 'https://solutions.brightcove.com/bcls/assets/captions/intro-vcs-fr.vtt',
        //         srclang: 'fr',
        //         label: 'French',
        //         kind: 'captions'
        //     },
        //     {
        //         kind: 'captions',
        //         src: 'https://solutions.brightcove.com/bcls/assets/captions/intro-vcs-jp.vtt' ,
        //         srclang: 'ja',
        //         label: 'Japanese'
        //     }
        // ]
        if (subtitleTracks && subtitleTracks?.length) {
            subtitleTracksList = subtitleTracks.map((item) => {
                const newObj = {};

                newObj.src = item.cloudUrl;
                newObj.kind = 'captions';
                newObj.srclang = item.language?.code || '';
                newObj.label = item.language?.label;

                return newObj;
            })
        }

    }

    useEffect(() => {
        if (props.notifications.clientSocket) {
            setSocket(props.notifications.clientSocket)
        }
    }, [props.notifications.clientSocket])

    useEffect(() => {
        if (id) {
            props.getVideoMetadata(id)

            // fetch member role
            if (userType == 2)
                props.getChannelRole(`channelId=${userChannelId}&userId=${userId}`)
        }
    }, [id])

    useEffect(() => {
        if (channelName && id && !adminSecretKey)
            // create stat of type view
            props.createVideoStats({ userId, slug: id, type: ENV.statTypes.viewed, channelName })

    }, [channelName, id])

    useEffect(() => {
        if (props.videos.createVideoStatAuth) {
            setStatId(props.videos.createVideoStatData?.statId)
            props.beforeVideoStats()
        }

    }, [props.videos.createVideoStatAuth])

    useEffect(() => {
        if (props.roles.channelRoleAuth) {
            let role = props.roles.channelRoleData.memberRole
            if (!role.viewChannelVideos)
                navigate('/my-videos')
            props.beforeChannelRole()

        }
    }, [props.roles.channelRoleAuth])

    useEffect(() => {
        if (props.video.getVideoMetadataAuth) {
            metadata = props.video.getVideoMetadata.metadata["meta-data"];
            videoTitle = props.video.getVideoMetadata.metadata['video-title'].split(".")[0]
            // subtitlesSource = process.env.REACT_APP_BACKEND_BASE_URL + `videos/${videoTitle}/subtitle.vtt.txt`
            console.log("UMER SURKHAIL HERE IT IS NOW WORKING I AM", {
                autoplay: true,
                controls: true,
                loop: false,
                responsive: true,
                metadata: true,
                sources: [{
                    src: `${ENV.streamingUrl}${videoTitle}/output.m3u8`,
                    type: 'application/x-mpegURL'
                }],
                tracks: subtitleTracksList
            })
            setVideoOptions({
                autoplay: true,
                controls: true,
                loop: false,
                responsive: true,
                metadata: true,
                sources: [{
                    src: `${ENV.streamingUrl}${videoTitle}/output.m3u8`,
                    type: 'application/x-mpegURL'
                }],
                tracks: subtitleTracksList
            })
            setAddCustomClass(false)
            // var element = document.getElementById("custom-player");
            // element.classList.remove("custom-controls");

            setLoader(false)
            props.beforeVideoMetadata()
        }
    }, [props.video.getVideoMetadataAuth])

    useEffect(() => {
        if (videoOptions.sources[0]?.src) {
            //get player, video width, height
            setTimeout(() => {
                calculateDimensions(false)
            }, 1000)
        }
    }, [videoOptions])

    const calculateDimensions = async (fullScreenToggle = false) => {
        let playerWidth = ''
        let playerHeight = ''

        if (fullScreenToggle) {
            playerWidth = window.screen.width;
            playerHeight = window.screen.height;
            //     //$("custom-player").width(screenWidth);
            //     // $("#custom-player").height(screenHeight);
            //     // $("#custom-player").css({"position": "absolute", "top": "0", "left": "0"})
            //     playerWidth = screenWidth;
            //     playerHeight = screenHeight;
        } else {
            playerWidth = $("video-js").width();
            playerHeight = $("video-js").height();
        }

        const videoWidth = playerRef.current.videoWidth();
        const videoHeight = playerRef.current.videoHeight();

        // console.log('Video height', videoHeight)
        // if(videoWidth > videoHeight) {
        //     renderedWidth = playerWidth;
        //     //100-500 = => -400
        //     let widthDifference = videoWidth - renderedWidth;
        //     if(widthDifference < 0) widthDifference *= -1;
        //     //% = o/t*100;
        //     let percentChangeInWidth  = (widthDifference / videoWidth) * 100;
        //      // % * t / 100 = o;
        //     renderedHeight = (percentChangeInWidth * videoHeight) / 100;
        //     let verticalBlanckSpaceEachSide = (playerHeight - renderedHeight) / 2;
        //     offsetX = 0;
        //     offsetY = verticalBlanckSpaceEachSide;
        // }
        // else if(videoHeight > videoWidth) {
        renderedHeight = playerHeight
        //100-500 = => -400
        // 360-666

        let heightDifference = videoHeight - renderedHeight;

        if (heightDifference < 0) heightDifference *= -1;
        //% = o/t*100;
        let percentChangeInHeight = (heightDifference / videoHeight) * 100;
        if (renderedHeight > videoHeight) {
            // % * t / 100 = o;
            renderedWidth = videoWidth + (percentChangeInHeight * videoWidth) / 100;
        }
        else {
            renderedWidth = videoWidth - (percentChangeInHeight * videoWidth) / 100;
        }

        let verticalBlanckSpaceEachSide = (playerWidth - renderedWidth) / 2;
        offsetX = verticalBlanckSpaceEachSide;
        offsetY = 0;


        // }
        // else {
        //     renderedWidth = playerWidth;
        //     renderedHeight = playerHeight;
        //     offsetX = 0;
        //     offsetY = 0;
        // }

        // $("#custom-player").append(`<div id="custom-objects" style="width:${renderedWidth}px; border:1px solid green; height:${renderedHeight}px; position: absolute; left: ${offsetX}px; top:${offsetY}px"></div>`)

    }

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting', player.tech());
            $(".detected-elements").remove();
            player.one('canplay', () => {
                videojs.log('video can play', player.tech());
                player.play();

            });
        });

        player.on('play', () => {
            videojs.log('Video is playing');
            $(".detected-elements").remove();
        });

        player.on('pause', () => {
            videojs.log('Video is pause');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });

        player.on('fullscreenchange', function (e) {
            $(".detected-elements").remove();
            if (player.isFullscreen()) {
                calculateDimensions(player.isFullscreen())
            } else {
                calculateDimensions(false)
            }
        })

        player.on('timeupdate', () => {
            onDurationChangeHandler()
        });

        // player.tech().vhs.playlists.media().attributes
    };

    const onDurationChangeHandler = () => {
        let seconds = playerRef.current.currentTime()
        let duration = playerRef.current.duration()
        setCurrentTime(seconds)

        let currentTimeInSec = Math.ceil(seconds)
        if (currentTimeInSec > 0) {
            if (currentTimeInSec < duration) {
                if ((currentTimeInSec % 60) === 0)
                    setNDuration(currentTimeInSec)
            }
            else {
                setNDuration(duration)
            }

        }
    }

    const emitWatchTimeEvent = (payload) => {
        socket.emit('watchTimeStat', { message: 'Event Emmitted with Payload', ...payload })
    }

    useEffect(() => {
        if (nDuration > 0 && statId && socket) {
            emitWatchTimeEvent({ statId, watchTime: nDuration })
        }
    }, [nDuration, statId, socket])

    // useEffect(() => {
    //     // Part 1:
    //     var vid = document.querySelector("video");
    //     var last_media_time, last_frame_num, fps;
    //     var fps_rounder = [];
    //     var frame_not_seeked = true;

    //     // Part 2 (with some modifications):
    //     function ticker(useless, metadata) {
    //         var media_time_diff = Math.abs(metadata.mediaTime - last_media_time);
    //         var frame_num_diff = Math.abs(metadata.presentedFrames - last_frame_num);
    //         var diff = media_time_diff / frame_num_diff;
    //         if (
    //             diff &&
    //             diff < 1 &&
    //             frame_not_seeked &&
    //             fps_rounder.length < 50 &&
    //             vid.playbackRate === 1 &&
    //             document.hasFocus()
    //         ) {
    //             fps_rounder.push(diff);
    //             fps = Math.round(1 / get_fps_average());
    //             // document.querySelector("#fps").textContent = "FPS: " + fps + ", certainty: " + fps_rounder.length * 2 + "%";
    //         }
    //         frame_not_seeked = true;
    //         last_media_time = metadata.mediaTime;
    //         last_frame_num = metadata.presentedFrames;
    //         vid.requestVideoFrameCallback(ticker);
    //     }
    //     vid.requestVideoFrameCallback(ticker);

    //     // Part 3:
    //     vid.addEventListener("seeked", function () {
    //         fps_rounder.pop();
    //         frame_not_seeked = false;
    //     });

    //     // Part 4:
    //     function get_fps_average() {
    //         return fps_rounder.reduce((a, b) => a + b) / fps_rounder.length;
    //     }

    //     // Detect window resize
    //     $(window).resize(function () {
    //         calculateDimensions(false)
    //     });

    //     // Call interval for mouse idle behaviour
    //     mouseInactivityTime()

    // }, [])

    let mouseInactivityTime = () => {
        let time;
        window.onload = resetTimer;
        document.getElementById('custom-player').onmousemove = resetTimer;
        document.getElementById('custom-player').onkeypress = resetTimer;


        function playVideo() {
            if (playerRef.current.isFullscreen()) {
                $(".detected-elements").remove();
                playerRef.current.play()
            }
        }

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(playVideo, 3000)
        }
    }

    useEffect(() => {
        // $(document).on("mousedown", function() {
        //     console.log("MOUSE DOWN EVENT IS CALLED")
        //     mouseIdDown = true;
        // });
        $(document).on("click", "video-js", function (e) {
            var video = document.getElementsByTagName("video");
            if (video[0].paused) {
                intentionalCheck = true
            }
            else {
                if (playerRef.current.play()) {
                    intentionalCheck = false
                }
            }
        })
        $(document).on("mouseup", function () {
            mouseIdDown = false;
        });
        $(document).on("mousemove", "video-js", function (e) {
            if (playerRef.current.seeking() && !mouseIdDown) {
                mouseIdDown = true;
            }
            if (!mouseIdDown) {
                pause(e);
            }
            if (mouseIdDown) {
                mouseIdDown = false;
            }
        })
        $(document).on("mouseleave", "video-js", function (e) {
            if (!intentionalCheck) {
                play();
            }
        })
    }, [])

    const play = () => {
        $(".detected-elements").remove();
        playerRef.current.play()
    }

    const findIndex = async (milliseconds, metadata) => {
        let nearestIndex = -1;
        let smallestDifference = Number.MAX_SAFE_INTEGER;

        // Check if the input index exists in the indexedArray
        if (metadata.hasOwnProperty(milliseconds)) {
            nearestIndex = milliseconds;
        } else {
            // Find the nearest index in the indexedArray
            for (const [key, value] of Object.entries(metadata)) {
                const currentDifference = Math.abs(parseInt(key) - milliseconds);
                if (currentDifference < smallestDifference) {
                    smallestDifference = currentDifference;
                    nearestIndex = parseInt(key);
                }
            }
        }

        return nearestIndex;
    }

    const pause = async (e) => {
        $(".detected-elements").remove();
        await calculateDimensions(false);
        if (mouseIdDown) {
            return;
        }
        if ($(e.target).hasClass('vjs-control-bar') || $(e.target).parents('.vjs-control-bar').length) {
            e.preventDefault()
            return false;
        }
        playerRef.current.pause()

        let milliseconds = Math.floor(playerRef.current.currentTime() * 1000)

        if (milliseconds === 0) {
            return false
        }

        let nearestIndex = await findIndex(milliseconds, metadata)

        if (metadata[nearestIndex.toString()].objects.length) {

            for (let i = 0; i < metadata[nearestIndex.toString()].objects.length; i++) {

                if (metadata[nearestIndex.toString()].objects[i].la !== "unknown") {

                    let x = metadata[nearestIndex.toString()].objects[i].x;
                    let y = metadata[nearestIndex.toString()].objects[i].y;
                    let startX = (renderedWidth * x) + offsetX;
                    let startY = (renderedHeight * y) + offsetY;
                    let w = metadata[nearestIndex.toString()].objects[i].w;
                    let h = metadata[nearestIndex.toString()].objects[i].h;
                    let endX = (renderedWidth * w) + offsetX;
                    let endY = (renderedHeight * h) + offsetY;
                    let elemWidth = endX - startX;
                    let elemHeight = endY - startY;
                    let sumWidthHeight = (parseFloat(metadata[nearestIndex.toString()].objects[i].w) * 100) + (parseFloat(metadata[nearestIndex.toString()].objects[i].h) * 100)
                    let zIndexOfElem = Math.round(200 - sumWidthHeight)

                    let color = objects[metadata[nearestIndex.toString()].objects[i].la] ? objects[metadata[nearestIndex.toString()].objects[i].la] : "red";

                    let detectedElements = document.createElement('div')

                    // detectedElements.classList.add("detected-elements");
                    detectedElements.id = metadata[nearestIndex.toString()].objects[i].id
                    detectedElements.style.left = startX + "px";
                    detectedElements.style.top = startY + "px";
                    detectedElements.style.width = elemWidth + "px";
                    detectedElements.style.height = elemHeight + "px";
                    detectedElements.style.position = "absolute";
                    detectedElements.style.color = "hsl(0deg 0% 0%)";
                    detectedElements.style.outline = `${color} solid 2px`;
                    detectedElements.style.opacity = 0
                    detectedElements.style.zIndex = zIndexOfElem

                    // Create span element.
                    var span = document.createElement('span');
                    span.innerHTML = metadata[nearestIndex.toString()].objects[i].la;
                    span.style.backgroundColor = color;
                    span.style.fontWeight = "bold";
                    span.style.fontSize = "14px";
                    span.style.color = "white";

                    // Create the text node for anchor element.
                    // var link = document.createTextNode("This is link");

                    // Append the text node to anchor element.
                    // anchor.appendChild(link); 

                    // Set the title.
                    // anchor.title = ""; 
                    detectedElements.classList.add("detected-elements");
                    // Set the href property.
                    // anchor.classList.add("detected-elements");
                    // anchor.href = "https://object.com/";
                    // anchor.target = "_blank";
                    // anchor.title = metadata[nearestIndex.toString()].objects[i].la;

                    detectedElements.onmousemove = function (e) {
                        pause(e);
                    };

                    // Append the span element to the Main DIV.
                    detectedElements.appendChild(span)

                    let playerWrapper = document.getElementById("vjs_video_3");
                    // playerWrapper.appendChild(anchor);
                    playerWrapper.appendChild(detectedElements);

                    // add onclick listener to objects
                    detectedElements.onclick = () => {
                        setObject(metadata[nearestIndex.toString()].objects[i]);
                    }

                    detectedElements.onmouseenter = () => {
                        detectedElements.style.opacity = 1
                    };

                    detectedElements.onmouseleave = () => {
                        detectedElements.style.opacity = 0
                    };

                }

            }
        }
    }

    useEffect(() => {
        setModal(clickedObject ? true : false)
        if (clickedObject)
            props.createVideoStats({ userId, slug: id, type: ENV.statTypes.objectsRate, channelName, object: clickedObject.la })

    }, [clickedObject])

    const onCloseHandler = () => {
        setModal(false)
        setObject(null)
    }

    return (
        <>
            {
                (metaTitle || metaDesc) &&
                <Helmet>
                    {metaTitle && <title>{metaTitle}</title>}
                    {metaDesc && <meta name="description" content={metaDesc} />}
                </Helmet>
            }
            {
                loader &&
                <FullPageLoader />
            }
            <div className='v-player-container dashboardHeader px40'>
                <Container fluid className='p-0 h-100'>
                    <div className='video-detail-section h-100'>
                        <div className='video-area'>
                            <div id="custom-player" className={`v-custom-player ${addCustomClass ? "custom-controls" : ""}`}>
                                <VideoJS options={videoOptions} onReady={(player) => handlePlayerReady(player)} />
                            </div>
                            <div className="clicked-object-detail">
                                <strong className="object-name d-block">Flower Shirt</strong>
                                <h3>Description</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam convallis lectus nibh, et interdum ante ultricies et. Etiam posuere scelerisque congue. </p>
                                <div className="img-holder mb-2">
                                    <img className="img-fluid" src="https://placehold.co/600x400" alt="" />
                                </div>
                                <ul className="rating list-unstyled d-flex mb-3">
                                    <li className="rated"><FontAwesomeIcon icon={faStar} /></li>
                                    <li className="rated"><FontAwesomeIcon icon={faStar} /></li>
                                    <li className="rated"><FontAwesomeIcon icon={faStar} /></li>
                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                </ul>
                                <a className="link-to d-inline-block align-top" href="https://www.google.com" target="_blank">Google</a>
                            </div>
                            <VideoDetails
                                setVideoSubtitle={setVideoSubtitle}
                                setMetaTitle={setMetaTitle}
                                setMetaDesc={setMetaDesc}
                                slug={id}
                                userId={userId}
                                userType={userType}
                                adminKey={adminSecretKey}
                            />
                            <div className='related-blogs'>
                                <h4>Comments</h4>
                                <VideoComments slug={id} {...props.videos} />
                            </div>
                        </div>
                        <div className='video-content'>
                            <RecommendedVideos
                                slug={id}
                                userId={userId}
                            />
                        </div>
                    </div>
                </Container>

            </div>
            {
                clickedObject &&
                <Modal show={openModal} onHide={onCloseHandler} className="narrowModel video-product-modal" centered>
                    {/* <Modal.Header closeButton>
                        <Modal.Title className='mb-0'>{clickedObject.la}</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body className='py-lg-4 py-3'>
                        <h4 className="modal-title">{clickedObject.la}</h4>
                        {clickedObject.ds ?
                            <p>{clickedObject.ds || 'N/A'}</p>
                            :
                            <div className="no-detail-found d-flex justify-content-center align-items-center mb-3">Description not Available</div>
                        }
                        {clickedObject.lk ?
                            <a target='_blank' href={clickedObject.lk}>{clickedObject.lk}</a>
                            :
                            <div className="no-detail-found d-flex justify-content-center align-items-center mb-3">Link not Available</div>
                        }
                        {/* <>
                            <Form.Group className='formGroup'>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    value={clickedObject.la}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className='formGroup '>
                                <Form.Label>Link</Form.Label>
                                <div className='d-flex'>
                                    <Form.Control
                                        className="form-control mb-0 me-2"
                                        value={clickedObject.lk || 'N/A'}
                                        readOnly
                                    />
                                    {
                                        clickedObject.lk ?
                                            <Button className="orange-btn theme-btn mt-0 object-visit-btn" onClick={() => window.open(clickedObject.lk)}>Visit</Button> :
                                            null
                                    }
                                </div>
                            </Form.Group>
                            <Form.Group className='formGroup'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    as="textarea"
                                    value={clickedObject.ds || 'N/A'}
                                    readOnly
                                />
                            </Form.Group>
                        </> */}
                    </Modal.Body>
                    <Modal.Footer className='pb-0'>
                        <div className='d-flex align-items-center'>
                            <button className="grey-btn text-uppercase me-lg-3 me-2 mt-0" onClick={onCloseHandler}>close</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            }

        </>
    );
}

const mapStateToProps = state => ({
    video: state.player,
    roles: state.roles,
    videos: state.videos,
    notifications: state.notifications
});

export default connect(mapStateToProps, { getVideoMetadata, beforeVideoMetadata, createVideoStats, beforeVideoStats, getChannelRole, beforeChannelRole })(Player);