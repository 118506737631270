import React, { useState } from 'react'
import { Button, Form, Modal, Navbar } from 'react-bootstrap'
import { Images } from '../../../../assets/assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import "./CommunityHeader.css"
function CommunityHeader() {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<header className="bg-white header">
			<div className='customBox container-fluid'>
				<div className='community-header-wrapper'>
					<Navbar expand="lg" className='d-flex align-items-center justify-content-between'>
						<div className=' d-flex'>
							<Navbar.Brand>
								<Link to='/'>							
									<img src={Images.siteLogo} alt='siteLogo' className='img-fluid' />
								</Link>
							</Navbar.Brand>
							<div className='search-form align-items-center comunity-search-bar'>
								<Form className=' w-100 h-100 d-flex align-items-center'>
									<span className='search-icon'><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
									<Form.Control type="text" placeholder="Search Videos with title, keywords or description" className=" mr-sm-2 h-100" />
									{/* <Button type="submit">Submit</Button> */}
								</Form>
							</div>
						</div>
						<div className='ask-question-modal-wrapper'>
							<Button className='upload-btn btn btn-primary' variant="primary" onClick={handleShow}>
								Ask A Question
							</Button>
							<Modal className='ask-question-modal' show={show} onHide={handleClose} centered>
								<Modal.Header closeButton>
									<Modal.Title>Ask Question</Modal.Title>
								</Modal.Header>
								<Modal.Body className=''>
									<Form className='community-ask-question-form'>
										<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
											{/* <Form.Label>Email address</Form.Label> */}
											<Form.Control type="text" placeholder="Write Your Question" />
										</Form.Group>
										<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">											
											<Form.Label></Form.Label>
											<Form.Control as="textarea" rows={3} />
										</Form.Group>
									</Form>
								</Modal.Body>
								<Modal.Footer className=''>
									<Button className='theme-btn orange-btn d-inline-block' variant="secondary" onClick={handleClose}>
										Close
									</Button>
									<Button className='orange-main-button d-inline-block' variant="primary" onClick={handleClose}>
										Add Question
									</Button>
								</Modal.Footer>
							</Modal>
						</div>
					</Navbar>
				</div>
			</div>
		</header>
	)
}

export default CommunityHeader