import React, { useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import '../../Models/Models.css'
import VideoUrlSection from './VideoUrl'
import moment from 'moment'
import DatePicker from "react-datepicker";
import { FaCalendarDay } from "react-icons/fa";

import "react-datepicker/dist/react-datepicker.css";

const Visibility = (props) => {

    const endDate = useRef(null)
    const startDate = useRef(null)

    return (
        <>
            <div className='modalPage'>

                <div className=" themeModal" >
                    <div className='seletFile modalBox'>
                        <Row>
                            <Col md={8} className='mb-md-0 mb-3'>
                                <h4 className='modal-H4'>Visibility</h4>
                                <p className='opacity-40'>Choose when to publish and who can see your video</p>
                                <div className='visibilityList'>
                                    <div className='visibilityBox themeRadio'>
                                        <ul>
                                            <li>
                                                <div>
                                                    <div className='customInput'>
                                                        <div className='radioFlex'>
                                                            <input
                                                                type="radio"
                                                                name="visibilityType"
                                                                value={1}
                                                                checked={props.videoDetails.visibilityType == '1'}
                                                                onChange={props.onChangeHandler}
                                                            />
                                                        </div>
                                                        <div>
                                                            <h5 className='h5Title'>Save or publish</h5>
                                                            <p className='opacity-40'>Make your video public, unlisted or private.</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        props.videoDetails.visibilityType == '1' ?
                                                            <ul className='outer-UL'>
                                                                <li>
                                                                    <div className='customInput'>
                                                                        <div className='radioFlex'>
                                                                            <input type="radio"
                                                                                name="publishType"
                                                                                value={1}
                                                                                checked={props.videoDetails.publishType == '1'}
                                                                                onChange={props.onChangeHandler} />
                                                                        </div>
                                                                        <div>
                                                                            <h5 className='h5Title'>Private</h5>
                                                                            <p className='opacity-40 mb-0'>Only you can watch your video</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='customInput'>
                                                                        <div className='radioFlex'
                                                                        ><input type="radio"
                                                                            name="publishType"
                                                                            value={2}
                                                                            checked={props.videoDetails.publishType == '2'}
                                                                            onChange={props.onChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <h5 className='h5Title'>Unlisted</h5>
                                                                            <p className='opacity-40 mb-0'>Anyone with the video link can watch your video</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='customInput'>
                                                                        <div className='radioFlex'>
                                                                            <input type="radio"
                                                                                name="publishType"
                                                                                value={3}
                                                                                checked={props.videoDetails.publishType == '3'}
                                                                                onChange={props.onChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <h5 className='h5Title'>Public</h5>
                                                                            <p className='opacity-40 mb-0'>Everyone can watch your video</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul> : null
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='visibilityBox'>
                                        <ul>
                                            <li>
                                                <div>
                                                    <div className='customInput'>
                                                        <div className='radioFlex'>
                                                            <input type="radio"
                                                                name="visibilityType"
                                                                value={2}
                                                                checked={props.videoDetails.visibilityType == '2'}
                                                                onChange={props.onChangeHandler} />
                                                        </div>
                                                        <div>
                                                            <h5 className='h5Title'>Schedule</h5>
                                                            <p className='opacity-40'>Schedule your publish date</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        props.videoDetails.visibilityType == '2' ?

                                                            <ul className='outer-UL'>
                                                                <li>
                                                                    <div className=''>
                                                                        <p className='opacity-40'>Publish Start Date</p>
                                                                        <label className='date-picker-wrapper'>
                                                                            <DatePicker
                                                                                ref={startDate}
                                                                                className="form-control"
                                                                                dateFormat="dd-MM-yyyy"
                                                                                selected={props.videoDetails.publishStartDate ? new Date(props.videoDetails.publishStartDate) : ''}
                                                                                onChange={(date) => props.dateChangeHandler(date, 'publishStartDate')}
                                                                                minDate={moment(new Date()).toDate()}
                                                                                placeholderText="Select Start Date"
                                                                            />
                                                                            <FaCalendarDay />
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        props.errors.publishStartDate &&
                                                                        <span className='submit-errors text-danger'>{props.errors.publishStartDate}</span>
                                                                    }
                                                                </li>
                                                                <li>
                                                                    <div className=''>
                                                                        <p className='opacity-40'>Publish End Date</p>
                                                                        <label className='date-picker-wrapper'>
                                                                            <DatePicker
                                                                                ref={endDate}
                                                                                className="form-control"
                                                                                dateFormat="dd-MM-yyyy"
                                                                                selected={props.videoDetails.publishEndDate ? new Date(props.videoDetails.publishEndDate) : ''}
                                                                                onChange={(date) => props.dateChangeHandler(date, 'publishEndDate')}
                                                                                minDate={moment(new Date()).toDate()}
                                                                                placeholderText="Select End Date"
                                                                            />
                                                                            <FaCalendarDay />

                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        props.errors.publishEndDate &&
                                                                        <span className='submit-errors text-danger'>{props.errors.publishEndDate}</span>
                                                                    }
                                                                </li>
                                                            </ul>
                                                            : null
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </Col>
                            <Col md={4}>
                                <VideoUrlSection video={props.videoDetails} inputs={props.fileInputs} />
                            </Col>
                        </Row>
                    </div >
                </div >
            </div >
        </>
    )
}
export default Visibility