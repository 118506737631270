import { LIST_POSITIONS, BEFORE_POSITION, } from '../../../redux/types';

const initialState = {
    listOpenPositions: null,
    listOpenPositionsAuth: false
}

export default function positionReducer(state = initialState, action) {
    switch (action.type) {
        case LIST_POSITIONS:
            return {
                ...state,
                listOpenPositions: action.payload,
                listOpenPositionsAuth: true
            }

        case BEFORE_POSITION:
            return {
                ...state,
                listOpenPositions: null,
                listOpenPositionsAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}