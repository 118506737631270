import React, { useEffect } from 'react'
import Header from '../Header/Header';

const Auth = ({ title, children }) => {

    useEffect(() => {
        if (title)
            document.title = 'BiiView | ' + title;
        else
            document.title = "BiiView";
    }, [title]);

    useEffect(() => {
        let bodyElement = document.getElementsByTagName('body')[0]
        if(bodyElement)
            bodyElement.classList.remove('dashboard-page')
    },[])

    return (
        <>
            <Header />
            {children}
        </>
    )
}

export default Auth