import {
    LIST_BLOG_CATS, GET_BLOG_CAT, BEFORE_BLOG_CAT, LIST_BLOGS, GET_BLOG, BEFORE_BLOG,
    BEFORE_BLOG_POST, LIST_POSTS, GET_POST, LATEST_BLOGS, HIGHLIGHTED_BLOGS, FEATURED_BLOGS, EXPLORE_BLOGS, TRENDING_BLOGS, SEARCH_BLOGS, LIST_POST_COMMENTS, BEFORE_POST_COMMENTS, UPSERT_COMMENT, DELETE_COMMENT
} from '../../../redux/types';

const initialState = {

    // blog categories

    blogCategoriesRes: null,
    blogCategoriesAuth: false,

    getBlogCategoryRes: null,
    getBlogCategoryAuth: false,

    // blogs 

    blogsRes: null,
    blogsAuth: false,

    latestblogRes: null,
    latestblogAuth: false,

    getBlogRes: null,
    getBlogAuth: false,

    highlightedBlogRes: null,
    highlightedBlogAuth: false,

    featuredBlogRes: null,
    featuredBlogAuth: false,

    exploreBlogRes: null,
    exploreBlogAuth: false,

    trendingBlogRes: null,
    trendingBlogAuth: false,

    searchedBlogsRes: null,
    searchedBlogsAuth: false,

    // blog posts 

    blogPostsRes: null,
    blogPostsAuth: false,

    getBlogPostRes: null,
    getBlogPostAuth: false,

    // blog post comments

    listBlogPostComments: null,
    listBlogPostCommentsAuth: false,

    upsertPostCommentRes: null,
    upsertPostCommentAuth: false,

    deleteCommentAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {

        // Blog Categories
        case LIST_BLOG_CATS:
            return {
                ...state,
                blogCategoriesRes: action.payload,
                blogCategoriesAuth: true,
            }
        case GET_BLOG_CAT:
            return {
                getBlogCategoryRes: action.payload,
                getBlogCategoryAuth: true
            }
        case BEFORE_BLOG_CAT:
            return {
                ...state,

                blogCategoriesRes: null,
                blogCategoriesAuth: false,

                getBlogCategoryRes: null,
                getBlogCategoryAuth: false,

            }

        // Blogs 
        case LIST_BLOGS:
            return {
                ...state,
                blogsRes: action.payload,
                blogsAuth: true
            }
        case GET_BLOG:
            return {
                ...state,
                getBlogRes: action.payload,
                getBlogAuth: true
            }
        case LATEST_BLOGS:
            return {
                ...state,
                latestblogRes: action.payload,
                latestblogAuth: true
            }
        case HIGHLIGHTED_BLOGS:
            return {
                ...state,
                highlightedBlogRes: action.payload,
                highlightedBlogAuth: true
            }
        case FEATURED_BLOGS:
            return {
                ...state,
                featuredBlogRes: action.payload,
                featuredBlogAuth: true
            }
        case EXPLORE_BLOGS:
            return {
                ...state,
                exploreBlogRes: action.payload,
                exploreBlogAuth: true
            }
        case TRENDING_BLOGS:
            return {
                ...state,
                trendingBlogRes: action.payload,
                trendingBlogAuth: true
            }
        case SEARCH_BLOGS:
            return {
                ...state,
                searchedBlogsRes: action.payload,
                searchedBlogsAuth: true
            }
        case BEFORE_BLOG:
            return {
                ...state,

                blogsRes: null,
                blogsAuth: false,

                getBlogRes: null,
                getBlogAuth: false,


                latestblogRes: null,
                latestblogAuth: false,

                highlightedBlogRes: null,
                highlightedBlogAuth: false,

                featuredBlogRes: null,
                featuredBlogAuth: false,

                exploreBlogRes: null,
                exploreBlogAuth: false,

                trendingBlogRes: null,
                trendingBlogAuth: false,

                searchedBlogsRes: null,
                searchedBlogsAuth: false

            }

        // Blog Posts
        case LIST_POSTS:
            return {
                ...state,
                blogPostsRes: action.payload,
                blogPostsAuth: true
            }
        case GET_POST:
            return {
                ...state,
                getBlogPostRes: action.payload,
                getBlogPostAuth: true
            }
        case BEFORE_BLOG_POST:
            return {
                ...state,

                blogPostsRes: null,
                blogPostsAuth: false,

                getBlogPostRes: null,
                getBlogPostAuth: false,
            }
        case LIST_POST_COMMENTS:
            return {
                ...state,
                listBlogPostComments: action.payload,
                listBlogPostCommentsAuth: true
            }
        case UPSERT_COMMENT:
            return {
                ...state,
                upsertPostCommentRes: action.payload,
                upsertPostCommentAuth: true
            }
        case DELETE_COMMENT:
            return {
                ...state,
                deleteCommentAuth: true
            }
        case BEFORE_POST_COMMENTS:
            return {
                ...state,
                listBlogPostComments: null,
                listBlogPostCommentsAuth: false,

                upsertPostCommentRes: null,
                upsertPostCommentAuth: false,

                deleteCommentAuth: false
            }
        default:
            return {
                ...state
            }
    }
}