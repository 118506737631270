const dataEncryptionKey = process.env.REACT_APP_DATA_ENCRYPTION_KEY;
const CryptoJS = require("crypto-js");
const cdnBaseUrl = process.env.REACT_APP_CLOUDINARY_BASE_URL

export const ENV = {
    cdnBaseUrl,

    // process variables
    url: process.env.REACT_APP_API_URL,
    assetUrl: process.env.REACT_APP_ASSETS_BASE_URL,
    appName: process.env.REACT_APP_NAME,
    dataEncryptionKey,
    baseUrl: process.env.REACT_APP_BASE_URL, // frontend's base url
    backendBaseUrl: process.env.REACT_APP_BACKEND_BASE_URL, // backend's base url
    streamingUrl: process.env.REACT_APP_STREAMING_URL,

    // recaptcha keys
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    recaptchaSecretKey: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,

    // Headers
    Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
    x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,

    uploadedImgPath: `${process.env.REACT_APP_ASSETS_BASE_URL}`,

    // default images placeholders
    defaultPlaceholderImage: `${process.env.REACT_APP_CLOUDINARY_BASE_URL}v1671779670/placeholder_dis4gd.png`,
    userPlaceholderImage: `${process.env.REACT_APP_CLOUDINARY_BASE_URL}v1672134396/userDefault_bo77jv.png`,

    //set user in local storage
    encryptUserData: function (data) {
        let userData = localStorage.getItem('encuse');
        if (userData && !data.accessToken) {
            let bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            let originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            if (originalData && originalData.accessToken) {
                data.accessToken = originalData.accessToken;
            }
        }
        data = JSON.stringify(data);
        let encryptedUser = CryptoJS.AES.encrypt(data, dataEncryptionKey).toString();
        localStorage.setItem('encuse', encryptedUser);
        return true;
    },

    //return required keys
    getUserKeys: function (keys = null) {
        let userData = localStorage.getItem('encuse');
        if (userData) {
            var bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            var originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            let user = {};

            if (keys) {
                keys = keys.split(" ");
                for (let key in keys) {
                    let keyV = keys[key];
                    user[keyV] = originalData[keyV];
                }
            }
            else {
                user = originalData;
            }
            return user;
        }
        return {};
    },

    //Object to query string
    objectToQueryString: function (body) {
        const qs = Object.keys(body).map(key => `${key}=${body[key]}`).join('&');
        return qs;
    },

    decimalNumberValidator: function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        let specialKeys = [46, 8, 9, 27, 13, 110, 190]

        if (e.target.value.includes('.')) {
            specialKeys = [46, 8, 9, 27, 13]
        }
        else {
            specialKeys = [46, 8, 9, 27, 13, 110, 190]
        }

        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        if (specialKeys.includes(e.keyCode) ||
            // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
            ((e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 90 || e.keyCode === 88) && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40) ||
            // Allow F1 to F12 keys 
            (e.keyCode >= 112 && e.keyCode <= 123)
        ) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }

    },

    integerNumberValidator: function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        const specialKeys = [46, 8, 9, 27, 13]

        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        if (specialKeys.includes(e.keyCode) ||
            // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
            ((e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 90 || e.keyCode === 88) && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    },

    videoSizeLimit: 100,// file size can be upto 100 MB only

    allowedMediaTypes: {
        images: ['jpg', 'jpeg', 'png', 'gif', 'svg'],
        videos: ['3g2', '3gp', 'm4v', 'mkv', 'mov', 'mp4', 'avi'],
        subtitles: ['vtt.txt']
    },

    pythonBaseUrl: process.env.REACT_APP_PYTHON_BASE_URL,
    googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,

    events: {
        notifications: [
            {
                type: 1,
                name: 'video-processing',
                label: 'Processing'
            },
            {
                type: 2,
                name: 'video-processed',
                label: 'Completed'
            },
            {
                type: 3,
                name: 'processing-failed',
                label: 'Failed'
            },
            {
                type: 4,
                name: 'contact-publisher'
            },
        ],
        processingProgressEvtName: 'processing-progress'
    },

    processingStates: {
        pending: 1,
        processing: 2,
        processed: 3,
        failed: 4
    },

    applicationTourPages: {
        // key in db : route path
        memberDashboardTour: 'publisher-dashboard',
        userDashboardTour: 'dashboard',
        myVideosTour: 'my-videos',
        exploreVideosTour: 'explore',
        channelAnalyticsTour: 'channel-analytics',
        videoAnalyticsTour: 'video-analytics',
        subtitlesTour: 'subtitles',
        settingsTour: 'settings',
        paymentHistoryTour: 'payment-history',
        billingInvoicesTour: 'invoices',
        myChannelsTour: 'channels',
        profileTour: 'profile',
        packagesTour: 'packages',
        processedVideoTour: 'media-player',
        positionsTour: 'positions',
        favouritesTour: 'my-favourites',
        watchHistoryTour: 'watch-history',
        channelDetailsTour: 'channel-details',

    },
    subtitleLanguages: [
        { label: 'English', code: 'en' },
        // { label: 'Urdu', code: 'ur' }
    ],

    defaultMetaDescription: "BiiView is the world's best-in-class AI platform built for lightning-fast visual commerce & product marketing through object recognition from videos at scale.",

    adminSecretKey: process.env.REACT_APP_ADMIN_SECRET_KEY,
    pythonVideoUploadingUrl: process.env.REACT_APP_PYTHON_VIDEO_UPLOADING,
    statTypes: {
        viewed: 1,
        watched: 2,
        impressions: 3,
        impressionsRate: 4,
        videoLiked: 5,
        videoDisliked: 6,
        objectsRate: 7
      }
}
