import React from 'react'
import '../../Models/Models.css'
import { Link } from 'react-router-dom'
import { deleteUserAccount } from '../../../UserComponents/users.actions'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { ENV } from '../../../../../config/config'

const AdvanceSettings = (props) => {
    const userId = ENV?.getUserKeys('_id')?._id
    const dispatch = useDispatch()

    const deleteAccountHandler = () => {
        Swal.fire({
            title: 'Delete Account',
            html: "If you'll delete your account, all your videos and channels will be lost forever. Are you sure you want to proceed?",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.value) {
                props.setLoader(true)
                dispatch(deleteUserAccount(userId))
            }
        })
    }
    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div className='advanceSetting'>
                        <div className='general mb-0'>
                            <h4>Audience</h4>
                            <p>Simplify your workflow by selecting a channel setting. If you skip this question, you'll be required to identify each video on your channel that's made for kids. This setting will affect existing and future videos. Settings for individual videos will override the channel setting.</p>
                        </div>
                        <div className='laws mb-0'>
                            <div>
                                <h5>Do you want to set your channel as made for kids?</h5>
                                <p>Regardless of your location, you're legally required to comply with the Children's Online Privacy Protection
                                    Act (COPPA) and/or other laws. You're required to tell us whether your videos are made for kids.</p>
                            </div>
                            <div className='themeRadio'>
                                <ul>
                                    <li>
                                        <div className='customInput'>
                                            <div className='radioFlex'>
                                                <input
                                                    type="radio"
                                                    name="audience"
                                                    value={1}
                                                    checked={props.audience === 1}
                                                    onChange={(e) => props.setData({ audience: parseInt(e.target.value) })}
                                                />
                                            </div>
                                            <div>
                                                <p>Yes, set this channel as made for kids. I always upload content that’s made for kids.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='customInput'>
                                            <div className='radioFlex'>
                                                <input
                                                    type="radio"
                                                    name="audience"
                                                    value={2}
                                                    checked={props.audience === 2}
                                                    onChange={(e) => props.setData({ audience: parseInt(e.target.value) })}
                                                />
                                            </div>
                                            <div>
                                                <p>No, set this channel as not made for kids. I never upload content that’s made for kids.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='customInput'>
                                            <div className='radioFlex'>
                                                <input
                                                    type="radio"
                                                    name="audience"
                                                    value={3}
                                                    checked={props.audience === 3}
                                                    onChange={(e) => props.setData({ audience: parseInt(e.target.value) })}
                                                />
                                            </div>
                                            <div>
                                                <p>I want to review this setting for every video.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className='linkAccount me-3'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div><p>Google Ads account linking</p></div>
                                    <Link to='' className="accountBtn link">LINK ACCOUNT</Link>
                                </div>
                                <p>Link your YouTube channel to a Google Ads account to let the linked Google Ads account run ads based on interactions
                                    with your channel’s videos and to access insights from your channel’s videos.</p>
                            </div> */}
                        </div>
                        {/* <div>
                        <div>
                            <div className='d-flex justify-content-start align-items-start checkboxText'>
                                <div className="form-check checkTable me-2 px-0 modalCheck mb-3">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                </div>
                                <h4 className='check-H4 mb-0'>Automatic captions</h4>
                            </div>
                            <p className='opacity-40'>Allows other creators to send their viewers to any of your live streams or Premieres. Your 
                            content can only be selected for a live redirect if it’s upcoming or in progress.</p>
                        </div>
                        <div>
                            <div className='d-flex justify-content-start align-items-start checkboxText mb-3'>
                                <div className="form-check checkTable me-2 px-0 modalCheck">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                </div>
                                <h4 className='check-H4 mb-0'>Advertisment</h4>
                            </div>
                            <p className='opacity-40'>Allows other creators to send their viewers to any of your live streams or Premieres. Your 
                            content can only be selected for a live redirect if it’s upcoming or in progress.</p>
                        </div>
                        <div>
                            <div className='d-flex justify-content-start align-items-start mb-3'>
                                <div className="form-check checkTable me-2 px-0 modalCheck">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                </div>
                                <h4 className='check-H4 mb-0'>Clips</h4>
                            </div>
                            <p className='opacity-40'>Allows other creators to send their viewers to any of your live streams or Premieres. Your 
                            content can only be selected for a live redirect if it’s upcoming or in progress.</p>
                        </div>
                    </div> */}
                        <div className='manageAccounts'>
                            <h4 className='mb-3'>Other Settings</h4>
                            <Link to='/profile' className='blueLink'>manage your account</Link>
                            <Link to='' className='blueLink' onClick={deleteAccountHandler}>delete your account</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdvanceSettings