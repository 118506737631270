import React, { useEffect, useState } from 'react'
import { Row, Col, Nav, Tab, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import General from './GeneralSettings/General'
import Channel from './ChannelSettings/Channel'
import UploadDefault from './VideoDefaultSettings/UploadDefault'
import Permissions from './Permissions/Permissions'
import { getChannel, beforeChannels, updateChannelDetails } from '../../MyChannels/channels.actions'
import { useDispatch, useSelector } from 'react-redux'
import { beforeUser } from '../../UserComponents/users.actions'
import Joyride
    from 'react-joyride'
import { channelSettingsNav, generalSettingsNav, socialSettingsNav, permissionsSettingsNav, uploadDefaultSettingsNav } from '../../ApplicationTour/TourCompIdentifiers'
import { beforeTourStatus, getUserTourStatus } from '../../ApplicationTour/applicationTour.actions'
import { ENV } from '../../../../config/config'
import { getPageTourSteps } from '../../ApplicationTour/applicationTourSteps'
import ApplicationTour from '../../ApplicationTour/ApplicationTours'
import './../Models/Models.css'
import SmallLoader from '../../../../utils/SmallLoader/SmallLoader'
import SocialSettings from './SocialSettings/SocialSettings'
import { validateUrl } from '../../../../utils/Validations/validations'

// import Community from './ModelTabsComp/Community';

const SettingsModal = ({ settingsModal, setSettingsModel }) => {

    const navigate = useNavigate()

    const dispatch = useDispatch()
    const channelId = localStorage.getItem('channelId')

    const [channelDetails, setDetails] = useState({})
    const [loader, setLoader] = useState(true)
    const [key, setKey] = useState(0)
    const [channelKey, setChannelKey] = useState('channel-basic')
    const [uploadKey, setUploadKey] = useState('upload-basic')
    const [channelOwnerId, setOwnerId] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [errors, setErrors] = useState({})

    const channelSelector = useSelector((state) => state.channels)
    const userSelector = useSelector((state) => state.user)
    const errorSelector = useSelector((state) => state.error)


    const handleClose = () => {
        setSettingsModel(false)
    };

    useEffect(() => {
        if (errorSelector) {
            setLoader(false)
            setSubmitted(false)
        }
    }, [errorSelector])

    useEffect(() => {
        if (channelId && settingsModal) {
            dispatch(getChannel(`channelId=${channelId}`))
        }
    }, [settingsModal])

    useEffect(() => {
        if (settingsModal) {
            // reset states
            setErrors({})
            setKey(0)
            setChannelKey('channel-basic')
            setUploadKey('upload-basic')
        }
    }, [settingsModal])

    useEffect(() => {
        if (channelSelector.getChannelAuth) {
            let data = channelSelector.getChannelData?.channel
            if (data.keywords?.length)
                data.keywords = data.keywords.toString()

            if (data.videoTags?.length)
                data.videoTags = data.videoTags.toString()

            setOwnerId(data.ownerId)
            setDetails(data)
            setLoader(false)
            dispatch(beforeChannels())
        }
    }, [channelSelector.getChannelAuth])

    useEffect(() => {
        if (channelSelector.updateDetailsAuth) {
            let { updatedDetails } = channelSelector.updateDetailsData
            setLoader(false)
            setSubmitted(false)
            setDetails(updatedDetails)
            if (updatedDetails?.name)
                localStorage.setItem('channelName', updatedDetails.name)
            dispatch(getChannel(`channelId=${channelId}`))
            dispatch(beforeChannels())
        }
    }, [channelSelector.updateDetailsAuth])

    useEffect(() => {
        if (userSelector.deleteAccountAuth) {
            setLoader(false)
            localStorage.clear()
            navigate('/login')
            dispatch(beforeUser())
        }
    }, [userSelector.deleteAccountAuth])


    const setChannelData = (data) => {
        setDetails({ ...channelDetails, ...data })
    }

    const saveDetails = () => {

        let payload = {}
        let errs = {}

        // Channel
        if (key == '0') {
            if (channelKey === 'channel-basic') {
                let keywords = channelDetails.keywords ? channelDetails.keywords.toString() : ''
                let check = true

                if (keywords) {
                    keywords = keywords.split(',').map((k) => k.replace(/\s+/g, " ").trim()).filter((k) => k !== '')
                    keywords.forEach((k) => {
                        if (!(/^#[a-zA-Z ]*$/.test(k)))
                            check = false
                    })

                    if (!check)
                        errs = { ...errs, keywords: 'Invalid Keywords' }
                }

                payload = {
                    countryResidence: channelDetails.countryResidence,
                    keywords,
                    aboutChannel: channelDetails.aboutChannel
                }

            }
            else if (channelKey === 'channel-advance')
                payload = {
                    audience: channelDetails.audience,
                }
        }
        // Upload Default
        else if (key == '1') {
            if (uploadKey === 'upload-basic') {
                let videoTags = channelDetails.videoTags ? channelDetails.videoTags.toString() : ''
                let check = true

                if (videoTags) {
                    videoTags = videoTags.split(',').map((k) => k.replace(/\s+/g, " ").trim()).filter((k) => k !== '')
                    videoTags.forEach((k) => {
                        if (!(/^#[a-zA-Z ]*$/.test(k)))
                            check = false
                    })

                    if (!check)
                        errs = { ...errs, videoTags: 'Invalid Tags' }

                }

                payload = {
                    videoTitle: channelDetails.videoTitle,
                    videoDescription: channelDetails.videoDescription,
                    videoVisibility: channelDetails.videoVisibility,
                    videoTags
                }
            }
            else if (uploadKey === 'upload-advance') {
                payload = {
                    chaptersAndMoments: channelDetails.chaptersAndMoments,
                    videoCategory: channelDetails.videoCategory,
                    videoLanguage: channelDetails.videoLanguage,
                    titleDescLanguage: channelDetails.titleDescLanguage,
                }
            }
        }
        // General
        else if (key == '3') {
            if (!channelDetails.name)
                errs = { ...errs, name: 'This field is required.' }
            else {
                let numericRegex = /^\d+$/
                let charactersRegex = /^[\w\s]+$/
                let lettersregex = /[a-zA-Z\s]/g;

                if (channelDetails.name.length < 3)
                    errs = { ...errs, name: 'Name is too short. Please enter atleast 3 characters' }
                else if (channelDetails.name.length > 30)
                    errs = { ...errs, name: 'Name is too long.' }
                else if (numericRegex.test(channelDetails.name))
                    errs = { ...errs, name: 'Invalid Name' }
                else if (!lettersregex.test(channelDetails.name))
                    errs = { ...errs, name: 'Invalid Name' }
                else if (!charactersRegex.test(channelDetails.name))
                    errs = { ...errs, name: 'Name can only include letters, digits and underscore' }

                payload = {
                    name: channelDetails.name.replace(/\s+/g, " ").trim()
                }

                if (channelDetails.channelImageUrl)
                    payload.channelImage = channelDetails.channelImageUrl

                if (channelDetails.channelBannerImageUrl)
                    payload.channelBannerImage = channelDetails.channelBannerImageUrl


            }
        }
        // Social
        else if (key == '4') {

            let { facebookUrl, linkedinUrl, instagramUrl, websiteUrl } = channelDetails

            if (facebookUrl && !(validateUrl(facebookUrl)))
                errs = { ...errs, facebookUrl: "Invalid Url" }

            if (linkedinUrl && !(validateUrl(linkedinUrl)))
                errs = { ...errs, linkedinUrl: "Invalid Url" }

            if (instagramUrl && !(validateUrl(instagramUrl)))
                errs = { ...errs, instagramUrl: "Invalid Url" }

            if (websiteUrl && !(validateUrl(websiteUrl)))
                errs = { ...errs, websiteUrl: "Invalid Url" }

            payload = {
                facebookUrl, linkedinUrl, instagramUrl, websiteUrl
            }

        }

        if (errs && (errs.videoTags || errs.keywords || errs.name || errs.facebookUrl || errs.linkedinUrl || errs.instagramUrl || errs.websiteUrl)) {
            setErrors({ ...errs })
            return
        }
        setErrors({})

        if (!Object.keys(payload).length) {
            setSettingsModel(false)
            return
        }

        payload.channelId = channelId

        let formData = new FormData()
        Object.keys(payload).forEach((key) => {
            formData.append(key, payload[key])
        })

        setSubmitted(true)
        setLoader(true)
        dispatch(updateChannelDetails(formData))
    }

    const childProps = {
        setLoader,
        loader,
        setChannelData,
        channelDetails,
        errors,
        setErrors
    };

    return (
        <>
            <div className='modalPage'>
                <div className="modal themeModal" >
                    <Modal className='settingModel fixedModal' show={settingsModal} onHide={handleClose} centered>
                        <div className='fixedRel'>
                            <Modal.Header closeButton>
                                <Modal.Title className='mb-0'>Settings</Modal.Title>
                            </Modal.Header>
                            <div className='modelTabs'>
                                <Tab.Container id="left-tabs-example" activeKey={key} onSelect={(k) => setKey(k)}>
                                    <Row>
                                        <Col sm={3} className='pe-0'>
                                            <Nav variant="pills" className="flex-column sideTab">
                                                {/* <Nav.Item>
                                                    <Nav.Lik eventKey="1">General</Nav.Link>
                                                </Nav.Item> */}
                                                <Nav.Item id={channelSettingsNav}>
                                                    <Nav.Link eventKey={0}>Channel</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item id={uploadDefaultSettingsNav}>
                                                    <Nav.Link eventKey={1}>Upload Default</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item id={permissionsSettingsNav}>
                                                    <Nav.Link eventKey={2}>Permissions</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item id={generalSettingsNav} >
                                                    <Nav.Link eventKey={3}>General</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item id={socialSettingsNav} >
                                                    <Nav.Link eventKey={4}>Social</Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item>
                                                    <Nav.Link eventKey="4">Community</Nav.Link>
                                                </Nav.Item> */}
                                            </Nav>
                                        </Col>
                                        <Col sm={9} className='px-0'>
                                            {loader ? <SmallLoader height={40} width={40} /> :
                                                <Tab.Content >
                                                    {/* <Tab.Pane eventKey="1"><General /></Tab.Pane> */}
                                                    <Tab.Pane eventKey={0}>
                                                        <Channel
                                                            {...childProps}
                                                            channelKey={channelKey}
                                                            setKey={setChannelKey}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey={1}>
                                                        <UploadDefault
                                                            {...childProps}
                                                            uploadKey={uploadKey}
                                                            setKey={setUploadKey}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey={2}>
                                                        <Permissions
                                                            setShow={setSettingsModel}
                                                            ownerId={channelOwnerId}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey={3}>
                                                        <General
                                                            name={channelDetails.name}
                                                            channelImage={channelDetails.channelImage}
                                                            channelBannerImage={channelDetails.channelBannerImage}
                                                            setData={setChannelData}
                                                            errors={errors}
                                                            setErrors={setErrors}
                                                            loader={loader}
                                                        />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey={4}>
                                                        <SocialSettings
                                                            facebookUrl={channelDetails?.facebookUrl}
                                                            linkedinUrl={channelDetails?.linkedinUrl}
                                                            instagramUrl={channelDetails?.instagramUrl}
                                                            websiteUrl={channelDetails?.websiteUrl}
                                                            setData={setChannelData}
                                                            errors={errors}
                                                            setErrors={setErrors}
                                                            loader={loader}
                                                        />
                                                    </Tab.Pane>
                                                    {/* <Tab.Pane eventKey="4"><Community /></Tab.Pane> */}
                                                </Tab.Content>
                                            }
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                            <Modal.Footer>
                                <div className='d-flex align-items-center '>
                                    <Button onClick={handleClose} className="orange-main-button text-uppercase me-lg-3 me-2 mt-0">close</Button>
                                    <Button disabled={submitted} onClick={saveDetails} className="theme-btn orange-btn text-uppercase mx-auto mt-0">Save</Button>
                                </div>
                            </Modal.Footer>
                        </div>
                    </Modal>
                </div>
            </div>

            <ApplicationTour page="settings" />

        </>
    )
}
export default SettingsModal