import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { getInvoice, beforeBillingHistory } from './billingHistory.actions'
import { Row, Col, Table } from 'react-bootstrap'
import { Images } from '../../../assets/assets'
import { Helmet } from 'react-helmet-async/lib/index.esm'

const ViewInvoice = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const billingSelector = useSelector((state) => state.billingHistory)

    const [invoiceData, setData] = useState()

    useEffect(() => {
        if (id) {
            dispatch(getInvoice(id))
        }
    }, [])

    useEffect(() => {
        if (billingSelector.getInvoiceAuth) {
            let { invoice } = billingSelector.getInvoiceData
            setData(invoice)
            dispatch(beforeBillingHistory())
        }

    }, [billingSelector.getInvoiceAuth])

    return (
        invoiceData &&
        <>
            {
                invoiceData?.user?.fullName && invoiceData?.plan?.name &&
                <Helmet>
                    <title>{`${invoiceData?.user?.fullName} - ${invoiceData?.plan?.name} - Invoice Details`}</title>
                </Helmet>

            }
            <section className='view-invoce-sec p-0'>
                <div className='invoice-header'>
                    {invoiceData.site && invoiceData.site.logo ?
                        // <img src={invoiceData.site.logo} alt="biiview" style={{ maxWidth: "160px" }} /> : ""}
                        <Link to="/">
                            <img src={Images.siteLogo} alt="biiview" style={{ maxWidth: "160px" }} />
                        </Link>
                        :
                        ""
                    }
                </div>
                <div className='view-invoice-p'>
                    <div className="py-4">
                        <Link style={{ color: "#fff" }} to="/organization/detail"><span><i className="fa fa-arrow-left" aria-hidden="true"></i></span></Link>
                    </div>
                    <Row className='mb-4'>
                        <Col md={6}>
                            <div className='wrapper-content'>
                                <p className='mb-0'>{`BiiView • ${invoiceData.site.address}`}</p>
                                {invoiceData.user && invoiceData.user.fullName ? <p className='mb-0'>{invoiceData.user.fullName}</p> : ''}
                                {invoiceData.user.address ? <p>{invoiceData.user.address}</p> : ''}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='wrapper-content'>
                                <p className='mb-0 border-b'>Invoice</p>
                                <div className='d-flex justify-content-between align-items-center flex-wrap border-b'>
                                    <p className='mb-0'>{"BiiView/" + moment(new Date(invoiceData.createdAt)).format("YYYY") + "/" + invoiceData.invoiceId}</p>
                                    <p className='mb-0'>Date {moment(new Date(invoiceData.createdAt)).format("DD/MM/YYYY")}</p>
                                </div>
                                <p className='mb-0 border-b'>{invoiceData.user && invoiceData.user.fullName ? invoiceData.user.fullName : ''}</p>
                                <p className='mb-0 border-b'>BiiView</p>
                            </div>
                        </Col>
                    </Row>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Pos</th>
                                <th>Plan</th>
                                <th>Description</th>
                                <th>Type</th>
                                <th>Price (USD)</th>
                                <th>Payment Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>{invoiceData.plan.name}</td>
                                <td>{invoiceData.plan.description}</td>
                                <td>{invoiceData.planDuration}</td>
                                <td>{invoiceData.price}</td>
                                <td>{invoiceData.paymentStatus}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className='bill-container mt-4 mb-4'>
                        <div className='bill-wrapper'>
                            <div className='d-flex justify-content-between align-items-center flex-wrap border-b'>
                                <p className='mb-0'>Net</p>
                                <p className='mb-0'>{invoiceData.price}$</p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center flex-wrap border-b'>
                                <p className='mb-0'>Total</p>
                                <p className='mb-0'>{invoiceData.price}$</p>
                            </div>
                        </div>
                    </div>
                    <table style={{ width: '1000px', margin: '0 0 15px' }}>
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '14px', lineHeight: '18px' }}>
                                        <tbody>
                                            <tr>
                                                <td width="406px" style={{ borderBottom: '1px solid #9f9f9f', paddingBottom: '5px' }}>
                                                    <p style={{ margin: 0, paddingBottom: '8px' }}>
                                                        Invoice {"BiiView/" + moment(new Date(invoiceData.createdAt)).format("YYYY") + "/" + invoiceData.invoiceId}
                                                    </p>
                                                </td>
                                                <td width="333px" style={{ borderBottom: '1px solid #9f9f9f' }}>&nbsp;</td>
                                                <td width="260px" style={{ borderBottom: '1px solid #9f9f9f', textAlign: 'right', paddingBottom: '5px' }}>
                                                    <p style={{ margin: 0, paddingBottom: '8px' }}>Page: 1 / 1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingTop: '10px', verticalAlign: 'top' }}>
                                                    <p>
                                                        BiiView
                                                        <br />
                                                        {invoiceData.site.address}<br />
                                                        Email: <span style={{ color: 'rgb(253 111 33)', paddingLeft: '0px' }}>{invoiceData.site.email}</span><br />
                                                        Website: <a style={{ color: 'rgb(253 111 33)', fontSize: '14px', lineHeight: '18px' }} href={invoiceData.site.url} target="_blank" rel='noreferrer'>{invoiceData.site.name}</a><br />
                                                        Company Website: <a style={{ color: 'rgb(253 111 33)', fontSize: '14px', lineHeight: '18px' }} href={invoiceData.company.url} target="_blank" rel='noreferrer'> {invoiceData.company.name}</a>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}

export default ViewInvoice