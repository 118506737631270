import { toast } from 'react-toastify';
import { ENV } from '../../../config/config';
import { GET_ERRORS, USER_LOGIN, USER_SIGNUP, FORGOT_PASSWORD, RESET_PASSWORD, BEFORE_USER, GET_USER, GET_PLAN_DETAILS, UPDATE_PROFILE, BEFORE_USER_PLAN, GET_CREATOR, BEFORE_CREATOR, DELETE_ACCOUNT, ADD_REMOVE_FAVOURITES, LIST_FAVOURITES, BEFORE_FAVOURITES, WATCH_HISTORY_VIDEOS, BEFORE_WATCH_HISTORY } from '../../../redux/types';
import { emptyError } from '../../../redux/shared/error/error.action';

export const beforeUser = () => {
    return {
        type: BEFORE_USER
    }
}

export const beforeCreator = () => {
    return {
        type: BEFORE_CREATOR
    }
}

export const beforeUserPlan = () => {
    return {
        type: BEFORE_USER_PLAN
    }
}

export const login = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}auth/login/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)

    }).then(res => res.json()).then(response => {
        if (response.success) {
            let { data } = response
            ENV.encryptUserData(data.user);

            if (Object.keys(data.channel)?.length)
                localStorage.setItem('channelId', data.channel?._id)
                localStorage.setItem('channelName', data.channel?.name)


            dispatch({
                type: USER_LOGIN,
                payload: response.data.user
            })
        } else {
            toast.error(response.message, { toastId: "login-error" })
            dispatch({
                type: GET_ERRORS,
                payload: response
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const signup = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}auth/register/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)

    }).then(res => res.json()).then(data => {
        if (data.success) {

            toast.success(data.message, { toastId: "signup-success" })
            dispatch({
                type: USER_SIGNUP,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "signup-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const forgotPassword = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}auth/forgot-password/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)

    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: 'forgot-pass-success' })
            dispatch({
                type: FORGOT_PASSWORD,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "forgot-pass-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const resetPassword = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}auth/reset-password/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)

    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: 'reset-pass-success' })
            dispatch({
                type: RESET_PASSWORD,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "reset-pass-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const getChannelCreator = (qs = '') => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}users/get`;
    if (qs)
        url = `${url}?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },

    }).then(res => res.json()).then(data => {

        if (data.success) {
            dispatch({
                type: GET_CREATOR,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "get-user-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const getUser = (qs = '') => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}users/get`;
    if (qs)
        url = `${url}?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },

    }).then(res => res.json()).then(data => {

        if (data.success) {
            dispatch({
                type: GET_USER,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "get-user-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const deleteUserAccount = (userId) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}users/delete/${userId}`;
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },

    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: 'delete-acc-success' })
            dispatch({
                type: DELETE_ACCOUNT,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "delete-acc-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};


export const getUserPlanDetails = (qs = '') => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    let url = `${ENV.url}users/plan-details`;

    if (qs)
        url = `${url}?${qs}`
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        },

    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_PLAN_DETAILS,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "active-plan-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const updateUserProfile = (body) => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    const url = `${ENV.url}users/update`;
    fetch(url, {
        method: 'PUT',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        },
        body

    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: 'edit-pro-success' })
            dispatch({
                type: UPDATE_PROFILE,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "edit-pro-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const addRemoveFavourites = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}users/favourites`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)

    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: ADD_REMOVE_FAVOURITES,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: ADD_REMOVE_FAVOURITES })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const listUserFavourites = (qs = '') => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}users/favourites/list`;
    if (qs)
        url = `${url}?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'cache-control': 'no-cache'
        },

    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_FAVOURITES,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: LIST_FAVOURITES })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const beforeFavourites = () => {
    return {
        type: BEFORE_FAVOURITES
    }
}

export const getUserWatchHistory = (qs = '') => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    let url = `${ENV.url}users/watch-history`;

    if (qs)
        url = `${url}?${qs}`
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        },

    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: WATCH_HISTORY_VIDEOS,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: WATCH_HISTORY_VIDEOS })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const beforeWatchHistory = () => {
    return {
        type: BEFORE_WATCH_HISTORY
    }
}



