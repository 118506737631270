import siteLogo from "./images/logo.svg"
import iconUser from "./images/icon-user.svg"
import dataClass from "./images/img-data-class.png"
import dataLabel from "./images/img-data-label.png"
import blogImg01 from "./images/img-blog-01.png"
import blogImg02 from "./images/img-blog-02.png"
import blogImg03 from "./images/img-blog-03.png"
import logoArhamsoft from "./images/logo-arhamsoft.svg"
import authPageLogo from "./images/auth-page-logo.svg"
import videoThumbnail01 from "./images/video-thumbnail-01.png"
import videoThumbnail02 from "./images/video-thumbnail-02.png"
import videoThumbnail03 from "./images/video-thumbnail-03.png"
import videoThumbnail04 from "./images/video-thumbnail-04.png"
import videoThumbnail05 from "./images/video-thumbnail-05.png"
import videoThumbnail06 from "./images/video-thumbnail-06.png"
import videoThumbnail07 from "./images/video-thumbnail-07.png"
import videoThumbnail08 from "./images/video-thumbnail-08.png"
import videoThumbnail09 from "./images/video-thumbnail-09.png"
import videoThumbnail10 from "./images/video-thumbnail-10.png"
import videoThumbnail11 from "./images/video-thumbnail-11.png"
import videoThumbnail12 from "./images/video-thumbnail-12.png"
import videoThumbnail13 from "./images/video-thumbnail-13.png"
import videoThumbnail14 from "./images/video-thumbnail-14.png"
import videoThumbnail15 from "./images/video-thumbnail-15.png"
import videoThumbnail16 from "./images/video-thumbnail-16.png"
import videoThumbnail17 from "./images/video-thumbnail-17.png"
import videoThumbnail18 from "./images/video-thumbnail-18.png"
import videoThumbnail19 from "./images/video-thumbnail-19.png"
import videoThumbnail20 from "./images/video-thumbnail-20.png"
import videoThumbnail21 from "./images/video-thumbnail-21.png"
import videoThumbnail22 from "./images/video-thumbnail-22.png"
import videoThumbnail23 from "./images/video-thumbnail-23.png"
import videoThumbnail24 from "./images/video-thumbnail-24.png"
import videoThumbnail25 from "./images/video-thumbnail-25.png"
import videoThumbnail26 from "./images/video-thumbnail-26.png"
import videoThumbnail27 from "./images/video-thumbnail-27.png"
import videoThumbnail28 from "./images/video-thumbnail-28.png"
import videoThumbnail29 from "./images/video-thumbnail-29.png"
import videoThumbnail30 from "./images/video-thumbnail-30.png"
import videoAuthor01 from "./images/video-author-01.png"
import videoAuthor02 from "./images/video-author-02.png"
import videoAuthor03 from "./images/video-author-03.png"
import videoAuthor04 from "./images/video-author-04.png"
import videoAuthor05 from "./images/video-author-05.png"
import videoAuthor06 from "./images/video-author-06.png"
import videoAuthor07 from "./images/video-author-07.png"
import videoAuthor08 from "./images/video-author-08.png"
import videoAuthor09 from "./images/video-author-09.png"
import videoAuthor10 from "./images/video-author-10.png"
import videoAuthor11 from "./images/video-author-11.png"
import videoAuthor12 from "./images/video-author-12.png"
import videoAuthor13 from "./images/video-author-13.png"
import videoAuthor14 from "./images/video-author-14.png"
import videoAuthor15 from "./images/video-author-15.png"
import videoAuthor16 from "./images/video-author-16.png"
import videoAuthor17 from "./images/video-author-17.png"
import videoAuthor18 from "./images/video-author-18.png"
import videoAuthor19 from "./images/video-author-19.png"
import videoAuthor20 from "./images/video-author-20.png"
import videoAuthor21 from "./images/video-author-11.png"
import videoAuthor22 from "./images/video-author-12.png"
import videoAuthor23 from "./images/video-author-13.png"
import videoAuthor24 from "./images/video-author-14.png"
import videoAuthor25 from "./images/video-author-15.png"
import videoAuthor26 from "./images/video-author-16.png"
import videoAuthor27 from "./images/video-author-17.png"
import videoAuthor28 from "./images/video-author-18.png"
import videoAuthor29 from "./images/video-author-19.png"
import videoAuthor30 from "./images/video-author-20.png"
import imgComingSoon from "./images/coming-soon.png"
import iconAllVideos from "./images/icon-all-videos.png"
import iconHomeVideos from "./images/icon-home-videos.png"
import iconFavoriteVideos from "./images/icon-favorite-videos.png"
import iconHistroyVideos from "./images/icon-history-videos.png"
import instaLogo from "./images/instaLogo.png"
import facebookLogo from "./images/facebookLogo.png"
import coverPhoto from "./images/cover-photo.jpg"
export const Images = {
    siteLogo,
    iconUser,
    dataClass,
    dataLabel,
    blogImg01,
    blogImg02,
    blogImg03,
    logoArhamsoft,
    authPageLogo,
    videoThumbnail01,
    videoThumbnail02,
    videoThumbnail03,
    videoThumbnail04,
    videoThumbnail05,
    videoThumbnail06,
    videoThumbnail07,
    videoThumbnail08,
    videoThumbnail09,
    videoThumbnail10,
    videoThumbnail11,
    videoThumbnail12,
    videoThumbnail13,
    videoThumbnail14,
    videoThumbnail15,
    videoThumbnail16,
    videoThumbnail17,
    videoThumbnail18,
    videoThumbnail19,
    videoThumbnail20,
    videoThumbnail21,
    videoThumbnail22,
    videoThumbnail23,
    videoThumbnail24,
    videoThumbnail25,
    videoThumbnail26,
    videoThumbnail27,
    videoThumbnail28,
    videoThumbnail29,
    videoThumbnail30,
    videoAuthor01,
    videoAuthor02,
    videoAuthor03,
    videoAuthor04,
    videoAuthor05,
    videoAuthor06,
    videoAuthor07,
    videoAuthor08,
    videoAuthor09,
    videoAuthor10,
    videoAuthor11,
    videoAuthor12,
    videoAuthor13,
    videoAuthor14,
    videoAuthor15,
    videoAuthor16,
    videoAuthor17,
    videoAuthor18,
    videoAuthor19,
    videoAuthor20,
    videoAuthor21,
    videoAuthor22,
    videoAuthor23,
    videoAuthor24,
    videoAuthor25,
    videoAuthor26,
    videoAuthor27,
    videoAuthor28,
    videoAuthor29,
    videoAuthor30,
    imgComingSoon,
    iconAllVideos,
    iconHomeVideos,
    iconFavoriteVideos,
    iconHistroyVideos,
    instaLogo,
    facebookLogo,
    coverPhoto
}