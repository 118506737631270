import React from 'react';
import { Link } from 'react-router-dom';
import { Images } from '../../../assets/assets';
import './UnderConstruction.css'
function UnderConstruction() {
    return (
        <div id="under-construction" className='d-flex justify-content-center align-items-center'>
            <div className="under-construction">
                <div className="under-construction-404">
                    <div className="img-holder">
                        <img className="img-fluid" src={Images.imgComingSoon} alt='Under Construction' />
                    </div>
                    {/* <h1>Under Construction</h1> */}
                </div>
                <h4 className="mb-3">we are sorry, but the page you requested is under construction</h4>
                <Link className="d-inline-block align-top orange-main-button" to="/">Go To Home</Link>
            </div>
        </div>
    );
}
export default UnderConstruction;