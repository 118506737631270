import React, { useState, useEffect } from 'react';
import '../../Forms/Forms.css'
import { Container, Form } from 'react-bootstrap'
import { useNavigate, } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { login, beforeUser } from '../users.actions';
import ReCAPTCHA from "react-google-recaptcha";
import { ENV } from '../../../../config/config';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { validateEmail } from '../../../../utils/Validations/validations';
import { Images } from '../../../../assets/assets';

const { recaptchaSiteKey } = ENV

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [loginData, setLoginData] = useState({ email: '', password: '' })
    const [captcha, setCaptcha] = useState('')
    const [errors, setErrors] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPass] = useState(false)


    const userSelector = useSelector((state) => state.user)
    const errorSelector = useSelector((state) => state.error)

    useEffect(() => {
        if (userSelector.userAuth) {
            let data = userSelector.userData
            setLoader(false)
            dispatch(beforeUser())

            const channelName = localStorage.getItem('channelName')
            if (localStorage.getItem('redirectTo')) {
                navigate(`/${localStorage.getItem('redirectTo')}`)
                localStorage.removeItem('redirectTo')
            }
            else
                navigate(data.type === 2 ? `/publisher-dashboard/${channelName}` : '/')
        }
    }, [userSelector.userAuth])

    useEffect(() => {
        if (errorSelector) {
            setLoader(false)
        }
    }, [errorSelector])

    const navigateToSignup = () => {
        navigate('/signup');
    };

    const navigateToForgot = () => {
        navigate('/forgot-password');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            loginHandler();
        }
    }

    const loginHandler = () => {

        setSubmitted(true)

        let emailErr = ''
        let passErr = ''
        let captchaErr = ''


        if (!loginData.email)
            emailErr = "This field is required."
        else
            emailErr = validateEmail(loginData.email)

        if (!loginData.password)
            passErr = "This field is required."

        if (!captcha)
            captchaErr = 'This field is required.'

        setErrors({ email: emailErr, password: passErr, captcha: captchaErr })

        if (emailErr || passErr || captchaErr)
            return

        setLoader(true)
        dispatch(login(loginData))

    };

    const onChange = (value) => {
        if (submitted && value)
            setErrors({ ...errors, captcha: '' })
        setCaptcha(value)
    }

    const onChangeHandler = (e) => {
        if (submitted) {
            if (e.target.value) {
                setErrors({ ...errors, [e.target.name]: '' })
            }
            else {
                setErrors({ ...errors, [e.target.name]: 'This field is required.' })
            }

        }
        setLoginData({ ...loginData, [e.target.name]: e.target.value })
    }

    return (
        <>
            {loader && <FullPageLoader />}
            <div className='theme-form login'>
                <div className='biiview-form'>
                    <Container fluid className='formContainer'>
                        <div className='wrapForm'>
                            <Form className='formStyle position-relative'>
                                <span className="auth-login-logo">
                                    <img className="img-fluid" src={Images.authPageLogo} alt='BiiView Logo' />
                                </span>
                                <div className='formHeader'>
                                    {

                                        <h2>{'Login your Account'}</h2>
                                    }
                                    <div className='d-flex align-items-end justify-content-center'>
                                        <p className='mb-0'>{'NEW USER?'}</p>
                                        <button onClick={navigateToSignup} className='link orangeLinkBtn ms-2'>REGISTER</button>
                                    </div>
                                </div>
                                <Form.Group className='formGroup' controlId="formBasicEmail" onKeyPress={handleKeyPress}>
                                    <Form.Label>Email Address <span className='color-red'>*</span></Form.Label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                        placeholder="Enter Your Email Address"
                                        name='email'
                                        value={loginData.email}
                                        onChange={onChangeHandler}>
                                    </input>
                                    {
                                        errors && errors.email &&
                                        <span className='submit-errors text-danger'>{errors.email}</span>
                                    }
                                </Form.Group>
                                <Form.Group className='formGroup' controlId="formBasicEmail" onKeyPress={handleKeyPress}>
                                    <Form.Label>Password <span className='color-red'>*</span>
                                    </Form.Label>
                                    <div className='position-relative'>
                                        <input
                                            type={showPassword ? 'text' : "password"}
                                            className="form-control"
                                            name='password'
                                            placeholder="Enter Your Password"
                                            value={loginData.password}
                                            onChange={onChangeHandler}>
                                        </input>
                                        {
                                            loginData.password ?
                                                showPassword ?
                                                    <BsEye className="pointer eye-icon" onClick={() => setShowPass(false)} /> :
                                                    <BsEyeSlash className="pointer eye-icon" onClick={() => setShowPass(true)} />
                                                : ''
                                        }
                                    </div>
                                    {
                                        errors && errors.password &&
                                        <span className='submit-errors text-danger'>{errors.password}</span>
                                    }
                                </Form.Group>
                                <div className='theme-capcha'>
                                    <ReCAPTCHA
                                        sitekey={recaptchaSiteKey}
                                        onChange={onChange}
                                    />
                                    {
                                        errors && errors.captcha &&
                                        <span className='submit-errors text-danger'>{errors.captcha}</span>
                                    }
                                    <div className="submit-btn-holder">
                                        <button type="button" className='orange-main-button text-uppercase end-mb' onClick={loginHandler}>log in</button>
                                    </div>
                                </div>
                                <div className='d-flex align-items-end justify-content-center text-uppercase'>
                                    <p className='mb-0 link outer-p'
                                        onClick={navigateToForgot}>FORGOT YOUR PASSWORD?
                                    </p>
                                </div>
                                {/* <div className='d-flex align-items-end justify-content-center text-uppercase mt-2'>
                                    <p className='mb-0 link outer-p'
                                        onClick={() => navigate('/')}>BACK TO HOME
                                    </p>
                                </div> */}
                            </Form>

                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}
export default Login