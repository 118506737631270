import React, { useState, useEffect } from 'react'
import './PaymentSuccessfull.css'
// import Lottie from 'react-lottie';
import PaymentLottie from '../../../assets/lottie/payment.json';
import { useSearchParams, useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
    const navigate = useNavigate()
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: PaymentLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const [params, setParams] = useSearchParams();
    const [invoice, setInvoiceId] = useState('')
    // const channelId = localStorage.getItem('channelId')
    const channelName = localStorage.getItem('channelName')

    useEffect(() => {
        if (params) {
            let invoiceId = params.get('invoice')
            if (invoiceId)
                setInvoiceId(invoiceId)
        }

    }, [params])

    return (
        <>
            <div className='payment-success-sec'>
                <div className='fixedModal payment-success-modal'>
                    <div className='payment-success text-center'>
                        {/* <Lottie
                            options={defaultOptions}
                            height={245}
                            width={245}
                        /> */}
                        <div className="d-flex justify-content-center">
                            <div>
                                <svg class="animated-check" viewBox="0 0 24 24"><path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
                            </div>
                        </div>
                        <h3>Payment Successfully Done</h3>
                        <p>You have been successfully subscribed to plan</p>
                        <div className='go-to-dashboard-btn'>
                            <button
                                type="button"
                                className="theme-btn orange-btn text-uppercase mt-0"
                                onClick={() => navigate(`/publisher-dashboard/${channelName}`)}
                            >
                                go to dashboard</button>
                        </div>
                        {
                            invoice &&
                            <div className='view-invoice-btn'>
                                <button
                                    type="button"
                                    className="text-uppercase mt-0"
                                    onClick={() => navigate(`/invoice/${invoice}`)}
                                >
                                    view invoice
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentSuccess