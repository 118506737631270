import React, { useEffect } from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

const Public = ({ title, children }) => {

    useEffect(() => {
        if (title)
            document.title = 'BiiView | ' + title;
        else
            document.title = "BiiView";
    }, [title]);

    useEffect(() => {
        let bodyElement = document.getElementsByTagName('body')[0]
        if(bodyElement)
            bodyElement.classList.remove('dashboard-page')
    },[])

    return (
        <>
            <Header />
            <div className="page-content" id="sticky-content">
                {children}
            </div>
            <Footer />
        </>
    )
}

export default Public