import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Accordion } from 'react-bootstrap'
import { FaPlus, FaMinus } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux'
import { listFaqs, beforeFaq } from './faqs.actions';
import FullPageLoader from '../FullPageLoader/FullPageLoader';

const FAQs = () => {
    const dispatch = useDispatch()

    const [faqs, setFaqs] = useState([])
    const [loader, setLoader] = useState(true)

    const faqsSelector = useSelector((state) => state.faqs)

    useEffect(() => {
        dispatch(listFaqs())
    }, [])

    useEffect(() => {
        if (faqsSelector.faqsAuth) {
            setFaqs(faqsSelector.faqsData?.faqs)
            setLoader(false)
            dispatch(beforeFaq())
        }
    }, [faqsSelector.faqsAuth])

    return (
        <>
            {loader && <FullPageLoader />}
            <section className='help-sec faq-sec pb-0'>
                <Container className='customBox'>
                    <Row>
                        <Col lg={12}>
                            <h1><span className='theme'>Frequently Asked Questions</span></h1>
                            <p>You can find the answers to your questions.</p>
                            <Accordion defaultActiveKey="0">
                                {
                                    faqs && faqs?.length ?
                                        faqs.map((faq, index) => {
                                            return (
                                                <Accordion.Item eventKey={index}>
                                                    <Accordion.Header>
                                                        <span className='plus-icon'><FaPlus /></span>
                                                        <span className='minus-icon'><FaMinus /></span>
                                                        {faq.title}
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='mb-0 faqs-content' dangerouslySetInnerHTML={{ __html: faq.desc }}>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                        : null
                                }
                            </Accordion>
                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    )
}

export default FAQs