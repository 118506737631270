import React from 'react'
import { useState, useEffect } from 'react'
import dashbordLogo from '../../../../assets/images/logo.png'
import dashboardIcon from '../../../../assets/images/dashboardIcon.svg'
import contentIcon from '../../../../assets/images/contentIcon.svg'
import analyticIcon from '../../../../assets/images/analyticIcon.svg'
import subtitleIcon from '../../../../assets/images/subtitleIcon.svg'
import settingIcon from '../../../../assets/images/settingIcon.svg'
import biiview from '../../../../assets/images/biiview.svg'
import MyChannelIcon from '../../../../assets/images/my-channels.svg'
import PackagesIcon from '../../../../assets/images/packages.svg'
import ProfileLineIcon from '../../../../assets/images/profile-line.svg'
import MyVideosIcon from '../../../../assets/images/my-videos.svg'
import payment from '../../../../assets/images/payment.svg'
import ArrowLeft from '../../../../assets/images/feather-arrow-left.svg'
import { AiOutlineAlignCenter } from "react-icons/ai"
import { Link, useNavigate } from 'react-router-dom'
import { RiLogoutCircleRLine } from "react-icons/ri";
import SettingsModal from '../Settings/SettingsModal'
import { ENV } from '../../../../config/config'
import { useDispatch, useSelector } from 'react-redux'
import { beforeChannelRole } from '../../ChannelRoles/channelRoles.actions'
import { beforeChannels, getChannel } from '../../MyChannels/channels.actions'
import { useSearchParams } from 'react-router-dom'
import { getVideo, beforeVideo } from '../Videos/video.actions'
import { beforeCounter } from '../../Notifications/notifications.actions'
import { Tooltip } from 'react-tooltip'
import { channelAnalyticsLink, contactAdmin, exploreVideosLink, memberDashboardLink, myChannelLink, myVideosLink, packagesLink, paymentHistoryLink, profileLink, settingsLink, subtitlesLink } from '../../ApplicationTour/TourCompIdentifiers'
import './MemberDashboardSidebar.css'
import '../DashboardPage/DasboardPage.css'

const { userPlaceholderImage, defaultPlaceholderImage } = ENV

const DashboardSidebar = ({ dashboardBar, setDashboardBar, channel }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [params, setParams] = useSearchParams()

    const channelId = channel || localStorage.getItem('channelId')
    const channelName = localStorage.getItem('channelName')

    const [channelData, setChannelData] = useState({})
    const [show, setShow] = useState(false)
    const [memberRole, setMemberRole] = useState()
    const [video, setVideo] = useState({})
    const [settingsModal, setSettingsModel] = useState(false)

    const [showMenu, setShowMenu] = useState(false)

    const rolesSelector = useSelector((state) => state.roles)
    const channelSelector = useSelector((state) => state.channels)
    const videoSelector = useSelector((state) => state.videos)

    const logoutHandler = () => {
        localStorage.clear()
        dispatch(beforeCounter())
        navigate('/login')
    }

    const menuShow = () => {
        setShowMenu(!showMenu);
    }

    useEffect(() => {
        if (params) {
            let videoId = params.get('video')
            if (videoId) {
                dispatch(getVideo(videoId, `all=${true}`))
            }
        }
    }, [params])

    useEffect(() => {
        if (videoSelector.getVideoAuth) {
            setVideo(videoSelector.getVideoData.video)
            dispatch(beforeVideo())
        }
    }, [videoSelector.getVideoAuth])

    useEffect(() => {
        if (rolesSelector.channelRoleAuth) {
            let role = rolesSelector.channelRoleData.memberRole
            setMemberRole(role)
            dispatch(beforeChannelRole())
        }
    }, [rolesSelector.channelRoleAuth])


    useEffect(() => {
        if (settingsModal)
            setSettingsModel(false)

    }, [window.location.pathname])

    useEffect(() => {
        if (channelId)
            dispatch(getChannel(`channelId=${channelId}`))
    }, [])

    useEffect(() => {
        if (channelSelector.getChannelAuth) {
            let data = channelSelector.getChannelData?.channel
            setChannelData(data)
            dispatch(beforeChannels())
        }
    }, [channelSelector.getChannelAuth])

    const backHandler = () => {
        navigate('/my-videos')
        setVideo({})
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 576) {
                setDashboardBar(true);
            }
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the initial state
        handleResize();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className='dashboardBody'>
                <div className='dashboard-Sidebar'>
                    <div className={dashboardBar ? 'dsahboardLogo logoTrue' : ' dsahboardLogo-change logoTrue'}
                        onClick={() => setDashboardBar(!dashboardBar)}><AiOutlineAlignCenter></AiOutlineAlignCenter></div>
                    {
                        dashboardBar ?
                            <div className={`dashboardFlex ${showMenu ? "show" : ""}`} onMouseOver={() => setShow(!show)}>
                                <div className="mobile-button" onClick={menuShow}>
                                    <AiOutlineAlignCenter />
                                </div>
                                <div>
                                    {/* <div className='dashboardLogo'>
                                        <Link to='/'> <img className='img-fluid' src={dashbordLogo} alt='BiiView'></img> </Link>
                                    </div> */}
                                    <div className='autoBar'>
                                        {
                                            window.location.pathname === '/video-analytics' || window.location.pathname.split('/')[1] === 'subtitles' ?
                                                <div className='sidebar-video-wrapper'>
                                                    <div className='d-flex align-items-center mb-3'>
                                                        <img src={ArrowLeft} alt='arrow-left' className='img-fluid pointer' onClick={backHandler} />
                                                        <p className='ms-3 mb-0'>My Videos</p>
                                                    </div>
                                                    <div className='video-wrapper-main'>
                                                        <div className='video-wrapper'>
                                                            <div className='image mb-3'>
                                                                <img src={video?.thumbnails && video?.thumbnails[0] || defaultPlaceholderImage} alt='video' className='img-fluid mb-2' />
                                                            </div>
                                                            <p className='mb-1'>Your Video</p>
                                                            {
                                                                video?.title ? <p className='opacity-p'>{video.title}</p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className='user'>
                                                    <div className='userLink'>
                                                        <div className='userBlock'>
                                                            <div className='userImg'><img className='img-fluid' src={channelData?.channelImage || userPlaceholderImage} alt={channelData?.name}></img></div>
                                                            <h3>{channelData?.name}</h3>
                                                            <p>{channelData?.uploadedVideosCount} {channelData?.uploadedVideosCount > 1 ? 'Videos' : 'Video'} Uploaded</p>
                                                        </div>
                                                        <ul className='dashboard-ul'>
                                                            {
                                                                memberRole && memberRole.viewChannelDashboard &&
                                                                <li id={memberDashboardLink}>
                                                                    <Link to={`/publisher-dashboard/${channelName}`} className={`${window.location.pathname?.toLowerCase().includes('/publisher-dashboard') ? 'active' : ''}`}>
                                                                        <img className='img-fluid me-3' src={dashboardIcon} alt='dashboard' />
                                                                        <span>Dashboard</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            {
                                                                memberRole && memberRole.viewChannelVideos &&
                                                                <li id={myVideosLink}>
                                                                    <Link to='/my-videos' className={`${window.location.pathname?.toLowerCase() === '/my-videos' ? 'active' : ''}`}>
                                                                        <img className='img-fluid me-3' src={MyVideosIcon} alt='my-videos' />
                                                                        <span>My Videos</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            <li id={exploreVideosLink}>
                                                                <Link to='/' className={`${window.location.pathname?.toLowerCase() === '/' ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={contentIcon} alt='explore videos' />
                                                                    <span>Explore Videos</span>
                                                                </Link>
                                                            </li>
                                                            {
                                                                memberRole && memberRole.viewChannelAnalytics &&
                                                                <li id={channelAnalyticsLink}>
                                                                    <Link to='/channel-analytics' className={`${window.location.pathname?.toLowerCase() === '/channel-analytics' ? 'active' : ''}`}>
                                                                        <img className='img-fluid me-3' src={analyticIcon} alt='analytics' />
                                                                        <span>Channel Analytics</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                            {/* {
                                                                        memberRole && memberRole.viewVideoSubtitles &&
                                                                        <li id={subtitlesLink}>
                                                                            <Link to='/subtitles' className={`${window.location.pathname?.toLowerCase() === '/subtitles' ? 'active' : ''}`}>
                                                                                <img className='img-fluid me-3' src={subtitleIcon} alt='subtitles' />
                                                                                <span>Subtitles</span>
                                                                            </Link>
                                                                        </li>
                                                                    } */}
                                                            <li id={settingsLink}>
                                                                <Link to={``} onClick={() => setSettingsModel(true)} className={`${settingsModal ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={settingIcon} alt='settings' />
                                                                    <span>Settings</span>
                                                                </Link>
                                                            </li>
                                                            <li id={paymentHistoryLink}>
                                                                <Link to='/payment-history' className={`${window.location.pathname?.toLowerCase() === '/payment-history' ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={payment} alt='payment' />
                                                                    <span>Payment History</span>
                                                                </Link>
                                                            </li>
                                                            <li id={myChannelLink}>
                                                                <Link to='/channels' className={`${window.location.pathname?.toLowerCase() === '/channels' ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={MyChannelIcon} alt='channels' />
                                                                    <span>My Channels</span>
                                                                </Link>
                                                            </li>
                                                            <li id={packagesLink}>
                                                                <Link to='/packages' className={`${window.location.pathname?.toLowerCase() === '/packages' ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={PackagesIcon} alt='packages' />
                                                                    <span>Packages</span>
                                                                </Link>
                                                            </li>
                                                            <li id={profileLink}>
                                                                <Link to='/profile' className={`${window.location.pathname?.toLowerCase() === '/profile' ? 'active' : ''}`}>
                                                                    <img className='img-fluid me-3' src={ProfileLineIcon} alt='profile' />
                                                                    <span>Profile</span>
                                                                </Link>
                                                            </li>
                                                            {
                                                                memberRole && memberRole.viewContactAdmin &&
                                                                <li id={contactAdmin}>
                                                                    <Link to='/contact-admin' className={`${window.location.pathname?.toLowerCase() === '/contact-admin' ? 'active' : ''}`}>
                                                                        <img className='img-fluid me-3' src={payment} alt='contact-admin' />
                                                                        <span>Contact Admin</span>
                                                                    </Link>
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className='logout-button-div d-flex justify-content-center'><button type="button" className="theme-btn orange-btn dbLogoutBtn" onClick={logoutHandler}>Sign Out</button></div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='dashboard-sidebar MobileSidebar'>
                                <div>
                                    {/* <div className='dashboardLogo px-1'>
                                        <div><Link to='/'><img className='img-fluid' src={biiview} alt='biiview'></img></Link></div>
                                    </div> */}
                                    <div className='autoBar'>
                                        <div className='user'>
                                            <div className='userLink'>
                                                <ul className='dashboard-ul'>
                                                    {memberRole && memberRole.viewChannelDashboard &&
                                                        <li id={memberDashboardLink}>
                                                            <Link id="dashboard" to={`/publisher-dashboard/${channelName}`} className={`${window.location.pathname?.toLowerCase().includes('/publisher-dashboard') ? 'active' : ''}`}>
                                                                <img className='img-fluid' src={dashboardIcon} alt='dashboard' />
                                                            </Link>
                                                            <Tooltip
                                                                anchorId="dashboard"
                                                                place="right"
                                                                content="Dashboard"
                                                            />
                                                        </li>
                                                    }
                                                    {
                                                        memberRole && memberRole.viewChannelVideos &&
                                                        <li id={myVideosLink}>
                                                            <Link id="my-videos" to='/my-videos' className={`${window.location.pathname?.toLowerCase() === '/my-videos' ? 'active' : ''}`}>
                                                                <img className='img-fluid' src={MyVideosIcon} alt='my-videos' />
                                                            </Link>
                                                            <Tooltip
                                                                anchorId="my-videos"
                                                                place="right"
                                                                content="My Videos"
                                                            />
                                                        </li>
                                                    }
                                                    <li id={exploreVideosLink}>
                                                        <Link id="explore" to='/' className={`${window.location.pathname?.toLowerCase() === '/' ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={contentIcon} alt='explore-videos' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="explore"
                                                            place="right"
                                                            content="Explore Videos"
                                                        />
                                                    </li>
                                                    {
                                                        memberRole && memberRole.viewChannelAnalytics &&
                                                        <li id={channelAnalyticsLink}>
                                                            <Link id="channel-analytics" to='/channel-analytics' className={`${window.location.pathname?.toLowerCase() === '/channel-analytics' ? 'active' : ''}`}>
                                                                <img className='img-fluid' src={analyticIcon} alt='analytics' />
                                                            </Link>
                                                            <Tooltip
                                                                anchorId="channel-analytics"
                                                                place="right"
                                                                content="Channel Analytics"
                                                            />
                                                        </li>
                                                    }
                                                    {
                                                        memberRole && memberRole.viewVideoSubtitles &&
                                                        <li id={subtitlesLink}>
                                                            <Link id="subtitles" to='/subtitles' className={`${window.location.pathname?.toLowerCase() === '/subtitles' ? 'active' : ''}`}>
                                                                <img className='img-fluid' src={subtitleIcon} alt='subtitles' />
                                                            </Link>
                                                            <Tooltip
                                                                anchorId="subtitles"
                                                                place="right"
                                                                content="Subtitles"
                                                            />
                                                        </li>
                                                    }
                                                    <li id={settingsLink}>
                                                        <Link id="settings" to='' onClick={() => setSettingsModel(true)} className={`${settingsModal ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={settingIcon} alt='settings' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="settings"
                                                            place="right"
                                                            content="Settings"
                                                        />
                                                    </li>
                                                    <li id={paymentHistoryLink}>
                                                        <Link id="payment-history" to='/payment-history' className={`${window.location.pathname?.toLowerCase() === '/payment-history' ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={subtitleIcon} alt='subtitles' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="payment-history"
                                                            place="right"
                                                            content="Payment History"
                                                        />
                                                    </li>
                                                    <li id={myChannelLink}>
                                                        <Link id="channels" to='/channels' className={`${window.location.pathname?.toLowerCase() === '/channels' ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={MyChannelIcon} alt='payment-history' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="channels"
                                                            place="right"
                                                            content="My Channels"
                                                        />
                                                    </li>
                                                    <li id={packagesLink}>
                                                        <Link id="packages" to='/packages' className={`${window.location.pathname?.toLowerCase() === '/packages' ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={PackagesIcon} alt='subtitles' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="packages"
                                                            place="right"
                                                            content="Packages"
                                                        />
                                                    </li>
                                                    <li id={profileLink}>
                                                        <Link id="profile" to='/profile' className={`${window.location.pathname?.toLowerCase() === '/profile' ? 'active' : ''}`}>
                                                            <img className='img-fluid' src={ProfileLineIcon} alt='subtitles' />
                                                        </Link>
                                                        <Tooltip
                                                            anchorId="profile"
                                                            place="right"
                                                            content="Profile"
                                                        />
                                                    </li>
                                                    {
                                                        memberRole && memberRole.viewContactAdmin &&
                                                        <li id={contactAdmin}>
                                                            <Link to='/contact-admin' className={`${window.location.pathname?.toLowerCase() === '/contact-admin' ? 'active' : ''}`}>
                                                                <img className='img-fluid' src={ProfileLineIcon} alt='contact-admin' />
                                                            </Link>
                                                            <Tooltip
                                                                anchorId="contact-admin"
                                                                place="right"
                                                                content="Contact Admin"
                                                            />
                                                        </li>

                                                    }
                                                </ul>
                                            </div>
                                            <div><button type="button" className="theme-btn orange-btn text-uppercase px-2 py-1 dbLogoutBtnMob m-auto" onClick={logoutHandler}><RiLogoutCircleRLine /></button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                {
                    settingsModal &&
                    <SettingsModal settingsModal={settingsModal} setSettingsModel={setSettingsModel} />
                }
            </div>
        </>
    )
}
export default DashboardSidebar