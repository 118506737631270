import { toast } from "react-toastify";
import { emptyError } from '../../../../redux/shared/error/error.action';
import { ENV } from '../../../../config/config';
import { GET_TOP_VIDEOS, BEFORE_DASHBOARD, GET_ERRORS, DASHBOARD_ANALYTICS } from "../../../../redux/types";

export const beforeDashboard = () => {
  return {
    type: BEFORE_DASHBOARD
  }
}

export const getTopVideos = (qs = '') => (dispatch) => {

  const { accessToken } = ENV.getUserKeys('accessToken')

  dispatch(emptyError());

  let url = `${ENV.url}dashboard/stats/top-videos`;
  if(qs)
    url = `${url}?${qs}`
  fetch(url, {
    method: "GET",
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
      'x-access-token': accessToken || ''
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_TOP_VIDEOS,
          payload: data,
        });
      } else {
        toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    })
};

export const getDashboardAnalytics = (qs = '') => (dispatch) => {

  const { accessToken } = ENV.getUserKeys('accessToken')

  dispatch(emptyError());

  let url = `${ENV.url}dashboard/stats/summary`;
  if (qs)
    url = `${url}?${qs}`
    
  fetch(url, {
    method: "GET",
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
      'x-access-token': accessToken || ''
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: DASHBOARD_ANALYTICS,
          payload: data,
        });
      } else {
        toast.error(data.message)
        dispatch({
          type: GET_ERRORS,
          payload: data
        })
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message)
          toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    })
};


