import React, { useState, useEffect } from 'react'
import '../Models/Models.css'
import { Modal } from 'react-bootstrap'
import { HiOutlineClipboardDocument } from "react-icons/hi2"
import copy from 'copy-to-clipboard';
import { ENV } from '../../../../config/config'
import SmallLoader from '../../../../utils/SmallLoader/SmallLoader';
import { Tooltip } from 'react-tooltip'
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookIcon,
    TwitterIcon,
} from "react-share";

const { defaultPlaceholderImage } = ENV

const ShareableLink = ({ video, setUrlModal, urlModal }) => {

    const [loader, setLoader] = useState(true)
    const [copied, setCopied] = useState('')
    const message = "Hey! Checkout my video "

    const handleClose = () => {
        setUrlModal(false)
    };

    useEffect(() => {
        if (video && Object.keys(video).length)
            setLoader(false)
    }, [video])

    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => {
                setCopied('')

            }, 1000);
            return () => clearTimeout(timer)
        }
    }, [copied])


    return (
        <>
            <div className='modalPage'>
                <div className="modal show" >
                    <Modal show={urlModal} onHide={handleClose} className="narrowModel" centered>
                        <Modal.Header closeButton>
                            <Modal.Title className='mb-0'>{video.title ? video.title : ''}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='py-lg-4 py-3'>
                            {
                                loader ?
                                    <SmallLoader width={40} height={40} /> :
                                    <div className='fileGroup'>
                                        <p>Get Video's Shareable Link</p>
                                        <div className='fileGroupList'>
                                            <div className='videoLinkImg'>
                                                <figure className='mb-0'><img className='img-fluid' src={video.thumbnailImage || defaultPlaceholderImage} alt='Video'></img></figure>
                                            </div>
                                            <div className='vidoDetails'>
                                                <div className='clipboardDiv'>
                                                    <a href={video.videoUrl ? video.videoUrl : ''} className='videoLink ellipse' target={'_blank'}>{video.videoUrl}</a>
                                                    <span data-tooltip-id="my-tooltip" className="position-relative cursor-pointer" data-tooltip-content="Copy" onClick={() => copy(video.videoUrl ? video.videoUrl : '', { onCopy: setCopied('copied') })}>
                                                        <HiOutlineClipboardDocument />
                                                        {copied && <p className='text-copied'>Copied!</p>}
                                                    </span>
                                                </div>
                                            </div>
                                            <Tooltip id="my-tooltip" />
                                        </div>
                                        <p>Share Video on Social Media</p>
                                        <div className='social-icons-blog-post d-flex align-items-center'>
                                            <FacebookShareButton
                                                url={`${video?.videoUrl}`}
                                                title={`${message}${video?.title}`}
                                            >
                                                <FacebookIcon size={32} round={true} />
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                url={`${video?.videoUrl}`}
                                                title={`${message}${video?.title}`}
                                            >
                                                <TwitterIcon size={32} round={true} />
                                            </TwitterShareButton>
                                            <WhatsappShareButton
                                                url={`${video?.videoUrl}`}
                                                title={`${message}${video?.title}`}
                                            >
                                                <WhatsappIcon size={32} round={true} />
                                            </WhatsappShareButton>
                                        </div>
                                    </div>
                            }

                        </Modal.Body>
                        <Modal.Footer className='pb-0'>
                            <div className='d-flex align-items-center'>
                                <button className="grey-btn text-uppercase me-lg-3 me-2 mt-0" onClick={handleClose}>close</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default ShareableLink