import { toast } from "react-toastify";
import { emptyError } from './../../../redux/shared/error/error.action';
import { ENV } from '../../../config/config';
import { BEFORE_METADATA, GET_ERRORS, GET_VIDEO_METADATA } from './../../../redux/types';
const { accessToken } = ENV.getUserKeys('accessToken')

export const beforeVideoMetadata = () => {
  return {
    type: BEFORE_METADATA
  }
}

export const getVideoMetadata = (id) => (dispatch) => {
    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());

    let url = `${ENV.url}videos/get/metadata/${id}`;

    fetch(url, {
        method: "GET",
        headers: {
        'Authorization': ENV.Authorization,
        'x-auth-token': ENV.x_auth_token,
        'x-access-token': accessToken || ''
        },
    })
    .then((res) => res.json())
        .then((data) => {
        if (data.success) {
            dispatch({
            type: GET_VIDEO_METADATA,
            payload: data,
            });
        } else {
            toast.error(data.message)
            dispatch({
            type: GET_ERRORS,
            payload: data
            })
        }
        })
        .catch((error) => {
        if (error.response && error.response.data) {
            const { data } = error.response;
            if (data.message)
            toast.error(data.message);
        }
        dispatch({
            type: GET_ERRORS,
            payload: error,
        });
    })
};
