import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, PinterestIcon, PinterestShareButton, TwitterIcon, TwitterShareButton } from 'react-share'
import { ENV } from '../../../config/config'
import { FaShareAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';

const SocialShare = (props) => {

    const [showIcons, setShowIcons] = useState(false)
    const [closeIcons, setCloseIcons] = useState(false)

    return (
        <div className='channel-details-btns share-btn d-flex align-items-center justify-content-start position-relative'>
            {showIcons && props.shareUrl?
                <div className='social-icons-blog-post d-flex align-items-center'>
                    <FacebookShareButton
                        url={`${ENV.url}/blog/${props.shareUrl}`}
                        title={props.title}
                    >
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton
                        url={`${props.shareUrl}`}
                        title={props.title}
                    >
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                        url={`${props.shareUrl}`}
                        title={props.title}
                    >
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                    <PinterestShareButton
                        url={`${props.shareUrl}`}
                        media={props.shareUrl}
                        title={props.title}
                    >
                        <PinterestIcon size={32} round={true} />
                    </PinterestShareButton>
                </div>
                : null}
            {
                !showIcons ?
                    props.blogPostShare ?
                        <Button className={`blog-share-btn ${showIcons ? "active" : ""}`} onClick={() => setShowIcons(true)} variant="">
                            <FaShareAlt className='me-2' />Share
                        </Button> :
                        <button className='share-button' onClick={() => setShowIcons(true)}>
                            <span><FontAwesomeIcon icon={faShare} /> Share</span>
                        </button> :
                    <Button className={ showIcons ? "active cross-btn" : ""} onClick={() => setShowIcons(false)} variant="">
                        <MdCancel className='' /> 
                    </Button>
            }
        </div>
    )
}

export default SocialShare