import React, { useState, useEffect } from 'react'
import './siteTabs.css'
import ReactECharts from 'echarts-for-react'
import { useDispatch, useSelector } from 'react-redux'
import { beforeVideoAnalytics } from '../Videos/video.actions'
import moment from 'moment'
import Select from 'react-select'
import { beforeObject, listObjects } from './IdentifiedObjects/objects.actions'

const ObjectsRate = (props) => {

    const dispatch = useDispatch()

    const [objects, setObjects] = useState([])
    const [object, setObject] = useState('')

    const colors = ['#5470C6', '#FD6F21'];
    const [options, setOptions] = useState({
        color: colors,
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                type: 'line',
                color: colors[1],
                symbol: "circle"
            }
        ]
    })

    const [clickedStatOption, setClickedOption] = useState(1);
    const [analytics, setAnalytics] = useState({})
    const videosSelector = useSelector((state) => state.videos)
    const objectsSelector = useSelector((state) => state.objects)

    useEffect(() => {
        if (videosSelector.videoAnalyticsAuth) {
            let data = videosSelector?.videoAnalyticsData
            if (data?.videoAnalytics && data?.statType == '5') {
                setAnalytics(data?.videoAnalytics)
            }

            props.setLoader(false)
            // setClickedOption(1)
            dispatch(beforeVideoAnalytics())
        }
    }, [videosSelector.videoAnalyticsAuth])

    useEffect(() => {
        console.log('objects', objects)
    },[objects])

    useEffect(() => {
        if (props.videoId)
            dispatch(listObjects(`videoId=${props.videoId}&listAll=${true}`))
    }, [props.videoId])

    useEffect(() => {
        if (objectsSelector.objectsAuth) {
            let { objectsList } = objectsSelector?.objectsRes
            let options = objectsList.map((o) => {
                return { value: o.title, label: o.title }
            })
            setObjects(options)
            setObject(options[0]?.value)
            dispatch(beforeObject())
        }
    }, [objectsSelector.objectsAuth])


    useEffect(() => {
        if (analytics) {
            props.setLoader(false)
            let optionsData = { ...options }
            let { objectClickRate } = analytics
            let xAxisDates = []
            let yAxisValues = []

            let key = objectClickRate
            let dateIntervals = []
            let seriesData = []

            if (key)
                if (key.data?.length) {
                    let indexes = []
                    key.data.forEach((stat) => {

                        let statDate = moment(new Date(stat.date)).format('DD-MMM-YY')
                        xAxisDates.push(statDate)
                        yAxisValues.push(stat.total);

                        dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate, 1, yAxisValues.length)
                        seriesData = new Array(dateIntervals.length).fill(0)
                        dateIntervals.forEach((dt, indx) => {
                            if (dt === statDate)
                                indexes.push(indx)
                        })
                    })
                    indexes.forEach((i, ind) => {
                        seriesData[i] = yAxisValues[ind]
                    })
                }
                else {
                    dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate)
                    seriesData = new Array(dateIntervals.length).fill(0)
                }

            optionsData.xAxis = { ...optionsData.xAxis, data: dateIntervals }
            optionsData.series = [
                {
                    ...optionsData.series[0],
                    name: 'Objects Click-Through Rate',
                    data: seriesData
                }
            ]

            setOptions(optionsData)
        }
    }, [clickedStatOption, analytics])

    useEffect(() => {
        if (object) {
            props.setSelectedObject(object)
            props.fetchAnalytics(`startDate=${moment(new Date(props.date.startDate))}&endDate=${moment(new Date(props.date.endDate))}&object=${object}`)
        }
    }, [object])


    // const statOptionsChangeHandler = (option) => {
    //     if (option !== clickedStatOption) {
    //         setClickedOption(option)
    //         props.setLoader(true)
    //     }
    // }

    return (
        <>
            <div className='dashboardPage'>
                <div className='analyticContent'>
                    <div className='analyticGraph'>
                        {
                            analytics &&
                            <div className='analyticsBar'>
                                <div className='analyticsBarBox pointer' /* onClick={() => statOptionsChangeHandler(5)} */>
                                    <span>Objects click-through rate</span>
                                    <h4>{analytics.objectClickRate ? analytics.objectClickRate?.total : 0}</h4>
                                </div>
                            </div>
                        }
                        {
                            objects?.length ?
                                <Select
                                    options={objects}
                                    name="objects"
                                    value={objects.filter(option => option.value === object)}
                                    // onChange={onChangeHandler}
                                    onChange={(e) => setObject(e.value)}
                                    classNamePrefix="triage-select"
                                /> :
                                null
                        }
                        <ReactECharts option={options} />

                    </div>
                </div>
            </div>
        </>
    )
}
export default ObjectsRate