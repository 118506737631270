import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import { ReactNetflixPlayer } from "react-netflix-player"
import { ENV } from './../../../config/config';
import { useParams } from 'react-router-dom';
import { getVlog } from './vlogs.action';
import ReactPlayer from 'react-player';

const PlayVlog = (props) => {

    const { id } = useParams()
    const [vlog, setVlog] = useState({})
    
    useEffect(() => {
        if(id)
            getVlog(id)
    }, [id])

    const getVlog = (id) => {
        let vlogQS = { id }
        const qs = ENV.objectToQueryString(vlogQS)

        props.getVlog(qs)
    }

    useEffect(() => {
        if (props.vlogs.getVlogAuth) {
            setVlog(props.vlogs.getVlogRes)
        }

    }, [props.vlogs.getVlogAuth])


    return (
        <div className='explore explore-sec vlogs-wrapper mx-auto'>
            <h3 className='Vlogs-heading ms-auto'>Biiview Vlogs</h3>
           <div className='d-flex flex-column align-items-center '>
                {/* <ReactNetflixPlayer
                    playerLanguage='en'
                    autoPlay={false}
                    title={vlog.title || ''}
                    titleMedia={vlog.title || 'Title'}
                    src={vlog && vlog.video}
                /> */}
                <ReactPlayer
                    url={vlog && vlog.video}
                    controls={true}
                    playing={true}
                    width="60%"
                    height="50vh"
                    // style={{objectFit: 'cover',}}
                       
                />
           </div>
        </div>
    )
}

const mapStateToProps = state => ({
    vlogs: state.vlogs,
});

export default connect(mapStateToProps, { getVlog })(PlayVlog);