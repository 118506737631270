import React from 'react'
import '../../Models/Models.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import UploadBasicInfo from './UploadBasicInfo '
import UploadAdvanceSettings from './UploadAdvanceSettings'
import SmallLoader from '../../../../../utils/SmallLoader/SmallLoader'

const UploadDefault = (props) => {

    const childProps = {
        setData: props.setChannelData,
        errors: props.errors,
    };

    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabs" >
                    {
                        // props.loader ?
                        //     <SmallLoader height={40} width={40} />
                        //     :
                            <Tabs activeKey={props.uploadKey} onSelect={(k) => props.setKey(k == '0' ? 'upload-basic' : 'upload-advance')}>
                                <TabList>
                                    <Tab eventKey="upload-basic">Basic Info</Tab>
                                    <Tab eventKey="upload-advance">Advance Settings</Tab>
                                </TabList>
                                <>
                                    <TabPanel>
                                        <UploadBasicInfo
                                            title={props.channelDetails.videoTitle}
                                            description={props.channelDetails.videoDescription}
                                            visibility={props.channelDetails.videoVisibility}
                                            tags={props.channelDetails.videoTags}
                                            {...childProps}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <UploadAdvanceSettings
                                            chaptersAndMoments={props.channelDetails.chaptersAndMoments}
                                            videoCategory={props.channelDetails.videoCategory}
                                            videoLang={props.channelDetails.videoLanguage}
                                            titleDescLang={props.channelDetails.titleDescLanguage}
                                            {...childProps}
                                        />
                                    </TabPanel>
                                </>
                            </Tabs>
                    }

                </div>
            </div>
        </>
    )
}
export default UploadDefault