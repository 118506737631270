import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { subscribePlan } from '../package.actions'
import { selectPlanSection } from '../../ApplicationTour/TourCompIdentifiers'
import { ENV } from '../../../../config/config'

const PackageCard = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const userId = ENV.getUserKeys('_id')?._id

    const [active, setActive] = useState(false)
    const [plan, setPlan] = useState({})

    const navigateToCardDetails = (planTenure, planId) => {
        if (userId)
            navigate('/card-details', { state: { planTenure, planId } })
        else {
            localStorage.setItem('redirectTo', 'packages')
            navigate('/login')
        }
    };

    useEffect(() => {
        if (props.plan)
            setPlan(props.plan)

    }, [props.plan])

    useEffect(() => {
        setActive(props.isPlanActive)

    }, [props.isPlanActive])

    const subscribe = (planId) => {
        props.setLoader(true)
        dispatch(subscribePlan({ planId }, true))
    }

    return (
        <div className='package-card'>
            <div>
                <p className='packageTitle'>{plan.name}</p>
                <ul className='packageCardHeader'>

                    {
                        props.planTenure ?
                            plan.monthlyPrice || plan.annualPrice ?
                                <div>
                                    <h3 className='packageH3'>${props.planTenure === 1 ? plan.monthlyPrice : props.planTenure === 2 ? plan.annualPrice : ''}
                                        <sub className='h3Sub'>{props.planTenure === 1 ? '/month' : props.planTenure === 2 ? '/year' : ''}</sub></h3>
                                </div> : null
                            :
                            <>
                                <div>
                                    <h3 className='packageH3'>${plan.monthlyPrice ? plan.monthlyPrice : 0}
                                        <sub className='h3Sub'>/month</sub></h3>
                                </div>
                                {
                                    plan.monthlyPrice && plan.annualPrice ?
                                        <div>
                                            <h3 className='packageH3'>${plan.annualPrice}
                                                <sub className='h3Sub'>/year</sub></h3>
                                        </div> : null
                                }
                            </>
                    }
                </ul>
                <ul className='package-ul'>
                    {
                        plan.videoLengthMins ?
                            <li><p>Can upload a short-length video upto {plan.videoLengthMins} minutes</p></li> :
                            null
                    }
                    {
                        plan.numberOfVideos ?
                            <li><p>Can upload upto {plan.numberOfVideos} videos</p></li> :
                            null
                    }
                    {
                        plan.videoSizeMbs ?
                            <li><p>Max File size of the video can be {plan.videoSizeMbs} Mbs</p>
                            </li> :
                            null
                    }
                    {
                        plan.editObjectsAnnotation ?
                            <li><p>Object's annotation can be changed</p>
                            </li> :
                            null
                    }
                    {/* {
                        plan.objectDetectionLevel ?
                            <li><p>{plan.objectDetectionLevel === 1 ?
                                'Basic' : plan.objectDetectionLevel === 2 ? 'Normal'
                                    : plan.objectDetectionLevel === 3 ? 'Corporate' : ''}
                                Level Object Detection</p></li> :
                            null
                    }
                    {
                        plan.accuracyRate ?
                            <li><p>{plan.accuracyRate}% Accuracy Rate</p></li> :
                            null
                    }
                    {
                        plan.linksPerVideo ?
                            <li><p>Short Links Creation</p></li> :
                            null
                    } */}
                    {/* {
                        plan.linksPerVideo ?
                            <li><p>{plan.linksPerVideo} Links per Video</p></li> :
                            null
                    }
                    {
                        plan.updateLinksCount ?
                            <li><p>Can change the product links upto {plan.updateLinksCount}</p>
                            </li> :
                            null
                    } */}
                </ul>
            </div>
            {/* <div>
            <button className='orange-btn text-uppercase mt-0'
            onClick={e => navigateToCardDetails('monthly')}>SELECT</button>
            </div> */}
            <div className="d-flex w-100 justify-content-center">
                {
                    !props.planTenure ?
                        !active ?
                            (plan.type !== 1 || plan.monthlyPrice || plan.annualPrice) ?
                                <Dropdown className='package-select-dropdown'>
                                    <Dropdown.Toggle className='orange-btn mt-0 w-100' variant="" id="dropdown-basic">
                                        <span /* id={selectPlanSection} */>Select</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='w-100'>
                                        <Dropdown.Item onClick={e => navigateToCardDetails(1, plan._id)} >Monthly</Dropdown.Item>
                                        <Dropdown.Item onClick={e => navigateToCardDetails(2, plan._id)} >Yearly</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> :
                                <div>
                                    <button
                                        // id={selectPlanSection}
                                        className='orange-btn mt-0 w-100'
                                        onClick={e => subscribe(plan._id)}
                                    >
                                        Select
                                    </button>
                                </div>
                            :
                            <div>
                                <button
                                    disabled={true}
                                    className='mt-0 w-100'
                                >
                                    Subscribed
                                </button>
                            </div>
                        : null

                }
            </div>
        </div>
    )
}

export default PackageCard