import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MyVideos.css";
import "../VideoAnalyticsComponent/VideoAnalytics/VidoeAnalytics.css";
import { Table, Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { AiOutlineEdit, AiOutlineShareAlt, AiFillDelete } from "react-icons/ai";
import { RiDeleteBin4Line } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import { SiBookmeter } from "react-icons/si";
import { AiFillPlayCircle, AiFillPlaySquare } from "react-icons/ai";
import { IoMdSettings } from 'react-icons/io'
import Pagination from "rc-pagination";
import 'rc-pagination/assets/index.css';
import UploadVideo from "./UploadVideo/UploadVideo";
import EditVideoDetails from "./EditVideoDetails/EditVideoDetails";
import EditDes from "./EditDes";
import ShareableLink from "./GetShareableLink";
import localeInfo from "rc-pagination/lib/locale/en_US";
import {
  beforeVideo,
  getVideoContent,
  beforeVideoContentUploaded,
  deleteVideo,
  deleteMultipleVideos
} from "./video.actions";
import { getChannelCreator, beforeCreator } from "../../UserComponents/users.actions";
import { beforeChannelRole } from "../../ChannelRoles/channelRoles.actions";
import { ENV } from "../../../../config/config";
import moment from "moment";
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import Select from 'react-select'
import { toast } from "react-toastify";
import FullPageLoader from "../../FullPageLoader/FullPageLoader";
import { Tooltip } from 'react-tooltip'
import ProgressBar from 'react-bootstrap/ProgressBar';
import EditAnnotation from "./EditAnnotation";
import { getVideoMetadata } from "../../Player/player.action";
import { getBoundingObjects } from "./video.actions";
import AddBoundingObjects from "./AddBoundingObjects";
import { FaObjectGroup } from "react-icons/fa";
import { addObjectsIcon, deleteVideosCheck, editAnnIcon, editVideoIcon, originalVideoIcon, otherVideoOptions, processedVideoIcon, uploadVideoButton, videoAnalyticsIcon, visibilityOptions } from "../../ApplicationTour/TourCompIdentifiers";
import { setNotificationCounter, beforeCounter } from "../../Notifications/notifications.actions";
import ApplicationTour from "../../ApplicationTour/ApplicationTours";
import { Helmet } from "react-helmet-async/lib/index.esm";

const { objectToQueryString, baseUrl, events, defaultPlaceholderImage, processingStates } = ENV
const storageFieldName = 'vid-progress'

const MyVideos = (props) => {

  const navigate = useNavigate()

  const { _id } = ENV.getUserKeys();
  let channelId = localStorage.getItem('channelId')
  const userName = ENV.getUserKeys('fullName')?.fullName
  const [upload, setUpload] = useState(false);
  const [loader, setLoader] = useState(true)
  const [edit, setEdit] = useState(false);
  const [editDes, setEditDes] = useState(false);
  const [urlModal, setUrlModal] = useState(false);
  const [video, setVideo] = useState();
  const [user, setUser] = useState()
  const [selectiveDetails, setSelective] = useState({})
  const [videoContent, setvideoContent] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [pagination, setpagination] = useState(null);
  const [pageSize, setpageSize] = useState(10);
  const [Page, setPage] = useState(1);
  const [selected, setSelected] = useState({
    value: '',
    label: 'All'
  })
  const [memberRole, setRole] = useState()
  const [checkAll, setCheckAll] = useState(false)
  const [checkedVideos, setCheckedVideos] = useState([])
  const [showDesc, setShowDesc] = useState([])
  const [annotationModal, setModal] = useState(false)
  const [objectsModal, setObjectsModal] = useState(false)
  const [socket, setSocket] = useState(null)
  const [progressPercentages, setPercentages] = useState({})
  const [processingStatus, setStatus] = useState({}) // type 1 => processing, 2 => processed, 3 => failed

  const descriptionLength = 80

  const visibilityTypes = [
    { value: '', label: 'All' },
    { value: 1, label: 'Private', visibilityType: 1 },
    { value: 2, label: 'Unlisted', visibilityType: 1 },
    { value: 3, label: 'Public', visibilityType: 1 },
    { value: 4, label: 'Draft', visibilityType: 1 },
    { value: -1, label: 'Scheduled', visibilityType: 2 }
  ]

  useEffect(() => {
    if (props.error) {
      setLoader(false)
    }
  }, [props.error])

  useEffect(() => {
    if (props.notifications.clientSocket) {
      setSocket(props.notifications.clientSocket)
    }
  }, [props.notifications.clientSocket])

  useEffect(() => {
    if (socket) {

      // video progress prcentages
      socket.on(`${events.processingProgressEvtName}-${_id}`, (data) => {
        let { videoId, progress } = data.payload
        localStorage.setItem(`${storageFieldName}-${videoId}`, progress)
        setPercentages((prevProgresses) => {
          return { ...prevProgresses, [videoId]: data.payload }
        })
      })

      // video processing status
      let notificationsData = [...events.notifications]
      notificationsData.forEach((n) => {

        socket.on(`${n.name}-${_id}`, (data) => {
          let { payload } = data
          let { type, videoId } = payload
          let status = n.label

          // in case of completed/failure processing, remove progress from storage
          if ((type === 3 || type === 2) && n.type === type) {
            localStorage.removeItem(`${storageFieldName}-${videoId}`)
            let percentages = { ...progressPercentages }
            delete percentages[videoId]
            setPercentages(percentages)
          }

          setStatus((prevStatus) => {
            return {
              ...prevStatus,
              [payload.videoId]: { status, type, reason: payload.failureReason || '' }
            }
          })
        })
      })
    }
  }, [socket])

  useEffect(() => {
    if (props.role.channelRoleAuth) {
      setRole(props.role.channelRoleData?.memberRole)
      props.beforeChannelRole()
    }
  }, [props.role.channelRoleAuth])

  useEffect(() => {
    if (props.videos.videoUploadedAuth) {
      props.beforeVideoContentUploaded();
      let qs = objectToQueryString({
        page: 1,
        pageSize: pageSize,
        channelId,
        _id
      });
      props.getVideoContent(qs);
    }
  }, [props.videos.videoUploadedAuth]);

  useEffect(() => {
    if (channelId && memberRole && memberRole?.viewChannelVideos) {
      let qs = objectToQueryString({
        // uId: _id,
        page: 1,
        pageSize: pageSize,
        channelId,
        _id
      });

      props.getVideoContent(qs);
      props.getChannelCreator(`channelId=${channelId}`)

    }
  }, [channelId, memberRole]);

  useEffect(() => {
    if (props.user.getChannelCreatorAuth) {
      let data = props.user.getChannelCreatorData?.data?.user
      setUser(data)
      props.beforeCreator()
    }
  }, [props.user.getChannelCreatorAuth])

  useEffect(() => {
    if (props.videos.editVideoAuth) {
      setLoader(false)
      props.getVideoContent(`channelId=${channelId}&_id=${_id}`)
      props.beforeVideo()
    }
  }, [props.videos.editVideoAuth])

  useEffect(() => {
    if (props.videos.listVideoContentAuth) {
      setLoader(false)
      let { videos, pagination } = props.videos.listVideoContent

      setShowDesc(new Array(videos.length).fill(false))
      setvideoContent(videos);
      setpagination(pagination);

      props.beforeVideo();
    }
  }, [props.videos.listVideoContentAuth]);

  useEffect(() => {
    if (videoContent && videoContent?.length) {
      if (allVideos?.length < pagination.total) {
        let allVideosContent = allVideos.concat(videoContent)
        setAllVideos(allVideosContent)
      }

    }
  }, [videoContent])

  useEffect(() => {
    if (props.videos.deleteVideoAuth) {
      let { totalNotifications } = props.videos.deleteVideoData

      if (checkedVideos?.length)
        setCheckedVideos([])

      setCheckAll(false)
      if (totalNotifications == 0)
        props.beforeCounter()
      else
        props.setNotificationCounter(totalNotifications)

      props.beforeVideo()
      props.getVideoContent(`channelId=${channelId}&_id=${_id}`)
    }
  }, [props.videos.deleteVideoAuth]);


  function onPageChange(page) {
    setPage(page);
    let payload = {
      channelId,
      page,
      pageSize,
      _id
    }

    if (selected.visibilityType) {
      payload = { ...payload, visibilityType: selected.visibilityType }
      if (selected.visibilityType !== 2)
        payload = { ...payload, publishType: selected.value }

    }

    const qs = objectToQueryString(payload);
    setLoader(true)
    props.getVideoContent(qs);
  }

  const deleteVideoHandler = (videoId = null, type = 2) => {
    Swal.fire({
      title: 'Are you sure you want to delete?',
      html: type === 1 ? 'If you delete this video, it will be lost permanently.' : 'If you delete these videos, they will be lost permanently',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(async (result) => {
      if (result.value) {
        setLoader(true)
        if (type === 1)
          props.deleteVideo(videoId)
        else
          props.deleteMultipleVideos(checkedVideos)

      }
    })
  }

  const onChangeSelect = (e) => {
    setSelected({ value: e.value, label: e.label, visibilityType: e.visibilityType })
    let qs = ''
    let payload = { channelId, _id }
    if (e.value) {
      if (e.value === -1 && e.visibilityType === 2)
        payload = { ...payload, visibilityType: e.visibilityType }
      else
        payload = { ...payload, visibilityType: e.visibilityType, publishType: e.value }

    }

    qs = objectToQueryString(payload);
    setLoader(true)
    props.getVideoContent(qs);
  }

  const setUploadHandler = () => {
    if (!user.subscribedPlan?.plan ||
      !Object.keys(user.subscribedPlan?.plan).length) {
      toast.info('Please subscribe to a package in order to upload videos!')
      navigate('/packages')
    }
    else if (user.hasPlanExpired) {
      if (user.subscribedPlan.plan.type !== 1)
        toast.info('Please complete your payment in order to upload videos!')
      else
        toast.info('Your Free Plan has been expired!')

    }
    else
      setUpload(!upload)
  }

  const checkAllHandler = (e) => {
    setCheckAll(e.target.checked)
    if (!e.target.checked)
      setCheckedVideos([])
  }

  useEffect(() => {
    if (checkAll && allVideos?.length) {
      let videoIds = allVideos?.map((vid) => vid._id)
      setCheckedVideos(videoIds)
    }
  }, [checkAll, allVideos])

  useEffect(() => {
    if (checkedVideos?.length && allVideos?.length) {
      if (checkedVideos.length !== allVideos?.length)
        setCheckAll(false)
      else
        setCheckAll(true)
    }
  }, [checkedVideos])

  const checkVideosHandler = (e, videoId) => {
    let checked = e.target.checked
    if (checked) {
      if (checkedVideos.indexOf(videoId) === -1)
        setCheckedVideos([...checkedVideos, videoId])
    }
    else {
      let filteredIds = checkedVideos.filter((id) => id !== videoId)
      setCheckedVideos(filteredIds)
    }
  }

  const showHideDesc = (index, value) => {
    const description = [...showDesc]
    description[index] = value
    setShowDesc(description)
  }

  const getProcessingStatus = (val) => {
    let progress = progressPercentages[val._id]
    let status = processingStatus[val._id]
    let storedProgress = JSON.parse(localStorage.getItem(`${storageFieldName}-${val._id}`))

    // precautionary case if somehow local storage value is not cleareds
    if ((val?.processed === 3 || val?.processed === 4) && storedProgress)
      localStorage.removeItem(`${storageFieldName}-${val?._id}`)

    if (status)
      return status?.status
    if (progress?.progress == 100 || storedProgress == 100)
      return 'Converting Video...'
    else if (val.processed) {
      if (val.processed === processingStates.pending)
        return 'Pending'
      if (val.processed === processingStates.processing)
        return 'Processing'
      if (val.processed === processingStates.processed)
        return 'Completed'
      if (val.processed === processingStates.failed)
        return 'Failed'
      else
        return 'N/A'
    }
  }

  const getProgressPercentage = (val) => {

    let progress = progressPercentages[val._id]
    let status = processingStatus[val._id]
    let storedProgress = JSON.parse(localStorage.getItem(`${storageFieldName}-${val._id}`))

    if (progress) {
      if (progress.progress !== 100) {
        return <ProgressBar variant="success" style={{ width: '75%' }} now={progress?.progress} label={`${progress?.progress}%`} />
      }
      else {
        return 'Please wait...'
      }
    }
    else if (storedProgress) {
      if (storedProgress !== 100)
        return <ProgressBar variant="success" style={{ width: '75%' }} now={storedProgress} label={`${storedProgress}%`} />
      else
        return 'Please wait...'
    }
    else if (val.processed === processingStates.processing) {
      if (status && (status?.type === 3 || status?.type === 2))
        return '-'
      else
        return 'Calculating'
    }
    else {
      return '-'
    }

  }

  return (
    <>
      {loader && <FullPageLoader />}
      {
        userName &&
        <Helmet>
          <title>{`${userName} - My Videos`}</title>
          <meta name="description" content={`Welcome to ${userName}'s Hub: My Videos. Dive into a curated collection of personalized content, tailored just for you. Explore, share, and relive moments.`} />
        </Helmet>

      }
      <div className="dashboardPage my-videos-sec">
        <div className="content px40">
          <div className="dashboardHeader d-sm-flex justify-content-sm-between justify-content-center align-items-center">
            <h2 className="text-uppercase">My Videos</h2>
            {/* {
              memberRole && memberRole?.createVideo &&
              <button
                id={uploadVideoButton}
                className="orange-btn text-uppercase mt-0 "
                onClick={setUploadHandler}
              >
                UPLOAD VIDEO
              </button>
            } */}
          </div>
          <div className="dashboardHeader d-sm-flex justify-content-end pt-0">
            <div className='select-items custom-react-select d-sm-flex align-items-center'>
              <span id={visibilityOptions} className="mb-0" style={{ whiteSpace: "nowrap" }}>Visibility Type</span>
              <Select classNamePrefix="triage-select" className="w-100 ms-0 ms-sm-2"
                placeholder='Select Type'
                options={visibilityTypes}
                defaultValue={selected}
                onChange={onChangeSelect}
              />
            </div>
          </div>
          <div className="themeTable table-responsive">
            <Table responsive className="videos-table">
              <thead>
                <tr>
                  {
                    videoContent?.length && memberRole && memberRole?.deleteVideo ?
                      <th className="checkId">
                        <div id={deleteVideosCheck} className="form-check checkTable">
                          {/* <input
                            className="form-check-input"
                            type="checkbox"
                            checked={checkAll}
                            id="flexCheckDefault"
                            onChange={checkAllHandler}
                          /> */}
                          <label className="right-label-checkbox no-label-text">
                            <input type="checkbox" id="flexCheckDefault" checked={checkAll} onChange={checkAllHandler} />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </th> : null
                  }

                  <th style={{ minWidth: "500px" }}>
                    {
                      checkedVideos?.length ?
                        <>
                          <span id="delete-all" className="pointer delete-all-icon d-inline-block me-4"><AiFillDelete onClick={deleteVideoHandler} /></span>
                          <Tooltip
                            anchorId="delete-all"
                            place="top"
                            content="Delete"
                          />
                        </>
                        :
                        null
                    }
                    <p className="d-inline-block">{checkedVideos?.length > 1 ? 'Videos' : 'Video'}</p>
                  </th>
                  <th style={{ minWidth: "130px" }}>
                    <p>Visibility</p>
                  </th>
                  <th style={{ minWidth: "160px" }}>
                    <p>Processing Status</p>
                  </th>
                  <th style={{ minWidth: "170px" }}>
                    <p>Processing Progress %</p>
                  </th>
                  <th style={{ minWidth: "210px" }}>
                    <p>Position in Queue</p>
                  </th>
                  <th style={{ minWidth: "190px" }}>
                    <p>Failure Reason</p>
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    <p>Restrictions</p>
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    <p>Date</p>
                  </th>
                  <th style={{ minWidth: "100px" }}>
                    <p>Views</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {videoContent?.length > 0 ? (
                  videoContent?.map((val, index) => {
                    return (
                      <tr key={index}>
                        {
                          memberRole && memberRole?.deleteVideo ?
                            <td className="checkId">
                              <div className="form-check checkTable">
                                {/* <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={checkedVideos.includes(val._id)}
                                  onChange={(e) => checkVideosHandler(e, val._id)}
                                  id="flexCheckDefault"
                                /> */}
                                <label className="right-label-checkbox no-label-text">
                                  <input type="checkbox" id="flexCheckDefault" checked={checkedVideos.includes(val._id)} onChange={(e) => checkVideosHandler(e, val._id)} />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </td> : null
                        }
                        <td className="video-area">
                          <div className="videoInfo">
                            <div className="videoImg">
                              <img
                                className="img-fluid"
                                src={
                                  val.thumbnails[0] || defaultPlaceholderImage
                                }
                                alt="Video"
                              ></img>
                            </div>
                            <div>
                              <p className="mb-0">{val.title.length > 50 ? `${val.title.slice(0, 50)}...` : val.title}</p>
                              <span className="description">
                                {
                                  val.description?.length > descriptionLength ?
                                    <span className="description">{val.description.slice(0, descriptionLength)}
                                      {
                                        !showDesc[index] ?
                                          <>
                                            <button className="link orangeLinkBtn" onClick={() => showHideDesc(index, true)}>Read More</button>
                                          </>
                                          :
                                          <>
                                            {val.description.slice(descriptionLength, val.description.length)}
                                            <button className="link orangeLinkBtn" onClick={() => showHideDesc(index, false)}>Read Less</button>
                                          </>
                                      }
                                    </span>
                                    :
                                    val.description
                                }
                              </span>
                              <div className="videoIcons">
                                {
                                  memberRole && memberRole?.editVideoDetails &&
                                  <>
                                    <button
                                      className="iconBtn themeEffect"
                                      id={`edit-video-${index}`}
                                      onClick={() => {
                                        setEdit(!edit);
                                        setVideo(val);
                                      }}
                                    >
                                      <MdEdit id={editVideoIcon} />
                                    </button>
                                    <Tooltip
                                      anchorId={`edit-video-${index}`}
                                      place="top"
                                      content="Edit Video"
                                    />
                                  </>
                                }
                                {
                                  memberRole && memberRole?.viewVideoAnalytics &&
                                  <span>
                                    <Link
                                      to={val.processed !== processingStates.processed ? '' : `/video-analytics/${val.slug}`}
                                      state={{ videoId: val._id}}
                                      id={`analytics-${index}`}
                                      className={`themeEffect ${val.processed !== processingStates.processed ? 'disabled-icon' : ''}`}
                                    >
                                      <SiBookmeter id={videoAnalyticsIcon} />
                                    </Link>
                                    <Tooltip
                                      anchorId={`analytics-${index}`}
                                      place="top"
                                      content="Analytics"
                                    // isOpen="true"  
                                    />
                                  </span>
                                }
                                <>
                                  {
                                    (memberRole && memberRole.viewChannelVideos) && (val?.processed === processingStates.processed || processingStatus[val._id]?.type == 2) ?
                                      <a
                                        className="iconBtn themeEffect"
                                        data-tooltip-id={`processed-video-${index}`}
                                        data-tooltip-content={"Processed Video"}
                                        href={`/media-player/${val.slug}/${val.channel?.name}`}
                                        target="_blank" rel='noreferrer'
                                      >
                                        <AiFillPlayCircle id={processedVideoIcon} />
                                      </a> :
                                      <Link
                                        className="iconBtn themeEffect disabled-icon"
                                        to=""
                                        data-tooltip-id={`processed-video-${index}`}
                                        data-tooltip-content={"Your Video is not processed yet. We will send you notification once it's done. Stay Tuned!"}
                                      >
                                        <AiFillPlayCircle id={processedVideoIcon} />
                                      </Link>
                                  }
                                  <Tooltip
                                    id={`processed-video-${index}`}
                                    place="top"
                                  />
                                </>
                                {
                                  memberRole && memberRole.viewChannelVideos ?
                                    <>
                                      <a
                                        className="iconBtn themeEffect"
                                        id={`original-video-${index}`}
                                        href={`/play-video/${val.slug}`}
                                        target="_blank" rel='noreferrer'
                                      >
                                        <AiFillPlaySquare id={originalVideoIcon} />
                                      </a>
                                      <Tooltip
                                        anchorId={`original-video-${index}`}
                                        place="top"
                                        content="Original Video"
                                      />
                                    </>
                                    : null
                                }
                                {
                                  memberRole && memberRole.editObjectsAnnotation && user?.subscribedPlan?.plan?.editAnnotation ?
                                    <>
                                      <button
                                        // disable if video is not processed
                                        disabled={processingStatus[val._id] ? processingStatus[val._id]?.type != 2 ? true : false : val?.processed !== processingStates.processed ? true : false}
                                        className={processingStatus[val._id] ? processingStatus[val._id]?.type != 2 ? 'iconBtn themeEffect disabled-icon' : 'iconBtn themeEffect' : val?.processed !== processingStates.processed ? 'iconBtn themeEffect disabled-icon' : 'iconBtn themeEffect'}
                                        id={`edit-annotation-${index}`}
                                        onClick={() => {
                                          setModal(true);
                                          setLoader(true)
                                          setVideo(val);
                                          props.getVideoMetadata(val.slug)
                                        }}
                                      >
                                        <IoMdSettings id={editAnnIcon} />
                                      </button>
                                      <Tooltip
                                        anchorId={`edit-annotation-${index}`}
                                        place="top"
                                        content={'Edit Annotation'}
                                      />
                                    </>
                                    : null
                                }
                                {
                                  memberRole && memberRole.addObjects ?
                                    <>
                                      <button
                                        // diable when video is not in pending stage
                                        disabled={processingStatus[val._id] || (val?.processed !== processingStates.pending)}
                                        className={processingStatus[val._id] || (val?.processed !== processingStates.pending) ? 'iconBtn themeEffect disabled-icon' : 'iconBtn themeEffect'}
                                        id={`add-objects-${index}`}
                                        onClick={() => {
                                          setObjectsModal(true);
                                          setLoader(true)
                                          setVideo(val);
                                          props.getBoundingObjects(val._id)
                                        }}
                                      >
                                        <FaObjectGroup id={addObjectsIcon} />
                                      </button>
                                      <Tooltip
                                        anchorId={`add-objects-${index}`}
                                        place="top"
                                        content="Add Objects"
                                      />
                                    </>
                                    : null
                                }
                                {
                                  memberRole && (memberRole?.editVideoDetails || memberRole?.getShareableLink || memberRole?.deleteVideo) ?
                                    <Dropdown className="iconDD">
                                      <Dropdown.Toggle
                                        className="iconBtn iconDD-Btn"
                                        id="-basic"
                                      >
                                        <span>
                                          <BiDotsVerticalRounded id={otherVideoOptions} />
                                        </span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {
                                          memberRole?.editVideoDetails &&
                                          <Dropdown.Item
                                            onClick={() => {
                                              setEditDes(true)
                                              setSelective({
                                                videoId: val._id,
                                                title: val.title,
                                                description: val.description
                                              });
                                            }}
                                          >
                                            <span className="me-2">
                                              <AiOutlineEdit />
                                            </span>
                                            Edit Title & Description{" "}
                                          </Dropdown.Item>
                                        }
                                        {
                                          memberRole?.getShareableLink &&
                                          <Dropdown.Item
                                            onClick={() => {
                                              setUrlModal(true)
                                              setSelective({
                                                videoUrl: `${baseUrl}media-player/${val?._id}`,
                                                title: val.title,
                                                thumbnailImage: val.thumbnails && val.thumbnails[0]
                                              });
                                            }}
                                          >
                                            <span className="me-2">
                                              <AiOutlineShareAlt />
                                            </span>
                                            Get Shareable Links
                                          </Dropdown.Item>
                                        }
                                        {
                                          memberRole?.viewVideoSubtitles &&
                                          <Dropdown.Item
                                            onClick={() => {
                                              navigate(`/subtitles?video=${val?._id}`)
                                            }}
                                          >
                                            <span className="me-2">
                                              <AiOutlineShareAlt />
                                            </span>
                                            Subtitles
                                          </Dropdown.Item>
                                        }
                                        {
                                          memberRole?.deleteVideo &&
                                          <Dropdown.Item
                                            onClick={() => deleteVideoHandler(val._id, 1)}
                                          >
                                            <span className="me-2">
                                              <RiDeleteBin4Line />
                                            </span>
                                            Delete
                                          </Dropdown.Item>
                                        }
                                      </Dropdown.Menu>
                                    </Dropdown> : null
                                }
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          {val?.visibilityType === 1
                            ?
                            <p>
                              {val?.publishType === 4
                                ? "Draft"
                                : val?.publishType === 3
                                  ? "Public"
                                  : val?.publishType === 2
                                    ? "Unlisted"
                                    : val?.publishType === 1
                                      ? "Private"
                                      : "N/A"}
                            </p>
                            :
                            <p>
                              {val?.visibilityType === 2
                                ? "Scheduled"
                                : "N/A"}
                            </p>
                          }
                        </td>
                        <td>
                          {
                            <p>{getProcessingStatus(val)}</p>
                            // <p>{processingStatus[val._id] ? processingStatus[val._id]?.status : val.processed ? val.processed == 1 ? 'Pending' : val.processed == 2 ? 'Processing' : val.processed == 3 ? 'Completed' : val.processed == 4 ? 'Failed' : '' : 'N/A'}</p>
                          }
                        </td>
                        <td>
                          <p>
                            {
                              getProgressPercentage(val)
                              // progressPercentages[val._id] ?
                              //   <ProgressBar variant="success" style={{ width: '75%' }} now={progressPercentages[val._id]?.progress} label={`${progressPercentages[val._id]?.progress}%`} />
                              //   : localStorage.getItem(`${storageFieldName}-${val._id}`) ?
                              //     <ProgressBar variant="success" style={{ width: '75%' }} now={localStorage.getItem(`${storageFieldName}-${val._id}`)} label={`${localStorage.getItem(`${storageFieldName}-${val._id}`)}%`} />
                              //     :
                              //     val.processed === 2 ?
                              //       processingStatus[val._id] && (processingStatus[val._id]?.type == 3 || processingStatus[val._id]?.type == 2) ? // failed pr completed 
                              //         '-' :
                              //         'Calculating' :
                              //       '-'
                            }
                          </p>
                        </td>
                        <td>
                          <p>{val.processingPosition !== null ? val.processingPosition : '-'}</p>
                        </td>
                        <td>
                          <p>{processingStatus[val._id] ? processingStatus[val._id]?.reason : val.processingFailedReason ? val.processingFailedReason : '-'}</p>
                        </td>
                        <td>
                          <p>None</p>
                        </td>
                        <td>
                          <p className="date-div">
                            {moment(new Date(val?.createdAt)).format("YYYY-MM-DD")}
                          </p>
                        </td>
                        <td>
                          <p>{val?.views}</p>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <div
                        className="alert alert-info customAlert"
                        role="alert"
                      >
                        No video Found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div>
          {pagination && pagination.pages && (
            <div className="px40">
              <Pagination
                className="mt-3 mb-3"
                defaultCurrent={1}
                pageSize
                current={pagination.page}
                total={pagination.pages}
                onChange={onPageChange}
                locale={localeInfo}
                hideOnSinglePage={true}
              />
            </div>
          )}
        </div>
      </div>
      {<UploadVideo setShow={setUpload} show={upload} setLoader={setLoader} />}
      {
        edit &&
        <EditVideoDetails video={video} setShow={setEdit} show={edit} setLoader={setLoader} />
      }
      {
        editDes &&
        <EditDes video={selectiveDetails} setShow={setEditDes} show={editDes} setLoader={setLoader} />
      }
      {
        urlModal &&
        <ShareableLink video={selectiveDetails} setUrlModal={setUrlModal} urlModal={urlModal} />
      }
      {
        annotationModal &&
        <EditAnnotation videoId={video?._id} show={annotationModal} setShow={setModal} loader={loader} setLoader={setLoader} />
      }
      {
        objectsModal &&
        <AddBoundingObjects selectedVideo={video} show={objectsModal} setShow={setObjectsModal} loader={loader} setLoader={setLoader} />
      }
      {
        videoContent?.length ?
          <ApplicationTour /> : null
      }

    </>
  );
};

const mapStateToProps = (state) => ({
  videos: state.videos,
  user: state.user,
  role: state.roles,
  error: state.error,
  notifications: state.notifications
});

export default connect(mapStateToProps, {
  getVideoContent,
  beforeVideo,
  beforeVideoContentUploaded,
  deleteVideo,
  deleteMultipleVideos,
  getChannelCreator,
  beforeCreator,
  beforeChannelRole,
  getVideoMetadata,
  getBoundingObjects,
  setNotificationCounter,
  beforeCounter
})(MyVideos);
