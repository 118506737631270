import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table } from 'react-bootstrap'
import '../../Subtitles/Subtitles.css'
import { listObjects, beforeObject } from './objects.actions'
import { ENV } from '../../../../../config/config'
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import 'rc-pagination/assets/index.css';

const { defaultPlaceholderImage } = ENV

const IdentifiedObjects = (props) => {

    const dispatch = useDispatch()
    const userId = ENV.getUserKeys('_id')?._id

    const [objects, setObjects] = useState([])
    const [filters, setFilters] = useState({})
    const [showFrames, setShowFrames] = useState([])
    const [paginationData, setPagination] = useState({})
    const objectSelector = useSelector((state) => state.objects)
    const framesLength = 10

    useEffect(() => {
        if (props.videoId) {
            let { duration } = props
            if (duration && duration !== 'custom') {
                setFilters({ duration })
            }
        }
    }, [props.duration])

    useEffect(() => {
        if (props.videoId) {
            let { customDate } = props
            if (customDate && customDate?.startDate && customDate?.endDate) {
                setFilters({ ...customDate })
            }
        }
    }, [props.customDate])

    useEffect(() => {
        if (filters && Object.keys(filters).length) {
            let qs = `videoId=${props.videoId}`
            let { duration, startDate, endDate } = filters
            if (duration) {
                qs = `${qs}&duration=${duration}`
            }
            else if (startDate && endDate) {
                qs = `${qs}&startDate=${startDate}&endDate=${endDate}`
            }

            props.setLoader(true)
            dispatch(listObjects(qs))

        }
    }, [filters])

    useEffect(() => {
        if (objectSelector.objectsAuth) {
            let { objectsList, pagination } = objectSelector.objectsRes
            setObjects(objectsList)
            setPagination(pagination)
            let showArray = new Array(objectsList.length).fill(false);
            setShowFrames(showArray)

            props.setLoader(false)
            dispatch(beforeObject())
        }
    }, [objectSelector.objectsAuth])

    const onPageChange = (page) => {
        props.setLoader(true)
        let qs = ENV.objectToQueryString({ page, ...filters, videoId: props.videoId  })
        dispatch(listObjects(qs))
    }

    const showHideFrames = (index, value) => {
        const frames = [...showFrames]
        frames[index] = value
        setShowFrames(frames)
    }

    return (
        <>
            <div className='dashboardPage'>
                <div className='content px40'>
                    <div className='themeTable'>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th><p>Object</p></th>
                                    <th><p>Category</p></th>
                                    <th><p>Frames</p></th>
                                    <th><p>Link</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    objects && objects.length ?
                                        objects.map((object, idx) => {
                                            return (
                                                <tr key={idx} >
                                                    <td>
                                                        <div className='videoInfo videoPara'>
                                                            <div className='videoImg'>
                                                                <img className='img-fluid' src={object.image || defaultPlaceholderImage} alt={object.title}></img>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='td-area'><p>{object.title}</p></td>
                                                    <td className='td-area'>
                                                        {
                                                            object.frames?.length > framesLength ?
                                                                <p>{object.frames.slice(0, framesLength).join(', ')}
                                                                    {
                                                                        !showFrames[idx] ?
                                                                            <>
                                                                                <button className="link orangeLinkBtn mt-2" onClick={() => showHideFrames(idx, true)}>Show More</button>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                ,{object.frames.slice(framesLength, object.frames.length).join(', ')}
                                                                                <button className="link orangeLinkBtn mt-2" onClick={() => showHideFrames(idx, false)}>Show Less</button>
                                                                            </>
                                                                    }
                                                                </p>
                                                                :
                                                                <p>{object.frames.join(', ')}</p>
                                                        }
                                                    </td>
                                                    <td className='td-area'><p>{object.url || '-'}</p></td>
                                                </tr>
                                            )
                                        }) :
                                        <tr>
                                            <td className='text-center' colSpan="4">Identified Objects are not found for the video</td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                        {
                            paginationData && paginationData.pages &&
                            < Pagination
                                className="m-3"
                                defaultCurrent={1}
                                pageSize // items per page
                                current={paginationData.page} // current active page
                                total={paginationData.pages} // total pages
                                onChange={onPageChange}
                                locale={localeInfo}
                                hideOnSinglePage={true}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default IdentifiedObjects