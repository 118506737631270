import React from 'react';
import '../../HomePage/Explore/Explore.css'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { ENV } from '../../../../config/config';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { FaPlayCircle } from 'react-icons/fa';
import { watchHistorySection } from '../../ApplicationTour/TourCompIdentifiers';
import { beforeWatchHistory, getUserWatchHistory } from '../users.actions';
import moment from 'moment';
import DateTimeFilters from '../../../../utils/Filters/Date&TimeFilters';
import CustomSearchModal from '../../../../utils/Filters/CustomSearchModal';
import { getDates } from '../../../../utils/Shared Functions/shared';
import { useNavigate } from 'react-router-dom';

const { getUserKeys } = ENV

const UserWatchHistory = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    let userId = getUserKeys('_id')?._id;

    const [videos, setVideos] = useState(null);
    const [date, setDate] = useState({
        startDate: moment(new Date()),
        endDate: moment(new Date())
    })
    const [searchCustom, setCustom] = useState(false)
    const [startTime, setStartTime] = useState(moment(new Date()).startOf('day').format('HH:mm'));
    const [endTime, setEndTime] = useState(moment(new Date()).endOf('day').format('HH:mm'));

    const [loader, setLoader] = useState(true)
    const [pagination, setPagination] = useState({})
    const [loadMore, setLoadMore] = useState(false)
    const [customModal, setModal] = useState(false)
    const durationList = [
        { value: '24', label: 'Last 24 hours', unit: 'hours' },
        { value: '7', label: 'Last 7 days', unit: 'days' },
        { value: '15', label: 'Last 15 days', unit: 'days' },
        { value: '30', label: 'Last 30 days', unit: 'days' },
        { value: 'custom', label: 'Custom' }
    ];
    const [selected, setSelected] = useState(durationList[0])
    const userSelector = useSelector((state) => state.user)

    useEffect(() => {
        if (userId) {
            resetFiltersHandler()
        }
    }, []);

    useEffect(() => {
        if (userSelector.userWatchHistoryVideosAuth) {
            let { watchHistory, pagination } = userSelector.userWatchHistoryVideos.data
            setLoader(false)
            setVideos(loadMore ? videos.concat(watchHistory) : watchHistory)
            setPagination(pagination)
            setLoadMore(false)

            dispatch(beforeWatchHistory());
        }
    }, [userSelector.userWatchHistoryVideosAuth]);


    const loadMoreHandler = () => {
        setLoadMore(true)
        setLoader(true)
        const { page } = pagination
        let qs = `userId=${userId}&startDate=${moment(new Date(date.startDate))}&endDate=${moment(new Date(date.endDate))}&startTime=${startTime}&endTime=${endTime}&page=${page + 1}`
        dispatch(getUserWatchHistory(qs))
    }

    useEffect(() => {
        if (selected.value) {
            let { startDate, endDate } = getDates(selected)
            setDate({ startDate, endDate })
        }
    }, [selected])

    useEffect(() => {
        if (searchCustom) {
            let qs = `userId=${userId}&startDate=${moment(new Date(date.startDate))}&endDate=${moment(new Date(date.endDate))}&startTime=${startTime}&endTime=${endTime}`
            dispatch(getUserWatchHistory(qs))
        }
    }, [searchCustom])


    const onSearchHandler = () => {
        setLoader(true)
        if (selected.value) {
            let qs = `userId=${userId}&startTime=${startTime}&endTime=${endTime}`
            if (selected.value !== 'custom') {
                setCustom(false)
                let duration = `${selected.value}-${selected.unit}`
                dispatch(getUserWatchHistory(`${qs}&duration=${duration}`))
            }
            else {
                dispatch(getUserWatchHistory(`${qs}&startDate=${moment(new Date(date.startDate))}&endDate=${moment(new Date(date.endDate))}`))
            }
        }
    }

    const durationChangeHandler = (e) => {
        if ((e.value === 'custom') || e.value !== selected.value) {

            if (e.value === 'custom') {
                setModal(true)
                setCustom(false)
            }

            setSelected({ value: e.value, label: e.label, unit: e.unit })
        }
    }

    const resetFiltersHandler = () => {
        let start = moment(new Date()).startOf('day').format('HH:mm')
        let end = moment(new Date()).endOf('day').format('HH:mm')
        let { startDate, endDate } = getDates(durationList[0])
        let duration = `${durationList[0].value}-${durationList[0].unit}`
        let qs = `userId=${userId}&startTime=${start}&endTime=${end}&duration=${duration}`

        dispatch(getUserWatchHistory(qs))

        setLoader(true)
        setDate({ startDate, endDate })
        setSelected(durationList[0])
        setStartTime(start)
        setEndTime(end)
    }

    const dateTimeFiltersProps = {
        date, startTime, endTime, setStartTime, setEndTime, selected, resetFiltersHandler, onSearchHandler, durationChangeHandler
    }

    const customSearchProps = {
        date, setDate, startTime, endTime, setStartTime, setEndTime, setSelected, setLoader, setModal, customModal, setCustom, durationList
    }


    return (
        <>
            {loader && <FullPageLoader />}
            <section className='explore explore-sec pt-0 px40'>
                <div className="dashboardHeader">
                    <h2 id={watchHistorySection} className="text-uppercase">Previously Watched</h2>
                </div>
                <DateTimeFilters {...dateTimeFiltersProps} />
                <div className='wrapVideos'>
                    {videos?.length > 0 && (
                        videos.map((exploreData, index) => {
                            return (
                                <div className='exploreCard' key={index}>
                                    <div className='exploreImg pointer position-relative' onClick={() => navigate(`/media-player/${exploreData.slug}/${exploreData?.channel?.name.toLowerCase()}`)} >
                                        <img className='img-fluid video-img' src={exploreData.thumbnail} alt={exploreData.title}></img>
                                        <div className='player-icon'><FaPlayCircle /></div>
                                    </div>
                                    <div className='cardFooter'>
                                        <div className='userImg me-3'>
                                            <img className='img-fluid' src={exploreData.channel.image} alt={exploreData.channel.name}></img>
                                        </div>
                                        <div className='videoReview pointer' /* onClick={() => onClickHandler(exploreData)} */>
                                            <p>{exploreData.title}</p>
                                            <span>{exploreData?.views ? exploreData.views : 0} {exploreData?.views > 1 ? 'Views' : 'View'}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    )
                    }
                </div>
                {
                    !videos?.length &&
                    <div className="no-record-found d-flex justify-content-center align-items-center">No Record Found</div>
                }

                {(pagination.total > videos?.length) ?
                    (<div className='text-center w-100 d-block'>
                        <button className="orange-btn load-more-btn text-uppercase mx-auto" onClick={loadMoreHandler}>Load more</button>
                    </div>)
                    :
                    ("")}
            </section>
            <CustomSearchModal {...customSearchProps} />
        </>
    )
}


export default UserWatchHistory;
