import React, { useEffect, useState } from 'react'
import '../VideoAnalyticsComponent/siteTabs.css'
import '../MemberSidebar/MemberDashboardSidebar.css'
import ReactECharts from 'echarts-for-react'
import { useDispatch, useSelector } from 'react-redux'
import { beforeChannelAnalytics } from '../../MyChannels/channels.actions'
import moment from 'moment'

const Content = (props) => {

    const dispatch = useDispatch()
    const colors = ['#FD6F21'];
    const tabColors = {
        'grey': '#393C48',
        'black': '#0D0F17'
    }
    const [clickedStatOption, setClickedOption] = useState(1);
    const [analytics, setAnalytics] = useState({})
    const [options, setOptions] = useState({
        color: colors,
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [],
                type: 'line',
                color: colors[1],
                symbol: "circle"
            }
        ]
    })
    const channelSelector = useSelector((state) => state.channels)

    useEffect(() => {
        if (channelSelector.channelAnalyticsAuth) {
            let data = channelSelector?.channelAnalyticsData
            if (data?.channelAnalytics && data?.statType == 1) {
                setAnalytics(data?.channelAnalytics)
            }
            props.setLoader(false)
            setClickedOption(1)
            dispatch(beforeChannelAnalytics())
        }
    }, [channelSelector.channelAnalyticsAuth])

    useEffect(() => {
        if (clickedStatOption && analytics) {
            props.setLoader(false)
            let optionsData = { ...options }
            let { views, impressions, impressionsClickRate, avgViewDurationStats } = analytics
            let xAxisDates = []
            let yAxisValues = []

            let key = clickedStatOption === 1 ? views : clickedStatOption === 2 ? impressions : clickedStatOption === 3 ? impressionsClickRate : clickedStatOption === 4 ? avgViewDurationStats : null
            let dateIntervals = []
            let seriesData = []

            if (key)
                if (key.data?.length) {
                    let indexes = []
                    key.data.map((stat) => {

                        let statDate = moment(new Date(stat.date)).format('DD-MMM-YY')
                        xAxisDates.push(statDate)
                        yAxisValues.push(stat.total);

                        dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate, 1, yAxisValues.length)
                        seriesData = new Array(dateIntervals.length).fill(0)
                        dateIntervals.map((dt, indx) => {
                            if (dt === statDate)
                                indexes.push(indx)
                        })
                    })
                    indexes.map((i, ind) => {
                        seriesData[i] = yAxisValues[ind]
                    })
                }
                else {
                    dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate)
                    seriesData = new Array(dateIntervals.length).fill(0)
                }

            optionsData.xAxis = { ...optionsData.xAxis, data: dateIntervals }
            optionsData.series = [
                {
                    ...optionsData.series[0],
                    name: clickedStatOption === 1 ? "Views" : clickedStatOption === 2 ? "Impressions" : clickedStatOption === 3 ? "Impressions Click Rate" : clickedStatOption === 4 ? "Avg View Duration" : '',
                    data: seriesData,
                }
            ]

            setOptions(optionsData)
        }
    }, [clickedStatOption, analytics])

    const statOptionsChangeHandler = (option) => {
        if (option !== clickedStatOption) {
            props.setLoader(true)
            setClickedOption(option)
        }
    }

    return (
        <>
            <div className='dashboardPage'>
                <div className='analyticContent'>
                    <div className='analyticGraph'>
                        <div className='analyticsBar'>
                            <div className={`analyticsBarBox pointer ${clickedStatOption === 1 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(1)}>
                                <span>Views</span>
                                <h4>{analytics.views ? analytics.views?.total : 0}</h4>
                            </div>
                            <div className={`analyticsBarBox pointer ${clickedStatOption === 2 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(2)}>
                                <span>Impressions</span>
                                <h4>{analytics.impressions ? analytics.impressions?.total : 0}</h4>
                            </div>
                            <div className={`analyticsBarBox pointer ${clickedStatOption === 3 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(3)}>
                                <span>Impressions click-through Rate</span>
                                <h4>{analytics.impressionsClickRate ? analytics.impressionsClickRate?.total : 0}</h4>
                            </div>
                            <div className={`analyticsBarBox pointer ${clickedStatOption === 4 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(4)}>
                                <span>Average View Duration</span>
                                <h4>{analytics.avgViewDurationStats ? analytics.avgViewDurationStats?.total : 0}</h4>
                            </div>
                        </div>
                        <ReactECharts option={options} />
                    </div>
                    {/* <Row className='tabBox'>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes mb-0'>
                                        <h4>How views find this video</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                        <div className='badgeFlex'>
                                            <Badge bg="secondary" pill >Overall</Badge>
                                            <Badge bg="secondary" pill >External</Badge>
                                            <Badge bg="secondary" pill >BiiView search</Badge>
                                            <Badge bg="secondary" pill >Suggested videos</Badge>
                                        </div>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2 op6'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Top videos</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2 op6'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>How viewers find your videos</h4>
                                        <p className='md-P mb-0'>Views · Last 28 days</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2 op6'>Not enough impressions data to show this report.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Top Remixed</h4>
                                        <p className='md-P mb-0'>Your content used to create Shorts · Last 28 days</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2 op6'>Nothing to show for these dates</p>
                                    <div>
                                        <button className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                </div>
            </div>
        </>
    )
}

export default Content