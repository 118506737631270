import React, { useState, useEffect } from 'react'
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { getUser, updateUserProfile, beforeUser } from '../users.actions';
import { useNavigate } from 'react-router-dom';
import { ENV } from '../../../../config/config';
import { BsEyeSlash, BsEye } from "react-icons/bs";
import './Profile.css';
import moment from 'moment';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { validateAge, validateEmail, validateName, validatePassword, validatePhone } from '../../../../utils/Validations/validations';
import ApplicationTour from '../../ApplicationTour/ApplicationTours';
import { updatePasswordSection, userDetailsSection } from '../../ApplicationTour/TourCompIdentifiers';
import { Helmet } from 'react-helmet-async/lib/index.esm';
const { userPlaceholderImage, allowedMediaTypes } = ENV

const Profile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [user, setUser] = useState({})
  const [image, setImage] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [loader, setLoader] = useState(true)
  const [errors, setErrors] = useState({})
  const [showPasswords, setShowPasswords] = useState({
    password: false,
    verifyPassword: false,
    currentPassword: false
  })


  const userSelector = useSelector((state) => state.user)
  const errorSelector = useSelector((state) => state.error)

  useEffect(() => {
    if (errorSelector) {
      setLoader(false)
    }
  }, [errorSelector])

  useEffect(() => {
    const userId = ENV.getUserKeys('_id')._id
    if (userId)
      dispatch(getUser(`userId=${userId}`))
  }, [])

  useEffect(() => {
    if (userSelector.getUserAuth) {
      let data = userSelector.getUserData?.data?.user
      setUser(data)
      setImage(data?.profileImage)
      setLoader(false)
      dispatch(beforeUser())
    }
  }, [userSelector.getUserAuth])

  useEffect(() => {
    if (userSelector.updateProfileAuth) {
      let { user } = userSelector.updateProfileData
      ENV.encryptUserData(user);
      setLoader(false)

      if (user.emailChanged) {
        localStorage.clear()
        navigate('/login')
      }
      else {
        // let channelId = localStorage.getItem('channelId')
        const channelName = localStorage.getItem('channelName')
        setUser({ ...user, currentPassword: '', password: '', verifyPassword: '' })
        navigate(user.type === 1 ? '/' : `/publisher-dashboard/${channelName}`)
        dispatch(getUser(`userId=${user._id}`))
      }
      dispatch(beforeUser())
    }

  }, [userSelector.updateProfileAuth])

  const onChangeHandler = (e) => {
    if (submitted) {

      if (e.target.name !== 'phone' && e.target.name !== 'address') {
        if (!e.target.value)
          setErrors({ ...errors, [e.target.name]: 'This field is required.' })
        else {
          let err = { ...errors, [e.target.name]: '' }
          setErrors(err)
        }
      }
    }
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const onFileChange = (e) => {
    const allowedImgTypes = allowedMediaTypes.images
    let file = e.target.files[0]

    if (file) {
      let fileExt = file.name.split('.').pop();
      if (allowedImgTypes.indexOf(fileExt) <= -1) {
        setErrors({ ...errors, profileImage: `Supported File Types are: ${allowedImgTypes.join(', ')}` })
        return
      }

      let reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = function (e) {
        let result = e.target.result
        setImage(result)
        setUser({ ...user, profileImage: file })
        setErrors({ ...errors, profileImage: '' })
      }
    }
  }

  const updateProfileHandler = (updateType) => {

    setSubmitted(true)
    let err = {}
    let msg = 'This field is required.'
    let payload = { ...user }

    if (updateType === 1) {

      if (!payload.fullName)
        err = { ...err, fullName: msg }
      else {
        payload.fullName = payload.fullName.replace(/\s+/g, " ").trim()
        let nameErr = validateName(payload.fullName)
        if (nameErr)
          err = { ...err, fullName: nameErr }
      }

      if (!payload.email)
        err = { ...err, email: msg }
      else {
        let emailErr = validateEmail(payload.email)
        err = { ...err, email: emailErr }
      }

      if (payload.phone) {
        let phoneErr = validatePhone(payload.phone)
        if (phoneErr) {
          err = { ...err, phone: "Invalid Contact Number." }
        }
      }

      if (payload.type == '2') {
        if (!payload.dob)
          err = { ...err, dob: msg }
        else {
          let dobErr = validateAge(payload.dob)
          if (dobErr)
            err = { ...err, dob: dobErr }
        }
      }

      delete payload['password']
      delete payload['currentPassword']
    }
    else {
      payload = {
        password: user.password,
        currentPassword: user.currentPassword,
        verifyPassword: user.verifyPassword
      }

      if (!payload.currentPassword)
        err = { ...err, currentPassword: msg }

      if (!payload.password || !payload.password.trim())
        err = { ...err, password: msg }
      else {
        let passErr = validatePassword(payload.password)
        if (passErr)
          err = { ...err, password: passErr }
      }

      if (!payload.verifyPassword)
        err = { ...err, verifyPassword: msg }

      if ((payload.password && payload.verifyPassword) &&
        payload.password !== payload.verifyPassword)
        err = { ...err, password: 'Both passwords do not match' }

    }

    setErrors(err)
    if (err.fullName || err.email || err.password || err.currentPassword || err.verifyPassword
      || err.phone || (payload.type == '2' && err.dob))
      return

    delete payload['verifyPassword']

    payload.updateType = updateType

    const formData = new FormData()
    for (let key in payload)
      if (payload[key] !== null)
        formData.append(key, payload[key])

    setLoader(true)
    dispatch(updateUserProfile(formData))

  };

  return (
    <>
      {loader && <FullPageLoader />}
      {
        user && user?.fullName &&
        <Helmet>
          <title>{`${user.fullName} - Biiview`}</title>
          <meta name="description" content={`Welcome to ${user.fullName}'s on Biiview - Your Personalized Hub of Discovery. Immerse yourself in a curated collection tailored just for you. Explore captivating content.`} />
        </Helmet>

      }
      <div className='profile-sec px40'>
        <Container fluid>
          {/* <div className="section-image">
            <Container fluid> */}
          <Row>
            <Col xl="8" className='mb-3'>
              <Form action="" className="form">
                <Card className="pb-4 table-big-boy">
                  <Card.Header id={userDetailsSection}>
                    <Card.Header className="pl-0 card-header-title">
                      <Card.Title className='mb-0' as="h4">Profile</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>

                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label>Name<span className="text-danger"> *</span></label>
                          <Form.Control
                            name="fullName"
                            value={user.fullName}
                            placeholder="John Doe"
                            type="text"
                            onChange={onChangeHandler}
                          ></Form.Control>

                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <label>Email<span className="text-danger"> *</span></label>
                          <Form.Control
                            name="email"
                            className="input-bg"
                            value={user.email}
                            placeholder="Email"
                            type="email"
                            onChange={onChangeHandler}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {
                      errors.fullName || errors.email ?
                        <Row>
                          <Col md="6">
                            <span className='text-danger submit-errors'>{errors.fullName ? errors.fullName : ''}</span>
                          </Col>
                          <Col className="pl-3" md="6">
                            <span className='text-danger submit-errors'>{errors.email ? errors.email : ''}</span>
                          </Col>
                        </Row> : null
                    }
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label>Address</label>
                          <Form.Control
                            name="address"
                            value={user.address || ''}
                            placeholder="Address"
                            type="text"
                            onChange={onChangeHandler}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label>Phone</label>
                          <Form.Control
                            name="phone"
                            value={user.phone || ''}
                            type="text"
                            placeholder='1234567890'
                            onChange={onChangeHandler}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      {
                        user.type === 2 ?
                          <Col md="6">
                            <Form.Group>
                              <label>Date of Birth <span className="text-danger"> *</span></label>
                              <Form.Control
                                name="dob"
                                value={user.dob ? moment(new Date(user.dob)).format('YYYY-MM-DD') : ''}
                                className="input-bg"
                                type="date"
                                onChange={onChangeHandler}
                              ></Form.Control>
                            </Form.Group>
                          </Col> : null
                      }
                    </Row>
                    {
                      errors.phone || errors.dob ?
                        <Row>
                          <Col md="6">
                            <span className='text-danger submit-errors'>{errors.phone ? errors.phone : ''}</span>
                          </Col>
                          <Col className="pl-3" md="6">
                            <span className='text-danger submit-errors'>{errors.dob ? errors.dob : ''}</span>
                          </Col>
                        </Row> : null
                    }
                    <Row>
                      <Col md={12}>
                        <div className="d-flex justify-content-end align-items-center">
                          <button type="button" className="theme-btn orange-btn dbLogoutBtn mt-2" onClick={() => updateProfileHandler(1)}>Update Profile</button>
                        </div>
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
            <Col xl="4" className='mb-3'>
              <Card className="card-user table-big-boy h-100">
                <Card.Body className='upload-img'>
                  <div className="author text-center">
                    <img
                      alt={user?.fullName}
                      className="avatar border-gray"
                      src={image ? image : userPlaceholderImage}
                    />
                    <Card.Title className='text-center' as="h5">{user?.fullName || ''}</Card.Title>
                    <p className="card-description"></p>
                  </div>


                  <Form className="profile-main-form">
                    <Form.Group className="pl-3 pr-3">
                      <div className="input-file-btn-holder ">
                        <Form.Control
                          className="text-white"
                          placeholder="Company"
                          type="file"
                          varient="info"
                          accept=".png,.jpeg,.jpg"
                          id="profileImage"
                          onChange={onFileChange}
                        ></Form.Control>
                        <span className="input-file-btn">Choose Image</span>
                      </div>
                    </Form.Group>
                    {
                      errors && errors.profileImage &&
                      <span className='text-danger submit-errors'>{errors.profileImage ? errors.profileImage : ''}</span>
                    }
                  </Form>

                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <Form action="" className="form">
                <Card className="table-big-boy">
                  <Card.Header>
                    <Card.Header id={updatePasswordSection} className="pl-0 card-header-title">
                      <Card.Title className='mb-0' as="h4">Change Password</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>


                    <Row>
                      <Col lg={4} md={6}>
                        <Form.Group>
                          <label>Current Password<span className="text-danger"> *</span>
                            &nbsp;
                            {
                              user.currentPassword ?
                                showPasswords.currentPassword ?
                                  <BsEye className="pointer" onClick={() => setShowPasswords({ ...showPasswords, currentPassword: false })} /> :
                                  <BsEyeSlash className="pointer" onClick={() => setShowPasswords({ ...showPasswords, currentPassword: true })} />
                                : ''
                            }
                          </label>
                          <Form.Control
                            name="currentPassword"
                            value={user.currentPassword}
                            placeholder="Current Password"
                            type={showPasswords.currentPassword ? 'text' : "password"}
                            id="currentPassword"
                            onChange={onChangeHandler}
                          ></Form.Control>
                          {
                            errors?.currentPassword &&
                            <span className='text-danger submit-errors'>{errors.currentPassword || ''}</span>
                          }
                        </Form.Group>
                      </Col>
                      <Col lg={4} md={6}>
                        <Form.Group>
                          <label>New Password<span className="text-danger"> *</span>
                            &nbsp;
                            {
                              user.password ?
                                showPasswords.password ?
                                  <BsEye className="pointer" onClick={() => setShowPasswords({ ...showPasswords, password: false })} /> :
                                  <BsEyeSlash className="pointer" onClick={() => setShowPasswords({ ...showPasswords, password: true })} />
                                : ''
                            }
                          </label>
                          <Form.Control
                            name="password"
                            value={user.password}
                            placeholder="New Password"
                            type={showPasswords.password ? 'text' : "password"}
                            id="password"
                            onChange={onChangeHandler}
                          ></Form.Control>
                          {
                            errors?.password &&
                            <span className='text-danger submit-errors'>{errors.password || ''}</span>
                          }
                        </Form.Group>
                      </Col>
                      <Col lg={4} md={6}>
                        <Form.Group>
                          <label>Confirm Password<span className="text-danger"> *</span>
                            &nbsp;
                            {
                              user.verifyPassword ?
                                showPasswords.verifyPassword ?
                                  <BsEye className="pointer" onClick={() => setShowPasswords({ ...showPasswords, verifyPassword: false })} /> :
                                  <BsEyeSlash className="pointer" onClick={() => setShowPasswords({ ...showPasswords, verifyPassword: true })} />
                                : ''
                            }
                          </label>
                          <Form.Control
                            name="verifyPassword"
                            value={user.verifyPassword}
                            placeholder="Confirm Password"
                            type={showPasswords.verifyPassword ? 'text' : "password"}
                            id="verifyPassword"
                            onChange={onChangeHandler}
                          ></Form.Control>
                          {
                            errors?.verifyPassword &&
                            <span className='text-danger submit-errors'>{errors.verifyPassword || ''}</span>
                          }
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="d-flex justify-content-end align-items-center">
                          <button type="button" className="theme-btn orange-btn dbLogoutBtn mt-2" onClick={() => updateProfileHandler(2)}>Update Passsword</button>
                        </div>
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
          {/* </Container>
          </div> */}
        </Container>
      </div>
    </>
  )
}

export default Profile