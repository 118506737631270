import React from 'react'
import { useState, useEffect } from 'react'
import { Row, Col, Tabs, Tab, Modal } from 'react-bootstrap'
import '../../Models/Models.css'
import Details from './Details'
import VideoEle from './VideoEle'
import Visibility from './Visibility'
import { AiOutlineCheck } from "react-icons/ai"
import { useSelector, useDispatch } from 'react-redux'
import { editVideo, uploadSubtitleFile } from '../video.actions'
import { getChannel, beforeChannels } from '../../../MyChannels/channels.actions'
import { beforeChannelRole, getChannelRole } from '../../../ChannelRoles/channelRoles.actions'
import $ from 'jquery';
import moment from 'moment'
import { ENV } from '../../../../../config/config'
const { allowedMediaTypes } = ENV

const UpdateVideoContent = (props) => {
    const dispatch = useDispatch()

    const userId = ENV.getUserKeys('_id')?._id
    const channelId = localStorage.getItem('channelId')
    const [currentTab, setCurrentTab] = useState(0);
    const [videoDetails, setDetails] = useState({ ...props.video } || {})
    const [fileInputs, setFileInputs] = useState({
        subtitle: '',
        endScreen: '',
        cards: [],
        thumbnails: []
    }) // fileInputs hold images preview urls
    const [isNext, setNext] = useState(false)
    const [errors, setErrors] = useState({})
    const [selectedImgIdx, setImgIdx] = useState(null)
    const [saveCheck, setSaveCheck] = useState(false)
    const [channelData, setData] = useState({})
    const [memberRole, setMemberRole] = useState()
    const [selected, setSelected] = useState('')

    const channelSelector = useSelector((state) => state.channels)
    const errorSelector = useSelector((state) => state.error)
    const rolesSelector = useSelector((state) => state.roles)

    useEffect(() => {
        if (errorSelector)
            props.setLoader(false)
    }, [errorSelector])

    useEffect(() => {
        if (channelId) {
            dispatch(getChannel(`channelId=${channelId}`))
            dispatch(getChannelRole(`channelId=${channelId}&userId=${userId}`))
        }
    }, [])

    useEffect(() => {
        if (rolesSelector.channelRoleAuth) {
            let role = rolesSelector.channelRoleData.memberRole
            setMemberRole(role)
            dispatch(beforeChannelRole())
        }
    }, [rolesSelector.channelRoleAuth])

    useEffect(() => {
        if (channelSelector.getChannelAuth) {
            setData(channelSelector?.getChannelData?.channel)
            dispatch(beforeChannels())
        }
    }, [channelSelector.getChannelAuth])

    useEffect(() => {
        if (props.video) {
            let thumbnails = props.video.thumbnails ? props.video.thumbnails : []
            let cards = props.video.cardImages ? props.video.cardImages : []

            props.video.thumbnails = thumbnails
            props.video.cardImages = cards

            if (props.video.subtitleFile) {
                $(`#subtitleLabel`).html('File Selected!')
            }

            if (!props.video.visibilityType) {
                props.video.visibilityType = 1
            }

            if (!props.video.publishType)
                props.video.publishType = channelData?.videoVisibility?.toString() || '1'

            if (props.video.keywords) {
                props.video.keywords = props.video.keywords.toString()
            }

            setDetails(props.video)
        }
    }, [props.video])

    const TabButton = (props) => {
        return (
            <>
                <div className='tabButton'>
                    <div className='d-flex flex-column'>
                        <div><p className='tabTitle'>{props.title}</p></div>
                        <div className='circleBef'><div className='tickCircle'><AiOutlineCheck /></div></div>
                    </div>
                </div>
            </>
        )
    }

    const handleClose = () => {
        props.setShow(false)
        setCurrentTab(0)
    };


    const onChangeHandler = (e) => {

        if (isNext && currentTab === 0) {
            if (e.target.name !== 'thumbnails' && !e.target.value) {
                setErrors({ ...errors, [e.target.name]: 'This field is required' })
            }
            else
                setErrors({ ...errors, [e.target.name]: '' })
        }

        setDetails({ ...videoDetails, [e.target.name]: e.target.value })
    }

    const dateChangeHandler = (date, name) => {
        let startDateErr = ''
        let endDateErr = ''

        if (saveCheck) {
            if (!date) {
                setErrors({ ...errors, [name]: 'This field is required' })
            }
            else if (name === 'publishStartDate') {
                if (moment(new Date(date)).isAfter(videoDetails.publishEndDate))
                    startDateErr = 'Invalid Date!'
            }
            else if (name === 'publishEndDate') {
                if (moment(new Date(date)).isBefore(videoDetails.publishStartDate))
                    endDateErr = 'Invalid Date!'
            }

            setErrors({ ...errors, publishStartDate: startDateErr, publishEndDate: endDateErr })
        }

        setDetails({ ...videoDetails, [name]: date })
    }

    const removeFilesHandler = (index, fileId) => {
        let details = { ...videoDetails }
        let inputs = { ...fileInputs }

        details[`${fileId}Url`].splice(index, 1)
        inputs[`${fileId}`].splice(index, 1)

        setDetails(details)
        setFileInputs(inputs)
    }

    const onFileChangeHandler = async (files, type, fileId) => {
        // type -> 1= subtitle, 2= cards, 3= end screen, 4= thumbnails
        const allowedImgTypes = allowedMediaTypes.images
        const allowedSubtitleTypes = allowedMediaTypes.subtitles
        let regex = /\.([^\.]+)$/

        if (files) {

            // if a specific file is selected whose type is not supported 
            if (selectedImgIdx !== null && allowedImgTypes.indexOf(files[0].name.match(regex)[1]) === -1) {
                setErrors({ ...errors, [fileId]: `Supported file types are : ${allowedImgTypes}` })
                setImgIdx(null)
                return
            }

            // for subtitle and endScreen image
            if (type === 1 || type === 3) {
                let fileExt = ''
                if (type === 1) {
                    let splittedStr = files.name.split('.')
                    let lastExt = splittedStr[splittedStr.length - 1]
                    let firstExt = splittedStr[splittedStr.length - 2]
                    fileExt = `${firstExt}.${lastExt}`
                }
                else
                    fileExt = files.name.match(regex)[1]

                let fileTypes = type === 1 ? allowedSubtitleTypes : allowedImgTypes
                let key = type === 1 ? 'subtitleFile' : 'endScreenImage'

                if (fileTypes.indexOf(fileExt) === -1) {
                    setDetails({ ...videoDetails, [key]: null, [`${key}Url`]: null })
                    setFileInputs({ ...fileInputs, [fileId]: '' })
                    setErrors({ ...errors, [fileId]: `Supported file types are : ${fileTypes}` })
                    $(`#${fileId}Label`).html('')
                    return
                }

                let reader = new FileReader();
                reader.readAsDataURL(files)
                reader.onload = function (e) {
                    let result = e.target.result
                    setFileInputs({ ...fileInputs, [fileId]: result })
                    setErrors({ ...errors, [fileId]: '' })
                    if (fileId === 'subtitle')
                        $(`#subtitleLabel`).html(`File Selected: ${files.name}`)

                }

                setDetails({ ...videoDetails, [type === 1 ? 'subtitleFileUrl' : 'endScreenImageUrl']: files, [type === 1 ? 'subtitleFile' : 'endScreenImage']: null })
            }

            // for cards and thumbnails
            if (type === 2 || type === 4) {
                let imagesArray = Array.from(files)
                if (!imagesArray.length)
                    return

                if (imagesArray.length > 5) {
                    imagesArray = imagesArray.splice(0, 5)
                }

                let key = type === 2 ? 'cardImages' : 'thumbnails'
                let results = []

                for (let i = 0; i < imagesArray.length; i++) {

                    let fileExt = imagesArray[i].name.split('.').pop()

                    if (allowedImgTypes.indexOf(fileExt) === -1) {
                        setDetails({
                            ...videoDetails, [key]: null, [`${key}Url`]: null
                        })

                        setFileInputs({ ...fileInputs, [fileId]: [] })
                        setErrors({ ...errors, [fileId]: `Supported file types are : ${allowedImgTypes}` })
                        return
                    }

                    let result = await readFilesSync(imagesArray[i])
                    results.push(result)
                }
                setErrors({ ...errors, [fileId]: '' })

                // if user has selected specific image to be  replaced 
                // (for cards and thumbnails)
                if (selectedImgIdx !== null) {

                    let inputData = [...fileInputs[fileId]]
                    let detailData = []

                    if (videoDetails[`${key}Url`])
                        detailData = [...videoDetails[`${key}Url`]]
                    else if (videoDetails[key])
                        detailData = [...videoDetails[key]]


                    inputData[selectedImgIdx] = results[0]
                    detailData[selectedImgIdx] = imagesArray[0]

                    setFileInputs({ ...fileInputs, [fileId]: inputData })
                    setDetails({ ...videoDetails, [`${key}Url`]: detailData, [key]: null })
                    setImgIdx(null)
                }
                else {
                    setFileInputs({ ...fileInputs, [fileId]: results })
                    setDetails({ ...videoDetails, [`${key}Url`]: imagesArray, [key]: null })
                }
            }

        }

    }

    const readFilesSync = async (file) => {
        let result = await new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => resolve(e.target.result);
        });
        setErrors({ ...errors, cards: '' })
        return result;
    }

    const resetSubtitle = (showInput, setShowInput) => {
        setShowInput({ ...showInput, subtitleInput: false })
        setErrors({ ...errors, subtitle: '' })
        setFileInputs({ ...fileInputs, subtitle: '' })

        let newData = { ...videoDetails, subtitleFile: '' }
        if (newData.subtitleFileUrl)
            newData = { ...newData, subtitleFileUrl: null }

        setDetails(newData)
    }

    const resetEndScreen = (showInput, setShowInput) => {
        setShowInput({ ...showInput, endScreenInput: false })
        setErrors({ ...errors, endScreen: '' })
        setFileInputs({ ...fileInputs, endScreen: '' })

        let newData = { ...videoDetails, endScreenImage: '' }
        if (newData.endScreenImageUrl)
            newData = { ...newData, endScreenImageUrl: null }
        setDetails(newData)
    }

    const resetCards = (showInput, setShowInput) => {
        setShowInput({ ...showInput, cardInput: false })
        setErrors({ ...errors, cards: '' })
        setFileInputs({ ...fileInputs, cards: [] })

        let newData = { ...videoDetails, cardImages: [] }
        if (newData.cardImagesUrl)
            newData = { ...newData, cardImagesUrl: null }

        setDetails(newData)
    }

    const tabChangeHandler = (tabKey) => {
        setNext(true)

        let title = ''
        let description = ''
        let thumbnailErr = ''
        let keywordsErr = ''

        let msg = 'This field is required'

        if (!videoDetails.title)
            title = msg

        if (!videoDetails.description)
            description = msg

        if (!videoDetails.thumbnails && !videoDetails.thumbnailsUrl)
            thumbnailErr = msg

        if (videoDetails.keywords) {
            let keywords = videoDetails.keywords ? videoDetails.keywords.toString() : ''
            let check = true

            if (keywords) {
                keywords = keywords.split(',').map((k) => k.replace(/\s+/g, " ").trim()).filter((k) => k !== '')
                keywords.forEach((k) => {
                    if (!(/^#[a-zA-Z ]*$/.test(k)))
                        check = false
                })

                if (!check)
                    keywordsErr = 'Invalid Keywords'
                else
                    keywordsErr = ''

                setDetails({ ...videoDetails, keywords })

            }
        }

        setErrors({ ...errors, title, description, thumbnails: thumbnailErr, keywords: keywordsErr })

        if (title || description || thumbnailErr || keywordsErr) {
            return
        }

        if (tabKey == 1 && !memberRole.createVideoSubtitle)
            tabKey = tabKey + 1

        setCurrentTab(tabKey)
    }

    const saveDetails = () => {
        setSaveCheck(true)
        //set loader when available
        let payload = { ...videoDetails, channelId: channelId || '' }

        let startDateErr = ''

        if (payload.visibilityType == '2') {
            if (!payload.publishStartDate) {
                startDateErr = 'This field is required!'
            }
            else {
                if (payload.publishEndDate)
                    if (moment(new Date(payload.publishStartDate)).isAfter(payload.publishEndDate))
                        startDateErr = 'Invalid Date'
            }

        }

        if (startDateErr) {
            setErrors({ publishStartDate: startDateErr })
            return
        }

        payload.endScreenImage = payload.endScreenImageUrl || payload.endScreenImage
        payload.thumbnails = payload.thumbnailsUrl ? [...payload.thumbnailsUrl] : [...payload.thumbnails]
        payload.cardImages = payload.cardImagesUrl ? [...payload.cardImagesUrl] : [...payload.cardImages]
        let subtitleFile = payload.subtitleFileUrl || payload.subtitleFile

        delete payload['subtitleFileUrl']
        delete payload['subtitleFile']

        delete payload['video']
        delete payload['views']

        let formData = new FormData()
        for (const key in payload) {
            if (!payload[key])
                formData.append(key, null)
            else {
                if ((key === 'thumbnails') || (key === 'cardImages')) {
                    if (payload[key].length) {
                        for (const image of payload[key]) {
                            formData.append(key, image);
                        }
                    }
                    else
                        formData.append(key, null)
                }
                else if (key === 'thumbnailsUrl' || key === 'endScreenImageUrl' ||
                    key === 'subtitleFileUrl' || key === 'cardImagesUrl')
                    delete payload[key]
                else {
                    formData.append(key, payload[key])
                }
            }
        }
        props.setLoader(true)
        dispatch(editVideo(formData))

        if (subtitleFile) {
            formData = new FormData()
            formData.append('_id', videoDetails?._id)
            formData.append('videoId', videoDetails.video?.split('videos/')[1].split('.')[0])
            formData.append('isFileSubtitle', true)
            if (selected){
                formData.append('langCode', selected.value)
                formData.append('langLabel', selected.label)
            }
            formData.append('subtitleFile', subtitleFile)
            dispatch(uploadSubtitleFile(formData))
        }

        handleClose()
    }

    return (
        <>
            <div className='modalPage'>
                <div className="modal themeModal show" >
                    <Modal show={props.show} onHide={handleClose} className="medium-Model vision-modal" centered>
                        <div className='animatedTab'>
                            <Modal.Header closeButton>
                                <Modal.Title className='mb-0'>{videoDetails && videoDetails.title}</Modal.Title>
                            </Modal.Header>
                            <div className='videoModal'>
                                <Row className=''>
                                    <Col className='pe-3'>
                                        <Tabs activeKey={currentTab} onSelect={(k) => tabChangeHandler(k)} id="controlled-tab-example">
                                            <Tab eventKey={0} title={<TabButton title="Details" />} label="Item One" disabled={currentTab === 0}>
                                                <Details
                                                    removeFilesHandler={removeFilesHandler}
                                                    onChangeHandler={onChangeHandler}
                                                    errors={errors}
                                                    videoDetails={videoDetails}
                                                    fileInputs={fileInputs}
                                                    onFileChange={onFileChangeHandler}
                                                    setImgIdx={setImgIdx}
                                                />
                                            </Tab>
                                            {
                                                memberRole?.createVideoSubtitle ?
                                                    <Tab eventKey={1} title={<TabButton title="Video Elements" />} disabled={currentTab === 1}>
                                                        <VideoEle
                                                            onFileChange={onFileChangeHandler}
                                                            fileInputs={fileInputs}
                                                            errors={errors}
                                                            resetEndScreen={resetEndScreen}
                                                            videoDetails={videoDetails}
                                                            resetCards={resetCards}
                                                            resetSubtitle={resetSubtitle}
                                                            setImgIdx={setImgIdx}
                                                            setSelected={setSelected}
                                                            selected={selected}
                                                        />
                                                    </Tab> : null
                                            }
                                            <Tab eventKey={2} title={<TabButton title="Visibility" />} disabled={currentTab === 2}>
                                                <Visibility
                                                    onChangeHandler={onChangeHandler}
                                                    dateChangeHandler={dateChangeHandler}
                                                    videoDetails={videoDetails}
                                                    fileInputs={fileInputs}
                                                    setDetails={setDetails}
                                                    errors={errors}
                                                />
                                            </Tab>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </div>
                            <Modal.Footer className='pb-0'>
                                <div>
                                    {
                                        currentTab < 2 ?
                                            <button className="orange-btn text-uppercase mx-auto mt-0"
                                                onClick={() => tabChangeHandler(parseInt(currentTab) + 1)}>next
                                            </button> :
                                            <button className="orange-btn text-uppercase mx-auto mt-0"
                                                onClick={saveDetails}>Save
                                            </button>
                                    }
                                </div>
                            </Modal.Footer>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default UpdateVideoContent