import { CREATE_CONTACT, BEFORE_CONTACT } from '../../../redux/types';

const initialState = {
    createContactData : null,
    createContactAuth : false
}

export default function contactReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_CONTACT:
            return {
                ...state,
                createContactData: action.payload,
                createContactAuth: true
            }

        case BEFORE_CONTACT:
            return {
                ...state,
                createContactData: null,
                createContactAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}