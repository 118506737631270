import { toast } from 'react-toastify';
import { emptyError } from '../../../redux/shared/error/error.action';
import { SEND_MESSAGE, BEFORE_CHAT, GET_ERRORS, GET_CHAT } from '../../../redux/types';
import { ENV } from '../../../config/config';

export const beforeChat= () => {
    return {
        type: BEFORE_CHAT
    }
}

export const sendMessage = (body) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}contact-admin/send-message`;

    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: SEND_MESSAGE,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getChat = (channelId) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}contact-admin/get?${channelId}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_CHAT,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};