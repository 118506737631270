import { LIST_OBJECTS, BEFORE_OBJECT, GET_OBJECT } from '../../../../../redux/types';


const initialState = {
    // Objects

    objectsRes: null,
    objectsAuth: false,

    getObjectRes: null,
    getObjectAuth: false,

}

export default function (state = initialState, action) {
    switch (action.type) {

        // objects
        case LIST_OBJECTS:
            return {
                ...state,
                objectsRes: action.payload,
                objectsAuth: true,
            }
        case GET_OBJECT:
            return {
                getObjectRes: action.payload,
                getObjectAuth: true
            }
        case BEFORE_OBJECT:
            return {
                ...state,

                objectsRes: null,
                objectsAuth: false,

                getObjectRes: null,
                getObjectAuth: false,
            }

        default:
            return {
                ...state
            }
    }
}