import { useEffect, useState } from 'react';
import './Header.css'
import { Container, Nav, Navbar, ListGroup, Row, Col, Button } from 'react-bootstrap'
// import logo from '../../../assets/images/logo.png'
import { Images } from '../../../assets/assets';
import { FaFontAwesome, FaSearch } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import UserNotifications from '../Notifications/UserNotifications';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getSearchedVideos, beforeSearchedVideos } from '../MemberDashboard/Videos/video.actions';
import { ENV } from '../../../config/config';
import ProfileComponent from '../Layouts/ProfileComponent';
import SmallLoader from '../../../utils/SmallLoader/SmallLoader';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUpload, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons';
import { beforeCreator, getChannelCreator } from '../UserComponents/users.actions';
import UploadVideo from '../MemberDashboard/Videos/UploadVideo/UploadVideo';
import { beforeChannelRole } from '../ChannelRoles/channelRoles.actions';
import { beforeBlog, listSearchedBlogs } from '../Blogs/blogs.actions';

const { defaultPlaceholderImage, getUserKeys } = ENV

const Header = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	let userData = getUserKeys();
	const userId = ENV.getUserKeys('_id')?._id
	const channelId = localStorage.getItem('channelId')

	const [memberRole, setRole] = useState()
	const [search, setSearch] = useState(false)
	const [loader, setLoader] = useState(false)
	const [searched, setSearched] = useState(false)
	const [input, setInput] = useState('')
	const [videos, setVideos] = useState([])
	const [listCheck, setCheck] = useState(false)
	const [bar, setBar] = useState(false)
	const [userlinks, setUserLinks] = useState(false)
	const [showSearchBar, setshowSearchBar] = useState(false);
	const [user, setUser] = useState()
	const [upload, setUpload] = useState(false)
	const [blogs, setBlogs] = useState([])
	let location = window.location.pathname.split('/')[1]

	const videoSelector = useSelector((state) => state.videos)
	const userSelector = useSelector((state) => state.user)
	const roleSelector = useSelector((state) => state.roles)
	const blogsSelector = useSelector((state) => state.blogs)

	useEffect(() => {
		if (channelId)
			dispatch(getChannelCreator(`channelId=${channelId}`))
	}, [])

	useEffect(() => {
		if (location === 'blogs' || location === 'blog')
			setVideos([])
		else
			setBlogs([])
	}, [location])

	useEffect(() => {
		if (!input) {
			setVideos([])
			setBlogs([])
		}
	}, [input])

	useEffect(() => {
		if (roleSelector.channelRoleAuth) {
			setRole(roleSelector.channelRoleData?.memberRole)
			dispatch(beforeChannelRole())
		}
	}, [roleSelector.channelRoleAuth])

	useEffect(() => {
		if (userSelector.getChannelCreatorAuth) {
			let data = userSelector.getChannelCreatorData?.data?.user
			setUser(data)
			dispatch(beforeCreator())
		}
	}, [userSelector.getChannelCreatorAuth])

	useEffect(() => {
		if (videoSelector.searchedVideosAuth) {
			setLoader(false)
			setSearched(false)
			setVideos(videoSelector.searchedVideosData.videos)
			if (!videoSelector.searchedVideosData.videos?.length)
				toast.error("We couldn't find any video against this search.")
			dispatch(beforeSearchedVideos());
		}
	}, [videoSelector.searchedVideosAuth]);

	useEffect(() => {
		if (blogsSelector.searchedBlogsAuth) {
			let { data } = blogsSelector.searchedBlogsRes
			setLoader(false)
			setSearched(false)
			setBlogs(data)
			if (!data?.length)
				toast.error("We couldn't find any blog or post against this search.")
			dispatch(beforeBlog());
		}
	}, [blogsSelector.searchedBlogsAuth]);


	useEffect(() => {
		setInput('')
		setSearch(false)
		setCheck(false)
	}, [window.location.pathname])

	const searchVideos = () => {
		if (input) {
			if (location === 'blogs' || location === 'blog')
				dispatch(listSearchedBlogs(`searchTerm=${encodeURIComponent(input)}`))
			else
				dispatch(getSearchedVideos(`searchTerm=${encodeURIComponent(input)}&all=${true}`))

			setCheck(true)
			setLoader(true)
			setSearched(true)
		}
		setSearchF(true)
	}


	const handleKeyPress = (e) => {
		console.log('key pressed')
		if (e.key === 'Enter') {
			e.preventDefault();
			searchVideos()
		}
	}

	const handleClose = () => {
		setCheck(false)
	}

	const renderList = () => {
		if (listCheck) {
			return (
				<ListGroup className={!listCheck ? `d-none` : userId ? 'search-results' : 'search-results non-active-user'} id="headerSearchList">
					<button onClick={handleClose} className='search-popup-close-btn close-button'><span><FontAwesomeIcon icon={faXmark} /></span></button>

					{
						loader ?
							<SmallLoader /> :
							<ListGroup>
								{
									(videos && videos.length) || (blogs && blogs?.length) ?
										<ListGroup.Item style={{ backgroundColor: '#FD6F21', color: '#fff', border: 'none' }}>{videos?.length ? 'Videos' : 'Blogs & Posts'}</ListGroup.Item>
										: ''
								}
								<div className='search-result-items'>
									{
										videos && videos.length ?
											videos.map((item, index) => {
												return (
													<ListGroup.Item>
														<Container>
															<Row>
																<Col className='col-4 px-sm-2 px-0'>
																	<div className='thumbnailImg search-img'>
																		<img className='videoImg' src={item.thumbnails[0].localUrl || defaultPlaceholderImage} alt='thumbnail' />
																	</div>
																</Col>
																<Col className='col-8'>
																	<Link to={`/media-player/${item?.slug}/${item?.channelName}`} key={index}><ListGroup.Item key={index}>{item.title}</ListGroup.Item></Link>
																</Col>

															</Row>
														</Container>
													</ListGroup.Item>
												)
											}) :
											blogs?.length ?
												blogs.map((item, index) => {
													return (
														<ListGroup.Item>
															<Container>
																<Row>
																	<Col className='col-4 px-sm-2 px-0'>
																		<div className='thumbnailImg search-img'>
																			<img className='videoImg' src={item.featuredImage} alt='blog' />
																		</div>
																	</Col>
																	<Col className='col-8'>
																		<Link to={`/blog/${item?.blogPost?.customUrl || item?.customUrl}`} key={index}><ListGroup.Item key={index}>{item.title}</ListGroup.Item></Link>
																	</Col>

																</Row>
															</Container>
														</ListGroup.Item>
													)
												})
												:
												null
									}
								</div>
							</ListGroup>
					}

				</ListGroup>)
		}
	}

	const setSearchF = (bool) => {
		setSearch(bool)
		if ($('.navbar-collapse.collapse').hasClass('show')) {
			$('.navbar-toggler').click();
			setBar(false);
		}
	}

	const setSearchAndBar = () => {
		setBar(!bar);
		setUserLinks(!userlinks);
	};

	const toggleSearch = () => {
		setshowSearchBar(!showSearchBar);
	};

	const setUploadHandler = () => {
		if (!user?.subscribedPlan?.plan ||
			!Object.keys(user.subscribedPlan?.plan).length) {

			toast.info('Please subscribe to a package in order to upload videos')
			if (userId === user._id)
				navigate('/packages')
		}
		else if (user?.hasPlanExpired) {
			if (user.subscribedPlan.plan.type !== 1)
				toast.info('Please complete your payment in order to upload videos!')
			else
				toast.info('Your Free Plan has been expired!')
		}
		else
			setUpload(true)
	}

	// const stickyContentElement = document.getElementById('sticky-content');
	const rootElement = document.getElementById('root');

	window.onscroll = function () {
		if (rootElement) {
			const headerHeight = document.getElementById('header-navbar')?.offsetHeight;
			let scrolledValue = document.documentElement.scrollTop;
			if (scrolledValue > headerHeight) {
				rootElement.style.paddingTop = `${headerHeight}px`
				rootElement.classList.add("scrolled");
			}
			else {
				rootElement.classList.remove("scrolled");
				rootElement.style.paddingTop = '0px'
			}
		}

	}

	return (
		<>
			<div id="header-navbar" className={userId ? 'header active-user' : 'header'}>
				<Navbar expand="lg">
					<Container fluid className={search ? 'customBox parentOpenSearch' : 'customBox'}>
						<div className='wrapHeaderBar'>
							<div className='nav-flex'>
								<div className="logo-search-holder d-flex align-items-center">
									<Link to="/" className='navbar-brand'>
										<img className='img-fluid' src={Images.siteLogo} alt='BiiView' />
									</Link>
									{/* <div className={showSearchBar ? 'search-form align-items-center position-relative show' : 'search-form align-items-center position-relative'}> */}
									<div className={`search-form align-items-center ${showSearchBar ? "show" : ""}`}>
										<span className="search-icon">
											<FontAwesomeIcon icon={faMagnifyingGlass} />
										</span>
										{
											<div className="input-holder flex-fill">
												<input className="w-100 h-100" type='text' placeholder="Search Videos with title, keywords or description" onChange={(e) => setInput(e.target.value)} onKeyDown={handleKeyPress} />
											</div>
										}
										{
											renderList()
										}
									</div>
								</div>
								<div className='nav-right-bar d-flex align-items-center'>
									<div className={userlinks ? 'user-links-block d-flex align-items-center hide' : 'user-links-block d-flex align-items-center'}>
										{
											userData?.type === 2 && memberRole && memberRole?.createVideo &&
											// <Lin? to="/my-videos" className="header-btn d-flex justify-content-center align-items-center">
											<Button onClick={setUploadHandler} className='upload-btn'>
												<span className="d-none d-md-block">Upload A Video</span>
												<span className="d-block d-md-none">
													<FontAwesomeIcon icon={faUpload} />
												</span>
											</Button>
											// </Link>
										}
										
										<span className="header-user-btn user-login d-flex justify-content-center align-items-center ms-1  ms-sm-2 ms-md-4 d-block d-xl-none" onClick={toggleSearch}>
											<FontAwesomeIcon icon={faMagnifyingGlass} />
										</span>
										{!userId &&
											<Link className="header-user-btn user-login d-flex justify-content-center align-items-center ms-1 ms-sm-2 ms-md-4" to="/login">
												<img src={Images.iconUser} alt="User Icon" />
											</Link>
										}
									</div>
									<Navbar.Toggle aria-controls="basic-navbar-nav" className='ms-md-3' >
										<span className='header-user-btn navBtns'
											// onClick={() => setBar(!bar)}
											onClick={() => setSearchAndBar()}
										>
											<div className={bar ? 'faBar faCross' : 'faBar'} >
												<span></span><span></span><span></span>
											</div>
										</span>
									</Navbar.Toggle>
									{userId ?
										<div className=" ms-1 ms-sm-2 d-flex align-items-center">
											{user?.type === 2 && <UserNotifications />}
											<ProfileComponent />
										</div>
										:
										null
									}
								</div>
							</div>
							{/* {
								renderList()
							} */}
							<Navbar.Collapse>
								<Nav className="ms-auto">
									<Link className="nav-link" to="/about-us">About</Link>
									<Link className="nav-link" to="/blogs">Blogs</Link>
									<Link className="nav-link" to="/contact-us">Contact</Link>
									<Link className='nav-link' to='/Community'>Community</Link>
									<Link className='nav-link' to='/vlogs'>Vlogs</Link>
									<Link className='nav-link' to='/packages'>Packages</Link>
								</Nav>
							</Navbar.Collapse>
						</div>
					</Container>
				</Navbar>
			</div>
			<UploadVideo setShow={setUpload} show={upload} setLoader={setLoader} />

		</>

	)
}
export default Header