import React from 'react'
import { Container } from 'react-bootstrap'
import { Images } from '../../../../assets/assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import "./CommunityPosts.css"
import { Link } from 'react-router-dom'

function CommunityPosts() {
	const comunityCars = [
		{
			profileImg: Images.videoAuthor01,
			name: 'Alex Fernandez',
			followBtn: 'Follow',
			experience: 'Professional Web Developer',
			postDate: 'Dec 10',
			postTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
			postDiscription: 'Nullam sit amet ante in velit aliquam vehicula. Phasellus blandit magna ultricies facilisis suscipit. Sed vitae porta elit, dignissim maximus velit. Nulla aliquam id metus et venenatis. Suspendisse semper tempor leo, ac viverra tellus ullamcorper in. ',
			postImage: Images.videoThumbnail01,
			views: '397.6k views',
			viewsUpvotes: '15k upvotes',
			upVotesBtnIcon: <FontAwesomeIcon icon={faArrowUp} />,
			upVotesBtnText: 'upvote',
			downVoteBtnIcon: <FontAwesomeIcon icon={faArrowDown} />,
			comments: '49 comments from Alex Fernandez and more',
		},
		{
			profileImg: Images.videoAuthor02,
			name: 'John Lou',
			followBtn: 'Follow',
			experience: 'Professional Graphic Designer ',
			postDate: 'Dec 11',
			postTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
			postDiscription: 'Nullam sit amet ante in velit aliquam vehicula. Phasellus blandit magna ultricies facilisis suscipit. Sed vitae porta elit, dignissim maximus velit. Nulla aliquam id metus et venenatis. Suspendisse semper tempor leo, ac viverra tellus ullamcorper in. ',
			postImage: Images.videoThumbnail02,
			views: '97.6k views',
			viewsUpvotes: '5k upvotes',
			upVotesBtnIcon: <FontAwesomeIcon icon={faArrowUp} />,
			upVotesBtnText: 'upvote',
			downVoteBtnIcon: <FontAwesomeIcon icon={faArrowDown} />,
			comments: '100 comments from John Lou and more',
		},
		{
			profileImg: Images.videoAuthor03,
			name: 'vin Adam',
			followBtn: 'Follow',
			experience: 'Professional App Developer',
			postDate: 'Dec 09',
			postTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
			postDiscription: 'Nullam sit amet ante in velit aliquam vehicula. Phasellus blandit magna ultricies facilisis suscipit. Sed vitae porta elit, dignissim maximus velit. Nulla aliquam id metus et venenatis. Suspendisse semper tempor leo, ac viverra tellus ullamcorper in. ',
			postImage: Images.videoThumbnail03,
			views: '39k views',
			viewsUpvotes: '1k upvotes',
			upVotesBtnIcon: <FontAwesomeIcon icon={faArrowUp} />,
			upVotesBtnText: 'upvote',
			downVoteBtnIcon: <FontAwesomeIcon icon={faArrowDown} />,
			comments: '30 comments from vin Adam and more',
		},
		{
			profileImg: Images.videoAuthor04,
			name: 'Fernandez sam',
			followBtn: 'Follow',
			experience: 'Fashion Designer',
			postDate: 'Dec 01',
			postTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
			postDiscription: 'Nullam sit amet ante in velit aliquam vehicula. Phasellus blandit magna ultricies facilisis suscipit. Sed vitae porta elit, dignissim maximus velit. Nulla aliquam id metus et venenatis. Suspendisse semper tempor leo, ac viverra tellus ullamcorper in. ',
			postImage: Images.videoThumbnail04,
			views: '500.7k views',
			viewsUpvotes: '90k upvotes',
			upVotesBtnIcon: <FontAwesomeIcon icon={faArrowUp} />,
			upVotesBtnText: 'upvote',
			downVoteBtnIcon: <FontAwesomeIcon icon={faArrowDown} />,
			comments: '1.5K comments from Fernandez sam and more',
		},
	]
	return (
		<div>
			<Container>
				{comunityCars.map((i, index) => (
					<section id='community-post' key={index}>
						<div className='community-posts-wrapper mx-auto'>
							<div className='community-posts'>
								<div className='community-user-details-wrapper d-flex align-items-center'>
									<Link to='/' className='community-author-img'>
										<img src={i.profileImg} alt='' className='img-fluid'/>
									</Link>
									<div className='comunity-author-nameDetails'>
										<div className='community-name-follow d-flex align-items-center'>
											<Link to='/'>{i.name}</Link>
											<button className='btn'>{i.followBtn}</button>
										</div>
										<div className='experience'>
											<span>{i.experience}</span>
											<span>{i.postDate}</span>
										</div>
									</div>
								</div>
								<div className='post-heading-discription'>
									<Link to='/CommunityQuestionsDetails'>{i.postTitle}</Link>
									<p>{i.postDiscription}</p>
								</div>
							</div>
							<div className='post-img'>
								<Link to='/'><img src={i.postImage} alt='' className='img-fluid'/></Link>
							</div>
							<div className='community-posts'>
								<div className='experience'>
									<span>{i.views}</span>
									<span>{i.viewsUpvotes}</span>
								</div>
								<div className='votes-btn-wrapper'>
									<button className='btn btn-primary d-inline-block theme-btn orange-btn'>{i.upVotesBtnIcon} {i.upVotesBtnText}</button>
									<button className='btn btn-primary d-inline-block theme-btn orange-btn'>{i.downVoteBtnIcon}</button>									
								</div>
								<div className='d-flex justify-content-center'>
									<button to='/' className='comments-count text-center'>{i.comments}</button>
								</div>
							</div>						
						</div>
					</section>
				))}

			</Container>
		</div>
	)
}

export default CommunityPosts