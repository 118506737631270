import React, { useState, useEffect } from 'react'
import '../Forms/Forms.css'
import { Container, Form } from 'react-bootstrap'
import formLogo from '../../../assets/images/formLogo.png'
import { useParams, useNavigate, Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import { FaCalendarDay } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux'
import { beforeUser } from '../UserComponents/users.actions';
import { beforeInvite, getInviteDetails, inviteSignup } from "./invites.actions";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { ENV } from '../../../config/config';
import FullPageLoader from '../FullPageLoader/FullPageLoader'

const { recaptchaSiteKey } = ENV

const Invite = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { inviteKey } = useParams();
    const [details, setDetails] = useState();
    const [isLoader, setLoader] = useState(true)

    useEffect(() => {
        dispatch(getInviteDetails(inviteKey))
    }, [])


    const [signupData, setSignupData] = useState({
        fullName: '',
        email: '',
        password: '',
        dob: ''
    })

    const [captcha, setCaptcha] = useState('')
    const [errors, setErrors] = useState({})
    const [showPassword, setShowPass] = useState(false)
    const [showVerifyPassword, setShowVerifyPass] = useState(false)

    const inviteSelector = useSelector((state) => state.invites)
    const errorSelector = useSelector((state) => state.error)
    const userSelector = useSelector((state) => state.user)

    useEffect(() => {
        if (userSelector.userSignupAuth) {
            if (userSelector.userSignupData.success) {
                dispatch(beforeUser())
                navigate('/login')
            }
        }
    }, [userSelector.userSignupAuth])


    useEffect(() => {
        if (errorSelector && errorSelector?.hasExpired) {
            navigate('/login')
        }
    }, [errorSelector])


    useEffect(() => {
        if (inviteSelector.inviteDetailsAuth) {
            setDetails(inviteSelector.inviteDetailsData.data);
            setSignupData({ ...signupData, email: inviteSelector.inviteDetailsData.data.inviteTo });

            setLoader(false)
            dispatch(beforeInvite())
            localStorage.clear()
        }
    }, [inviteSelector])

    const signupHandler = () => {

        let fullNameErr = ''
        let emailErr = ''
        let dobErr = ''
        let passErr = ''
        let captchaErr = ''
        let verifyPassErr = ''
        let msg = 'This field is required.'
        let data = signupData;
        data.email = details.inviteTo;
        data.channelId = details.channelId;
        data.role = details.role;
        data.inviteKey = details.inviteKey;
        if (!signupData.fullName)
            fullNameErr = msg
        else {
            let regex = /^[a-zA-Z ]{3,30}$/;
            if (!signupData.fullName.match(regex))
                fullNameErr = 'Name should be atleast 3 characters long and should only contain alphabets'
        }

        if (!signupData.email)
            emailErr = msg

        else {
            let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (!signupData.email.match(regex))
                emailErr = 'Invalid Email'
        }

        if (!signupData.password)
            passErr = msg

        if (!signupData.verifyPassword)
            verifyPassErr = msg

        if (!signupData.dob)
            dobErr = msg


        if (!captcha)
            captchaErr = msg


        setErrors({
            fullName: fullNameErr,
            email: emailErr,
            password: passErr,
            verifyPassword: verifyPassErr,
            dob: dobErr,
            captcha: captchaErr,
        })

        if (fullNameErr || emailErr || passErr || verifyPassErr || dobErr || captchaErr)
            return

        delete signupData['verifyPassword']
        document.getElementById('verifyPasswordInput').value = ''
        dispatch(inviteSignup(data))

    };


    return (
        isLoader ? <FullPageLoader /> :
            <>
                <section className='theme-form Login'>
                    <div className='form-overlay'></div>
                    <div className='biiview-form'>
                        {
                            details && details.status == 2 &&
                            <div>
                                Success {navigate('/login')}
                            </div>
                        }
                        {
                            details && details.status != 2 &&
                            <Container fluid className='formContainer'>
                                <div className='formLogo'>
                                    <Link to='/'> <img className='img-fluid' src={formLogo} alt='BiiView'></img> </Link>
                                </div>

                                <div className='wrapForm'>
                                    <Form className='formStyle'>
                                        <div className='formHeader'>
                                            <h2>Become Publisher</h2>
                                        </div>

                                        <Form.Group className='formGroup' controlId="formBasicEmail">
                                            <Form.Label>Full Name</Form.Label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter Your Name"
                                                value={signupData.fullName}
                                                onChange={(e) => setSignupData({ ...signupData, fullName: e.target.value })}
                                            >
                                            </input>
                                            {
                                                errors && errors.fullName &&
                                                <span className='submit-errors text-danger'>{errors.fullName}</span>
                                            }
                                        </Form.Group>

                                        <Form.Group className='formGroup' controlId="formBasicEmail">
                                            <Form.Label>Email Address</Form.Label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter Your Email Address"
                                                value={signupData.email}
                                                onChange={(e) => setSignupData({ ...signupData, email: e.target.value })}
                                                disabled={true}
                                            >
                                            </input>
                                            {
                                                errors && errors.email &&
                                                <span className='submit-errors text-danger'>{errors.email}</span>
                                            }
                                        </Form.Group>

                                        <Form.Group className='formGroup' controlId="formBasicEmail">
                                            <Form.Label>Password
                                                &nbsp;
                                                {
                                                    signupData.password ?
                                                        showPassword ?
                                                            <BsEye className="pointer" onClick={() => setShowPass(false)} /> :
                                                            <BsEyeSlash className="pointer" onClick={() => setShowPass(true)} />
                                                        : ''
                                                }
                                            </Form.Label>
                                            <input
                                                type={showPassword ? 'text' : "password"}
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Enter Your Password"
                                                value={signupData.password}
                                                onChange={(e) => setSignupData({ ...signupData, password: e.target.value })}
                                            >
                                            </input>
                                            {
                                                errors && errors.password &&
                                                <span className='submit-errors text-danger'>{errors.password}</span>
                                            }
                                        </Form.Group>

                                        <Form.Group className='formGroup' controlId="formBasicEmail">
                                            <Form.Label>Verify Password
                                                &nbsp;
                                                {
                                                    signupData.verifyPassword ?
                                                        showVerifyPassword ?
                                                            <BsEye className="pointer" onClick={() => setShowVerifyPass(false)} /> :
                                                            <BsEyeSlash className="pointer" onClick={() => setShowVerifyPass(true)} />
                                                        : ''
                                                }
                                            </Form.Label>
                                            <input
                                                type={showVerifyPassword ? 'text' : "password"}
                                                className="form-control"
                                                id="verifyPasswordInput"
                                                placeholder="Re-Enter Password"
                                                value={signupData.verifyPassword}
                                                onChange={(e) => setSignupData({ ...signupData, verifyPassword: e.target.value })}
                                            >
                                            </input>
                                            {
                                                errors && errors.verifyPassword &&
                                                <span className='submit-errors text-danger'>{errors.verifyPassword}</span>
                                            }
                                        </Form.Group>

                                        <Form.Group className='formGroup' controlId="formBasicEmail">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <div className='inputDiv calendar-block'>
                                                <FaCalendarDay />

                                                <input
                                                    type="date"
                                                    className="form-control theme-calendar"
                                                    placeholder="Select DOB"
                                                    value={signupData.dob}
                                                    onChange={(e) => setSignupData({ ...signupData, dob: e.target.value })}
                                                >
                                                </input>
                                            </div>
                                            {
                                                errors && errors.dob &&
                                                <span className='submit-errors text-danger'>{errors.dob}</span>
                                            }
                                        </Form.Group>

                                        <div className='theme-capcha'>
                                            <div className='captchaStyle'>
                                                <ReCAPTCHA
                                                    sitekey={recaptchaSiteKey}
                                                    onChange={(value) => setCaptcha(value)}
                                                />
                                            </div>
                                            {
                                                errors && errors.captcha &&
                                                <span style={{ fontSize: '12px' }} className='form-control text-danger'>{errors.captcha}</span>
                                            }
                                            <button type="button" className='orange-btn text-uppercase' onClick={signupHandler}>CONTINUE</button>

                                        </div>
                                    </Form>
                                </div>
                            </Container>
                        }
                    </div>
                </section>
            </>
    )
}
export default Invite