import React from 'react';
import { useState, useRef, useEffect } from "react";
import '../../Models/Models.css'
import { TiArrowSortedDown } from "react-icons/ti"
import { countries } from "countries-list";
import Select from 'react-select';


const BasicInfo = (props) => {

    const [options, setOptions] = useState();
    const [country, setCountry] = useState();

    const countriesList = Object.keys(countries).map((key) => countries[key].name)

    useEffect(() => {
        if (countriesList) {
            const items = countriesList.map(item => ({
                value: item,
                label: item
            }));

            setOptions(items)
        }
    }, [])


    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div>
                        <div className='general'>
                            <div className='modalSelect mb-2'>
                                {/* <select className="form-select" aria-label="Default select example" value={props.country} onChange={(e) => props.setData({ countryResidence: e.target.value })}>
                                    {
                                        countriesList && countriesList.length ?
                                            countriesList.map((c, ind) => {
                                                return (
                                                    <option key={ind} value={c}>{c}</option>

                                                )
                                            }) :
                                            <option value="">No country found</option>

                                    }
                                </select> */}
                                <div className="custom-react-select flex-fill">
                                    <Select
                                        options={options}
                                        value={options && options.length > 0 ? options.find(option => option.value === props.country) : null} // Setting the selected option based on category state
                                        onChange={(e) => props.setData({ countryResidence: e.value })} // Update category state with the selected option's value
                                        classNamePrefix="triage-select"
                                    />
                                </div>
                                {/* <span><TiArrowSortedDown /></span> */}
                            </div>
                            <p>Choose the country where you’re currently based.</p>
                        </div>
                        <div className='general mb-0'>
                            <div className='mb-2'>
                                <div className="innerTab-input-group">
                                    <input type="text" className="form-control" placeholder="#myChannel, #biiview" aria-label="Username" value={props.keywords} onChange={(e) => props.setData({ keywords: e.target.value })} />
                                </div>
                            </div>
                            {
                                props.errors && props.errors.keywords &&
                                <span className='submit-errors text-danger'>{props.errors.keywords}</span>
                            }
                            <p>Enter comma-separated values</p>
                            <p>Note: Each Keyword must start with a hashtag followed by alphabets.</p>
                        </div>
                        <div className='general mb-0'>
                            <div className='mb-2'>
                                <div className="innerTab-input-group">
                                    <textarea className="form-control" rows={3} placeholder = "Write a description about your channel..." name='aboutChannel' value={props.aboutChannel} onChange={(e) => props.setData({ aboutChannel: e.target.value })}></textarea>
                                </div>
                            </div>
                            {/* {
                                props.errors && props.errors.aboutChannel &&
                                <span className='submit-errors text-danger'>{props.errors.aboutChannel}</span>
                            } */}
                            {/* <p>Write a description about your channel.</p> */}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default BasicInfo