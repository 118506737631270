import React from 'react';
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams, } from 'react-router-dom';
import { beforeVlog, listVlogs } from './vlogs.action';
import { ENV } from './../../../config/config';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
// import './Vlogs.css'

const ExploreVlogs = (props) => {

    const navigate = useNavigate();
    const [loader, setLoader] = useState(true)
    const [pagination, setPagination] = useState(null)
    const [vlogsList, setVlogsList] = useState([])
    const [loadMoreBtn, setLoadMoreBtn] = useState(false)

    useEffect(() => {      
        listVlogs();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.vlogs.vlogsAuth) {

            setVlogsList(props.vlogs.vlogsRes)
            setPagination(props.vlogs.pagination)

            if (loader)
                setLoader(false)

            props.beforeVlog()

        }

    }, [props.vlogs.vlogsAuth])

    // handle show load more button state when pagination is set
    useEffect(() => {
        if (pagination)
            setLoadMoreBtn((vlogsList && pagination.total > 0 && pagination.total > vlogsList.length && vlogsList.length > 0))
    }, [pagination]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = () => {
        const { page } = pagination
        if (loader)
            setLoader(false)

        // get more vlogs
        listVlogs(page + 1)
    }

    const listVlogs = (page = 1, limit = 12) => {
        let vlogsQS = { page, limit }
        const qs = ENV.objectToQueryString(vlogsQS)

        props.listVlogs(qs)

    }

    return (
        <>
            {loader && <FullPageLoader />}
            <div className='vlogs-wrapper explore explore-sec '>             
                <div className="dashboardHeader">
                    <h3 className='Vlogs-heading'>Biiview Vlogs</h3>
                </div>
                <div className="vlogs-vedios other-videos ">
                    <div className="d-flex flex-wrap">
                        {vlogsList?.length > 0 ? (
                            vlogsList.map((vlog, index) => {
                                return (
                                    <div className="video-holder vlog-vedio-holder" key={index} onClick={() => navigate(`/vlog/${vlog._id}`)}>
                                        <div className="img-holder vlog-img-holder">
                                            <img src={vlog.thumbnail} alt={vlog.title} />
                                            <div className="play-icon-holder d-flex justify-content-center align-items-center">
                                                <FontAwesomeIcon icon={faCirclePlay} alt="" />
                                            </div>
                                        </div>
                                        <strong className="video-title">{vlog.title}</strong>  
                                    </div>
                                )
                            })
                        )
                        :
                            <div className="no-record-found d-flex justify-content-center align-items-center">No Record Found</div>
                        }
                    </div>
                </div>
                {
                    loadMoreBtn && 
                    <div className='text-center w-100 d-block'>
                        <button className="orange-btn load-more-btn text-uppercase mx-auto" onClick={() => loadMore()}>Load more</button>
                    </div>
                }
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    vlogs: state.vlogs,
});

export default connect(mapStateToProps, { beforeVlog, listVlogs })(ExploreVlogs);