import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FullPageLoader from '../FullPageLoader/FullPageLoader'
import { beforePosition, listOpenPositions } from './positions.actions'
import { getSiteSettings, beforeSiteSettings } from '../SiteSettings/siteSettings.actions'
import { Link } from 'react-router-dom'
import './positions.css'

const Positions = (props) => {
    const dispatch = useDispatch()

    const [loader, setLoader] = useState(true)
    const [openPositions, setPositions] = useState([])
    const [paginationData, setPagination] = useState({})
    const [siteSettings, setSettings] = useState({})
    const [loadMore, setLoadMore] = useState(false);

    const positionsSelector = useSelector((state) => state.positions)
    const settingsSelector = useSelector((state) => state.siteSettings)

    useEffect(() => {
        dispatch(getSiteSettings())
        dispatch(listOpenPositions())
    }, [])

    useEffect(() => {
        if (settingsSelector.getSiteSettingsAuth) {
            let { settings } = settingsSelector.getSiteSettingsData
            setSettings(settings)
            dispatch(beforeSiteSettings())
        }
    }, [settingsSelector.getSiteSettingsAuth])

    useEffect(() => {
        if (positionsSelector.listOpenPositionsAuth) {
            let { positions, pagination } = positionsSelector.listOpenPositions?.data

            setPositions(loadMore ? openPositions.concat(positions) : positions)
            setPagination(pagination)
            setLoadMore(false)
            setLoader(false)

            dispatch(beforePosition())
        }
    }, [positionsSelector.listOpenPositionsAuth])

    const loadMorePositions = () => {
        setLoadMore(true)
        setLoader(true)
        const { page } = paginationData
        dispatch(listOpenPositions(`page=${page + 1}`))
    }

    return (
        <>
            {loader && <FullPageLoader />}
            <section className='theme-form Login'>
                <div className='biiview-form packages-sec'>
                    <Container fluid className='formContainer'>
                        <div className='wrapForm'>
                            <div className='formStyle package'>
                                <div className='formHeader border-0 mb-0 d-flex align-items-center justify-content-between'>
                                    <h2 className='text-uppercase text-decoration-underline'>Positions</h2>
                                    <Link to='/about-us' className="button mb-0 link textBtn">back</Link>
                                </div>
                                <Row>
                                    {
                                        openPositions && openPositions.length ?
                                            openPositions.map((position, index) => {
                                                return (
                                                    <Col md={6} key={index} className='mb-3'>
                                                        <div className='package-card'>
                                                            <div>
                                                                <ul className='packageCardHeader'>
                                                                    <div>
                                                                        <h3 className='packageH3 font'>{position.title}</h3>
                                                                    </div>
                                                                </ul>
                                                                <ul className='package-ul'>
                                                                    {
                                                                        position.description ?
                                                                            <li><p>{position.description}</p></li> :
                                                                            null
                                                                    }
                                                                </ul>
                                                            </div>
                                                            <div>
                                                                <h4><a style={{ color: '#FD6F21' }} className="iconBtn themeEffect" href={`mailto:${siteSettings.email}`}>Apply here</a></h4>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                )
                                            }) :
                                            <p>There are currently no open positions.</p>
                                    }
                                </Row>

                            </div>
                        </div>
                    </Container>
                </div>
            </section>
            {
                paginationData?.total > openPositions?.length ?
                    <div className='text-center w-100 d-block'>
                        <button className="orange-btn text-uppercase mx-auto" onClick={loadMorePositions}>Load more</button>
                    </div>
                    :
                    null
            }
        </>
    )
}

export default Positions