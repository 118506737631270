import React from "react";
import { useState, useRef, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { ENV } from "../../../../../config/config";
import {
  beforeVideoContentUploaded,
  VideoUploadedSucess,
  getCategories,
  beforeVideo,
  createVideo,
  deleteVideo,
  updateVideoUploadStatus
} from "../video.actions";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import "../../Models/Models.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { FaTimes } from "react-icons/fa";
import Select from 'react-select';
const { allowedMediaTypes, defaultPlaceholderImage } = ENV;

const UploadVideo = (props) => {
  const navigate = useNavigate();
  const { _id } = ENV.getUserKeys();
  const [thumbnailImg, setThumbnailImg] = useState([]);
  const [description, setDescription] = useState(props.channelData.videoDescription);
  const [title, setTitle] = useState(props.channelData.videoTitle);
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState(props.channelData.videoCategory);
  const [submitted, setSubmitted] = useState(false);
  const [saveCheck, setCheck] = useState(false);
  const [data, setData] = useState({})
  const inputRef = useRef();
  const [errorMsgs, setErrorMsgs] = useState({
    flag: false,
    title: "",
    description: "",
    thumbnailImg: "",
    category: "",
  });
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    if (_id)
      props.getCategories();
  }, [])

  useEffect(() => {
    if (props.videoData && Object.keys(props?.videoData)?.length)
      setData({ ...props.videoData })
  }, [props.videoData])

  useEffect(() => {
    if (props.errors) {
      props.setLoader(false)
      setCheck(false)
    }
  }, [props.errors])

  const handleClose = () => {
    props.setShow(false);
    setErrorMsgs({
      flag: false,
      title: "",
      description: "",
      thumbnailImg: "",
      category: "",
    })
  }

  useEffect(() => {
    if (props.videos.videoUploadedAuth) {
      setSubmitted(false)
      setCheck(false)
      props.setLoader(false)
      props.beforeVideoContentUploaded()
      navigate("/my-videos");
    }
  }, [props.videos.videoUploadedAuth]);

  useEffect(() => {
    if (props.videos.videoCategoriesAuth) {
      const items = props.videos.videoCategories.categories.map(category => ({
        value: category._id,
        label: category.name
      }));
      setCategories(items);
      props.beforeVideo();
    }
  }, [props.videos.videoCategoriesAuth]);

  useEffect(() => {
    if (props.videos.createVideoAuth) {
      let { video } = props.videos.createVideoData
      props.beforeVideo()
      if (video) {
        let videoName = (video.video)?.split('videos/')[1]
        const formData = new FormData();
        formData.append('video', props.files, videoName)
        uploadFile(ENV.pythonVideoUploadingUrl, formData, video._id)
      }

    }
  }, [props.videos.createVideoAuth]);

  const AddImages = (e) => {
    e.preventDefault();

    const allowedImgTypes = allowedMediaTypes.images;
    let errordata = {};
    let fileExt = e.target.files[0].name.split('.').pop();

    if (allowedImgTypes.includes(fileExt)) {

      setErrorMsgs({ ...errorMsgs, thumbnailImg: "" });
      let images = thumbnailImg;
      images.push(e.target.files[0]);
      setThumbnailImg([...images]);

    } else {
      errordata.thumbnailImg = `Supported file types are : ${allowedImgTypes}`;
      setErrorMsgs(errordata);
    }
  };

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);

    setProgress(percent);
  };

  const uploadFile = (url, formData, videoId) => {
    url = `${ENV.pythonVideoUploadingUrl}store-video/`
    return axios.post(url, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress,
    })
      .then((res) => {
        if (res.data?.message) {
          handleClose()
          toast.success('Video uploaded successfully!');
          props.VideoUploadedSucess();
          props.updateVideoUploadStatus(videoId)
        }
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
          setCheck(false)
          props.setLoader(true)
          props.deleteVideo(videoId, false)
        }
      });
  };

  useEffect(() => {
    if (props.videos.deleteVideoAuth) {
      props.setLoader(false)
    }
  }, [props.videos.deleteVideoAuth]);

  async function SaveVideo(e) {

    setSubmitted(true)

    e.preventDefault();
    let tempFlag = true;

    if (!title || !description || !category || !thumbnailImg?.length)
      tempFlag = false;


    if (tempFlag) {
      setErrorMsgs({
        title: '',
        description: '',
        category: '',
        thumbnailImg: ''
      })

      const fileExt = props.files.name.split('.').pop();
      const formData = new FormData();
      const channelId = localStorage.getItem('channelId') || ''

      formData.append("uId", _id);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("categoryId", category);
      formData.append("publishType", 4);
      formData.append("channelId", channelId);
      formData.append("videoLengthInMins", data?.videoLengthInMins || '');
      formData.append("videoSizeInMbs", data?.videoSizeInMbs || '');
      formData.append("videoExt", fileExt);

      for (const image of thumbnailImg) {
        formData.append("thumbnails", image);
      }

      setCheck(true)
      props.createVideo(formData)

    } else {
      props.setLoader(false)
    }
  }

  const removeThumbnailHandler = (index) => {
    let thumbnails = [...thumbnailImg]

    thumbnails.splice(index, 1)

    setThumbnailImg(thumbnails)

  }

  useEffect(() => {
    if (submitted) {
      let err = {
        flag: false,
        title: '',
        description: '',
        category: '',
        thumbnailImg: ''
      }
      let msg = 'This field is required.'
      if (!title || !title.trim()) {
        err.title = msg
        err.flag = true
      }

      if (!description || !description.trim()) {
        err.description = msg
        err.flag = true
      }

      if (!category) {
        err.category = msg
        err.flag = true
      }

      if (!thumbnailImg.length) {
        err.thumbnailImg = "Atleat one thumbnail is required.";
        err.flag = true;
      }

      setErrorMsgs({ ...errorMsgs, ...err })

    }

  }, [submitted, title, description, category, thumbnailImg])
  
  return (
    <>
      <div className="modalPage">
        <div className="modal themeModal show">
          <Modal
            show={props.show}
            onHide={handleClose}
            className="narrowModel"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="mb-0">{props.files.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
              <div className="seletFile modalBox">
                <h4 className="modal-H4">Details</h4>
                <div className="fileGroup">
                  <div className="fileGroupList">
                    <p className="fileLabel">Title (required) <span className="text-danger">*</span></p>
                    <Form.Group>
                      <Form.Control
                        name="title"
                        value={title}
                        placeholder="Enter Title"
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                      ></Form.Control>
                      {errorMsgs.title ? (
                        <span className="text-danger submit-errors">{errorMsgs.title}</span>
                      ) : (
                        <span />
                      )}
                    </Form.Group>
                  </div>
                  {progress ? (
                    <>
                      <div> Uploading Video Status</div>
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-info progress-bar-striped"
                          role="progressbar"
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: progress + "%" }}
                        >
                          {progress}%
                        </div>
                      </div>
                    </>
                  )
                    : null
                  }
                  <div className="fileGroupList">
                    <p className="fileLabel">Description <span className="text-danger">*</span></p>
                    <Form.Group
                      className="w-100"
                      as="textarea"
                      rows={3}
                      name="description"
                      value={description}
                      placeholder="Tell us something about your video…."
                      onChange={(e) => setDescription(e.target.value)}
                    ></Form.Group>
                    {errorMsgs.description ? (
                      <span className="text-danger submit-errors">
                        {errorMsgs.description}
                      </span>
                    ) : (
                      <span />
                    )}
                  </div>
                  <div className="fileGroupList">
                    <p className="fileLabel">Category <span className="text-danger">*</span></p>
                    <Form.Group className="w-100 custom-form-select">
                      {/* <Form.Select
                        name="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value={""}>Select Category</option>
                        {categories && categories.map((category, index) => (
                          <option key={index} value={category._id}>
                            {category.name}
                          </option>
                        ))}
                      </Form.Select> */}
                      <div className="custom-react-select">
                        {categories &&
                          <Select
                            options={categories}
                            
                            value={categories && categories.length > 0 ? categories.find(option => option.value === category) : null} // Setting the selected option based on category state
                            onChange={(e) => setCategory(e.value)} // Update category state with the selected option's value
                            classNamePrefix="triage-select"
                          />
                        }
                      </div>
                    </Form.Group>
                    {errorMsgs.category ? (
                      <span className="text-danger submit-errors">
                        {errorMsgs.category}
                      </span>
                    ) : (
                      <span />
                    )}
                  </div>
                </div>
                <div className="thumbnail">
                  <h4 className="modal-H4">Thumbnail <span className="text-danger">*</span></h4>
                  <div className="thumbnailImg">
                    {thumbnailImg.length > 0 ? (
                      thumbnailImg.map((val, index) => {
                        let reader = new FileReader();
                        reader.onload = function (e) {
                          $(`#nft-${index}`).attr("src", e.target.result);
                        };
                        reader.readAsDataURL(val);
                        return (
                          <div className="position-relative">
                            <img
                              className="videoImg"
                              src={defaultPlaceholderImage}
                              id={`nft-${index}`}
                              alt="biiview"
                            ></img>
                            <div className="remove-img" onClick={() => removeThumbnailHandler(index)}><FaTimes /></div>
                          </div>
                        );
                      })
                    ) : (
                      <div></div>
                    )}
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={AddImages}
                      ref={inputRef}
                      hidden
                    >
                    </input>
                    {thumbnailImg && thumbnailImg.length < 1 ? (
                      <div
                        className="videoImg"
                        onClick={(e) => inputRef.current.click()}
                      >
                        <img
                          src={defaultPlaceholderImage}
                          className="videoImg"
                          alt="biiview"
                        ></img>

                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {errorMsgs.thumbnailImg ? (
                    <span className="text-danger submit-errors">
                      {errorMsgs.thumbnailImg}
                    </span>
                  ) : (
                    <span />
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="pb-0">
              <div>
                <Button
                  onClick={SaveVideo}
                  className="theme-btn orange-btn text-uppercase mx-auto mt-0"
                  disabled={saveCheck}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  videos: state.videos,
  errors: state.errors
});
export default connect(mapStateToProps, {
  beforeVideoContentUploaded,
  VideoUploadedSucess,
  getCategories,
  beforeVideo,
  createVideo,
  deleteVideo,
  updateVideoUploadStatus
})(UploadVideo);
