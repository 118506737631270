import { LIST_TEAM_MEMBERS, BEFORE_TEAM_MEMBER, LIST_ABOUT_STATS, BEFORE_ABOUT_STATS, } from '../../../redux/types';

const initialState = {

    // team
    listTeamMembersData: null,
    listTeamMembersAuth: false,

    aboutUsStats: null,
    aboutUsStatsAuth: false
}

export default function aboutUsReducer(state = initialState, action) {
    switch (action.type) {

        // team
        case LIST_TEAM_MEMBERS:
            return {
                ...state,
                listTeamMembersData: action.payload,
                listTeamMembersAuth: true
            }

        case BEFORE_TEAM_MEMBER:
            return {
                ...state,
                listTeamMembersData: null,
                listTeamMembersAuth: false,
            }

        //stats
        case LIST_ABOUT_STATS:
            return {
                ...state,
                aboutUsStats: action.payload,
                aboutUsStatsAuth: true
            }

        case BEFORE_ABOUT_STATS:
            return {
                ...state,
                aboutUsStats: null,
                aboutUsStatsAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}