// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// USERS
export const BEFORE_USER = 'BEFORE_USER';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_SIGNUP = 'USER_SIGNUP';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const BEFORE_CREATOR = 'BEFORE_CREATOR';
export const GET_CREATOR = 'GET_CREATOR';
export const GET_USER = 'GET_USER';
export const GET_PLAN_DETAILS = 'GET_PLAN_DETAILS';
export const BEFORE_USER_PLAN = 'BEFORE_USER_PLAN'
export const WATCH_HISTORY_VIDEOS = 'WATCH_HISTORY_VIDEOS'
export const BEFORE_WATCH_HISTORY = 'BEFORE_WATCH_HISTORY'

// EMAILS
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL = 'GET_EMAIL';
export const BEFORE_EMAIL = 'BEFORE_EMAIL';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const ADD_EMAIL = 'ADD_EMAIL';
export const DELETE_EMAIL = 'DELETE'

// SITE SETTINGS
export const GET_SITE_SETTINGS = 'GET_SITE_SETTINGS';
export const BEFORE_SITE_SETTINGS = 'BEFORE_SITE_SETTINGS';

// FAQS
export const LIST_FAQS = 'LIST_FAQS'
export const BEFORE_FAQ = 'BEFORE_FAQ'

// CONTACTS
export const BEFORE_CONTACT = 'BEFORE_CONTACT';
export const CREATE_CONTACT = 'CREATE_CONTACT';

//CONTENT MANAGEMENT
export const GET_CONTENT_PAGE = 'GET_CONTENT_PAGE'
export const GET_CONTENT_PAGES = 'GET_CONTENT_PAGES'
export const EDIT_CONTENT_PAGE = 'EDIT_CONTENT_PAGE'
export const DELETE_CONTENT_PAGE = 'DELETE_CONTENT_PAGE'
export const ADD_CONTENT_PAGE = 'ADD_CONTENT_PAGE'
export const BEFORE_CONTENT = 'BEFORE_CONTENT'

// NEWSLETTER 
export const LIST_NEWSLETTERS = 'LIST_NEWSLETTERS'
export const BEFORE_NEWSLETTERS = 'BEFORE_NEWSLETTERS'
export const SEND_NEWS_LETTER_EMAILS = 'SEND_NEWS_LETTER_EMAILS'

// FAQ_CAT
export const CREATE_FAQ_CAT = 'CREATE_FAQ_CAT'
export const BEFORE_FAQ_CAT = 'BEFORE_FAQ_CAT'
export const LIST_FAQ_CAT = 'LIST_FAQ_CAT'
export const GET_FAQ_CAT = 'GET_FAQ_CAT'
export const EDIT_FAQ_CAT = 'EDIT_FAQ_CAT'
export const DELETE_FAQ_CAT = 'DELETE_FAQ_CAT'

// PLANS
export const LIST_PLANS = 'LIST_PLANS'
export const GET_PLAN = 'GET_PLAN'
export const BEFORE_PLANS = 'BEFORE_PLANS'
export const SUBSCRIBE_PLAN = 'SUBSCRIBE_PLAN'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'

// VLOGS
export const BEFORE_VLOG = 'BEFORE_VLOG'
export const LIST_VLOGS = 'LIST_VLOGS'
export const GET_VLOGS = 'GET_VLOGS'

// BLOG CATEGORIES
export const LIST_BLOG_CATS = 'LIST_BLOG_CATS'
export const GET_BLOG_CAT = 'GET_BLOG_CAT'
export const BEFORE_BLOG_CAT = 'BEFORE_BLOG_CAT'


// BLOGS 
export const LIST_BLOGS = 'LIST_BLOGS'
export const GET_BLOG = 'GET_BLOG'
export const BEFORE_BLOG = 'BEFORE_BLOG'
export const LATEST_BLOGS = 'LATEST_BLOGS'
export const HIGHLIGHTED_BLOGS = 'HIGHLIGHTED_BLOGS'
export const FEATURED_BLOGS = 'FEATURED_BLOGS'
export const EXPLORE_BLOGS = 'EXPLORE_BLOGS'
export const TRENDING_BLOGS = 'TRENDING_BLOGS'
export const SEARCH_BLOGS = 'SEARCH_BLOGS'


// BLOG POSTS
export const LIST_POSTS = 'LIST_POSTS'
export const GET_POST = 'GET_POST'
export const BEFORE_BLOG_POST = 'BEFORE_BLOG_POST'


// ABOUT US

// TEAM MEMBERS
export const CREATE_TEAM_MEMBER = 'CREATE_TEAM_MEMBER'
export const LIST_TEAM_MEMBERS = 'LIST_TEAM_MEMBERS'
export const GET_TEAM_MEMBER = 'GET_TEAM_MEMBER'
export const BEFORE_TEAM_MEMBER = 'BEFORE_TEAM_MEMBER'

// STATS
export const LIST_ABOUT_STATS = 'LIST_ABOUT_STATS'
export const BEFORE_ABOUT_STATS = 'BEFORE_ABOUT_STATS'


// CHANNELS
export const LIST_CHANNELS = 'LIST_CHANNELS'
export const GET_CHANNEL = 'GET_CHANNEL'
export const GET_CHANNEL_DETAILS = 'GET_CHANNEL_DETAILS'
export const GET_CHANNEL_VIDEOS = 'GET_CHANNEL_VIDEOS'
export const UPDATE_CHANNEL_DETAILS = 'UPDATE_CHANNEL_DETAILS'
export const BEFORE_CHANNEL = 'BEFORE_CHANNEL'
export const SUBSCRIBE_UNSUBSCRIBE_CHANNEL = 'SUBSCRIBE_UNSUBSCRIBE_CHANNEL'
export const BEFORE_CHANNEL_SUBS = 'BEFORE_CHANNEL_SUBS'

// CHANNEL ROLES
export const LIST_CHANNEL_ROLES = 'LIST_CHANNEL_ROLES'
export const GET_CHANNEL_ROLE = 'GET_CHANNEL_ROLE'
export const BEFORE_CHANNEL_ROLE = 'BEFORE_CHANNEL_ROLE'

// VIDEOS
export const EDIT_VIDEO = 'EDIT_VIDEO'
export const BEFORE_VIDEO = 'BEFORE_VIDEO'
export const LIST_VIDEO = 'LIST_VIDEO'
export const CREATE_VIDEO = 'CREATE_VIDEO'
export const DELETE_VIDEO = 'DELETE_VIDEO'
export const BEFORE_VIDEO_CONTENT = 'BEFORE_VIDEO_CONTENT'
export const VIDEO_CONTENT_UPLOADED = 'VIDEO_CONTENT_UPLOADED'
export const BEFORE_VIDEO_CONTENT_UPLOADED = 'BEFORE_VIDEO_CONTENT_UPLOADED'
export const GET_VIDEO = 'GET_VIDEO'
export const PUBLIC_VIDEO = 'PUBLIC_VIDEO'
export const BEFORE_PUBLIC_VIDEO_CONTENT = 'BEFORE_PUBLIC_VIDEO_CONTENT'
export const VIDEO_CATEGORIES = 'VIDEO_CATEGORIES'
export const RECOMMENDED_VIDEOS = 'RECOMMENDED_VIDEOS'
export const BEFORE_RECOMMENDED_VIDEOS = 'BEFORE_RECOMMENDED_VIDEOS'
export const SEARCHED_VIDEOS = 'SEARCHED_VIDEOS'
export const BEFORE_SEARCHED_VIDEOS = 'BEFORE_SEARCHED_VIDEOS'
export const CHANGE_UPLOAD_STATUS = 'CHANGE_UPLOAD_STATUS'
export const EDIT_ANNOTATION = 'EDIT_ANNOTATION'
export const BEFORE_ANNOTATION_EDIT = 'BEFORE_ANNOTATION_EDIT'
export const ADD_BOUNDING_OBJECTS = 'ADD_BOUNDING_OBJECTS'
export const BEFORE_BOUNDING_OBJECTS = 'BEFORE_BOUNDING_OBJECTS'
export const GET_BOUNDING_OBJECTS = 'GET_BOUNDING_OBJECTS'
export const GET_TRENDING_VIDEOS = 'GET_TRENDING_VIDEOS'
export const ADD_REMOVE_LIKE_DISLIKE = 'ADD_REMOVE_LIKE_DISLIKE'
export const GET_TOP_VIDEOS_VIEWS = 'GET_TOP_VIDEOS_VIEWS'
// export const ADD_REMOVE_LIKE = 'ADD_REMOVE_LIKE'
// export const ADD_REMOVE_DISLIKE = 'ADD_REMOVE_DISLIKE'
export const BEFORE_LIKE_DISLIKE = 'BEFORE_LIKE_DISLIKE'

// export const CREATE_VIDEO_STAT = 'CREATE_VIDEO_STAT'
// export const BEFORE_STAT = 'BEFORE_STAT'

// TEST VIEDOS
export const BEFORE_METADATA = 'BEFORE_METADATA'
export const GET_VIDEO_METADATA = 'GET_VIDEO_METADATA'

//DASHBOARD
export const GET_TOP_VIDEOS = 'GET_TOP_VIDEOS'
export const DASHBOARD_ANALYTICS = 'DASHBOARD_ANALYTICS'
export const BEFORE_DASHBOARD = 'BEFORE_DASHBOARD'

// INVITE
export const INVITE = 'INVITE'
export const BEFORE_INVITE = 'BEFORE_INVITE'
export const INVITE_DETAILS = 'INVITE_DETAILS'

// Channel Users
export const GET_CHANNEL_USERS = 'GET_CHANNEL_USERS'
export const DELETE_CHANNEL_USER = 'DELETE_CHANNEL_USER'

// BILLING HISTORY
export const LIST_BILLING_HISTORY = 'LIST_BILLING_HISTORY'
export const GET_INVOICE = 'GET_INVOICE'
export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE'
export const BEFORE_BILLING_HISTORY = 'BEFORE_BILLING_HISTORY'

// OBJECTS
export const LIST_OBJECTS = 'LIST_OBJECTS'
export const GET_OBJECT = 'GET_OBJECT'
export const BEFORE_OBJECT = 'BEFORE_OBJECT'

// VIDEO STATS
export const VIDEO_STAT = 'VIDEO_STAT'
export const BEFORE_STAT = 'BEFORE_STAT'

// ANALYTICS
export const VIDEO_ANALYTICS = 'VIDEO_ANALYTICS'
export const BEFORE_VID_ANALYTICS = 'BEFORE_STAT'
export const CHANNEL_ANALYTICS = 'CHANNEL_ANALYTICS'
export const BEFORE_CHANNEL_ANALYTICS = 'BEFORE_CHANNEL_ANALYTICS'

// POSITIONS
export const LIST_POSITIONS = 'LIST_POSITIONS'
export const GET_POSITION = 'GET_POSITION'
export const BEFORE_POSITION = 'BEFORE_POSITION'

// NOTIFICATIONS
export const SET_SOCKET = 'SET_SOCKET'
export const BEFORE_SOCKET = 'BEFORE_SOCKET'
export const SET_COUNTER = 'SET_COUNTER'
export const BEFORE_COUNTER = 'BEFORE_COUNTER'
export const USER_NOTIFICATIONS = 'USER_NOTIFICATIONS'
export const BEFORE_NOTIFICATIONS = 'BEFORE_NOTIFICATIONS'
export const EDIT_NOTIFICATION_STATUS = 'EDIT_NOTIFICATION_STATUS'
export const BEFORE_NOTIFICATION_STATUS = 'BEFORE_NOTIFICATION_STATUS'

// INFORMATIVE
export const GET_INFO_PAGE = 'GET_INFO_PAGE'
export const LIST_INFO_PAGES = 'LIST_INFO_PAGES'
export const BEFORE_INFORMATIVE = 'BEFORE_INFORMATIVE'

// APPLICATION TOUR
export const EDIT_TOUR_STATUS = 'EDIT_TOUR_STATUS'
export const GET_TOUR_STATUS = 'GET_TOUR_STATUS'
export const BEFORE_TOUR = 'BEFORE_TOUR'

// SUBTITLES
export const LIST_SUBTITLES = 'LIST_SUBTITLES'
export const BEFORE_SUBTITLE = 'BEFORE_SUBTITLE'

// CONTACT ADMIN
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const GET_CHAT = 'GET_CHAT'
export const BEFORE_CHAT = 'BEFORE_CHAT'

// FAVOURITES
export const ADD_REMOVE_FAVOURITES = 'ADD_REMOVE_FAVOURITES'
export const LIST_FAVOURITES = 'LIST_FAVOURITES'
export const BEFORE_FAVOURITES = 'BEFORE_FAVOURITES'

// BLOG POST COMMENTS
export const UPSERT_COMMENT = 'UPSERT_COMMENT'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const LIST_POST_COMMENTS = 'LIST_POST_COMMENTS'
export const BEFORE_POST_COMMENTS = 'BEFORE_POST_COMMENTS'

// VIDEO COMMENTS
export const UPSERT_VIDEO_COMMENT = 'UPSERT_VIDEO_COMMENT'
export const DELETE_VIDEO_COMMENT = 'DELETE_VIDEO_COMMENT'
export const LIST_VIDEO_COMMENTS = 'LIST_VIDEO_COMMENTS'
export const BEFORE_VIDEO_COMMENTS = 'BEFORE_VIDEO_COMMENTS'




