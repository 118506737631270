import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, Dropdown } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import moment from 'moment';
import { ENV } from '../../../../config/config';
import { beforeUser, getUser } from '../../UserComponents/users.actions';
import { Link, useNavigate } from 'react-router-dom';
import { upsertVideoComment, beforeVideoComment, listVideoComments, deleteVideoComment } from '../Videos/video.actions';
import SmallLoader from '../../../../utils/SmallLoader/SmallLoader';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { AiOutlineEdit } from 'react-icons/ai';
import { RiDeleteBin4Line } from 'react-icons/ri';
import Swal from 'sweetalert2'

const VideoComments = ({ slug, listVideoCommentsRes, listVideoCommentsAuth, upsertVideoCommentAuth, upsertVideoCommentRes, deleteVideoCommentAuth }) => {

    const dispatch = useDispatch()

    const userId = ENV.getUserKeys('_id')?._id
    // const publisherId = ENV.getUserKeys('_id')?._id
    const [addComment, setAddComment] = useState(false)
    const [videoComments, setComments] = useState([])
    const [pagination, setPagination] = useState({})

    const [comment, setComment] = useState('')
    const [loader, setLoader] = useState(true)
    const [errors, setErrors] = useState({})
    const [user, setUser] = useState({})
    const [loadMore, setLoadMore] = useState(false);
    const [editedCommentId, setvideoId] = useState('');

    const userSelector = useSelector((state) => state.user)
    // const blogs = useSelector((state) => state.user)

    useEffect(() => {
        if (userId)
            dispatch(getUser(`userId=${userId}`))
    }, [])

    useEffect(() => {
        if (userSelector.getUserAuth) {
            let data = userSelector.getUserData?.data?.user
            setUser(data)
            dispatch(beforeUser())
        }
    }, [userSelector.getUserAuth])

    useEffect(() => {
        if (slug)
            dispatch(listVideoComments(`slug=${slug}`))
    }, [slug])

    useEffect(() => {
        if (listVideoCommentsAuth) {
            let { comments, pagination } = listVideoCommentsRes
            setComments(loadMore ? videoComments.concat(comments) : comments)
            setPagination(pagination)
            setLoader(false)
            setLoadMore(false)
            dispatch(beforeVideoComment())
        }
    }, [listVideoCommentsAuth])

    useEffect(() => {
        if (upsertVideoCommentAuth) {
            let { comments } = upsertVideoCommentRes
            setComments([...comments])
            dispatch(beforeVideoComment())
        }
    }, [upsertVideoCommentAuth])

    useEffect(() => {
        if (deleteVideoCommentAuth) {
            dispatch(listVideoComments(`slug=${slug}`))
            dispatch(beforeVideoComment())
        }
    }, [deleteVideoCommentAuth])


    const deleteCommentHandler = (commentId) => {
        Swal.fire({
            title: 'Delete Comment',
            html: "Are you sure you want to delete your comment?",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.value) {
                setLoader(true)
                dispatch(deleteVideoComment(commentId))
            }
        })
    }

    const submit = () => {
        if (comment) {

            setErrors({})
            setComment('')

            if (editedCommentId)
                dispatch(upsertVideoComment({ _id: editedCommentId, comment, type: 2 })) // edit
            else
                dispatch(upsertVideoComment({ slug, authorId: userId, comment, type: 1 })) // add

        }
        else {
            setErrors({ ...errors, comment: 'Please type a comment.' })
        }

    };

    const showMoreHandler = () => {
        setLoadMore(true)
        setLoader(true)

        const { page } = pagination
        dispatch(listVideoComments(`slug=${slug}&page=${page + 1}`))
    }

    const showLessHandler = () => {
        setLoader(true)
        setLoadMore(false)

        dispatch(listVideoComments(`slug=${slug}&page=${1}`))
    }


    return (
        // loader ? <SmallLoader /> :
        <>
            <Row className='d-flex align-items-center justify-content-start'>
                {
                    userId ?
                        <>
                            <Col lg={12}>
                                <div className='d-flex comment-box-wrapper'>
                                    <div className='userImg me-2'>
                                        <img src={user?.profileImage} className="img-fluid" alt={`${user?.fullName}`} />
                                    </div>
                                    {/* <p>{user?.fullName}</p> */}
                                    <div className='text-area-wrapper'>
                                        <Form.Control as="textarea" rows={3} placeholder="Add a Comment..." name='name' value={comment} onChange={(e) => setComment(e.target.value)} />
                                        {
                                            errors && errors.comment &&
                                            <span className='text-danger'>{errors.comment}</span>
                                        }
                                        <div className='submit-btn text-end'>
                                            <Button variant="" onClick={submit}>Post</Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </> :
                        <Col md={5}>
                            <p>
                                <Link variant="" className='iconBtn themeEffect' to={'/login'}>Login</Link> to Add Comment
                            </p>
                        </Col>
                }

            </Row>
            <Row>
                <Col lg={12}>
                    {
                        loader ? <SmallLoader height={30} width={30} /> :
                            videoComments?.length ?
                                videoComments.map((c) => {
                                    return (
                                        <div className='d-flex main-comment-wrapper'>

                                            <div className='userImg me-2'>
                                                <img src={c?.author?.profileImage} className="img-fluid" alt={`${c?.author?.name}`} />
                                            </div>

                                            <div className='user-comment-wrapper'>
                                                <div className='d-flex justify-content-between'>
                                                    <div className=''>
                                                        <span className='comment-user-name'>{c?.author?.name}</span>
                                                        <span className='comment-time'>{moment(c?.createdAt).fromNow()}</span>
                                                    </div>
                                                    <div >
                                                        {
                                                            c.author._id === userId &&
                                                            <Dropdown className="">
                                                                <Dropdown.Toggle
                                                                    className="iconBtn iconDD-Btn"
                                                                    id="-basic"
                                                                >
                                                                    <span>
                                                                        <BiDotsVerticalRounded />
                                                                    </span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {
                                                                        <Dropdown.Item
                                                                            onClick={() => {
                                                                                setvideoId(c._id)
                                                                                setComment(c.comment)
                                                                            }}
                                                                        >
                                                                            <span className="me-2">
                                                                                <AiOutlineEdit />
                                                                            </span>
                                                                            Edit
                                                                        </Dropdown.Item>
                                                                    }
                                                                    {
                                                                        <Dropdown.Item
                                                                            onClick={() => deleteCommentHandler(c._id)}
                                                                        >
                                                                            <span className="me-2">
                                                                                <RiDeleteBin4Line />
                                                                            </span>
                                                                            Delete
                                                                        </Dropdown.Item>
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        }
                                                    </div>
                                                </div>
                                                <span className='comment'>{c?.comment}</span>
                                            </div>


                                        </div>
                                    )
                                }) :
                                <span className='no-coments-yet'>There are no comments yet!</span>
                    }
                </Col>
                {console.log('stat', pagination.total, videoComments?.length)}
                {
                    videoComments?.length ?
                        pagination.total > videoComments?.length ?
                            // <Col>
                            <div className='w-100 d-block'>
                                <button className="show-btns text-uppercase" onClick={showMoreHandler}>Show More</button>
                            </div>
                            // </Col>
                            :
                            videoComments?.length >= pagination.total &&
                            <div className='w-100 d-block'>
                                <button className="show-btns text-uppercase" onClick={showLessHandler}>Show Less</button>
                            </div>
                        : null
                }

            </Row>
            {/* </section> */}
        </>
    )
}

export default VideoComments