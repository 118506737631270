import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ENV } from '../../../config/config';
import { useSelector, useDispatch } from "react-redux";
import { io } from 'socket.io-client';
import { BsFillBellFill } from 'react-icons/bs'
import { AiOutlineClose, AiOutlineArrowUp } from 'react-icons/ai'
import { Modal } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    getUserNotifications,
    setNotificationCounter,
    setSocketInstance,
    beforeNotificationsStatus,
    beforeUserNotifications,
    beforeCounter,
    editSeenStatus
} from './notifications.actions';
import moment from 'moment';
import SmallLoader from '../../../utils/SmallLoader/SmallLoader';
import { notificationsIcon } from '../ApplicationTour/TourCompIdentifiers';
const { events, backendBaseUrl, userPlaceholderImage } = ENV

const UserNotifications = (props) => {
    const dispatch = useDispatch()

    const [counter, setCounter] = useState()
    const [page, setPage] = useState(2) // next page for infinite scroll
    const [moreCheck, setMoreCheck] = useState(true)
    const [showSmallLoader, setSmallLoader] = useState(true)
    const [counterSeen, setCounterSeen] = useState(false)
    const [noticationsSeen, setNotSeen] = useState(false)
    const [openModal, setOpen] = useState(false)
    const [notificationsList, setList] = useState([])
    const [newNot, setNewNot] = useState(false)
    const userId = ENV.getUserKeys('_id')?._id
    const channelId = localStorage.getItem('channelId')

    const notificationsSelector = useSelector((state) => state.notifications)

    useEffect(() => {
        let socket = io(backendBaseUrl);
        socket.on('connect', function () {
            dispatch(setSocketInstance(socket))
            notificationsHandler(socket)
        });
        return () => socket.close();
    }, [])

    useEffect(() => {
        if (notificationsSelector.notificationsCounter != 0) {
            let notificationCounter = notificationsSelector.notificationsCounter
            notificationCounter = notificationCounter ? notificationCounter : null

            setCounter(notificationCounter)
            localStorage.setItem('notificationsCounter', notificationCounter || 0)

            setCounterSeen(false)
            setNotSeen(false)


        }
        else {
            setCounter(null)
        }

    }, [notificationsSelector.notificationsCounter])

    useEffect(() => {
        // if new notification is received while modal is opened
        if (openModal && newNot) {
            let filteredList = notificationsList.filter((n) => n.type === 0)
            if (!filteredList.length) {
                let list = [{ type: 0 }].concat(notificationsList)
                setList(list)
            }
            setNewNot(false)
        }
    }, [newNot])

    const notificationsHandler = (socket) => {

        if (!socket.connected) {
            socket = io(backendBaseUrl, { 'forceNew': true });
            socket.on('connect', function () {
                notificationsHandler(socket)
            });
        }

        let notificationsData = [...events.notifications]
        notificationsData.forEach((n) => {
            // video processing events specific to each user
            socket.on(`${n.name}-${userId}`, () => {
                onReceivingEventHandler()
            })

            // admin and user chat event specific to each channel
            socket.on(`${n.name}-${channelId}`, () => {
                onReceivingEventHandler()
            })
        })
    }

    const onReceivingEventHandler = () => {
        dispatch(setNotificationCounter())
        setNewNot(true)
    }

    const getNotifications = (filter) => {
        filter.channelId = channelId
        let qs = ENV.objectToQueryString(filter)
        dispatch(getUserNotifications(qs))
    }

    useEffect(() => {
        if (userId) {
            // fetch unread notifications
            getNotifications({ userId, hasSeen: 2, all: true })
        }
    }, [])

    useEffect(() => {
        if (openModal)
            setOpen(false)

    }, [window.location.pathname])


    useEffect(() => {
        // fetch notifications 
        if (openModal) {
            getNotifications({ userId })
        }

    }, [openModal])

    useEffect(() => {
        if (notificationsSelector.userNotificationsAuth) {
            const { userNotifications, total } = notificationsSelector.userNotifications

            // unread notifications
            if (total !== undefined) {
                setCounter(total ? total : null)
                localStorage.setItem('notificationsCounter', total)
                dispatch(setNotificationCounter(total))
            }
            if (userNotifications) {
                setSmallLoader(false)
                if (userNotifications.length) {
                    setList([...notificationsList, ...userNotifications])
                }
                else {
                    setMoreCheck(false)
                }
            }
            dispatch(beforeUserNotifications())
        }
    }, [notificationsSelector.userNotificationsAuth])

    useEffect(() => {
        if (notificationsList && notificationsList.length) {

            let nCounter = 0
            notificationsList.forEach((n) => {
                if (!n.hasSeen)
                    nCounter++
            })
            if (!counter && nCounter > 0)
                setCounter(nCounter)
        }
    }, [notificationsList])

    useEffect(() => {
        if (notificationsSelector.editNotificationsStatusAuth) {

            // reset states when notification has been seen
            setCounterSeen(true)
            setNotSeen(true)
            setCounter(null)
            localStorage.setItem('notificationsCounter', 0)

            dispatch(beforeCounter())
            dispatch(beforeNotificationsStatus())
        }
    }, [notificationsSelector.editNotificationsStatusAuth])

    const closeModal = () => {
        setOpen(false);
        setList([])
        setPage(2)
    }

    const fetchData = () => {
        getNotifications({ page, userId: ENV.getUserKeys()?._id })
        setPage(page + 1)
    }

    const loadNewNotifications = () => {
        setSmallLoader(true)
        setList([])
        getNotifications({ userId })
        setTimeout(() => dispatch(editSeenStatus(`userId=${userId}&channelId=${channelId}`)), 1500)
    }

    const getNotificationsText = (notification) => {
        if (notification.type === 1)
            return (
                <>
                    {`Your Video `}
                    <Link style={{ color: '#FD6F21' }} className='iconBtn themeEffect' to={`/media-player/${notification.video?.slug}/${notification.video?.channelName?.toLowerCase(/\W+/g, '-')}`}>{notification.video.title}</Link>
                    {` has been started processing`}

                </>
            )
        else if (notification.type === 2)
            return (
                <>
                    {`Your Video `}
                    <Link style={{ color: '#FD6F21' }} className='iconBtn themeEffect' to={`/media-player/${notification.video?.slug}/${notification.video?.channelName?.toLowerCase(/\W+/g, '-')}`}>{notification.video.title}</Link>
                    {` has been processed`}
                </>
            )
        else if (notification.type === 3)
            return (
                <>
                    {`Your Video `}
                    <Link style={{ color: '#FD6F21' }} className='iconBtn themeEffect' to={`/media-player/${notification.video?.slug}/${notification.video?.channelName?.toLowerCase(/\W+/g, '-')}`}>{notification.video.title}</Link>
                    {` could not be processed due to some fault. Please upload your video again.`}
                </>
            )
        else if (notification.type === 4)
            return (
                <>
                    {`Admin has sent you a `}
                    <Link style={{ color: '#FD6F21' }} className='iconBtn themeEffect' to={`/contact-admin`}>message</Link>
                </>
            )
        else
            return (
                <>
                    <AiOutlineArrowUp />
                    Show New Notifications
                </>
            )
    }

    return (
        <>
            <div id={notificationsIcon} className='position-relative header-user-btn d-flex justify-content-center align-items-center' onClick={() => { setOpen(true); setTimeout(() => dispatch(editSeenStatus(`userId=${userId}&channelId=${channelId}`)), 1500) }}>
                <BsFillBellFill />
                <span className='notification-counter' style={{ fontWeight: !counterSeen ? 'bold' : '' }} >{counter}</span>
            </div>
            {
                openModal &&
                <div className="modal-wrapper">
                    <Modal
                        centered
                        size="md"
                        show={openModal}
                        onHide={closeModal}
                        className='notification-modal'
                    >
                        <Modal.Header className="text-center  modal-title-wrapper">
                            <Modal.Title className='mb-0 d-flex justify-content-between w-100'>
                                NOTIFICATIONS
                                <AiOutlineClose className="pointer notification-btn-close" onClick={closeModal} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="row pt-2 pb-0">
                            <div className="container">
                                <div className="row">
                                    {
                                        <div className="col-12">
                                            {
                                                showSmallLoader ?
                                                    <SmallLoader />
                                                    :
                                                    <ul className='list-unstyled'>
                                                        <InfiniteScroll
                                                            dataLength={notificationsList.length}
                                                            next={fetchData}
                                                            hasMore={moreCheck}
                                                            loader={
                                                                <SmallLoader />
                                                            }
                                                            height={400} // must
                                                        >
                                                            {

                                                                notificationsList?.length > 0 ?

                                                                    notificationsList?.map((notification, idx) => {
                                                                        return (

                                                                            <li key={`notification-${idx}`}>
                                                                                <div className="my-2">
                                                                                    <div className='d-flex align-items-start'>
                                                                                        {
                                                                                            notification.type === 0 ?
                                                                                                <div className='col-12' onClick={loadNewNotifications}>
                                                                                                    <p className='mb-0 notification-text pointer'>
                                                                                                        {getNotificationsText({ type: 0 })}
                                                                                                    </p>
                                                                                                </div>
                                                                                                :
                                                                                                <>
                                                                                                    <div className='userImg me-2'>
                                                                                                        <img src={notification.video.thumbnail || userPlaceholderImage} className="img-fluid" alt={`${idx}`} />
                                                                                                    </div>
                                                                                                    <div className='rest-owner'>
                                                                                                        <div className='col-12'>
                                                                                                            <p className='mb-0 notification-text' style={{ fontWeight: noticationsSeen || notification.hasSeen ? '' : 'bold' }}>
                                                                                                                {getNotificationsText(notification)}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className='col-12'>
                                                                                                            <p className='mb-0 notification-date'>{moment(new Date(notification.createdAt)).format('DD/MM/YYYY HH:mm')}</p>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className='h-100 d-flex justify-content-center align-items-center'>There are no new notifications</li>
                                                            }

                                                        </InfiniteScroll>
                                                    </ul>

                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    )
}

export default UserNotifications
