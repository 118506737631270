import { USER_LOGIN, USER_SIGNUP, FORGOT_PASSWORD, RESET_PASSWORD, BEFORE_USER, BEFORE_USER_PLAN, GET_USER, GET_PLAN_DETAILS, UPDATE_PROFILE, GET_CREATOR, BEFORE_CREATOR, DELETE_ACCOUNT, LIST_FAVOURITES, ADD_REMOVE_FAVOURITES, BEFORE_FAVOURITES, WATCH_HISTORY_VIDEOS, BEFORE_WATCH_HISTORY } from '../../../redux/types';

const initialState = {
    userData: null,
    userAuth: false,

    userSignupData: null,
    userSignupAuth: false,

    forgotPasswordData: null,
    forgotPasswordAuth: false,

    resetPasswordData: null,
    resetPasswordAuth: false,

    getUserData: null,
    getUserAuth: false,

    getChannelCreatorData: null,
    getChannelCreatorAuth: false,

    updateProfileData: null,
    updateProfileAuth: false,

    userPlanDetailsData: null,
    userPlanDetailsAuth: false,

    listUserFavourites: null,
    listUserFavouritesAuth: false,

    addRemoveFavourite: null,
    addRemoveFavouriteAuth: false,

    deleteAccountAuth: false,

    userWatchHistoryVideos: null,
    userWatchHistoryVideosAuth: false
}

export default function userRed(state = initialState, action) {
    switch (action.type) {
        case USER_LOGIN:
            return {
                ...state,
                userData: action.payload,
                userAuth: true
            }
        case USER_SIGNUP:
            return {
                ...state,
                userSignupData: action.payload,
                userSignupAuth: true
            }

        case FORGOT_PASSWORD:
            return {
                ...state,
                forgotPasswordData: action.payload,
                forgotPasswordAuth: true
            }

        case RESET_PASSWORD:
            return {
                ...state,
                resetPasswordData: action.payload,
                resetPasswordAuth: true
            }
        case GET_USER:
            return {
                ...state,
                getUserData: action.payload,
                getUserAuth: true
            }
        case GET_CREATOR:
            return {
                ...state,
                getChannelCreatorData: action.payload,
                getChannelCreatorAuth: true
            }
        case GET_PLAN_DETAILS:
            return {
                ...state,
                userPlanDetailsData: action.payload,
                userPlanDetailsAuth: true
            }
        case UPDATE_PROFILE:
            return {
                ...state,
                updateProfileData: action.payload,
                updateProfileAuth: true
            }
        case DELETE_ACCOUNT:
            return {
                ...state,
                deleteAccountAuth: true
            }
        case WATCH_HISTORY_VIDEOS:
            return {
                ...state,
                userWatchHistoryVideos: action.payload,
                userWatchHistoryVideosAuth: true
            }
        case BEFORE_USER:
            return {
                ...state,
                userData: null,
                userAuth: false,

                userSignupData: null,
                userSignupAuth: false,

                forgotPasswordData: null,
                forgotPasswordAuth: false,

                resetPasswordData: null,
                resetPasswordAuth: false,

                getUserData: null,
                getUserAuth: false,

                updateProfileData: null,
                updateProfileAuth: false,

                userPlanDetailsData: null,
                userPlanDetailsAuth: false,

                deleteAccountAuth: false
            }
        case BEFORE_CREATOR:
            return {
                ...state,
                getChannelCreatorData: null,
                getChannelCreatorAuth: false
            }
        case BEFORE_USER_PLAN:
            return {
                ...state,
                userPlanDetailsData: null,
                userPlanDetailsAuth: false
            }
        case LIST_FAVOURITES:
            return {
                ...state,
                listUserFavourites: action.payload,
                listUserFavouritesAuth: true
            }
        case ADD_REMOVE_FAVOURITES:
            return {
                addRemoveFavourite: action.payload,
                addRemoveFavouriteAuth: true
            }
        case BEFORE_FAVOURITES:
            return {
                listUserFavourites: null,
                listUserFavouritesAuth: false,

                addRemoveFavourite: null,
                addRemoveFavouriteAuth: false
            }
        case BEFORE_WATCH_HISTORY:
            return {
                ...state,
                userWatchHistoryVideos: null,
                userWatchHistoryVideosAuth: false
            }
        default:
            return {
                ...state
            }
    }
}