import React, { useEffect, useState } from 'react'

const IndividualCard = (props) => {

    const [plan, setPlan] = useState({})

    useEffect(() => {
        if (props.plan)
            setPlan(props.plan)

    }, [props.plan])


    return (
        <div className='individual-plans'>
            <h2>{plan.name}</h2>
            <ul className='individual-plans-ul ps-2'>
                {
                    plan.videoLengthMins ?
                        <li>Can upload a short-length video upto {plan.videoLengthMins} minutes</li> :
                        null
                }
                {
                    plan.numberOfVideos ?
                        <li>Can upload upto {plan.numberOfVideos} videos</li> :
                        null
                }
                {
                    plan.videoSizeMbs ?
                        <li>Max File size of the video can be {plan.videoSizeMbs} Mbs
                        </li> :
                        null
                }
                {
                    plan.videoSizeMbs ?
                        <li>Object's annotation can be changed
                        </li> :
                        null
                }
                {/* {
                    plan.objectDetectionLevel ?
                        <li>{plan.objectDetectionLevel === 1 ?
                            'Basic' : plan.objectDetectionLevel === 2 ? 'Normal'
                                : plan.objectDetectionLevel === 3 ? 'Corporate' : ''}
                            Level Object Detection</li> :
                        null
                }
                {
                    plan.accuracyRate ?
                        <li>{plan.accuracyRate}% Accuracy Rate</li> :
                        null
                }
                {
                    plan.linksPerVideo ?
                        <li>Short Links Creation</li> :
                        null
                }
                {
                    plan.linksPerVideo ?
                        <li>{plan.linksPerVideo} Links per Video</li> :
                        null
                }
                {
                    plan.updateLinksCount ?
                        <li>Can change the product links upto {plan.updateLinksCount}
                        </li> :
                        null
                } */}
            </ul>
        </div>
    )
}

export default IndividualCard