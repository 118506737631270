import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { beforeTeam, listTeamMembers } from './aboutUs.actions'

const Team = () => {

  const dispatch = useDispatch()

  const [team, setTeam] = useState([])

  const teamSelector = useSelector((state) => state.aboutUs)

  useEffect(() => {
    dispatch(listTeamMembers())
  }, [])

  useEffect(() => {
    if (teamSelector.listTeamMembersAuth) {
      let { teamMembers } = teamSelector.listTeamMembersData.data
      setTeam(teamMembers)
      dispatch(beforeTeam())
    }
  }, [teamSelector.listTeamMembersAuth])

  return (
    <>
      <section className='team-sec'>
        <Container className='customBox'>
          <h1>Meet our <span className='theme d-block'>Amazing Team</span></h1>
          <div className='teams row d-flex flex-wrap'>
            {
              team && team.length ?
                team.map((member, idx) => {
                  return (
                    <div key = {idx} className='team px-2'>
                      <div className='img'>
                        <img src={member.profileImage} className="img-fluid w-100 h-100" alt='member' />
                      </div>
                      <strong className='team-name mb-2'>{member.name}</strong>
                      <p className='team-designation mb-0'>{member.designation}</p>
                    </div>
                  )
                }) :
                <div className="px-3">
                  <div className="no-record-found d-flex justify-content-center align-items-center">No Team Members Found</div>
                </div>
            }
          </div>
        </Container>
      </section>
    </>
  )
}

export default Team