import { GET_TOUR_STATUS, EDIT_TOUR_STATUS, BEFORE_TOUR } from '../../../redux/types';

const initialState = {

    userAppTourDetails: null,
    userAppTourDetailsAuth: false,

    editUserTourDetails: null,
    editUserTourDetailsAuth: false

}

export const appTourReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_TOUR_STATUS:
            return {
                ...state,
                userAppTourDetails: action.payload,
                userAppTourDetailsAuth: true
            }
        case EDIT_TOUR_STATUS:
            return {
                ...state,
                editUserTourDetails: action.payload,
                editUserTourDetailsAuth: true,
            }
        case BEFORE_TOUR:
            return {
                userAppTourDetails: null,
                userAppTourDetailsAuth: false,

                editUserTourDetails: null,
                editUserTourDetailsAuth: false
            }
        default:
            return {
                ...state
            }
    }
}