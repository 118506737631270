import { toast } from 'react-toastify';
import { emptyError } from '../../../redux/shared/error/error.action';
import { LIST_CHANNELS, BEFORE_CHANNEL, GET_ERRORS, CHANNEL_ANALYTICS, BEFORE_CHANNEL_ANALYTICS, UPDATE_CHANNEL_DETAILS, GET_CHANNEL, GET_CHANNEL_VIDEOS, GET_CHANNEL_DETAILS, SUBSCRIBE_UNSUBSCRIBE_CHANNEL, BEFORE_CHANNEL_SUBS } from '../../../redux/types';
import { ENV } from '../../../config/config';

export const beforeChannels = () => {
  return {
    type: BEFORE_CHANNEL
  }
}

export const listChannels = (qs = '') => dispatch => {
  toast.dismiss();
  dispatch(emptyError());

  const accessToken = ENV.getUserKeys('accessToken')?.accessToken
  let url = `${ENV.url}channels/list`;

  if (qs)
    url = `${url}?${qs}`

  fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
      'x-access-token': accessToken || ''
    },
  }).then(res => res.json()).then(data => {
    if (data.success) {
      dispatch({
        type: LIST_CHANNELS,
        payload: data
      })
    } else {
      toast.error(data.message, {
        toastId: "channels-error"
      })
      dispatch({
        type: GET_ERRORS,
        payload: data
      })
    }
  }).catch(error => {
    dispatch({
      type: GET_ERRORS,
      payload: error
    })
  })
};

// creating different actions to get channel details to avoid conflicts 
export const getChannel = (qs = '') => dispatch => {
  toast.dismiss();
  dispatch(emptyError());

  let url = `${ENV.url}channels/get`;
  if (qs)
    url = `${url}?${qs}`

  fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
    },
  }).then(res => res.json()).then(data => {
    if (data.success) {
      dispatch({
        type: GET_CHANNEL,
        payload: data
      })
    } else {
      toast.error(data.message, {
        toastId: "get-channel-error"
      })
      dispatch({
        type: GET_ERRORS,
        payload: data
      })
    }
  }).catch(error => {
    dispatch({
      type: GET_ERRORS,
      payload: error
    })
  })
};

export const getChannelDetails = (qs = '') => dispatch => {
  toast.dismiss();
  dispatch(emptyError());

  let url = `${ENV.url}channels/get`;
  if (qs)
    url = `${url}?${qs}`

  fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
    },
  }).then(res => res.json()).then(data => {
    if (data.success) {
      dispatch({
        type: GET_CHANNEL_DETAILS,
        payload: data
      })
    } else {
      toast.error(data.message, {
        toastId: "get-channel-error"
      })
      dispatch({
        type: GET_ERRORS,
        payload: data
      })
    }
  }).catch(error => {
    dispatch({
      type: GET_ERRORS,
      payload: error
    })
  })
};

export const getChannelVideos = (qs = '') => dispatch => {
  toast.dismiss();
  dispatch(emptyError());

  let url = `${ENV.url}channels/get/videos`;
  if (qs)
    url = `${url}?${qs}`

  fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token,
    },
  }).then(res => res.json()).then(data => {
    if (data.success) {
      dispatch({
        type: GET_CHANNEL_VIDEOS,
        payload: data
      })
    } else {
      toast.error(data.message, {
        toastId: GET_CHANNEL_VIDEOS
      })
      dispatch({
        type: GET_ERRORS,
        payload: data
      })
    }
  }).catch(error => {
    dispatch({
      type: GET_ERRORS,
      payload: error
    })
  })
};

export const updateChannelDetails = (body) => dispatch => {
  dispatch(emptyError());

  let url = `${ENV.url}channels/settings`
  fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    },
    body
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        toast.success(data.message, { toastId: 'settings-updated' })
        dispatch({
          type: UPDATE_CHANNEL_DETAILS,
          payload: data,
        });
      }
      else {
        toast.error(data.message);
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

// Channel Analytics 
export const getChannelAnalytics = (qs = '') => dispatch => {
  dispatch(emptyError());

  let url = `${ENV.url}channels/analytics`
  if (qs)
    url = `${url}?${qs}`

  fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: CHANNEL_ANALYTICS,
          payload: data,
        });
      }
      else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

export const beforeChannelAnalytics = () => {
  return {
    type: BEFORE_CHANNEL_ANALYTICS
  }
}

// subscribe - unsubscribe channel
export const subscribeUnsubscribeChannel = (body) => dispatch => {
  dispatch(emptyError());

  let url = `${ENV.url}/channel/subscribers/add-remove`

  fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Authorization': ENV.Authorization,
      'x-auth-token': ENV.x_auth_token
    },
    body: JSON.stringify(body)
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        toast.success(data.message, { toastId: SUBSCRIBE_UNSUBSCRIBE_CHANNEL })
        dispatch({
          type: SUBSCRIBE_UNSUBSCRIBE_CHANNEL,
          payload: data,
        });
      }
      else {
        toast.error(data.message, { toastId: SUBSCRIBE_UNSUBSCRIBE_CHANNEL })
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
    });
};

export const beforeChannelSubscription = () => {
  return {
    type: BEFORE_CHANNEL_SUBS
  }
}


