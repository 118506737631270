import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listAboutStats, beforeAboutStats } from './aboutUs.actions'

const Reviews = () => {


    const dispatch = useDispatch()
    const aboutSelector = useSelector((state) => state.aboutUs)

    const [stats, setStats] = useState({})

    useEffect(() => {
        dispatch(listAboutStats())
    }, [])

    useEffect(() => {
        if (aboutSelector.aboutUsStatsAuth) {
            let { data } = aboutSelector.aboutUsStats
            setStats(data)
            dispatch(beforeAboutStats())
        }
    }, [aboutSelector.aboutUsStatsAuth])

    return (
        <section className='reviews'>
            <Container className='customBox'>
                <Row>
                    <Col lg={4} sm={6} className='mb-3 mb-lg-0'>
                        <div className='reviews-wrapper'>
                            <div className='icon mb-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97">
                                    <g id="happy_clients" data-name="happy clients" transform="translate(-136 -137)">
                                        <g id="happy_clients-2" data-name="happy clients" transform="translate(-57.297 -48.734)">
                                            <path id="Path_13137" data-name="Path 13137" d="M228.391,246.372c-.733,2.255-1.466,4.285-2.142,6.314s-1.3,3.834-1.917,5.694a4.454,4.454,0,0,1-.733,1.466,3.5,3.5,0,0,1-4.454,1.24,3.552,3.552,0,0,1-2.03-4.285c.62-2.819,1.3-5.581,2.03-8.344s1.24-4.9,1.748-7.385a23.56,23.56,0,0,0,.169-4.454c-.338-.113-.451.113-.564.226l-3.777,3.214a2.9,2.9,0,0,1-4,.169,3.039,3.039,0,0,1,.056-4.341L217,231.545c1.691-1.748,3.439-3.5,5.074-5.3a4.285,4.285,0,0,1,3.5-1.409h4.4c.451.056.677-.113.9-.564,1.409-3.552,2.932-7.047,4.4-10.543a2.087,2.087,0,0,1,.282-.62c.789-1.691,2.2-2.311,3.721-1.691a2.816,2.816,0,0,1,1.635,3.665c-.451,1.691-1.3,4-1.917,6.032s-1.3,4.567-2.2,6.709a8.492,8.492,0,0,0-.677,4.454c0,1.522.113,3.946,0,5.92a43.544,43.544,0,0,0,.846,7.555c.677,3.721,1.3,7.386,1.917,11.106.451,2.593-.846,4.4-3.27,4.679a3.27,3.27,0,0,1-3.326-1.691,7.217,7.217,0,0,1-.789-1.917c-.9-3.665-1.861-7.273-2.819-10.937Z" transform="translate(0 -1.301)" fill="#a64c1d" />
                                            <path id="Path_13138" data-name="Path 13138" d="M286.407,235.417c-.507,3.1.282,5.92,1.015,8.739l2.706,11.106a4.849,4.849,0,0,1,0,2.537,3.383,3.383,0,0,1-2.875,2.537A3.439,3.439,0,0,1,283.7,258.7a8.283,8.283,0,0,1-.789-1.579l-3.721-10.994c-.056-.225-.056-.451-.282-.564s-.226.507-.282.789l-2.875,10.881a6.089,6.089,0,0,1-.733,1.635,3.608,3.608,0,0,1-6.6-1.86,24.788,24.788,0,0,1,.62-4.51c.677-4.172,1.409-8.344,2.142-12.516a3.491,3.491,0,0,0,.056-.733V228.877a2.593,2.593,0,0,0-.282-1.128,12.569,12.569,0,0,1-.9-2.424c-1.184-3.608-2.312-7.216-3.439-10.825a4.4,4.4,0,0,1-.226-1.691,2.424,2.424,0,0,1,1.635-2.424,2.537,2.537,0,0,1,2.932.451,4.905,4.905,0,0,1,1.071,1.579l4.454,10.825c.226.395.395.62.9.564h4.679a4.792,4.792,0,0,1,3.5,1.748c1.635,1.8,3.326,3.5,5.018,5.187s2.65,2.706,3.946,4.116a2.875,2.875,0,0,1,.846,3.214,2.764,2.764,0,0,1-4.228,1.353,33.2,33.2,0,0,1-3.439-2.875Z" transform="translate(-23.795 -0.832)" fill="#a64c1d" />
                                            <path id="Path_13139" data-name="Path 13139" d="M231,209.2a6.54,6.54,0,1,1-4.681,1.915A6.6,6.6,0,0,1,231,209.2Z" transform="translate(-5.481 -0.436)" fill="#a64c1d" />
                                            <path id="Path_13140" data-name="Path 13140" d="M288.74,221.28a6.483,6.483,0,1,1,4.641-1.9A6.54,6.54,0,0,1,288.74,221.28Z" transform="translate(-30.694)" fill="#a64c1d" />
                                        </g>
                                        <g id="Ellipse_23" data-name="Ellipse 23" transform="translate(136 137)" fill="none" stroke="#fff" stroke-width="1" stroke-dasharray="3">
                                            <circle cx="48.5" cy="48.5" r="48.5" stroke="none" />
                                            <circle cx="48.5" cy="48.5" r="48" fill="none" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>Happy Clients</p>
                            <h1 className='mb-0'>{stats?.totalUsers || 0}</h1>
                        </div>
                    </Col>
                    <Col lg={4} sm={6} className='mb-3 mb-lg-0'>
                        <div className='reviews-wrapper'>
                            <div className='icon mb-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97">
                                    <g id="video_processed" data-name="video processed" transform="translate(-721 -137)">
                                        <path id="video_processed-2" data-name="video processed" d="M92.466,127.024l11.582-3.861v19.3l-11.582-3.861V144.4a1.931,1.931,0,0,1-1.93,1.93H51.93A1.931,1.931,0,0,1,50,144.4V121.233a1.931,1.931,0,0,1,1.93-1.93H90.536a1.931,1.931,0,0,1,1.93,1.93ZM57.721,138.605v3.861H80.884v-3.861ZM69.3,109.651a9.651,9.651,0,1,1,9.651,9.651A9.655,9.655,0,0,1,69.3,109.651Zm3.861,0a5.791,5.791,0,1,0,5.791-5.8A5.793,5.793,0,0,0,73.163,109.651Zm-19.3,1.93a7.725,7.725,0,1,1,7.729,7.729A7.726,7.726,0,0,1,53.861,111.582Zm3.861,0a3.861,3.861,0,1,0,3.861-3.861A3.862,3.862,0,0,0,57.721,111.582Z" transform="translate(696.476 61.337)" fill="#a44b1d" />
                                        <g id="Ellipse_24" data-name="Ellipse 24" transform="translate(721 137)" fill="none" stroke="#fff" stroke-width="1" stroke-dasharray="3">
                                            <circle cx="48.5" cy="48.5" r="48.5" stroke="none" />
                                            <circle cx="48.5" cy="48.5" r="48" fill="none" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>Videos Processed</p>
                            <h1 className='mb-0'>{stats?.processedVideos || 0}</h1>
                        </div>
                    </Col>
                    <Col lg={4} sm={6}>
                        <div className='reviews-wrapper'>
                            <div className='icon mb-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97">
                                    <g id="object_recognized" data-name="object recognized" transform="translate(-1323 -138)">
                                        <g id="object_recognized-2" data-name="object recognized" transform="translate(1185.999 -17.258)">
                                            <path id="Path_13141" data-name="Path 13141" d="M314.09,185.457c0,1.253,0,2.53.025,3.807,0,.565-.2,1.007-.786,1.007s-.761-.467-.737-1.032c.025-2.137-.025-4.25.025-6.387.025-.786-.246-.983-.983-.958-1.793.049-3.611.025-5.4.025-.565,0-1.13-.049-1.13-.761s.565-.761,1.154-.761c2.162.025,4.323.049,6.485,0,1.032-.025,1.4.368,1.376,1.376s-.025,2.456-.025,3.685Z" transform="translate(-103.315 -0.148)" fill="#a44b1d" />
                                            <path id="Path_13142" data-name="Path 13142" d="M160.281,185.015V181.33c0-.761.344-1.13,1.105-1.13,2.309.025,4.593,0,6.878,0,.54,0,1.032.1,1.032.737s-.565.761-1.105.761c-1.719,0-3.439.074-5.158-.025-1.032-.049-1.228.319-1.2,1.253.074,1.867.025,3.758.025,5.625,0,.639.025,1.376-.835,1.326s-.737-.712-.737-1.3Z" transform="translate(-0.057)" fill="#a44b1d" />
                                            <path id="Path_13143" data-name="Path 13143" d="M314.088,321.237c0,1.155-.025,2.309,0,3.439s-.295,1.376-1.277,1.351H306.2c-.614,0-1.13-.123-1.105-.835s.54-.688,1.081-.663c1.842,0,3.66-.025,5.5,0,.663.025.86-.2.835-.835-.025-2.039,0-4.078,0-6.116,0-.614.025-1.277.786-1.277s.786.663.786,1.277Z" transform="translate(-103.313 -98.713)" fill="#a44b1d" />
                                            <path id="Path_13144" data-name="Path 13144" d="M160.225,320.261c0-1.253,0-2.53-.025-3.783,0-.565.147-1.056.737-1.081s.786.491.786,1.105c-.025,2.088.025,4.176-.025,6.239-.025.884.27,1.13,1.105,1.081,1.744-.049,3.513,0,5.281-.025.54,0,1.13-.025,1.13.737s-.54.786-1.13.786c-2.211-.025-4.421-.025-6.608,0-.884,0-1.277-.344-1.253-1.253S160.225,321.538,160.225,320.261Z" transform="translate(0 -98.032)" fill="#a44b1d" />
                                            <path id="Path_13145" data-name="Path 13145" d="M215.887,207.222a11.315,11.315,0,0,0-2.063-.824,35.235,35.235,0,0,0-15.23-.962,17.565,17.565,0,0,0-6.46,1.951A4.078,4.078,0,0,0,189.9,211.4c.074,7.86.049,15.747.049,23.607,0,3.435.491,4.26,3.365,5.469a10.191,10.191,0,0,0,1.523.55,36.322,36.322,0,0,0,15.942.687,15.14,15.14,0,0,0,5.822-2.006,3.787,3.787,0,0,0,1.842-3.545c-.074-4.122-.025-8.245-.025-12.367h-.025c0-4.04-.1-8.08.049-12.092A4.276,4.276,0,0,0,215.887,207.222Zm.1,4.315a14.362,14.362,0,0,1-5.7,1.951,44.57,44.57,0,0,1-6.166.55,31.055,31.055,0,0,1-9.973-1.566,6.653,6.653,0,0,1-1.99-1.017c-1.007-.8-1.032-1.457.025-2.2a12.4,12.4,0,0,1,5.085-1.869,34.049,34.049,0,0,1,16.286.66,7.853,7.853,0,0,1,2.456,1.154C217.14,210,217.14,210.767,215.985,211.537Z" transform="translate(-19.681 -18.732)" fill="#a44b1d" />
                                        </g>
                                        <g id="Ellipse_25" data-name="Ellipse 25" transform="translate(1323 138)" fill="none" stroke="#fff" stroke-width="1" stroke-dasharray="3">
                                            <circle cx="48.5" cy="48.5" r="48.5" stroke="none" />
                                            <circle cx="48.5" cy="48.5" r="48" fill="none" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>Objects Recognized</p>
                            <h1 className='mb-0'>{stats?.recognizedObjects || 0}</h1>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Reviews