import { INVITE, INVITE_DETAILS, BEFORE_INVITE, GET_CHANNEL_USERS, DELETE_CHANNEL_USER } from '../../../redux/types';

const initialState = {
    inviteData: null,
    inviteAuth: false,

    inviteDetailsData : null,
    inviteDetailsAuth: false,

    channelUsers : null,
    channelUsersAuth: false,

    deleteChannelUserAuth: false,    
}

export default function invitesReducer(state = initialState, action) {
    switch (action.type) {
        case INVITE:
            return {
                ...state,
                inviteData: action.payload,
                inviteAuth: true
            }
        case INVITE_DETAILS:
            return {
                ...state,
                inviteDetailsData: action.payload,
                inviteDetailsAuth: true
            }
        case GET_CHANNEL_USERS:
            return {
                ...state,
                channelUsers: action.payload,
                channelUsersAuth: true
            }
        case DELETE_CHANNEL_USER:
            return {
                ...state,
                deleteChannelUserAuth: true
            }
        case BEFORE_INVITE:
            return {
                inviteData: null,
                inviteAuth: false,
                inviteDetailsData : null,
                inviteDetailsAuth: false,
                channelUsers : null,
                channelUsersAuth: false,
                deleteChannelUserAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}