import React from 'react'
import CommunityHeader from '../CommunityHeader/CommunityHeader'
import CommunityQuestions from './CommunityQuestions/CommunityQuestions'
import CommunityPosts from '../CommunityPosts/CommunityPosts'

function CommunityQuestionsDetails() {
	return (
		<div className='community-wrapper'>
			<CommunityHeader />
			<CommunityQuestions />
			<CommunityPosts />
		</div>
	)
}

export default CommunityQuestionsDetails