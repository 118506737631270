import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { listInfoPages, beforeInformative } from './informative.actions'
import { useSelector, useDispatch } from 'react-redux'
import FullPageLoader from '../FullPageLoader/FullPageLoader'
import { Link } from 'react-router-dom'
import './informative.css'

const Informative = () => {

    const [infoData, setInfoData] = useState([])
    const [loader, setLoader] = useState(true)

    const dispatch = useDispatch()
    const informativeSelector = useSelector((state) => state.informative)

    useEffect(() => {
        dispatch(listInfoPages())
    }, [])

    useEffect(() => {
        if (informativeSelector.informativeAuth) {
            let data = informativeSelector.informativeData.cms
            setLoader(false)
            setInfoData(data)
            dispatch(beforeInformative())
        }

    }, [informativeSelector.informativeAuth])

    return (
        loader ? <FullPageLoader /> :
            <>
                <section className='help-sec faq-sec informative-sec works'>
                    <Container className='position-relative'>
                        {/* <div className="bar"></div> */}
                        <h1><span className='theme'>Informative Pages</span></h1>
                        <div className='informative-wrapper'>
                            <div className='informative-links'>
                                <ul className='informative-ul'>
                                    {
                                        infoData && infoData?.length ?
                                            infoData?.map((infoPage, key) => (
                                                <li key={key}>
                                                    <Link to={`/informative/${infoPage.slug}`}>{infoPage.title}</Link>
                                                </li>
                                            )) :
                                            <li>There are no informative pages yet</li>
                                    }
                              
                                </ul>
                            </div>
                        </div>
                    </Container>
                </section>
            </>
    )
}

export default Informative