import { activeChannelButton, activePlanSection, addObjectsIcon, analyticsSelect, audienceTab, becomeMemberSection, cancelSubButton, channelAnalyticsLink, channelSettingsNav, contactAdmin, contentTab, dashboardCategory, deleteVideosCheck, downloadInvoiceIcon, editAnnIcon, editVideoIcon, engagementTab, exploreVideosLink, exploreVideosSearch, exploreVideosSelect, favouriteVideosSection, generalSettingsNav, myChannelLink, myChannelsSection, myVideosLink, notificationsIcon, originalVideoIcon, otherVideoOptions, overviewTab, packagesLink, paymentHistoryLink, permissionsSettingsNav, previousPlansSection, processedVideoIcon, profileLink, profileMenu, reachTab, realtimeSelect, recommendedVideosSection, researchTab, selectChannelButton, selectPlanSection, settingsLink, socialSettingsNav, summarySection, topVideosSection, updatePasswordSection, uploadDefaultSettingsNav, uploadVideoButton, uploadVideoDashboard, userDetailsSection, vidAnalyticsAudience, vidAnalyticsOverview, vidAnalyticsSelect, videoAnalyticsIcon, vidIdentifiedObjTab, vidRealtimeSelect, viewBillingLink, viewInvoiceIcon, visibilityOptions, watchHistoryFilterSection, watchHistorySection } from "./TourCompIdentifiers"

export const getPageTourSteps = (key) => {
    const prop = { disableBeacon: true }
    switch (key) {
        case 'memberDashboardTour':
            return [
                {
                    content: <><h6>Welcome to Biiview!</h6><br /><p>Let's take a tour together</p></>,
                    target: 'body',
                    placement: 'center',
                    ...prop
                },
                {
                    content: 'Upload Videos from your dashboard',
                    target: `#${uploadVideoDashboard}`,
                    ...prop
                },
                {
                    content: 'Manage your uploaded videos from here',
                    target: `#${myVideosLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: "Explore other's videos",
                    target: `#${exploreVideosLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: "Keep track of your channel's progress through these stats",
                    target: `#${channelAnalyticsLink}`,
                    ...prop,
                    placement: 'right',
                },
                // {
                //     content: "Manage subtitles for videos",
                //     target: `#${subtitlesLink}`,
                //     ...prop,
                //     placement: 'right',
                // },
                {
                    content: "Manage your channel's and videos settings",
                    target: `#${settingsLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: "Keep track of your plans and billing histories",
                    target: `#${paymentHistoryLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: "Switch to your other channels",
                    target: `#${myChannelLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: "Explore all available membership plans",
                    target: `#${packagesLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: "Set up your profile",
                    target: `#${profileLink}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: "Chat with Admin",
                    target: `#${contactAdmin}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: "View your channel's summary for specified duration",
                    target: `#${summarySection}`,
                    ...prop,
                },
                {
                    content: "View some of the top Videos of your channel",
                    target: `#${topVideosSection}`,
                    ...prop,
                },
                {
                    content: "Receive important updates through notifications",
                    target: `#${notificationsIcon}`,
                    ...prop,
                },
                {
                    content: "A menu for easy access to some of the features.",
                    target: `#${profileMenu}`,
                    ...prop,
                },


            ]
        case 'userDashboardTour':
            return [
                {
                    content: <><h6>Welcome to Biiview!</h6><br /><p>Let's take a tour together</p></>,
                    target: 'body',
                    placement: 'center',
                    ...prop
                },
                {
                    content: 'Explore Videos shared by people from all over the world!',
                    target: `body`,
                    placement: 'center',
                    ...prop
                },
                {
                    content: 'Select a video category and browse its related videos',
                    target: `#${dashboardCategory}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: "Search videos by their title, description or tags (if any)",
                    target: `#${exploreVideosSearch}`,
                    ...prop,
                    placement: 'right',
                },
                {
                    content: "A menu for easy access to some of the features.",
                    target: `#${profileMenu}`,
                    ...prop,
                },
                {
                    content: "You can also become a publisher of BiiView community by subscribing any plan of your choice!",
                    target: `#${becomeMemberSection}`,
                    ...prop,
                    placement: 'right',
                },

            ]
        case 'myVideosTour':
            return [
                {
                    content: 'All the videos uploaded by you and your channel members are displayed here.',
                    target: 'body',
                    ...prop
                },
                {
                    content: 'Upload video into your channel',
                    target: `#${uploadVideoButton}`,
                    ...prop
                },
                {
                    content: 'You can delete single/multiple videos',
                    target: `#${deleteVideosCheck}`,
                    ...prop
                },
                {
                    content: 'Edit your video details',
                    target: `#${editVideoIcon}`,
                    ...prop
                },
                {
                    content: "View your video's statistics",
                    target: `#${videoAnalyticsIcon}`,
                    ...prop
                },
                {
                    content: "Check out your processed video",
                    target: `#${processedVideoIcon}`,
                    ...prop
                },
                {
                    content: 'Your original uploaded Video',
                    target: `#${originalVideoIcon}`,
                    ...prop
                },
                {
                    content: 'You can edit marked objects in your video',
                    target: `#${editAnnIcon}`,
                    ...prop
                },
                {
                    content: 'You can mark new objects in your video',
                    target: `#${addObjectsIcon}`,
                    ...prop
                },
                {
                    content: "Edit/Delete specific video, add subtiles and share your video with others",
                    target: `#${otherVideoOptions}`,
                    ...prop
                },
                {
                    content: 'You can filter out videos in your channel based on their visibility',
                    target: `#${visibilityOptions}`,
                    ...prop
                }
            ]
        case 'exploreVideosTour':
            return [
                {
                    content: "Explore videos shared by other people!",
                    target: 'body',
                    placement: 'center',
                    ...prop
                },
                {
                    content: "Search videos by their categories",
                    target: `#${exploreVideosSelect}`,
                    ...prop
                },
                {
                    content: "Search videos by their title, description or tags (if any)",
                    target: `#${exploreVideosSearch}`,
                    ...prop
                },

            ]
        case 'channelAnalyticsTour':
            return [
                {
                    content: "Check your channel's viewership stats",
                    target: `#${overviewTab}`,
                    ...prop
                },
                {
                    content: "Check your channel's reach",
                    target: `#${contentTab}`,
                    ...prop
                },
                {
                    content: "See your audience",
                    target: `#${audienceTab}`,
                    ...prop
                },
                {
                    content: "Search other videos",
                    target: `#${researchTab}`,
                    ...prop
                },
                {
                    content: "Get Analytics for a specific duration",
                    target: `#${analyticsSelect}`,
                    ...prop
                },
                {
                    content: "Get Realtime stats",
                    target: `#${realtimeSelect}`,
                    ...prop
                }
            ]
        case 'subtitlesTour':
            return [

            ]
        case 'settingsTour':
            return [
                {
                    content: "Update your channel's details",
                    target: `#${channelSettingsNav}`,
                    placement: "right",
                    ...prop
                },
                {
                    content: "Manage default settings for every uploaded video",
                    target: `#${uploadDefaultSettingsNav}`,
                    placement: "right",
                    ...prop
                },
                {
                    content: "Add/Remove members from your channel",
                    target: `#${permissionsSettingsNav}`,
                    placement: "right",
                    ...prop
                },
                {
                    content: "Update your channel's name & display",
                    target: `#${generalSettingsNav}`,
                    placement: "right",
                    ...prop
                },
                {
                    content: "Link your channel's social account",
                    target: `#${socialSettingsNav}`,
                    placement: "right",
                    ...prop
                },
            ]
        case 'paymentHistoryTour':
            return [
                // {
                //     content: "View your Plans and Billing Histories",
                //     target: `body`,
                //     placement: "center",
                //     ...prop
                // },
                {
                    content: "You can check your Active Plan Details",
                    target: `#${activePlanSection}`,
                    ...prop
                },
                {
                    content: "Cancel your active subscription at any time",
                    target: `#${cancelSubButton}`,
                    ...prop
                },
                {
                    content: "View and download your paid invoices",
                    target: `#${viewBillingLink}`,
                    ...prop
                },
                {
                    content: "Keep track of your previously subscribed plans",
                    target: `#${previousPlansSection}`,
                    ...prop
                },
            ]
        case 'billingInvoicesTour':
            return [
                {
                    content: "View invoice of your subscription",
                    target: `#${viewInvoiceIcon}`,
                    ...prop
                },
                {
                    content: "Download invoice as pdf",
                    target: `#${downloadInvoiceIcon}`,
                    ...prop
                },
            ]
        case 'myChannelsTour':
            return [
                {
                    content: "View all the channels you are part of.",
                    target: `#${myChannelsSection}`,
                    placement: 'auto',
                    ...prop
                },
                {
                    content: "Switch to any of the channel by selecting",
                    target: `#${selectChannelButton}`,
                    ...prop
                },
                {
                    content: "Your currently active channel",
                    target: `#${activeChannelButton}`,
                    ...prop
                },
            ]
        case 'packagesTour':
            return [
                {
                    content: "Subscribe to any Free or Paid (Monthly / Annual) plan of your choice",
                    target: `#${selectPlanSection}`,
                    placement: 'right',
                    ...prop
                }
            ]
        case 'profileTour':
            return [
                {
                    content: "Update your general information",
                    target: `#${userDetailsSection}`,
                    ...prop
                },
                {
                    content: "Update your account's password",
                    target: `#${updatePasswordSection}`,
                    ...prop
                }
            ]
        case 'videoAnalyticsTour':
            return [
                {
                    content: "An overview of your video's viewership",
                    target: `#${vidAnalyticsOverview}`,
                    ...prop
                },
                {
                    content: "Check your video's reach",
                    target: `#${reachTab}`,
                    ...prop
                },
                {
                    content: "Take a look at how much viewers are engaged in your video",
                    target: `#${engagementTab}`,
                    ...prop
                },
                {
                    content: "See your audience",
                    target: `#${vidAnalyticsAudience}`,
                    ...prop
                },
                {
                    content: "View all the identified objects from your video",
                    target: `#${vidIdentifiedObjTab}`,
                    ...prop
                },
                {
                    content: "Get Analytics for a specific duration",
                    target: `#${vidAnalyticsSelect}`,
                    ...prop
                },
                {
                    content: "Get Realtime stats",
                    target: `#${vidRealtimeSelect}`,
                    ...prop
                }
            ]
        case 'positionsTour':
            return [

            ]
        case 'watchHistoryTour':
            return [
                {
                    content: "Watch your previously watched videos again from here!",
                    target: `#${watchHistorySection}`,
                    ...prop
                },
                {
                    content: "Filter out videos for specific time and date.",
                    target: `#${watchHistoryFilterSection}`,
                    ...prop
                },
            ]
        case 'processedVideoTour':
            return [
                {
                    content: <p>Hover over the video to see the identified objects!</p>,
                    target: `body`,
                    ...prop
                },
                {
                    content: <p>Click on each object to view/edit its details.</p>,
                    target: `body`,
                    ...prop
                },
                {
                    content: "Watch similar videos",
                    target: `#${recommendedVideosSection}`,
                    ...prop
                },
            ]
        case 'favouritesTour':
            return [
                {
                    content: "Watch all your favourite videos from here.",
                    target: `#${favouriteVideosSection}`,
                    ...prop
                },
            ]
        case 'channelDetailsTour':
            return [
                {
                    content: "All the necessary details you want to know about channel",
                    target: `#${favouriteVideosSection}`,
                    ...prop
                },
                {
                    content: "Subscribe the channel to get important updates",
                    target: `#${favouriteVideosSection}`,
                    ...prop
                },
                {
                    content: "You can view the channel videos",
                    target: `#${favouriteVideosSection}`,
                    ...prop
                },
            ]
        default:
            return []
    }

} 