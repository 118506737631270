import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { Modal, Form } from 'react-bootstrap'
import moment from 'moment';
import { ENV } from '../../config/config';


const CustomSearchModal = ({ date, setDate, startTime, endTime, setStartTime, setEndTime, setCustom, setSelected, selectedTab, setLoader, setModal, customModal, durationList }) => {

    const [customDateErr, setDateErr] = useState({})

    const customSearch = () => {
        let errs = {}
        if (!date.startDate)
            errs.startDate = 'This field is required'

        if (!date.endDate)
            errs.endDate = 'This field is required'

        if (date.startDate && date.endDate) {
            if (moment(new Date(date.startDate)).isAfter(date.endDate))
                errs.startDate = 'Invalid Date!'

        }
        setDateErr({ ...errs })

        if (errs.startDate || errs.endDate)
            return

        setCustom(true)
        setModal(false)
        if (selectedTab !== 3) {
            setLoader(true)
        }
    }

    const onCloseHandler = () => {
        setCustom(false)
        setModal(false)
        setSelected(durationList[0])
    }


    return (
        <div className='modalPage'>
            <div className="modal show" >
                <Modal show={customModal} onHide={onCloseHandler} className="narrowModel" centered>
                    <Modal.Header closeButton>
                        <Modal.Title className='mb-0'>Select Date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='py-lg-4 py-3'>
                        <>
                            <div className='d-flex'>
                                <Form.Group className='formGroup me-3 w-75' controlId="formBasicEmail">
                                    <Form.Label><div className='d-flex align-items-center'>Start Date<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                    <div className='inputDiv'>
                                        <DatePicker
                                            className="form-control"
                                            placeholder="Start Date"
                                            selected={date.startDate ? new Date(date.startDate) : ''}
                                            onChange={(d) => setDate({ ...date, startDate: moment(new Date(d)).format('MMM DD, YYYY') })}
                                            dateFormat="dd-MM-yyyy"
                                            maxDate={new Date()}
                                            minDate={new Date(moment(new Date()).subtract(6, 'months').format('MMM DD, YYYY'))}
                                        />
                                    </div>
                                    {
                                        customDateErr && customDateErr?.startDate &&
                                        <span className='submit-errors text-danger'>{customDateErr.startDate}</span>
                                    }
                                </Form.Group>
                                <Form.Group className='formGroup me-3 w-75' controlId="formBasicEmail">
                                    <Form.Label><div className='d-flex align-items-center'>End Date<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                    <div className='inputDiv'>
                                        <DatePicker
                                            className="form-control"
                                            placeholder="End Date"
                                            selected={date.endDate ? new Date(date.endDate) : ''}
                                            onChange={(d) => setDate({ ...date, endDate: moment(new Date(d)).format('MMM DD, YYYY') })}
                                            dateFormat="dd-MM-yyyy"
                                            maxDate={new Date()}
                                            minDate={new Date(moment(new Date()).subtract(1, 'years').format('MMM DD, YYYY'))}
                                        />
                                    </div>
                                    {
                                        customDateErr && customDateErr?.endDate &&
                                        <span className='submit-errors text-danger'>{customDateErr.endDate}</span>
                                    }
                                </Form.Group>
                            </div>
                            <div className='d-flex'>
                                <Form.Group className='formGroup me-3 w-75' controlId="formBasicEmail">
                                    <Form.Label><div className='d-flex align-items-center'>Start Time<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                    <TimePicker onKeyDown={(e) => ENV.integerNumberValidator(e)} clearIcon={false} onChange={setStartTime} value={startTime} locale="sv-sv" format='H:mm' disableClock={true} />
                                </Form.Group>
                                <Form.Group className='formGroup me-3 w-75' controlId="formBasicEmail">
                                    <Form.Label><div className='d-flex align-items-center'>End Time<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                    <TimePicker onKeyDown={(e) => ENV.integerNumberValidator(e)} clearIcon={false} onChange={setEndTime} value={endTime} locale="sv-sv" format='H:mm' disableClock={true} />
                                </Form.Group>
                            </div>
                        </>
                    </Modal.Body>
                    <Modal.Footer className='pb-0'>
                        <div className='d-flex align-items-center'>
                            <button className="text-uppercase me-lg-3 me-2 mt-0 black-btn" onClick={customSearch}>{selectedTab !== 3 ? 'search' : 'set'}</button>
                            <button className="grey-btn text-uppercase me-lg-3 me-2 mt-0" onClick={onCloseHandler}>close</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}


export default CustomSearchModal