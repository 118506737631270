import { SEND_MESSAGE, BEFORE_CHAT, GET_CHAT } from '../../../redux/types';

const initialState = {
    getChatData: null,
    getChatAuth: false
}

export default function contactAdminRed(state = initialState, action) {
    switch (action.type) {

        // case SEND_MESSAGE:
        //     return {
        //         ...state,
        //         createContactData: action.payload,
        //         createContactAuth: true
        //     }

        case GET_CHAT:
            return {
                ...state,
                getChatData: action.payload,
                getChatAuth: true
            }

        case BEFORE_CHAT:
            return {
                ...state,
                getChatData: null,
                getChatAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}