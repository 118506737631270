import { toast } from 'react-toastify';
import { emptyError } from '../../../redux/shared/error/error.action';
import { LIST_BILLING_HISTORY, GET_INVOICE, DOWNLOAD_INVOICE, BEFORE_BILLING_HISTORY, GET_ERRORS } from '../../../redux/types';
import { ENV } from '../../../config/config';

export const beforeBillingHistory = () => {
    return {
        type: BEFORE_BILLING_HISTORY
    }
}

export const getInvoice = (id) => dispatch => {
    toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}payment-history/invoice/${id}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_INVOICE,
                payload: data
            })
        } else {
            toast.error(data.message, {
                toastId: "get-invoice-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const downloadInvoice = (id) => dispatch => {
    toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}payment-history/invoice/${id}?downloadInvoice=${true}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: DOWNLOAD_INVOICE,
                payload: data
            })
        } else {
            toast.error(data.message, {
                toastId: "get-invoice-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const listBillingHistory = (qs = '') => dispatch => {
    toast.dismiss();
    dispatch(emptyError());
    let url = `${ENV.url}payment-history/list`;
    const accessToken = ENV.getUserKeys('accessToken')?.accessToken

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: LIST_BILLING_HISTORY,
                payload: data
            })
        } else {
            toast.error(data.message, {
                toastId: "billing-error"
            })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
