import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import DatePicker from "react-datepicker";
import { getPlan, beforePlans, subscribePlan } from '../package.actions';
import { useSelector, useDispatch } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import '../../Forms/Forms.css'
import PackageCard from '../Package/PackageCard';
import { ENV } from '../../../../config/config';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { validateName } from '../../../../utils/Validations/validations';
import './CardDetails.css'

const CardDetails = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { state } = useLocation();
    const planId = state?.planId
    const planTenure = state?.planTenure

    const userId = ENV.getUserKeys('_id')?._id
    const [paymentData, setData] = useState({
        fullName: '',
        cardNumber: '',
        cardCvc: '',
        cardExpiry: ''
    })

    const [paymentErr, setErr] = useState({
        fullName: '',
        cardNumber: '',
        cardCvc: '',
        cardExpiry: '',
    })
    const [loader, setLoader] = useState(true)
    const [submitted, setSubmitted] = useState(false)
    const [plan, setPlan] = useState({})

    const plansSelector = useSelector((state) => state.packages)
    const errorSelector = useSelector((state) => state.error)

    useEffect(() => {
        if (errorSelector)
            setLoader(false)
    }, [errorSelector])

    useEffect(() => {
        if (planId && userId) {
            dispatch(getPlan(planId))
        }
        else {
            navigate('/packages')
        }

    }, [])

    useEffect(() => {
        if (plansSelector.getPlanAuth) {
            setLoader(false)
            setPlan(plansSelector.getPlanData?.plan)
            dispatch(beforePlans())
        }
    }, [plansSelector.getPlanAuth])

    useEffect(() => {
        if (plansSelector.subscribePlanAuth) {
            let { subscribedPlan } = plansSelector.subscribePlan
            setLoader(false)
            navigate(`/payment-successful?invoice=${subscribedPlan.id}`)
            dispatch(beforePlans())
        }
    }, [plansSelector.subscribePlanAuth])


    const onChangeHandler = (e) => {
        let errors = { ...paymentErr }
        let { name, value } = e.target
        if (submitted) {
            if (!value)
                errors = { ...errors, [name]: 'This field is required!' }
            else
                errors = { ...errors, [name]: '' }

        }

        setErr({ ...paymentErr, ...errors })

        if (name === 'cardNumber' || name === 'cardCvc')
            value = value.replace(/[^0-9]/g, '');

        if (name === 'cardNumber')
            value = value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();

        if (name === 'cardNumber' && value.length > 16)
            value = value.substr(0, 19);

        if (name === 'cardCvc' && value.length > 4)
            value = value.substr(0, 4);

        setData({ ...paymentData, [name]: value })
    }

    const dateChangeHandler = (date) => {
        let dateErr = ''
        if (submitted) {
            if (date)
                dateErr = ''
            else
                dateErr = 'This field is required'
        }

        setErr({ ...paymentErr, cardExpiry: dateErr })
        setData({ ...paymentData, cardExpiry: date })
    }

    const subscribe = () => {
        setSubmitted(true)

        let errors = {}
        let msg = 'This field is required.'

        if (!paymentData.fullName)
            errors = { ...errors, fullName: msg }
        else {
            paymentData.fullName = paymentData.fullName.replace(/\s+/g, " ").trim()
            let err = validateName(paymentData.fullName)
            if (err)
                errors = { ...errors, fullName: err }
        }

        if (!paymentData.cardNumber)
            errors = { ...errors, cardNumber: msg }

        if (!paymentData.cardCvc)
            errors = { ...errors, cardCvc: msg }

        if (!paymentData.cardExpiry)
            errors = { ...errors, cardExpiry: msg }

        if (errors.fullName || errors.cardNumber || errors.cardCvc || errors.cardExpiry) {
            setErr({ ...paymentErr, ...errors })
            return
        }

        setLoader(true)
        dispatch(subscribePlan({ ...paymentData, planId, planTenure }))
    }

    return (
        <>
            {loader && <FullPageLoader />}
            {
                plan &&
                <section className='theme-form Login card-details-sec'>
                    <div className='biiview-form'>
                        <Container fluid className='formContainer'>
                            <Row className='justify-content-center custom-card-width'>
                                <Col md={6}>
                                    <div className='h-100'>
                                        <Form className='card-form h-100'>
                                            <div className='formHeader'> <h4>Card Details</h4> </div>
                                            <Form.Group className='formGroup' controlId="formBasicEmail">
                                                <Form.Label><div className='d-flex align-items-center'>Name On Card<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                                <input type="text" className="form-control" name="fullName" value={paymentData.fullName} onChange={onChangeHandler} placeholder="John Smith"></input>
                                            </Form.Group>
                                            {
                                                paymentErr && paymentErr.fullName &&
                                                <span className='submit-errors text-danger'>{paymentErr.fullName}</span>
                                            }
                                            <Form.Group className='formGroup' controlId="formBasicEmail">
                                                <Form.Label><div className='d-flex align-items-center'>Card Number<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                                <input type="text" className="form-control" name="cardNumber" value={paymentData.cardNumber} onChange={onChangeHandler} placeholder="0000 0000 0000 0000"></input>
                                            </Form.Group>
                                            {
                                                paymentErr && paymentErr.cardNumber &&
                                                <span className='submit-errors text-danger'>{paymentErr.cardNumber}</span>
                                            }
                                            <div className='d-flex date-cvc'>
                                                <Form.Group className='formGroup me-3 w-100' controlId="formBasicEmail">
                                                    <Form.Label><div className='d-flex align-items-center'>Expiry Date<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                                    <div className='inputDiv mb-2'>
                                                        <DatePicker
                                                            className="form-control"
                                                            placeholderText="Expiry Date (MM/YY)"
                                                            selected={paymentData.cardExpiry}
                                                            onChange={dateChangeHandler}
                                                            showMonthYearPicker
                                                            dateFormat="MM/yyyy"
                                                            minDate={new Date()}
                                                        />
                                                    </div>
                                                    {
                                                        paymentErr && paymentErr.cardExpiry &&
                                                        <span className='submit-errors text-danger'>{paymentErr.cardExpiry}</span>
                                                    }
                                                </Form.Group>
                                                <Form.Group className='formGroup' controlId="formBasicEmail">
                                                    <Form.Label><div className='d-flex align-items-center'>CVC<span className="text-danger danger-asterik"> *</span></div></Form.Label>
                                                    <input type="text" className="form-control" name="cardCvc" value={paymentData.cardCvc} onChange={onChangeHandler} placeholder="912"></input>
                                                    {
                                                        paymentErr && paymentErr.cardCvc &&
                                                        <span className='submit-errors text-danger'>{paymentErr.cardCvc}</span>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className='theme-capcha'>
                                                <button type="button" className='orange-btn text-uppercase' onClick={subscribe}>PAY NOW</button>
                                            </div>

                                        </Form>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <PackageCard plan={plan} planTenure={planTenure} />

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            }
        </>
    )
}

export default CardDetails