import React from 'react';
import './NotFound.css'
function NotFound() {
    return (
        <div id="notfound" className='d-flex justify-content-center align-items-center'>
            <div className="notfound">
                <div className="notfound-404">
                    <strong className="sub-heading">Oops! Page not found</strong>
                    <h1>404</h1>
                </div>
                <p className='mb-0'>we are sorry, but the page you requested was not found</p>
            </div>
        </div>
    );
}
export default NotFound;