import React, { useEffect } from 'react';
import { useState } from 'react';
import './Subtitles.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Table } from 'react-bootstrap';
// import video1 from '../../../../assets/images/card5.png'
import VideoSubtitles from './VideoSubtitles';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { listSubtitles, beforeSubtitle } from './subtitles.actions';
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import 'rc-pagination/assets/index.css';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';

const Subtitles = () => {
    const dispatch = useDispatch()
    const subtitleSelector = useSelector((state) => state.subtitles)
    const [videoSubtitles, setVideoSubtitles] = useState(false)
    const [paginationData, setPagination] = useState({})
    const [videoDetails, setDetails] = useState({})
    const [vidId, setId] = useState('')
    const [loader, setLoader] = useState(true)
    const [params, setParams] = useSearchParams()

    useEffect(() => {
        if (params) {
            let videoId = params.get('video')
            if (videoId) {
                setId(videoId)
                dispatch(listSubtitles(`id=${videoId}`))
            }
        }
    }, [params])

    useEffect(() => {
        if (subtitleSelector.listSubtitlesAuth) {
            let { subtitles, pagination, details } = subtitleSelector?.listSubtitlesData?.data
            setVideoSubtitles(subtitles)
            setPagination(pagination)
            setDetails(details)
            setLoader(false)
            dispatch(beforeSubtitle())
        }
    }, [subtitleSelector.listSubtitlesAuth])

    // const PublishedTab = () => {
    //     return(
    //         <>
    //             <div className='themeTable'>
    //                 <Table responsive>
    //                 <thead>
    //                     <tr>
    //                     <th><p>Video</p></th>
    //                     <th><p>Languages</p></th>
    //                     <th><p>Modified Date</p></th>
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     <tr>
    //                         <td>
    //                         <button className='cuetomModelBtn' /* onClick={() => setVideoSubtitles(!videoSubtitles)} */>
    //                             <div className='videoInfo videoPara'>
    //                                 <div className='videoImg'>
    //                                     <img className='img-fluid' src={video1} alt='Video'></img>
    //                                 </div>

    //                                 <div>
    //                                     <p className='analytic-P'>Video 20221205071229 Soldier</p>
    //                                     <p className='light-p'>Integer ac interdum lacus. Nunc porta semper lacus a varius. Pellentesque habitant morbi tristique senectus et netus et malesuada fames.</p>

    //                                 </div>
    //                             </div>
    //                         </button>

    //                         </td>
    //                         <td className='td-area'><p>English</p></td>
    //                         <td className='td-area'><p>09-12-2022</p></td>
    //                     </tr>
    //                 </tbody>
    //                 </Table>
    //             </div>
    //         </>
    //     )
    // }

    const onPageChange = (page) => {
        setLoader(true)
        let qs = `page=${page}&id=${vidId}`
        dispatch(listSubtitles(qs))
    }

    return (
        loader ? <FullPageLoader /> :
            <>
                <div className='dashboardPage'>
                    <div className='dashboardHeader px40'>
                        <h2 className='text-uppercase'>Subtitles</h2>
                    </div>
                    <div className='subtitles themeAnalytics px40'>
                        <Tabs>
                            <div className='tabFlex'>
                                <TabList>
                                    <Tab>All</Tab>
                                    {/* <Tab>Drafts</Tab>
                                <Tab>Published</Tab> */}
                                </TabList>
                            </div>

                            <TabPanel className='subTabPanel'>
                                <div className='themeTable'>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th><p>Video</p></th>
                                                <th><p>Languages</p></th>
                                                <th><p>Link</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                videoSubtitles?.length ?
                                                    videoSubtitles.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                    <button className='cuetomModelBtn' /* onClick={() => setVideoSubtitles(!videoSubtitles)} */>
                                                                        <div className='videoInfo videoPara'>
                                                                            <div className='videoImg'>
                                                                                <img className='img-fluid' src={videoDetails?.thumbnails[0]} alt='Video'></img>
                                                                            </div>

                                                                            <div>
                                                                                <p className='analytic-P'>{videoDetails?.title}</p>
                                                                                <p className='light-p'>{videoDetails?.desc}</p>
                                                                            </div>
                                                                        </div>
                                                                    </button>

                                                                </td>
                                                                <td className='td-area'><p>{item?.language?.label}</p></td>
                                                                <td className='td-area'><a href={item?.cloudUrl} target="_blank" rel='noreferrer'>{item?.cloudUrl}</a></td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    <tr>
                                                        <td>No subtitles found</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </TabPanel>
                            {/* <TabPanel className='subTabPanel'><PublishedTab/></TabPanel>
                        <TabPanel className='subTabPanel'><PublishedTab/></TabPanel> */}

                        </Tabs>
                    </div>
                    {/* {videoSubtitles && <VideoSubtitles/>} */}
                </div>
                {
                    paginationData && paginationData.pages &&
                    < Pagination
                        className="m-3"
                        defaultCurrent={1}
                        pageSize // items per page
                        current={paginationData.page} // current active page
                        total={paginationData.pages} // total pages
                        onChange={onPageChange}
                        locale={localeInfo}
                        hideOnSinglePage={true}
                    />
                }
            </>
    )
}
export default Subtitles