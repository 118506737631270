import { BEFORE_METADATA, GET_ERRORS, GET_VIDEO_METADATA } from './../../../redux/types';

const initialState = {
    getVideoMetadata: null,
    getVideoMetadataAuth: false,
};

export default function playerReducer(state = initialState, action) {
    switch (action.type) {
        case GET_VIDEO_METADATA:
            return {
                getVideoMetadataAuth: true,
                getVideoMetadata: action.payload
            }
        case BEFORE_METADATA:
            return {
                getVideoMetadata: null,
                getVideoMetadataAuth: false,
            }
        default:
            return state;
    }
}