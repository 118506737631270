import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { FaChevronLeft, FaChevronRight, FaInstagram, FaFacebook, FaTwitter, FaPinterest, FaShareAlt } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, Link, useSearchParams } from 'react-router-dom';
import { ENV } from '../../../../config/config';
import {
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TwitterShareButton,
    FacebookIcon,
    LinkedinIcon,
    PinterestIcon,
    TwitterIcon,
} from "react-share";
import { listBlogPosts, listBlogs, listBlogCategories, beforeBlogCategory, beforeBlogPost, beforeBlog, getBlogPost } from '../blogs.actions';
import './BlogDetails.css'
import moment from 'moment'
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import SmallLoader from '../../../../utils/SmallLoader/SmallLoader';
import { toast } from 'react-toastify';
import CommentSection from './CommentSection';
import { Helmet } from 'react-helmet-async/lib/index.esm';
import './CkEditorCss.css'
import SocialShare from '../../SocialShare/SocialShare';
const BlogPosts = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const { blogId, postId } = useParams()
    const { customUrl } = useParams()

    const [searchParams, setSearchParams] = useSearchParams();
    const blogId = searchParams.get('blogId')
    const postId = searchParams.get('postId')

    const [blogPosts, setPosts] = useState([])
    const [blogPost, setPost] = useState()
    const [relatedBlogs, setRelatedBlogs] = useState([])
    const [popularTags, setTags] = useState([])
    const [blogCategories, setCategories] = useState([])
    const [postIndex, setPostIndex] = useState(0)
    const [showIcons, setShowIcons] = useState(false)
    const [loader, setLoader] = useState(true)
    const [relatedBlogsLoader, setBlogsLoader] = useState(true)
    const [relatedPostsLoader, setPostsLoader] = useState(true)

    const errorSelector = useSelector((state) => state.error)
    const blogsSelector = useSelector((state) => state.blogs)

    useEffect(() => {
        // when an error is received
        if (errorSelector && (errorSelector.invalidBlog || errorSelector.blogNotFound)) {
            navigate('/blogs')
            setLoader(false)
        }

    }, [errorSelector])

    // useEffect(() => {
    //     if (blogId) {
    //         let qs = ENV.objectToQueryString({ all: true, blogId })
    //         dispatch(listBlogs(qs, false))

    //         if (postId) {
    //             qs = ENV.objectToQueryString({ id: postId, incrementReadCount: true })
    //             dispatch(getBlogPost(qs, false))
    //         }
    //         // else
    //         //     setLoader(false)
    //     }
    // }, [blogId, postId])

    useEffect(() => {
        if (customUrl && customUrl !== "undefined") {
            dispatch(listBlogs(`blogPostUrl=${customUrl}&all=${true}`, false))
            dispatch(getBlogPost(`blogPostUrl=${customUrl}&incrementReadCount=${true}`, false))
        }
        else {
            navigate('/blogs')
            toast.info('There are currently no posts in this blog!')
        }
    }, [customUrl])

    useEffect(() => {
        if (blogPost && Object.keys(blogPost)?.length) {
            setLoader(false)
        }
    }, [blogPost])

    useEffect(() => {
        if (blogsSelector.blogCategoriesAuth) {
            let { categories } = blogsSelector.blogCategoriesRes
            setCategories(categories)
            dispatch(beforeBlogCategory())
        }
    }, [blogsSelector.blogCategoriesAuth])

    useEffect(() => {
        if (blogsSelector.blogPostsAuth) {
            let posts = blogsSelector.blogPostsRes?.blogPosts
            let tags = blogsSelector.blogPostsRes?.popularTags

            if (posts && posts.length && !customUrl) {
                setPost(posts[0])
                navigate(`/blog/${posts[0]?.customUrl}`)
            }
            else {
                setPosts(posts)
                setPostIndex(0)
                setTags(tags)
                dispatch(beforeBlogPost())
                // setLoader(false)
            }
            setPostsLoader(false)
        }
    }, [blogsSelector.blogPostsAuth])

    useEffect(() => {
        if (blogsSelector.getBlogPostAuth) {
            let postData = blogsSelector.getBlogPostRes.blogPost
            if (postData) {
                setPost(postData)
                dispatch(beforeBlogPost())
            }
        }
    }, [blogsSelector.getBlogPostAuth])

    useEffect(() => {
        if (blogsSelector.blogsAuth) {
            let relatedBlogsData = blogsSelector.blogsRes.blogs

            if (relatedBlogsData) {

                setRelatedBlogs(relatedBlogsData)
                setBlogsLoader(false)

                dispatch(listBlogPosts(`blogPostUrl=${customUrl}&all=${true}`, false))

                let qs = ENV.objectToQueryString({ all: true })
                dispatch(listBlogCategories(qs, false))
            }

            dispatch(beforeBlog())
        }
    }, [blogsSelector.blogsAuth])

    const changePost = (key) => {
        if (key === 1) { // previous post

            let previousPost = blogPosts[postIndex - 1]
            if (previousPost && previousPost._id) {
                setPost(previousPost)
                setPostIndex(postIndex - 1)
            }
        }

        if (key === 2) { // next post

            let nextPost = blogPosts[postIndex + 1]
            if (nextPost && nextPost._id) {
                setPost(nextPost)
                setPostIndex(postIndex + 1)
            }
        }
    }

    return (
        loader ? <FullPageLoader /> :
            <>
                {
                    blogPost &&
                    <Helmet>
                        <title>{blogPost?.metaTitle}</title>
                        <meta name="description" content={blogPost?.metaDescription} />
                    </Helmet>
                }
                <section className='blog-details-sec  pb-0'>
                    <Container className='customBox'>
                        <div className='breadcrum'>
                            <p><span className='prev'>Blogs</span>{blogPost && ` / ${blogPost.title}`}</p>
                        </div>
                        <Row>
                            <Col lg={8}>
                                <div className='heading'>
                                    <h1>{blogPost && blogPost.title}</h1>
                                </div>
                                {
                                    blogPost ?
                                        <>
                                            <div className='post-details d-flex justify-content-between align-items-center flex-wrap'>
                                                <div className='auther-date d-flex flex-wrap'>
                                                    {
                                                        blogPost ?
                                                            <p className='author-name mb-2'>Posted by {blogPost.author ? blogPost.author.name : 'Anonymous'}</p> :
                                                            ''
                                                    }
                                                    <p className='mb-2'>{blogPost && blogPost.publishDate ? moment(new Date(blogPost.publishDate)).format('DD-MM-YYYY') : ''}</p>
                                                </div>
                                                <SocialShare title={blogPost?.title} shareUrl={`${ENV.url}blog/${blogPost?.customUrl}`} blogPostShare={true}/>
                                                {/* <div className='share-btn d-flex align-items-center'>
                                                    {showIcons ?
                                                        <div className='social-icons-blog-post d-flex align-items-center'>
                                                            <FacebookShareButton
                                                                url={`${ENV.url}/blog/${blogPost?.customUrl}`}
                                                                title={blogPost.title}
                                                            >
                                                                <FacebookIcon size={32} round={true} />
                                                            </FacebookShareButton>
                                                            <TwitterShareButton
                                                                url={`${ENV.url}/blog/${blogPost?.customUrl}`}
                                                                title={blogPost.title}
                                                            >
                                                                <TwitterIcon size={32} round={true} />
                                                            </TwitterShareButton>
                                                            <LinkedinShareButton
                                                                url={`${ENV.url}/blog/${blogPost?.customUrl}`}
                                                                title={blogPost.title}
                                                            >
                                                                <LinkedinIcon size={32} round={true} />
                                                            </LinkedinShareButton>
                                                            <PinterestShareButton
                                                                url={`${ENV.url}/blog/${blogPost?.customUrl}`}
                                                                title={blogPost.title}
                                                            >
                                                                <PinterestIcon size={32} round={true} />
                                                            </PinterestShareButton>
                                                        </div>
                                                        : null}
                                                    <Button className={showIcons ? "active" : ""} onClick={() => setShowIcons(!showIcons)} variant=""><FaShareAlt className='me-2' />Share</Button>
                                                </div> */}
                                                {/* <Button className={showIcons ? "active" : ""} onClick={() => setShowIcons(!showIcons)} variant="">
                                                    <FaShareAlt className='me-2' />Share
                                                </Button> */}
                                            </div>
                                            {
                                                blogPost && blogPost.featuredImage &&
                                                <div className='post-img'>
                                                    <img src={blogPost.featuredImage} className='contained-img' alt={customUrl} />
                                                </div>

                                            }
                                            <div className='post-details'>
                                                {
                                                    blogPost &&
                                                    <>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                             __html: blogPost.content,
                                                            }} className="ck ck-content">
                                                        </div>
                                                        {blogPosts ?
                                                            ((blogPosts[postIndex - 1]) && (blogPosts[postIndex + 1])) ?
                                                                <div className='blog-names'>
                                                                    <div className='wrapper1 pointer'>
                                                                        {(blogPosts[postIndex - 1]) ?
                                                                                <div className='d-flex align-items-center' onClick={() => changePost(1)}>
                                                                                    <FaChevronLeft />
                                                                                    <p className='mb-0'>Previous</p>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    <div className='wrapper2 pointer'>
                                                                        {
                                                                            (blogPosts[postIndex + 1]) ?
                                                                                <div className='d-flex align-items-center' onClick={() => changePost(2)}>
                                                                                    <p className='mb-0'>Next</p>
                                                                                    <FaChevronRight />
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                            :
                                                            ""
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </> :
                                        <div className="no-record-found d-flex justify-content-center align-items-center">No Post Found</div>
                                }
                                {/* <div className="blog-comment-form">
                                    <h4>Leave a Comment</h4>
                                    <form className="comment-form">
                                        <div className="mb-3">
                                            <textarea className="form-control" placeholder='Write Your Comment'></textarea>
                                        </div>
                                        <div className="btn-holder">
                                            <button className="orange-main-button text-uppercase">Submit</button>
                                        </div>
                                    </form>
                                </div> */}
                                <div className='blog-comment-form'>
                           			<h4>Comments</h4>
                            		<CommentSection postId={blogPost._id} {...blogsSelector} />
                       			</div>
                                <div className='related-blogs'>
                                    <h4>Related Blogs</h4>
                                    <Row>
                                        {
                                            relatedBlogsLoader ?
                                                <SmallLoader width="30" height="30" /> :
                                                relatedBlogs && relatedBlogs.length ?
                                                    relatedBlogs.map((blog, index) => {
                                                        return (
                                                            <Col key={index} md={4} sm={6}>
                                                                <div className='related-blogs-wrapper'>
                                                                    <div className='img'>
                                                                        <img src={blog.featuredImage} alt={blog.title} className='img-fluid mb-0 contained-img' />
                                                                    </div>
                                                                    <div className='content'>
                                                                        <p className='blog-date mb-2'>{moment(new Date(blog.createdAt)).format('DD-MM-YYYY')}</p>
                                                                        <Link to={`/blog/${blog?.blogPost?.customUrl}`} className='blog-heading mb-0 pointer'>
                                                                            {blog.title}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                        )
                                                    }) :
                                                    <p>There are no related blogs yet.</p>
                                        }
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className='related-posts'>
                                    <p className='heading-sec'>Related Posts</p>
                                    <div className='related-posts-wrapper'>
                                        {
                                            relatedPostsLoader ?
                                                <SmallLoader width="30" height="30" /> :
                                                blogPost && blogPosts && blogPosts.length ?
                                                    blogPosts.map((post, idx) => {
                                                        return (
                                                            <div key={idx} className='related-post d-flex align-items-center'>
                                                                <div className='img'>
                                                                    <img src={post.featuredImage} alt={post.title} className='img-fluid h-100' />
                                                                </div>
                                                                <div className='content'>
                                                                    <p className='related-post-date mb-2'>{post?.publishDate ? moment(new Date(post.publishDate)).format('DD-MM-YYYY') : ''}</p>
                                                                    <Link to={`/blog/${post?.customUrl}`} className='related-post-description mb-0 pointer'>
                                                                        {post.title}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            // </Link>
                                                        )
                                                    }) :
                                                    <p>There are no related posts yet.</p>
                                        }
                                    </div>
                                </div>
                                <div className='popular-tags'>
                                    <p className='heading-sec'>Popular Tags</p>
                                    <div className='popular-tags-wrapper d-flex flex-wrap'>
                                        {
                                            blogPost && popularTags && popularTags.length ?
                                                popularTags.map((tag, idx) => {
                                                    return (
                                                        tag?.tag ? <span key={idx} className='tag'>{tag.tag}</span> : null
                                                    )
                                                }) :
                                                <p>No Popular Tags found</p>
                                        }
                                    </div>
                                </div>
                                <div className='catagories'>
                                    <p className='heading-sec'>Categories</p>
                                    <div className='categories-wrapper'>
                                        {
                                            blogCategories && blogCategories.length ?
                                                blogCategories.map((category, index) => {
                                                    return (
                                                        <div key={index} className='category d-flex justify-content-between align-items-center'>
                                                            <a className='category-name d-flex align-items-center' href='#'>
                                                                <FaChevronRight />
                                                                <span>{category.category}</span>
                                                            </a>
                                                            <span className='category-count'>{category.blogsLength || '0'}</span>
                                                        </div>
                                                    )
                                                }) :
                                                <div className="no-record-found d-flex justify-content-center align-items-center">No Categories found</div>

                                        }
                                    </div>
                                </div>
                                {/* <div className='social-media'>
                                    <p className='heading-sec'>Social Media</p>
                                    <Row>
                                        <Col sm={6}>
                                            <div className='social instagram d-flex align-items-center'>
                                                <div className='icon'>
                                                    <FaInstagram />
                                                </div>
                                                <div className='details d-flex flex-column'>
                                                    <span>52k</span>
                                                    <span>Followers</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className='social facebook d-flex align-items-center'>
                                                <div className='icon'>
                                                    <FaFacebook />
                                                </div>
                                                <div className='details d-flex flex-column'>
                                                    <span>52k</span>
                                                    <span>Followers</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className='social twitter d-flex align-items-center'>
                                                <div className='icon'>
                                                    <FaTwitter />
                                                </div>
                                                <div className='details d-flex flex-column'>
                                                    <span>52k</span>
                                                    <span>Followers</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className='social pinterest d-flex align-items-center'>
                                                <div className='icon'>
                                                    <FaPinterest />
                                                </div>
                                                <div className='details d-flex flex-column'>
                                                    <span>52k</span>
                                                    <span>Followers</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div> */}
                            </Col>
                        </Row>
                      
                    </Container>

                </section>
            </>
    )
}

export default BlogPosts