import { BEFORE_INFORMATIVE, LIST_INFO_PAGES, GET_INFO_PAGE } from '../../../redux/types';

const initialState = {
    informativeData: null,
    informativeAuth: false,

    infoContent: null,
    infoContentAuth: false
}

export default function informativeRed(state = initialState, action) {
    switch (action.type) {
        case LIST_INFO_PAGES:
            return {
                ...state,
                informativeData: action.payload,
                informativeAuth: true
            }
        case GET_INFO_PAGE:
            return {
                ...state,
                infoContent: action.payload,
                infoContentAuth: true
            }
        case BEFORE_INFORMATIVE:
            return {
                ...state,
                informativeData: null,
                informativeAuth: false,

                infoContent: null,
                infoContentAuth: false
            }
        default:
            return {
                ...state
            }
    }
}