import React, { useEffect } from 'react'
import { useState } from 'react'
import '../../Models/Models.css'
import { Form, Card } from 'react-bootstrap'
import { ENV } from '../../../../../config/config'
import SmallLoader from '../../../../../utils/SmallLoader/SmallLoader'
const { allowedMediaTypes, defaultPlaceholderImage } = ENV

const General = (props) => {

    const [image, setImage] = useState('')
    const [channelBannerImage, setBannerImage] = useState('')


    useEffect(() => {
        if (props.channelImage)
            setImage(props.channelImage)

        if (props.channelBannerImage)
            setBannerImage(props.channelBannerImage)

    }, [props.channelImage, props.channelBannerImage])

    const onFileChange = (e, key) => {
        const allowedImgTypes = allowedMediaTypes.images
        let file = e.target.files[0]

        if (file) {
            let fileExt = file.name.split('.').pop();
            if (allowedImgTypes.indexOf(fileExt) <= -1) {
                props.setErrors({ ...props.errors, [key]: `Supported File Types are: ${allowedImgTypes.join(', ')}` })
                return
            }

            let reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = function (e) {
                let result = e.target.result
                if (key === 'channelImage')
                    setImage(result)
                else if (key === 'channelBannerImage')
                    setBannerImage(result)

                props.setErrors({ ...props.errors, [key]: `` })
                props.setData({ [key === 'channelImage' ? 'channelImageUrl' : 'channelBannerImageUrl']: file })
            }
        }
    }

    return (
        // props.loader ?
        //     <SmallLoader height={40} width={40} />
        //     :
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div className='general'>
                        <h4>General Settings</h4>
                        <div className='fileGroupList'>
                            <p className='fileLabel'>Image</p>
                            <div className="position-relative mb-3">
                                <Form.Control
                                    className="text-white mb-0"
                                    placeholder="Company"
                                    type="file"
                                    varient="info"
                                    accept=".png,.jpeg,.jpg"
                                    id="channelImage"
                                    onChange={(e) => onFileChange(e, 'channelImage')}
                                >
                                </Form.Control>
                                <span className="choose-file-text">Choose File</span>
                            </div>
                            {
                                props.errors && props.errors.channelImage &&
                                <span className='submit-errors text-danger'>{props.errors.channelImage}</span>
                            }
                        </div>
                        {
                            image &&
                            <div className='userBlock d-flex justify-content-start'>
                                <div className='userImg'>
                                    <img className='img-fluid' src={image || defaultPlaceholderImage} alt='channel'>
                                    </img>
                                </div>
                            </div>
                        }
                        <div className='fileGroupList'>
                            <p className='fileLabel'>Banner Image</p>
                            <div className="position-relative mb-3">
                                <Form.Control
                                    className="text-white mb-0"
                                    placeholder="Company"
                                    type="file"
                                    varient="info"
                                    accept=".png,.jpeg,.jpg"
                                    id="channelBannerImage"
                                    onChange={(e) => onFileChange(e, 'channelBannerImage')}
                                >
                                </Form.Control>
                                <span className="choose-file-text">Choose File</span>
                            </div>
                            {
                                props.errors && props.errors.channelBannerImage &&
                                <span className='submit-errors text-danger'>{props.errors.channelBannerImage}</span>
                            }
                        </div>
                        {
                            channelBannerImage &&
                            <div className='userBlock d-flex justify-content-start'>
                                <div className='userImg'>
                                    <img className='img-fluid' src={channelBannerImage} alt='channel'>
                                    </img>
                                </div>
                            </div>
                        }
                        <div className='fileGroupList'>
                            <p className='fileLabel'>Channel Name (required)</p>
                            <Form.Control
                                type="text"
                                name="name"
                                value={props?.name || ''}
                                onChange={(e) => props.setData({ name: e.target.value })}
                            >
                            </Form.Control>
                            {
                                props.errors && props.errors.name &&
                                <span className='submit-errors text-danger'>{props.errors.name}</span>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default General