import React, { useState, useEffect } from 'react';
import './Footer.css'
import { Container } from 'react-bootstrap'
import { Link } from "react-router-dom";
import logo from '../../../assets/images/logo.png'
import { FaFacebookF, FaLinkedin, FaPinterest, FaTwitter, FaYoutube } from 'react-icons/fa';
import { getSiteSettings, beforeSiteSettings } from '../SiteSettings/siteSettings.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Images } from '../../../assets/assets';

const Footer = () => {
    const dispatch = useDispatch()
	const date = new Date();
	let year = date.getFullYear(); 
    const settingsSelector = useSelector((state) => state.siteSettings)

    const [siteSettings, setSettings] = useState({})

    useEffect(() => {
        dispatch(getSiteSettings())
    }, [])

    useEffect(() => {
        if (settingsSelector.getSiteSettingsAuth) {
            let { settings } = settingsSelector.getSiteSettingsData
            setSettings(settings)
            dispatch(beforeSiteSettings())
        }
    }, [settingsSelector.getSiteSettingsAuth])

    return (
        <footer className='footer'>
			<Container fluid className='customBox'>
				<div className='footerTop'>
					<strong className='footerLogo d-inline-block align-top'>
						<Link className="d-inline-block align-top" to='/'><img className='img-fluid' src={logo} alt='Biiview'></img></Link>
					</strong>
					<div className='socialIcon'>
						<ul className='p-0'>
							<li className="facebook">
								<a href="/" target="_blank" rel='noreferrer'><FaFacebookF /></a>
							</li>
							<li className="twitter">
								<a href="/" target="_blank" rel='noreferrer'><FaTwitter /></a>
							</li>
							<li className="linkedin">
								<a href="/" target="_blank" rel='noreferrer'><FaLinkedin /></a>
							</li>
							<li className="pinterest">
								<a href="/" target="_blank" rel='noreferrer'><FaPinterest /></a>
							</li>
							<li className="youtube">
								<a href="/" target="_blank" rel='noreferrer'><FaYoutube /></a>
							</li>							
							{/* {
								siteSettings.facebook ?
									<li>
										<a href={siteSettings.facebook} target="_blank" rel='noreferrer'><FaFacebookF /></a>
									</li> :
									null
							}
							{
								siteSettings.twitter ?
									<li>
										<a href={siteSettings.twitter} target="_blank" rel='noreferrer'><FaTwitter /></a>
									</li> :
									null
							}
							{
								siteSettings.linkedIn ?
									< li >
										<a href={siteSettings.linkedIn} target="_blank" rel='noreferrer'><FaLinkedin /></a>
									</li> :
									null
							}
							{
								siteSettings.pinterest ?
									<li>
										<a href={siteSettings.pinterest} target="_blank" rel='noreferrer'><FaPinterest /></a>
									</li> :
									null
							}
							{
								siteSettings.youtube ?
									<li>
										<a href={siteSettings.youtube} target="_blank" rel='noreferrer'><FaYoutube /></a>
									</li> :
									null
							} */}
						</ul>
					</div>
					<div className="subscription-form-holder d-flex justify-content-center">
						<div className="subscription-form-block d-flex flex-column align-items-start">
							<label className="mb-2 text-start">Subscribe to our newsletter</label>
							<form className="subscription-form d-flex justify-content-between flex-column flex-sm-row">
								<div className="input-holder">
									<input type="email" placeholder='Enter Your Email' />
								</div>
								<button className="d-flex justify-content-center align-items-center" type="submit">Subscribe</button>
							</form>
						</div>
					</div>
					<div className='footerLink'>
						<ul className='p-0'>
							<li><Link to="/" className='link'>home</Link></li>
							<li><Link to="/about-us" className='link'>About Us</Link></li>
							<li><Link to="/blogs" className='link'>blogs</Link></li>
							<li><Link to="/contact-us" className='link'>Contact Us</Link></li>
							<li><Link to="/terms-and-conditions" className='link'>Terms & Conditions</Link></li>
							<li><Link to="/privacy-policy" className='link'>Privacy Policy</Link></li>
							<li><Link to="/informative" className='link'>Informative Pages</Link></li>
						</ul>
					</div>
				</div>
			</Container>
			<div className='footerBtm'>
				<Container fluid className='customBox'>
					<p className='copyright'>&copy; {year} BiiView. All Rights Reserved</p>
				</Container>
			</div>
			<a className="asoft-logo" href="https://www.arhamsoft.com" target='_blank'>
				<img className="img-fluid" src={Images.logoArhamsoft} alt='ArhamSoft' />
			</a>
		</footer>
    )
}
export default Footer