import React from 'react'
import '../../Models/Models.css'
import { Form } from 'react-bootstrap'
import { TiArrowSortedDown } from "react-icons/ti"
import Select from 'react-select';
const UploadBasicInfo = (props) => {

    const onChangeHandler = (e) => {
        props.setData({ [e.target.name]: e.target.value })
    }
    
    const options = [
        { value: '1', label: 'Private' },
        { value: '2', label: 'Unlisted' },
        { value: '3', label: 'Public' },
        { value: '4', label: 'Draft' }
    ];
    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabFlow" >
                    <div>
                        <div className='general mb-0'>
                            <div className='fileGroup'>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>Title</p>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Title"
                                        value={props.title || ''}
                                        name="videoTitle"
                                        onChange={onChangeHandler}
                                    >
                                    </Form.Control>
                                </div>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>Description</p>
                                    <Form.Group
                                        className='w-100'
                                        as="textarea"
                                        name="videoDescription"
                                        value={props.description || ''}
                                        rows={3}
                                        placeholder='Tell us something about your video….'
                                        onChange={onChangeHandler}>
                                    </Form.Group>
                                </div>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>Visibility</p>
                                    <div className='modalSelect mb-2'>
                                        {/* <select className="form-select" aria-label="Default select example" name="videoVisibility" value={props.visibility} onChange={onChangeHandler}>
                                            <option value="">Select Visibility</option>
                                            <option value={1}>Private</option>
                                            <option value={2}>Unlisted</option>
                                            <option value={3}>Public</option>
                                            <option value={4}>Draft</option>
                                        </select>
                                        <span><TiArrowSortedDown /></span> */}
                                        <div className="custom-react-select flex-fill">
                                            {options && options.length ?
                                                <Select
                                                    options={options}
                                                    name="videoVisibility"
                                                    value={options && options.length > 0 ? options.find(option => option.value === props.visibility) : null}
                                                    // onChange={onChangeHandler}
                                                    onChange={(e) => props.setData({ visibility: e.value })}
                                                    classNamePrefix="triage-select"
                                                />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>Tags</p>
                                    <Form.Group
                                        className='w-100'
                                        as="textarea"
                                        name="videoTags"
                                        rows={3}
                                        value={props.tags || ''}
                                        onChange={onChangeHandler}
                                        placeholder='#myVideo, #biiview'>
                                    </Form.Group>
                                </div>
                                {
                                    props.errors && props.errors.videoTags &&
                                    <span className='submit-errors text-danger'>{props.errors.videoTags}</span>
                                }
                                <p>Enter comma-seperated values</p>
                                <p>Note: Each Tag must start with a hashtag followed by alphabets.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UploadBasicInfo 