import React, { useState, useEffect } from 'react';
import '../../Forms/Forms.css'
import { Container, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { forgotPassword, beforeUser } from '../users.actions';
import { validateEmail } from '../../../../utils/Validations/validations';
import { Images } from '../../../../assets/assets';

const ForgotPass = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [data, setData] = useState('')
    const [error, setError] = useState('')
    const [loader, setLoader] = useState(false)

    const navigateToLogin = () => {
        navigate('/login');
    };

    const userSelector = useSelector((state) => state.user)
    const errorSelector = useSelector((state) => state.error)

    useEffect(() => {
        if (userSelector.forgotPasswordAuth) {
            setLoader(false)
            dispatch(beforeUser())
            navigate('/login')
        }
    }, [userSelector.forgotPasswordAuth])

    useEffect(() => {
        if (errorSelector) {
            setLoader(false)
        }
    }, [errorSelector])

    const submit = (e) => {
        let err = ''
        let input = data.trim()

        if (!input) {
            err = 'Please enter email'
        }
        else {
            err = validateEmail(input)
        }

        setError(err)
        if (err)
            return

        setLoader(true)
        dispatch(forgotPassword({ email: input }))
    }

    return (
        <>
            {loader && <FullPageLoader />}
            <div className='theme-form Login'>
                <div className='biiview-form'>
                    <Container fluid className='formContainer'>
                        <div className='wrapForm'>
                            <Form className='formStyle position-relative' onSubmit={submit}>
                                <span className="auth-login-logo">
                                    <img className="img-fluid" src={Images.authPageLogo} alt='BiiView Logo' />
                                </span>
                                <div className='formHeader border-0 mb-0'>
                                    <h2>Trouble logging in?</h2>
                                    <div className='d-flex align-items-end justify-content-start troubbleText'><p
                                        className='mb-0 outer-p text-start forgot'>Enter your Email that we’ll send you a link to get back your account.</p></div>
                                </div>
                                <Form.Group className='formGroup mb-0' controlId="formBasicEmail">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                        value={data}
                                        onChange={(e) => setData(e.target.value)}
                                    >
                                    </input>
                                    {
                                        error &&
                                        <span className='submit-errors text-danger'>{error}</span>
                                    }
                                </Form.Group>
                                <div className='submit-btn-holder d-flex justify-content-center'>
                                    <button type="button" className='orange-main-button text-uppercase end-mb' onClick={submit}>SEND LOGIN LINK</button>
                                </div>
                                <div className='d-flex align-items-end justify-content-center pt-5'><p className='mb-0 outer-p link themeLink' onClick={navigateToLogin}>BACK TO LOGIN</p></div>
                            </Form>
                        </div>
                    </Container>
                </div>
            </div>

        </>
    )
}
export default ForgotPass