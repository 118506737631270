import React from 'react'
import '../../Models/Models.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import BasicInfo from './BasicInfo'
import AdvanceSettings from './AdvanceSettings'
import SmallLoader from '../../../../../utils/SmallLoader/SmallLoader'

const Channel = (props) => {

    return (
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabs" >
                    {
                        // props.loader ?
                        //     <SmallLoader height={40} width={40} /> :
                            <Tabs activeKey={props.channelKey} onSelect={(k) => props.setKey(k == '0' ? 'channel-basic' : 'channel-advance')}>
                                <TabList >
                                    <Tab eventKey={'channel-basic'}>
                                        Basic Info
                                    </Tab>
                                    <Tab eventKey={'channel-advance'}>
                                        Advance Settings
                                    </Tab>
                                </TabList>
                                <>
                                    <TabPanel>
                                        <BasicInfo
                                            country={props.channelDetails.countryResidence}
                                            keywords={props.channelDetails.keywords}
                                            aboutChannel={props.channelDetails.aboutChannel}
                                            setData={props.setChannelData}
                                            errors={props.errors}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <AdvanceSettings
                                            audience={props.channelDetails.audience}
                                            setData={props.setChannelData}
                                            setLoader={props.setLoader}
                                        />
                                    </TabPanel>
                                </>
                            </Tabs>
                    }

                </div>
            </div>
        </>
    )
}
export default Channel