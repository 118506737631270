import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Query = () => {
    return (
        <>
            <section className='query-sec'>
                <Container className='customBox'>
                    <Row>
                        <Col lg={6}>
                            <h1 className='mb-0'>Do you have any <span className='theme'>questions?</span> Send us a <span className='theme'>Message</span>,and our team will contact you.</h1>
                        </Col>
                        <Col lg={5} className="d-flex align-items-end offset-lg-1">
                            <h3 className='mb-0'>Our team is 24/7 ready and eager to engage with you and address any queries you may have. Feel free to reach out, and we will promptly respond to your message.</h3>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Query