import { GET_SITE_SETTINGS, BEFORE_SITE_SETTINGS } from '../../../redux/types';

const initialState = {
    getSiteSettingsData : null,
    getSiteSettingsAuth : false
}

export default function siteSettingsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SITE_SETTINGS:
            return {
                ...state,
                getSiteSettingsData: action.payload,
                getSiteSettingsAuth: true
            }

        case BEFORE_SITE_SETTINGS:
            return {
                ...state,
                getSiteSettingsData: null,
                getSiteSettingsAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}