import React, { useState, useEffect } from 'react'
import Joyride, { STATUS, ACTIONS } from 'react-joyride';
import { getPageTourSteps } from './applicationTourSteps'
import { ENV } from '../../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { beforeTourStatus, getUserTourStatus, upsertUserTourStatus } from './applicationTour.actions';

const { applicationTourPages, getUserKeys } = ENV
const tourEndStatuses = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED];

const ApplicationTour = (props) => {
    const dispatch = useDispatch()

    const [tourSteps, setSteps] = useState()
    const [startTour, setStart] = useState(false)
    const [tourPageKey, setKey] = useState(props.tourPageKey)
    const [hasTourFinished, setFinished] = useState(0)
    const [userAppTourDetails, setDetails] = useState({})
    const [isBackPressed, setPressed] = useState(false)

    const userId = getUserKeys('_id')?._id
    const location = window.location.pathname.split('/')[1]

    const appTourSelector = useSelector((state) => state.appTour)

    useEffect(() => {
        setPressed(false)
        if (props.page || location) {
            let key = (Object.keys(applicationTourPages).filter((k) => applicationTourPages[k] === (props.page || location)))[0]
            setKey(key)
        }
    }, [window.location.pathname, props.page])

    useEffect(() => {
        console.log('tourPageKey',tourPageKey)
        if (tourPageKey) {
            dispatch(getUserTourStatus(userId))
            let steps = getPageTourSteps(tourPageKey)
            setSteps([...steps])
        }

    }, [tourPageKey])

    useEffect(() => {
        if (appTourSelector.userAppTourDetailsAuth) {
            setDetails(appTourSelector?.userAppTourDetails?.userTour)
            dispatch(beforeTourStatus())
        }
    }, [appTourSelector.userAppTourDetailsAuth])

    useEffect(() => {
        if (appTourSelector.editUserTourDetailsAuth) {
            setFinished(0)
            dispatch(beforeTourStatus())
        }
    }, [appTourSelector.editUserTourDetailsAuth])

    window.onpopstate = () => {
        setPressed(true)
    }

    const handleJoyrideCallback = (data) => {
        const { status, action } = data;

        if ((tourEndStatuses.includes(status) || action === ACTIONS.CLOSE)) {
            setStart(false)
            setFinished(1)
        }
    }

    useEffect(() => {
        if (tourSteps?.length)
            setStart(true)
    }, [tourSteps])

    useEffect(() => {
        // mark as visited only when tour completed or skipped 
        if (hasTourFinished === 1 && !isBackPressed) {
            dispatch(upsertUserTourStatus({ [tourPageKey]: true, userId }))
            setFinished(0)
        }
    }, [hasTourFinished])

    return (
        ((Object.keys(userAppTourDetails)?.length && !userAppTourDetails[tourPageKey]) || userAppTourDetails.firstTimeUser) && tourSteps?.length ?
            <Joyride
                steps={tourSteps}
                callback={handleJoyrideCallback}
                run={startTour}
                continuous={true}
                disableOverlayClose={true}
                showSkipButton
                spotlightClicks={true}
                locale={{ 'last': 'Close' }}
                styles={{
                    options: {
                        zIndex: 10000
                    },
                }}
            /> :
            null
    )
}

export default ApplicationTour



