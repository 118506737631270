import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Join = () => {
    return (
        <>
            <section className='join-sec'>
                <Container className='customBox'>
                    <Row>
                        <Col lg={6}>
                            <h1>Become a Part of <span className='theme d-block'>Our Team</span></h1>
                        </Col>
                        <Col lg={6}>
                            <p>Achieving excellence requires great effort and the best talent. Be a valuable contributor to BiiView and collaborate in shaping the future of AI visual recognition solutions.</p>
                            <Link to='/positions' className='d-inline-block align-top mt-0 orange-main-button text-uppercase'>Explore Open Positions!</Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Join