import { LIST_SUBTITLES, BEFORE_SUBTITLE } from '../../../../redux/types';

const initialState = {

    listSubtitlesData: null,
    listSubtitlesAuth: false,
}

export default function subtitlesRed(state = initialState, action) {
    switch (action.type) {

        case LIST_SUBTITLES:
            return {
                ...state,
                listSubtitlesData: action.payload,
                listSubtitlesAuth: true
            }

        case BEFORE_SUBTITLE:
            return {
                ...state,
                listSubtitlesData: null,
                listSubtitlesAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}