import { BEFORE_FAQ, LIST_FAQS } from '../../../redux/types';

const initialState = {
    faqsData: null,
    faqsAuth: false
}

export default function faqsRed(state = initialState, action) {
    switch (action.type) {
        case LIST_FAQS:
            return {
                ...state,
                faqsData: action.payload,
                faqsAuth: true
            }
        case BEFORE_FAQ:
            return {
                ...state,
                faqsData: null,
                faqsAuth: false
            }
        default:
            return {
                ...state
            }
    }
}