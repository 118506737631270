import React, { useState, useEffect } from 'react';
import '../../Forms/Forms.css'
import { Container, Form, Tab, Tabs } from 'react-bootstrap'
import { useNavigate, Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector, useDispatch } from 'react-redux'
import { signup, beforeUser } from '../users.actions';
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { ENV } from '../../../../config/config';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { validateAge, validateEmail, validateName, validatePassword } from '../../../../utils/Validations/validations';
import { Images } from '../../../../assets/assets';
import { toast } from 'react-toastify';
const { recaptchaSiteKey } = ENV

const Signup = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [signupData, setSignupData] = useState({
        fullName: '',
        email: '',
        password: '',
        verifyPassword: '',
        dob: ''
    })
    const [captcha, setCaptcha] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState({})
    const [key, setKey] = useState('1');
    const [showPassword, setShowPass] = useState(false)
    const [showVerifyPassword, setShowVerifyPass] = useState(false)
    const [userConsent, setUserConsent] = useState(false)

    const userSelector = useSelector((state) => state.user)
    const errorSelector = useSelector((state) => state.error)

    useEffect(() => {
        if (userSelector.userSignupAuth) {
            if (userSelector.userSignupData.success) {
                setLoader(false)
                dispatch(beforeUser())
                navigate('/login')
            }
        }
    }, [userSelector.userSignupAuth])

    useEffect(() => {
        if (errorSelector) {
            setLoader(false)
        }
    }, [errorSelector])


    function onChange(value) {
        if (submitted && value)
            setErrors({ ...errors, captcha: '' })
        setCaptcha(value)
    }

    const navigateToLogin = () => {
        navigate('/login');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            signupHandler();
        }
    }

    const onChangeHandler = (e) => {
        if (submitted) {
            if (e.target.value) {
                setErrors({ ...errors, [e.target.name]: '' })
            }
            else {
                setErrors({ ...errors, [e.target.name]: 'This field is required.' })
            }

        }
        setSignupData({ ...signupData, [e.target.name]: e.target.value })
    }

    const signupHandler = () => {
        console.log('signupData.verifyPassword',signupData.verifyPassword)
        setSubmitted(true)
        let fullNameErr = ''
        let emailErr = ''
        let passErr = ''
        let captchaErr = ''
        let verifyPassErr = ''
        let dobErr = ''
        let msg = 'This field is required.'

        if (!signupData.fullName)
            fullNameErr = msg
        else {
            signupData.fullName = signupData.fullName.replace(/\s+/g, " ").trim()
            fullNameErr = validateName(signupData.fullName)
        }

        if (!signupData.email)
            emailErr = msg
        else
            emailErr = validateEmail(signupData.email)

        if (!signupData.password || !signupData.password.trim())
            passErr = msg
        else {
            passErr = validatePassword(signupData.password)
        }

        if (!signupData.verifyPassword)
            verifyPassErr = msg

        if ((signupData.password && signupData.verifyPassword) &&
            signupData.password !== signupData.verifyPassword)
            verifyPassErr = 'Both passwords do not match'

        if (!signupData.dob && key == '2')
            dobErr = msg
        else {
            dobErr = validateAge(signupData.dob)
        }

        if (!captcha)
            captchaErr = msg

        console.log(passErr)

        setErrors({ fullName: fullNameErr, email: emailErr, password: passErr, verifyPassword: verifyPassErr, captcha: captchaErr, dob: dobErr })

        if (fullNameErr || emailErr || passErr || captchaErr || verifyPassErr || (key == '2' && dobErr))
            return

        if (!userConsent) {
            toast.error('Please agree to our terms & conditions by marking the checkbox below.')
            return
        }
        
        // delete signupData['verifyPassword']
        setSignupData({...signupData, verifyPassword: ''})
        document.getElementById('verifyPasswordInput').value = ''
        setLoader(true)
        dispatch(signup(signupData))

    };

    const tabChangeHandler = (k) => {
        if (key !== k) {
            setKey(k)
            setErrors({})
            setShowPass(false)
            setShowVerifyPass(false)
            setSignupData({
                fullName: '',
                email: '',
                password: '',
                verifyPassword: '',
                dob: ''
            })
        }
    }

    const tabForm = (key) => (
        <>
            <Form className='formStyle mb-3 position-relative'>
                <span className="auth-login-logo">
                    <img className="img-fluid" src={Images.authPageLogo} alt='BiiView Logo' />
                </span>
                <div className='formHeader'>
                    {
                        key == '1' ?
                            <h2>Create New Account</h2> :
                            <h2>Become Publisher</h2>

                    }
                    <div className='d-flex align-items-end justify-content-center'>
                        <p className='mb-0'>{key == '1' ? 'EXISTING USER?' : 'ALREADY PUBLISHER?'}</p>
                        <button onClick={navigateToLogin} className='link orangeLinkBtn ms-2'>LOGIN</button>
                    </div>
                </div>

                <Form.Group className='formGroup' controlId="formBasicEmail" onKeyPress={handleKeyPress}>
                    <Form.Label>Full Name <span className='color-red'>*</span></Form.Label>
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Name"
                        name='fullName'
                        value={signupData.fullName}
                        onChange={onChangeHandler}
                    >
                    </input>
                    {
                        errors && errors.fullName &&
                        <span className='submit-errors text-danger'>{errors.fullName}</span>
                    }
                </Form.Group>

                <Form.Group className='formGroup' controlId="formBasicEmail" onKeyPress={handleKeyPress}>
                    <Form.Label>Email Address <span className='color-red'>*</span></Form.Label>
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Email Address"
                        name='email'
                        value={signupData.email}
                        onChange={onChangeHandler}
                    >
                    </input>
                    {
                        errors && errors.email &&
                        <span className='submit-errors text-danger'>{errors.email}</span>
                    }
                </Form.Group>

                <Form.Group className='formGroup' controlId="formBasicEmail" onKeyPress={handleKeyPress}>
                    <Form.Label>
                        Password <span className='color-red'>*</span>
                    </Form.Label>
                    <div className="position-relative">
                        <input
                            type={showPassword ? 'text' : "password"}
                            className="form-control"
                            placeholder="Enter Your Password"
                            name='password'
                            value={signupData.password}
                            onChange={onChangeHandler}
                            onCopy={(e) => {
                                e.preventDefault()
                                return false;
                            }}
                        >
                        </input>
                        {
                            errors && errors.password &&
                            <span className='submit-errors text-danger'>{errors.password}</span>
                        }
                    </div>
                </Form.Group>
                <Form.Group className='formGroup' controlId="formBasicEmail" onKeyPress={handleKeyPress}>
                    <Form.Label>
                        Verify Password <span className='color-red'>*</span>
                    </Form.Label>
                    <div className="position-relative">
                        <input
                            type={showVerifyPassword ? 'text' : "password"}
                            className="form-control"
                            placeholder="Re-Enter Password"
                            id="verifyPasswordInput"
                            name='verifyPassword'
                            value={signupData.verifyPassword}
                            onChange={onChangeHandler}
                            onCopy={(e) => {
                                e.preventDefault()
                                return false;
                            }}
                        >
                        </input>
                        {
                            signupData.verifyPassword ?
                                showVerifyPassword ?
                                    <BsEye className="pointer eye-icon" onClick={() => setShowVerifyPass(false)} /> :
                                    <BsEyeSlash className="pointer eye-icon" onClick={() => setShowVerifyPass(true)} />
                                : ''
                        }
                    </div>
                    {
                        errors && errors.verifyPassword &&
                        <span className='submit-errors text-danger'>{errors.verifyPassword}</span>
                    }
                </Form.Group>
                {key == '2' &&
                    <Form.Group className='formGroup' controlId="formBasicEmail">
                        <Form.Label>Date of Birth <span className='color-red'>*</span></Form.Label>
                        <div>
                            <input
                                type="text"
                                onFocus={(e) => { e.target.type = 'date' }}
                                className="inputDiv form-control d-block"
                                placeholder="Select DOB"
                                value={signupData.dob}
                                max={new Date().getFullYear()}
                                onChange={(e) => setSignupData({ ...signupData, dob: e.target.value })}
                                id="date"
                            />
                        </div>
                        {
                            errors && errors.dob &&
                            <span className='submit-errors text-danger'>{errors.dob}</span>
                        }
                    </Form.Group>
                }
                <div className="mb-3 pt-2">
                    <label className="right-label-checkbox text-start">I agree with the<Link to='/terms-and-conditions' target="_blank" className='ms-2 themeLink'> Terms & Conditions.</Link>
                        <input type="checkbox" checked={userConsent} onChange={() => setUserConsent(!userConsent)} />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className='theme-capcha'>
                    <ReCAPTCHA
                        sitekey={recaptchaSiteKey}
                        onChange={onChange}
                    />
                    {
                        errors && errors.captcha &&
                        <span className='submit-errors text-danger'>{errors.captcha}</span>
                    }
                    <button type="button" className='orange-main-button text-uppercase' onClick={signupHandler}>sign up</button>
                </div>
            </Form>
        </>
    )
    return (
        <>
            {loader && <FullPageLoader />}
            <div className='theme-form Login'>
                <div className='biiview-form'>
                    <Container fluid className='formContainer'>
                        <div className='wrapForm'>
                            <Tabs
                                activeKey={key}
                                onSelect={tabChangeHandler}
                            >
                                <Tab eventKey="1" title="User">
                                    {tabForm('1')}
                                </Tab>
                                <Tab eventKey="2" title="Publisher">
                                    {tabForm('2')}
                                </Tab>
                            </Tabs>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}
export default Signup