import React, { useState, useEffect } from 'react';
import './siteTabs.css';
// import { Row, Col } from 'react-bootstrap'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { beforeVideoAnalytics } from '../Videos/video.actions';
import ReactECharts from 'echarts-for-react'

const Reach = (props) => {

    const dispatch = useDispatch()

    const colors = ['#FD6F21'];

    const [options, setOptions] = useState({
        color: colors,
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            data: []
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [],
                type: 'line',
                color: colors[1],
                symbol: "circle"
            }
        ]
    })
    const [clickedStatOption, setClickedOption] = useState(1);
    const [analytics, setAnalytics] = useState({})
    const videosSelector = useSelector((state) => state.videos)

    useEffect(() => {
        if (videosSelector.videoAnalyticsAuth) {
            let data = videosSelector?.videoAnalyticsData
            if (data?.videoAnalytics && data?.statType == '1') {
                console.log('data?.videoAnalytics', data?.videoAnalytics)
                setAnalytics(data?.videoAnalytics)
            }

            props.setLoader(false)
            dispatch(beforeVideoAnalytics())
        }
    }, [videosSelector.videoAnalyticsAuth])

    useEffect(() => {
        if (clickedStatOption && analytics) {
            props.setLoader(false)
            let optionsData = { ...options }
            let { impressions, impressionsClickRate, views, uniqueViewers } = analytics
            let xAxisDates = []
            let yAxisValues = []

            let key = clickedStatOption === 1 ? impressions : clickedStatOption === 2 ? impressionsClickRate : clickedStatOption === 3 ? views : clickedStatOption === 4 ? uniqueViewers : null
            let dateIntervals = []
            let seriesData = []
            if (key)
                if (key.data?.length) {
                    let indexes = []
                    key.data.map((stat) => {

                        let statDate = moment(new Date(stat.date)).format('DD-MMM-YY')
                        xAxisDates.push(statDate)
                        yAxisValues.push(stat.total);

                        dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate, 1, yAxisValues.length)
                        seriesData = new Array(dateIntervals.length).fill(0)
                        dateIntervals.map((dt, indx) => {
                            if (dt === statDate)
                                indexes.push(indx)
                        })
                    })
                    indexes.map((i, ind) => {
                        seriesData[i] = yAxisValues[ind]
                    })
                }
                else {
                    dateIntervals = props.getDateIntervals(props.date.startDate, props.date.endDate)
                    seriesData = new Array(dateIntervals.length).fill(0)
                }

            optionsData.xAxis = { ...optionsData.xAxis, data: dateIntervals }
            optionsData.series = [
                {
                    ...optionsData.series[0],
                    name: clickedStatOption === 1 ? "Impressions" : clickedStatOption === 2 ? "Impressions Click Rate" : clickedStatOption === 3 ? "Views" : clickedStatOption === 4 ? "Unique Viewers" : "",
                    data: seriesData
                }
            ]
            setOptions(optionsData)
        }
    }, [clickedStatOption, analytics])

    const statOptionsChangeHandler = (option) => {
        if (option !== clickedStatOption) {
            setClickedOption(option)
            props.setLoader(true)
        }
    }

    return (
        <>
            <div className='dashboardPage'>
                <div className='analyticContent'>
                    <div className='analyticGraph'>
                        <div className='analyticsBar'>
                            <div className={`analyticsBarBox pointer ${clickedStatOption === 1 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(1)}>
                                <span>Impressions</span>
                                <h4>{analytics.impressions ? analytics.impressions?.total : 0}</h4>
                            </div>
                            <div className={`analyticsBarBox pointer ${clickedStatOption === 2 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(2)}>
                                <span>Impressions click-through rate</span>
                                <h4>{analytics.impressionsClickRate ? analytics.impressionsClickRate?.total : 0}</h4>
                            </div>
                            <div className={`analyticsBarBox pointer ${clickedStatOption === 3 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(3)}>
                                <span>Views</span>
                                <h4>{analytics.views ? analytics.views?.total : 0}</h4>
                            </div>
                            <div className={`analyticsBarBox pointer ${clickedStatOption === 4 ? 'selectedTabColor' : ''}`} onClick={() => statOptionsChangeHandler(4)}>
                                <span>Unique Viewers</span>
                                <h4>{analytics.uniqueViewers ? analytics.uniqueViewers?.total : 0}</h4>
                            </div>
                        </div>
                        <ReactECharts option={options} />
                    </div>
                    {/* <Row className='tabBox'>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>How views find this video</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2 op6'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Impressions and how they led to watch time</h4>
                                        <p className='md-P mb-0'>Data available Nov 18 – Dec 15, 2022 (28 days)</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2 op6'>Not enough impressions data to show this report.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>External sites or apps</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2 op6'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>BiiView search terms</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2 op6'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Content suggesting this video</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                    </div>
                                    <p className='md-P mb-lg-3 mb-2 op6'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                </div>
            </div>
        </>
    )
}
export default Reach